import ReactConfetti from "react-confetti";
import { Link } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";
import { useEffect, useState } from "react";

import AsoebiLayout from "@/layout/AsoebiLayout";

export default function QuestionnaireAnswered() {
    const { width, height } = useWindowSize();
    const [showConfetti, setShowConfetti] = useState(true);

    function stopConfetti() {
        setTimeout(() => setShowConfetti(false), 9000);
    }

    useEffect(() => {
        if (showConfetti) {
            stopConfetti();
        }
    }, []);

    return (
        <AsoebiLayout>
            {showConfetti && <ReactConfetti width={width} height={height} />}
            <section className="questionnaire__completed mx-auto vh-55 d-flex flex-column my-5">
                <div className="text-center">
                    <h1 className="text-center mb-4">Thanks for your order 🎉</h1>
                    <p>Payment receipt sent to your 📧 email</p>
                </div>
                <p>
                    <Link
                        to="/asoebi-tracker"
                        className="font-weight-bold d-flex mx-auto justify-content-center"
                    >← Create new Asoebi Order Form</Link>
                </p>
            </section>
        </AsoebiLayout>
    );
}
