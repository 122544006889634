export default function AddUserIcon() {
    return (
        <svg
            className="bi bi-person-plus-fill"
            fill="#545565"
            height="24"
            viewBox="0 0 16 16"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            <path
                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                fillRule="evenodd"
            />
        </svg>
    );
}
