import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";

import SiteModal from "@/components/modals/SiteModal";
import Input from "@/components/form/Input";
import checklistFormContent from "@/json/form/checklistItem.json";
import Textarea from "@/components/form/Textarea";
import Button from "@/components/Button";
import SelectDate from "@/components/form/SelectDate";
import { addChecklist, updateAudit } from "@/axios/post-request";
import { useChecklist } from "@/hooks/useChecklist";
import useUser from "@/hooks/useUser";
import useMyEvents from "@/hooks/useMyEvents";

type taskType = {
    isDone: boolean;
    due?: Date | null;
    name: string;
    note?: string | null;
    ceremony: { id: string; name: string };
};

interface Props {
    show: boolean;
    onHide: () => void;
}

export default function AddChecklistItemModal({ show, onHide }: Props) {
    const queryClient = useQueryClient();
    const { data, selectedEventId } = useUser();
    const { activeEvent, status } = useMyEvents();
    const { todo, setTodo } = useChecklist();
    const [task, setTask] = useState<taskType>({
        name: "",
        note: "",
        due: null,
        ceremony: { id: "", name: "" },
        isDone: false,
    });
    const [loading, setIsLoading] = useState(false);

    function inputHandler(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setTask({ ...task, [e.target.name]: e.target.value });
    }

    function selectDateHandler(due: Date | null) {
        setTask({ ...task, due });
    }

    function onSelectChange(e: ChangeEvent<HTMLSelectElement>) {
        const selectedCeremony = activeEvent && activeEvent[0]?.ceremonies.filter((_ceremony) => _ceremony.id === e.target.value);
        const _name = selectedCeremony && selectedCeremony[0].name ? selectedCeremony[0].name : "Main Wedding";
        setTask({ ...task, ceremony: { id: e.target.value, name: _name } });
    }

    async function onSubmitHandler(e: ChangeEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!task.name) {
            return toast.error("Checklist name is required");
        }
        if (!task.ceremony.id) {
            return toast.error("Please select a ceremony");
        }
        const id = uuidv4();

        const todoWithSimilarName = todo.filter((todoItem) => ((todoItem.name.toLowerCase() === task.name.toLowerCase()) &&
        (todoItem.ceremony.id === task.ceremony.id)));

        if (todoWithSimilarName.length > 0) {
            return toast.error("A checklist with similar name exist");
        }

        setIsLoading(true);

        await addChecklist(
            {
                name: task.name,
                note: task?.note ? task?.note : null,
                due: task?.due ? task?.due : null,
                ceremony: task.ceremony,
                isDone: false,
                id,
            },
            selectedEventId,
            task.ceremony.id,
        )
            .then(() => {
                setTodo([
                    ...todo,
                    {
                        id,
                        name: task.name,
                        note: task?.note ? task?.note : null,
                        due: task?.due ? task?.due : null,
                        ceremony: {
                            id: task.ceremony.id,
                            name: task.ceremony.name,
                        },
                        isDone: false,
                    },
                ]);
                queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
                updateAudit(
                    [{
                        id: uuidv4(),
                        name: data?.result?.name as string,
                        user_id: data?.result?.id as string,
                        email: data?.result?.email as string,
                        type: "checklist",
                        action: "add_checklist",
                        changes: {
                            to: {
                                id,
                                name: task.name,
                                note: task?.note ? task?.note : null,
                                due: task?.due ? task?.due : null,
                                ceremony: task.ceremony,
                                isDone: false,
                            },
                        },
                        title: task.name,
                        timestamp: new Date(),
                        metadata: {
                            ceremony: task.ceremony.name,
                        },
                    }],
                    selectedEventId,
                );
                onHide();
            })
            .catch(() => {
                toast.error("unable to add checklist");
            });
        setIsLoading(false);
    }

    const note = task.note as string;

    return (
        <SiteModal title="Add Checklist Item" show={show} onHide={onHide}>
            <section>
                <p>Add task for your event</p>
                <form className="checklist_item_modal  my-3" onSubmit={onSubmitHandler}>
                    <Input input={checklistFormContent.input} value={task?.name} maxLength={50} onChange={inputHandler} />
                    <div className="d-flex form_group">
                        <div className="col-lg-8 col-12 px-0">
                            <SelectDate label="Deadline" onClick={selectDateHandler} />
                        </div>
                        <div className="col-lg-4 col-12 px-0">
                            {status === "error" ? (
                                <p>unable to fetch ceremonies</p>
                            ) : status === "loading" ? (
                                <p>fetching ceremonies...</p>
                            ) : (
                                <div className="form_element select">
                                    <label htmlFor="tag">Ceremony</label>
                                    <select name="tag" className="form-control" onChange={onSelectChange}>
                                        <option>Select Ceremony</option>
                                        {activeEvent &&
                                            activeEvent[0]?.ceremonies.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name ? item.name : "Main Wedding"}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            )}
                        </div>
                    </div>
                    <Textarea input={checklistFormContent.textarea} value={note} onChange={inputHandler} />
                    <div className="d-flex justify-content-end mt-2 align-items-end">
                        <Button text="Save" className="bg_red text-white font-weight-bold" type="submit" isLoading={loading} />
                    </div>
                </form>
            </section>
        </SiteModal>
    );
}
