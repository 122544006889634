export default function generateRandomColor() {
    const randomColor = Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0");
    return `#${randomColor}`;
}

export function generateRandomLinearGradientColor() {
    const color1 = generateRandomColor();
    const color2 = generateRandomColor();

    return `linear-gradient(90deg, ${color1} 0%, ${color2} 100%)`;
}
