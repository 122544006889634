import { FormProvider, useForm } from "react-hook-form";

import Button from "@/components/Button";
import CustomInput from "@/components/form/CustomInput";
import AsoebiLayout from "@/layout/AsoebiLayout";

export default function DashboardAuth() {
    const methods = useForm();
    return (
        <AsoebiLayout>
            <section className="vh-100 ">
                <FormProvider {...methods}>
                    <form className="col-6 my-5 p-4 mx-auto bg-white shadow rounded">
                        <h2 className="mt-5 text-center">Admin Login</h2>
                        <CustomInput className="my-4" input={{ name: "email", placeholder: "Email address", type: "email" }} />
                        <CustomInput className="my-4" input={{ name: "password", placeholder: "Password", type: "password" }} />
                        <Button text="Login" className="btn btn-primary px-4 py-2 font-weight-bold text-white mx-auto my-5" />
                    </form>
                </FormProvider>
            </section>
        </AsoebiLayout>
    );
}
