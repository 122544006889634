/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Path, Group } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function DoubleChairElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            {...item}
            name="floor_plan_element"
            scaleX={3}
            scaleY={3}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Path
                data="M0 2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2C14 2 13.5523 2 13 2H1C0.447715 2 0 2 0 2ZM14 15H0H14ZM0 15V1V15ZM14 1V15V1Z"
                fill="#E845BA"
                mask="url(#path-1-outside-1_11427_19941)"
            />
            <Path
                data="M1 15L13 15C13.5523 15 14 14.5523 14 14L14 2C14 1.44772 13.5523 1 13 1L0.999999 1C0.447715 1 -5.92389e-07 1.44772 -5.68248e-07 2L-4.37114e-08 14C-1.95703e-08 14.5523 0.447716 15 1 15Z"
                fill="#D9D9D9"
            />
            <Path data="M14 2C14 1.44772 14.4477 1 15 1H27C27.5523 1 28 1.44772 28 2V14C28 14.5523 27.5523 15 27 15H15C14.4477 15 14 14.5523 14 14V2Z" />
            <Path
                data="M14 2C14 0.895431 14.8954 0 16 0H26C27.1046 0 28 0.895431 28 2C28 2 27.5523 2 27 2H15C14.4477 2 14 2 14 2ZM28 15H14H28ZM14 15V1V15ZM28 1V15V1Z"
                fill="#E845BA"
                mask="url(#path-5-outside-2_11427_19941)"
            />
            <Path
                data="M15 15L27 15C27.5523 15 28 14.5523 28 14L28 2C28 1.44772 27.5523 1 27 1L15 1C14.4477 1 14 1.44772 14 2L14 14C14 14.5523 14.4477 15 15 15Z"
                fill="#D9D9D9"
            />
        </Group>
    );
}
