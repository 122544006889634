/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, Fragment, PropsWithChildren, useEffect } from "react";
import { Html } from "react-konva-utils";
import { Dropdown } from "react-bootstrap";
import ThreeDotsIcon from "@/assets/icon/ThreeDotsIcon";
import SiteDropdown from "@/components/Dropdown";
import useFloorPlan from "@/hooks/useFloorPlan";
import { FloorPlanObjectListType } from "@/data/types";
import { Transformer } from "react-konva";
interface Props {
	trRef: React.MutableRefObject<undefined | any>;
	elementRef: React.MutableRefObject<undefined | any>;
	isSelected: boolean;
	isTransformable?: boolean;
	showElementDropdown?: boolean;
	item: {
		x: number;
		y: number;
		id: string;
		width: number;
		type: FloorPlanObjectListType;
		height: number;
		fill: string;
	};
}

export default function DraggableElement({ children, item, isTransformable, trRef, elementRef, isSelected,
    showElementDropdown = true }: PropsWithChildren<Props>) {
    const { deleteFloorPlanElementHandler, activeFloorPlanElementId, floorPlanStage, activeFloorPlanStageId, duplicateFloorPlanElementHandler } =
        useFloorPlan();
    const currentFloorPlanIndex = floorPlanStage.findIndex((item) => item.id === activeFloorPlanStageId);

    function deleteElementHandler() {
        if (activeFloorPlanElementId) {
            deleteFloorPlanElementHandler(currentFloorPlanIndex, activeFloorPlanElementId);
        }
    }

    function duplicateElementHandler() {
        if (activeFloorPlanElementId) {
            duplicateFloorPlanElementHandler(currentFloorPlanIndex, activeFloorPlanElementId);
        }
    }

    const styles: CSSProperties = { position: "relative" };

    const dropdownStyles = {
        left: item.x + item.width,
        top: item.y,
    };

    useEffect(() => {
        if (isSelected && trRef) {
            // we need to attach transformer manually
            trRef?.current?.nodes([elementRef.current]);
            trRef?.current?.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <Fragment>
            {children}
            {showElementDropdown && <Html>
                <div style={styles}>
                    {isSelected && (
                        <SiteDropdown styles={dropdownStyles} className="element_menu custom" title={<ThreeDotsIcon />}>
                            <Dropdown.Item onClick={duplicateElementHandler}>Duplicate</Dropdown.Item>
                            <Dropdown.Item onClick={deleteElementHandler}>Delete</Dropdown.Item>
                        </SiteDropdown>
                    )}
                </div>
            </Html>}
            {isSelected && isTransformable && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Fragment>
    );
}
