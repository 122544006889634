/* eslint-disable no-mixed-spaces-and-tabs */
import { ChangeEvent, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

import SiteModal from "@/components/modals/SiteModal";
import Button from "../Button";
import Input from "../form/Input";
import { renewCollaboratorAccountRequest, verifyPayment } from "@/axios/post-request";
import useUser from "@/hooks/useUser";
import { CollaboratorPaymentPlan, CollaboratorPlanStatus, PaymentType, EventType } from "@/types";
import payStackConfig from "@/payStackConfig";
import { initializePaymentType, paystackReferenceType } from "@/data/types";
import { getUID } from "@/axios/config";
import { COLLABORATOR_PRICING, PAYSTACK_CURRENCY_CONSTANT } from "@/utils/constant";
import { validateEmail } from "@/helper";

interface Props {
	title: string;
	show: boolean;
	onHide: () => void;
	collaborators: EventType["collaborators"];
}

export default function CollaboratorListModal({ title, show, onHide, collaborators }: Props) {
    const [renewUserAccount, setRenewUserAccount] = useState({ email: "", id: "" });
    const [duration, setDuration] = useState(1);
    const [loading, setLoading] = useState(false);
    const { selectedEventId, data } = useUser();
    const queryClient = useQueryClient();

    const amount = duration * COLLABORATOR_PRICING; // each month for a collaborators cost 1,000
    const email = data?.result?.email as string;

    const config = payStackConfig(email, amount);

    const initializePayment = usePaystackPayment(config) as initializePaymentType;

    /*  eslint-disable-next-line @typescript-eslint/no-explicit-any */
    function proceedToRenewCollaboratorAccount(collaborator: { email: string; id: string } | any) {
        setRenewUserAccount({ email: collaborator.email, id: collaborator.id });
    }

    function updateDuration(event: ChangeEvent<HTMLInputElement>) {
        setDuration(Number(event.target.value));
    }

    const modalTitle =
		renewUserAccount.id || renewUserAccount.email
		    ? `Renew ${renewUserAccount.email.toLowerCase()} collaborator's account`
		    : `${title}'s Collaborators`;
    const renewPricing = duration * COLLABORATOR_PRICING;

    // when renewing for an expired collaborator, the number of event is 1
    const actual_collaborator_renewal_amount = 1 * Number(duration) * COLLABORATOR_PRICING;

    function renewCollaboratorAccount(event: ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!renewUserAccount.email) {
            return toast.error("Email is required");
        }
        if (!validateEmail(renewUserAccount.email)) {
            return toast.error("Valid email is required");
        }
        setLoading(true);
        initializePayment((reference) => onSuccess(reference), onClose);
    }

    async function onSuccess(reference: paystackReferenceType) {
        const uid = getUID();
        const verifyPaymentRequest = await verifyPayment({
            reference_id: reference.reference,
            event_id: selectedEventId,
            user_id: uid,
            type: PaymentType.COLLABORATOR,
            amount: amount * PAYSTACK_CURRENCY_CONSTANT,
            actual_amount: actual_collaborator_renewal_amount * PAYSTACK_CURRENCY_CONSTANT,
        });
        if (verifyPaymentRequest.status === 500 || !verifyPaymentRequest.data.status) {
            return toast.error(verifyPaymentRequest.data.message);
        }

        toast.success("🎉 subscription payment successfully");
        return await renewCollaboratorAccountRequest(duration, renewUserAccount.email, selectedEventId)
            .then(() => {
                toast.success("account updated");
                onHide();
                setLoading(false);
                queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
            })
            .catch(() => {
                toast.error("Oops an error occured");
                onHide();
                setLoading(false);
            });
    }

    function onClose() {
        toast.error("Unable to make payment");
        setLoading(true);
        return onHide();
    }

    return (
        <SiteModal title={modalTitle} show={show} onHide={onHide} className="collaborators_list">
            <>
                {!(renewUserAccount.id || renewUserAccount.email) ? (
                    <ul className="pl-3 collaborators_list_modal">
                        {collaborators.map((collaborator, index) => (
                            <li className="my-3" key={index}>
								Email -<span className="mx-1">{collaborator.email}</span>, Role -<span className="mx-1">{collaborator.role}</span>
                                <span className={`payment_status ml-1 ${collaborator?.plan_status} ${collaborator?.payment_plan} `}>
                                    {collaborator?.plan_status === CollaboratorPlanStatus.ACTIVE
                                        ? collaborator?.payment_plan
                                            ? collaborator?.payment_plan
                                            : CollaboratorPaymentPlan.FREE
                                        : collaborator?.plan_status}
                                </span>
                                {collaborator?.plan_status === CollaboratorPlanStatus.EXPIRED && (
                                    <button
                                        className="btn bg-red renew"
                                        type="button"
                                        onClick={() => proceedToRenewCollaboratorAccount(collaborator)}>
										Renew
                                    </button>
                                )}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div>
                        <h6>You're about to renew this collaborator's account, so as to regain access to this event</h6>
                        <form className="mt-3 mb-3" onSubmit={renewCollaboratorAccount}>
                            <Input
                                value={duration}
                                className="col-12 my-4 px-0"
                                min={1}
                                input={{
                                    label: "Duration (month)",
                                    placeholder: "4",
                                    name: "duration",
                                }}
                                required
                                onChange={updateDuration}
                            />
                            <Input
                                value={renewPricing.toLocaleString("en-US")}
                                input={{ label: "Total Sum (₦)", name: "pricing", placeholder: "Total Sum (₦)" }}
                                readOnly
                            />
                            <Button className="bg_red mt-5 text-white mx-auto font-weight-bold py-2" type="submit" text="Pay" isLoading={loading} />
                        </form>
                    </div>
                )}
            </>
        </SiteModal>
    );
}
