import { PropsWithChildren } from "react";
import ListFilterIcon from "@/assets/icon/ListFilterIcon";
import SiteDropdown from "@/components/Dropdown";
import "@/styles/filter_dropdown.scss";

interface Props {
	title: string;
	text?: string;
	disabled?: boolean;
}

export default function FilterDropdown({ children, title, text = "Filter", disabled = false }: PropsWithChildren<Props>) {
    return (
        <SiteDropdown
            className="custom filter_dropdown"
            title={
                <>
                    <ListFilterIcon />
                    <div className="dropdown_group">
                        <span className="text">{text}</span>
                        <span className="line">|</span> {title}
                    </div>
                </>
            }
            disabled={disabled}>
            {children}
        </SiteDropdown>
    );
}
