import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { FloorPlanObjectList, type FloorPlanObjectListType } from "@/data/types";
import { FloorPlanType, stageType } from "@/types";

type useUIType = {
    sidebar: boolean;
    toggleSidebar: () => void;
    activeFloorPlanElementId: string | null;
    selectActiveFloorPlanElementId: (value: string | null) => void;
    activeFloorPlanStageId: string | null;
    updateActiveFloorPlanStageId: (value: string | null) => void;
    floorPlanStage: FloorPlanType;
    setFloorPlanStage: (value: FloorPlanType | []) => void;
    updateFloorPlanHandler: (value: ChangeEvent<HTMLInputElement>, index: number) => void;
    addMoreFloorPlanHandler: () => void;
    addDefaultFloorPlanHandler: (name: string, id: string) => void;
    updateFloorPlanStageHandler: (index: number, stageIndex: number, value?: { x: number; y: number; height: number; width: number }) => void;
    addFloorPlanStageObjectHandler: (
        floorPlanIndex: number,
        elementType: FloorPlanObjectListType,
        id: string,
        title?: string,
        image?: string,
    ) => void;
    updateFloorPlanElementHandler: (floorPlanIndex: number, id: string, image: string) => void;
    transformFloorPlanElement: (value: stageType, index: number) => void;
    deleteFloorPlanElementHandler: (floorPlanIndex: number, stageId: string) => void;
    duplicateFloorPlanElementHandler: (floorPlanIndex: number, stageId: string) => void;
    updateFloorPlanElementColorHandler: (floorPlanIndex: number, stageIndex: number, fill: string) => void;
    deleteFloorPlanHandler: (floorPlanId: string) => void;
    updateFloorPlanName: (floorPlanName: string, floorPlanStageIndex: number) => void;
    addFloorPlanDimensionHandler: (floorPlanIndex: number, elementType: FloorPlanObjectListType) => void;
    addFloorPlanTextHandler: (floorPlanIndex: number) => void;
    updateFloorPlanTextHandler: (floorPlanIndex: number, textId: string, text: string) => void;
    selectActiveObjectHandler: (floorPlanIndex: number, objectType: FloorPlanObjectListType | null) => void;
};

const useFloorPlan = create<useUIType>()(
    persist(
        (set) => ({
            sidebar: true,
            toggleSidebar: () => set(({ sidebar }) => ({ sidebar: !sidebar })),
            activeFloorPlanElementId: null,
            selectActiveFloorPlanElementId: (selectedElementId: string | null) => set(() => ({ activeFloorPlanElementId: selectedElementId })),
            activeFloorPlanStageId: null,
            updateActiveFloorPlanStageId: (selectedStageId: string | null) => set(() => ({ activeFloorPlanStageId: selectedStageId })),
            floorPlanStage: [],
            setFloorPlanStage: (value: FloorPlanType | []) => set(() => ({ floorPlanStage: value })),
            updateFloorPlanHandler: (event: ChangeEvent<HTMLInputElement>, index: number) =>
                set(({ floorPlanStage }) => {
                    floorPlanStage[index] = {
                        ...floorPlanStage[index],
                        [event.target.name]: event.target.value,
                        stage: [],
                    };
                    return {
                        floorPlanStage,
                    };
                }),
            addMoreFloorPlanHandler: () =>
                set(({ floorPlanStage }) => {
                    return {
                        floorPlanStage: [
                            ...floorPlanStage,
                            {
                                floor_plan_name: "",
                                id: uuidv4(),
                                floor_plan_icon: "",
                                stage: [],
                            },
                        ],
                    };
                }),
            addDefaultFloorPlanHandler: (name: string, id: string) =>
                set(({ floorPlanStage, sidebar }) => {
                    const stageWidth = window.innerWidth;
                    const stageHeight = window.innerHeight;
                    const dimensionElementSizes = {
                        height: 400,
                        width: 1000,
                    };
                    const divider = sidebar ? 10 : 5;
                    const rectX = (stageWidth - dimensionElementSizes.width) / divider;
                    const rectY = (stageHeight - dimensionElementSizes.height) / 10;
                    const floorPlanObjectId = `${FloorPlanObjectList.regular__dimensions}__${id}`;

                    return {
                        activeFloorPlanStageId: id,
                        floorPlanStage: [
                            ...floorPlanStage,
                            {
                                floor_plan_name: name ? name : "New Floor Plan",
                                id: id,
                                floor_plan_icon: "",
                                activeFloorPlanElementId: floorPlanObjectId,
                                stage: [
                                    {
                                        ...dimensionElementSizes,
                                        fill: "white",
                                        id: floorPlanObjectId,
                                        x: rectX,
                                        y: rectY,
                                        type: FloorPlanObjectList.regular__dimensions,
                                    },
                                ],
                            },
                        ],
                    };
                }),
            updateFloorPlanName: (floorPlanStageName: string, floorPlanStageIndex: number) =>
                set(({ floorPlanStage }) => {
                    floorPlanStage[floorPlanStageIndex] = {
                        ...floorPlanStage[floorPlanStageIndex],
                        floor_plan_name: floorPlanStageName,
                    };
                    return {
                        floorPlanStage,
                    };
                }),
            updateFloorPlanStageHandler: (index: number, stageIndex: number, value?: { x: number; y: number; height: number; width: number }) =>
                set(({ floorPlanStage }) => {
                    floorPlanStage[index] = {
                        ...floorPlanStage[index],
                        stage: [...floorPlanStage[index].stage],
                    };
                    const randomNumber = Math.floor(Math.random() * 4) + 1;
                    floorPlanStage[index].stage[stageIndex] = {
                        ...floorPlanStage[index].stage[stageIndex],
                        x: value ? value.x : randomNumber * 100,
                        y: value ? value.y : randomNumber * 100,
                        fill: "#D9D9D9",
                        height: value ? value.height : 24,
                        width: value ? value.width : 24,
                    };
                    return {
                        floorPlanStage,
                    };
                }),
            addFloorPlanStageObjectHandler: (
                floorPlanIndex: number,
                elementType: FloorPlanObjectListType,
                id: string,
                title?: string,
                image?: string,
            ) => {
                const dimensionElementSizes = {
                    height: 500,
                    width: 1000,
                };
                const defaultSizes = {
                    height: 50,
                    width: 50,
                };
                return set(({ floorPlanStage }) => {
                    const floorPlanObjectWhiteFill = elementType.includes("dimensions") || elementType.includes("lines");
                    const floorPlanObjectFill = floorPlanObjectWhiteFill ? "#fff" : "#D9D9D9";
                    const floorPlanObjectId = `${elementType}__${id}`;
                    const floorPlanObjectSizes = floorPlanObjectWhiteFill ? dimensionElementSizes : defaultSizes;
                    const imageObj = image ? { image } : {};
                    if (floorPlanStage[floorPlanIndex].stage) {
                        const stage = floorPlanStage[floorPlanIndex].stage.filter((item) => item.type === FloorPlanObjectList.regular__dimensions);

                        const stageText = title ? { text: title } : {};
                        floorPlanStage[floorPlanIndex].stage = [
                            ...floorPlanStage[floorPlanIndex].stage,
                            {
                                ...floorPlanObjectSizes,
                                ...stageText,
                                ...imageObj,
                                x: stage[0].x + stage[0].width / 2 + floorPlanStage[floorPlanIndex].stage.length * 5,
                                y: stage[0].y + stage[0].height / 2.5 + floorPlanStage[floorPlanIndex].stage.length * 5,
                                fill: floorPlanObjectFill,
                                id: floorPlanObjectId,
                                type: elementType,
                            },
                        ];
                    } else {
                        floorPlanStage[floorPlanIndex].stage = [
                            {
                                ...floorPlanObjectSizes,
                                ...imageObj,
                                x: window.innerWidth / 3,
                                y: window.innerHeight / 4,
                                fill: floorPlanObjectFill,
                                id: floorPlanObjectId,
                                type: elementType,
                            },
                        ];
                    }
                    return {
                        floorPlanStage,
                        activeFloorPlanElementId: floorPlanObjectId,
                    };
                });
            },
            updateFloorPlanElementHandler: (floorPlanIndex: number, id: string, image: string) => {
                const defaultSizes = {
                    height: 50,
                    width: 50,
                };
                return set(({ floorPlanStage }) => {
                    const floorPlanObjectId = `${FloorPlanObjectList.image_element}__${id}`;
                    const imageObj = image ? { image } : {};
                    if (floorPlanStage[floorPlanIndex].stage) {
                        const stage = floorPlanStage[floorPlanIndex].stage.filter((item) => item.type === FloorPlanObjectList.regular__dimensions);
                        const selectedStageIndex = floorPlanStage[floorPlanIndex].stage.findIndex((item) => item.id === id);

                        if (selectedStageIndex !== -1) {
                            floorPlanStage[floorPlanIndex].stage[selectedStageIndex] = {
                                ...floorPlanStage[floorPlanIndex].stage[selectedStageIndex],
                                ...defaultSizes,
                                ...imageObj,
                                x: stage[0].x + stage[0].width / 2 + floorPlanStage[floorPlanIndex].stage.length * 5,
                                y: stage[0].y + stage[0].height / 2.5 + floorPlanStage[floorPlanIndex].stage.length * 5,
                                fill: "#D9D9D9",
                                id: floorPlanObjectId,
                                type: FloorPlanObjectList.image_element,
                            };
                        }
                    }
                    return {
                        floorPlanStage,
                    };
                });
            },
            addFloorPlanDimensionHandler: (floorPlanIndex: number, elementType: FloorPlanObjectListType) =>
                set(({ floorPlanStage }) => {
                    const dimensionElementSizes = {
                        height: 600,
                        width: 1000,
                    };
                    const stageWidth = window.innerWidth;
                    const stageHeight = window.innerHeight;
                    const rectX = (stageWidth - dimensionElementSizes.width) / 4;
                    const rectY = (stageHeight - dimensionElementSizes.height) / 10;
                    const stageDimensions = floorPlanStage[floorPlanIndex].stage.filter((item) =>
                        item.type.includes(FloorPlanObjectList.regular__dimensions),
                    );

                    if (stageDimensions.length > 0) {
                        const stageDimensionIndex = floorPlanStage[floorPlanIndex].stage.findIndex((item) => item.id === stageDimensions[0].id);
                        floorPlanStage[floorPlanIndex].stage[stageDimensionIndex] = {
                            ...stageDimensions[0],
                            ...dimensionElementSizes,
                            x: rectX,
                            y: rectY,
                            type: elementType,
                        };
                    } else {
                        const floorPlanObjectId = `${elementType}__${uuidv4()}`;
                        floorPlanStage[floorPlanIndex].stage = [
                            ...floorPlanStage[floorPlanIndex].stage,
                            {
                                ...dimensionElementSizes,
                                fill: "white",
                                id: floorPlanObjectId,
                                x: rectX,
                                y: rectY,
                                type: elementType,
                            },
                        ];
                    }
                    return { floorPlanStage };
                }),
            addFloorPlanTextHandler: (floorPlanIndex: number) =>
                set(({ floorPlanStage }) => {
                    const floorPlanTextId = `${FloorPlanObjectList.stage__text}__${uuidv4()}`;

                    floorPlanStage[floorPlanIndex].stage = [
                        ...floorPlanStage[floorPlanIndex].stage,
                        {
                            fill: "black",
                            id: floorPlanTextId,
                            height: 20,
                            width: 50,
                            x: window.innerWidth / 3,
                            y: window.innerHeight / 4,
                            type: FloorPlanObjectList.stage__text,
                            text: "Text",
                        },
                    ];

                    return { floorPlanStage, activeFloorPlanElementId: floorPlanTextId };
                }),
            updateFloorPlanTextHandler: (floorPlanIndex: number, textId: string, text: string) =>
                set(({ floorPlanStage }) => {
                    const stageTextIndex = floorPlanStage[floorPlanIndex].stage.findIndex((item) => item?.id === textId);
                    floorPlanStage[floorPlanIndex].stage[stageTextIndex] = {
                        ...floorPlanStage[floorPlanIndex].stage[stageTextIndex],
                        fill: "black",
                        text,
                    };

                    return { floorPlanStage };
                }),
            updateFloorPlanElementColorHandler: (index: number, stageIndex: number, fill: string) =>
                set(({ floorPlanStage }) => {
                    floorPlanStage[index] = {
                        ...floorPlanStage[index],
                        stage: [...floorPlanStage[index].stage],
                    };
                    floorPlanStage[index].stage[stageIndex] = {
                        ...floorPlanStage[index].stage[stageIndex],
                        fill,
                    };
                    return {
                        floorPlanStage,
                    };
                }),
            duplicateFloorPlanElementHandler: (index: number, stageId: string) =>
                set(({ floorPlanStage }) => {
                    const selectedStageElement = floorPlanStage[index].stage.filter((stageItem) => stageItem?.id === stageId);
                    floorPlanStage[index] = {
                        ...floorPlanStage[index],
                        stage: [
                            ...floorPlanStage[index].stage,
                            {
                                ...selectedStageElement[0],
                                id: `${selectedStageElement[0].type}__${uuidv4()}`,
                                x: selectedStageElement[0].x + 10,
                                y: selectedStageElement[0].y + 10,
                            },
                        ],
                    };
                    return {
                        floorPlanStage,
                    };
                }),
            selectActiveObjectHandler: (floorPlanIndex: number, objectType: FloorPlanObjectListType | null) =>
                set(({ floorPlanStage }) => {
                    if (floorPlanStage[floorPlanIndex]?.active_object_type) floorPlanStage[floorPlanIndex].active_object_type = objectType;

                    return { floorPlanStage };
                }),
            deleteFloorPlanElementHandler: (index: number, stageId: string) =>
                set(({ floorPlanStage }) => {
                    const updatedStage = floorPlanStage[index].stage.filter((stageItem) => stageItem?.id !== stageId);
                    floorPlanStage[index] = {
                        ...floorPlanStage[index],
                        stage: updatedStage,
                    };
                    return {
                        floorPlanStage,
                    };
                }),
            deleteFloorPlanHandler: (floorPlanId: string) =>
                set(({ floorPlanStage }) => {
                    const updatedFloorPlan = floorPlanStage.filter((item) => item.id !== floorPlanId);
                    return {
                        floorPlanStage: updatedFloorPlan,
                    };
                }),
            transformFloorPlanElement: (value: stageType, index: number) =>
                set(({ floorPlanStage }) => {
                    floorPlanStage[index].stage = value;
                    return { floorPlanStage };
                }),
        }),
        { name: "floor_plan", storage: createJSONStorage(() => localStorage) },
    ),
);

export default useFloorPlan;
