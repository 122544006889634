/* eslint-disable @typescript-eslint/no-explicit-any */
import { Circle, Group, Path, Rect } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function ShapesElement({ refValue, onSelect, item, onDragStart, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            {...item}
            name="floor_plan_element"
            fill={item.fill}
            scaleX={3}
            scaleY={3}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Path stroke={item.fill} data="M8.3 10a.7.7 0 0 1-.626-1.079L11.4 3a.7.7 0 0 1 1.198-.043L16.3 8.9a.7.7 0 0 1-.572 1.1Z" />
            <Rect stroke={item.fill} x={3} y={14} height={7} width={7} />
            <Circle stroke={item.fill} radius={3.5} height={7.5} width={7.5} />
        </Group>
    );
}
