/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Path, Group } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function TableOfEightElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            {...item}
            name="floor_plan_element"
            scaleX={4}
            scaleY={4}
            fill={item.fill}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Path
                fill={item.fill}
                data="M6.99993 4.04286C8.63308 4.04286 9.95701 5.36685 9.95701 7C9.95701 8.63315 8.63308 9.95714 6.99993 9.95714C5.36679 9.95714 4.04286 8.63315 4.04286 7C4.04286 5.36685 5.36679 4.04286 6.99993 4.04286Z"
            />
            <Path
                fill={item.fill}
                data="M6.39976 2L7.59975 2C7.82067 2 7.99976 1.82091 7.99976 1.59999L7.99976 0.399934C7.99976 0.179017 7.82067 -7.27973e-05 7.59975 -7.27876e-05L6.39976 -7.27352e-05C6.17885 -7.27255e-05 5.99976 0.179016 5.99976 0.399934L5.99976 1.59999C5.99976 1.82091 6.17885 2 6.39976 2Z"
            />
            <Path
                fill={item.fill}
                data="M6.40001 14L7.59999 14C7.82091 14 8 13.8209 8 13.6L8 12.3999C8 12.179 7.82091 11.9999 7.59999 11.9999L6.40001 11.9999C6.17909 11.9999 6 12.179 6 12.3999L6 13.6C6 13.8209 6.17909 14 6.40001 14Z"
            />
            <Path
                fill={item.fill}
                data="M10.9598 10.1115L10.1113 10.96C9.95506 11.1162 9.95505 11.3695 10.1113 11.5257L10.9598 12.3743C11.116 12.5305 11.3692 12.5305 11.5255 12.3743L12.374 11.5257C12.5302 11.3695 12.5302 11.1162 12.374 10.96L11.5255 10.1115C11.3693 9.95525 11.116 9.95525 10.9598 10.1115Z"
            />
            <Path
                fill={item.fill}
                data="M2.47472 1.62562L1.62616 2.47418C1.46995 2.63039 1.46995 2.88366 1.62616 3.03988L2.47466 3.88844C2.63087 4.04466 2.88413 4.04466 3.04035 3.88845L3.8889 3.03989C4.04512 2.88368 4.04512 2.63041 3.88891 2.47419L3.04041 1.62563C2.8842 1.46941 2.63093 1.46941 2.47472 1.62562Z"
            />
            <Path
                fill={item.fill}
                data="M12 6.39952V7.59958C12 7.8205 12.1791 7.99958 12.4 7.99958H13.6C13.8209 7.99958 14 7.8205 14 7.59958V6.39952C14 6.1786 13.8209 5.99951 13.6 5.99951H12.4C12.1791 5.99951 12 6.1786 12 6.39952Z"
            />
            <Path
                fill={item.fill}
                data="M0 6.39952V7.59958C0 7.8205 0.179089 7.99958 0.400007 7.99958H1.59999C1.82091 7.99958 2 7.8205 2 7.59958V6.39952C2 6.1786 1.82091 5.99951 1.59999 5.99951H0.400007C0.179089 5.99951 0 6.1786 0 6.39952Z"
            />
            <Path
                fill={item.fill}
                data="M10.111 3.03968L10.9595 3.88821C11.1157 4.04443 11.369 4.04442 11.5252 3.88821L12.3737 3.03962C12.5299 2.8834 12.5299 2.63013 12.3737 2.47392L11.5252 1.62539C11.369 1.46917 11.1157 1.46918 10.9595 1.6254L10.111 2.47398C9.95476 2.6302 9.95476 2.88347 10.111 3.03968Z"
            />
            <Path
                fill={item.fill}
                data="M1.62587 11.5255L2.4744 12.3741C2.63061 12.5303 2.88388 12.5303 3.04008 12.374L3.88861 11.5255C4.04482 11.3692 4.04482 11.116 3.8886 10.9598L3.04007 10.1112C2.88386 9.95501 2.63059 9.95502 2.47439 10.1112L1.62586 10.9598C1.46965 11.116 1.46965 11.3693 1.62587 11.5255Z"
            />
        </Group>
    );
}
