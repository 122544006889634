/* eslint-disable max-len */

export default function PencilIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_5455_303)">
                <path
                    d="M9.08499 2.91352L13.0856 6.91414L4.39843 15.6013L0.83155 15.9951C0.35405 16.0479 -0.0493872 15.6441 0.00373782 15.1666L0.400613 11.5973L9.08499 2.91352ZM15.56 2.31789L13.6816 0.439453C13.0956 -0.146484 12.1453 -0.146484 11.5594 0.439453L9.79218 2.20664L13.7928 6.20727L15.56 4.44008C16.1459 3.85383 16.1459 2.90383 15.56 2.31789Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_5455_303">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
