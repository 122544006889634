import { EVENT_PRICING_TEXT } from "@/utils/constant";
import { useState } from "react";

type planType = "basic" | "essential" | "premium";

interface Props {
	choosePricingPlanHandler: (plan: planType) => void;
	type: "event" | "collaborator" | "all";
}

export default function PricingTableView({ type, choosePricingPlanHandler }: Props) {
    const [hoverPlan, setHoverPlan] = useState("");
    const addCollaboratorCondition = type === "collaborator" || type === "all";
    const addEventCondition = type === "event" || type === "all";

    return (
        <div className="pricing_table">
            <h5>Start planning for free, then add a plan to unlock additional planning features</h5>
            <div className="pricing_table_view">
                <div className="d-flex pricing_table_header">
                    <div className="features">
                        <h6>Key Capabilities</h6>
                    </div>
                    <div onMouseOver={() => setHoverPlan("free")} className={hoverPlan === "free" ? "active free" : "free"}>
                        <h6>Free</h6>
                    </div>
                    {addCollaboratorCondition && (
                        <div onMouseOver={() => setHoverPlan("basic")} className={hoverPlan === "basic" ? "active" : ""}>
                            <h6>Basic</h6>
                            <p>{EVENT_PRICING_TEXT.BASIC} per collaborator per month</p>
                        </div>
                    )}
                    {addEventCondition && (
                        <>
                            <div onMouseOver={() => setHoverPlan("essential")} className={hoverPlan === "essential" ? "active" : ""}>
                                <h6>Essential</h6>
                                <p>{EVENT_PRICING_TEXT.ESSENTIAL} per event one-time payment</p>
                            </div>
                            <div onMouseOver={() => setHoverPlan("premium")} className={hoverPlan === "premium" ? "active" : ""}>
                                <h6>Premium</h6>
                                <p>{EVENT_PRICING_TEXT.PREMIUM} per event one-time payment</p>
                            </div>
                        </>
                    )}
                    <div onMouseOver={() => setHoverPlan("custom")} className={hoverPlan === "custom" ? "active" : ""}>
                        <h6>Custom</h6>
                        <p>[Contact Sales]</p>
                    </div>
                </div>
                <div className="pricing_table_body">
                    <div className="table_row">
                        <div className="feature">Budget Tracker</div>
                        <div className={hoverPlan === "free" ? "active free" : " free"} onMouseOver={() => setHoverPlan("free")}>
							✓
                        </div>
                        {addCollaboratorCondition && (
                            <div className={hoverPlan === "basic" ? "active" : ""} onMouseOver={() => setHoverPlan("basic")}>
								✓
                            </div>
                        )}
                        {addEventCondition && (
                            <>
                                <div className={hoverPlan === "essential" ? "active" : ""} onMouseOver={() => setHoverPlan("essential")}>
									✓
                                </div>
                                <div className={hoverPlan === "premium" ? "active" : ""} onMouseOver={() => setHoverPlan("premium")}>
									✓
                                </div>
                            </>
                        )}
                        <div className={hoverPlan === "custom" ? "active" : ""} onMouseOver={() => setHoverPlan("custom")}>
							✓
                        </div>
                    </div>
                    <div className="table_row">
                        <div className="feature">Checklist</div>
                        <div className={hoverPlan === "free" ? "active free" : "free"} onMouseOver={() => setHoverPlan("free")}>
							✓
                        </div>
                        {addCollaboratorCondition && (
                            <div className={hoverPlan === "basic" ? "active" : ""} onMouseOver={() => setHoverPlan("basic")}>
								✓
                            </div>
                        )}
                        {addEventCondition && (
                            <>
                                <div className={hoverPlan === "essential" ? "active" : ""} onMouseOver={() => setHoverPlan("essential")}>
									✓
                                </div>
                                <div className={hoverPlan === "premium" ? "active" : ""} onMouseOver={() => setHoverPlan("premium")}>
									✓
                                </div>
                            </>
                        )}
                        <div className={hoverPlan === "custom" ? "active" : ""} onMouseOver={() => setHoverPlan("custom")}>
							✓
                        </div>
                    </div>
                    <div className="table_row bigger_row">
                        <div className="feature">Number of Collaborator</div>
                        <div className={hoverPlan === "free" ? "active free" : "free"} onMouseOver={() => setHoverPlan("free")}>
							2
                        </div>
                        {addCollaboratorCondition && (
                            <div className={hoverPlan === "basic" ? "active" : ""} onMouseOver={() => setHoverPlan("basic")}>
                                <p className="mb-1">2 included </p>
                                <p className="mt-1">Pay for as many as you need</p>
                            </div>
                        )}
                        {addEventCondition && (
                            <>
                                <div className={hoverPlan === "essential" ? "active" : ""} onMouseOver={() => setHoverPlan("essential")}>
                                    <p className="mb-1">2 included </p>
                                    <p className="mt-1">Pay for as many as you need</p>
                                </div>
                                <div className={hoverPlan === "premium" ? "active" : ""} onMouseOver={() => setHoverPlan("premium")}>
                                    <p>4 included</p>
                                    <p className="mt-1">Pay for as many as you need</p>
                                </div>
                            </>
                        )}
                        <div className={hoverPlan === "custom" ? "active" : ""} onMouseOver={() => setHoverPlan("custom")}>
                            <p>Pay for as many as you need</p>
                        </div>
                    </div>
                    <div className="table_row">
                        <div className="feature">Number of Events</div>
                        <div className={hoverPlan === "free" ? "active free" : "free"} onMouseOver={() => setHoverPlan("free")}>
							3
                        </div>
                        {addCollaboratorCondition && (
                            <div className={hoverPlan === "basic" ? "active" : ""} onMouseOver={() => setHoverPlan("basic")}>
                                <p>3</p>
                            </div>
                        )}
                        {addEventCondition && (
                            <>
                                <div className={hoverPlan === "essential" ? "active" : ""} onMouseOver={() => setHoverPlan("essential")}>
                                    <p>Pay for as many as you need</p>
                                </div>
                                <div className={hoverPlan === "premium" ? "active" : ""} onMouseOver={() => setHoverPlan("premium")}>
                                    <p>Pay for as many as you need</p>
                                </div>
                            </>
                        )}
                        <div className={hoverPlan === "custom" ? "active" : ""} onMouseOver={() => setHoverPlan("custom")}>
                            <p>Pay for as many as you need</p>
                        </div>
                    </div>
                    <div className="table_row">
                        <div className="feature">Duplicate Events</div>
                        <div className={hoverPlan === "free" ? "active free" : "free"} onMouseOver={() => setHoverPlan("free")}>
							✓
                        </div>
                        {addCollaboratorCondition && (
                            <div className={hoverPlan === "basic" ? "active" : ""} onMouseOver={() => setHoverPlan("basic")}>
								✓
                            </div>
                        )}
                        {addEventCondition && (
                            <>
                                <div className={hoverPlan === "essential" ? "active" : ""} onMouseOver={() => setHoverPlan("essential")}>
									✓
                                </div>
                                <div className={hoverPlan === "premium" ? "active" : ""} onMouseOver={() => setHoverPlan("premium")}>
									✓
                                </div>
                            </>
                        )}
                        <div className={hoverPlan === "custom" ? "active" : ""} onMouseOver={() => setHoverPlan("custom")}>
							✓
                        </div>
                    </div>
                    <div className="table_row">
                        <div className="feature">Event Audit</div>
                        <div onMouseOver={() => setHoverPlan("free")} className={hoverPlan === "free" ? "active free" : "free"}>
							✓
                        </div>
                        {addCollaboratorCondition && (
                            <div className={hoverPlan === "basic" ? "active" : ""} onMouseOver={() => setHoverPlan("basic")}>
								✓
                            </div>
                        )}
                        {addEventCondition && (
                            <>
                                <div className={hoverPlan === "essential" ? "active" : ""} onMouseOver={() => setHoverPlan("essential")}>
									✓
                                </div>
                                <div className={hoverPlan === "premium" ? "active" : ""} onMouseOver={() => setHoverPlan("premium")}>
									✓
                                </div>
                            </>
                        )}
                        <div className={hoverPlan === "custom" ? "active" : ""} onMouseOver={() => setHoverPlan("custom")}>
							✓
                        </div>
                    </div>
                    <div className="table_row">
                        <div className="feature">Premium Support</div>
                        <div className={hoverPlan === "free" ? "active free" : "free"} onMouseOver={() => setHoverPlan("free")}></div>
                        {addCollaboratorCondition && (
                            <div className={hoverPlan === "basic" ? "active" : ""} onMouseOver={() => setHoverPlan("basic")}></div>
                        )}
                        {addEventCondition && (
                            <>
                                <div className={hoverPlan === "essential" ? "active" : ""} onMouseOver={() => setHoverPlan("essential")}></div>
                                <div className={hoverPlan === "premium" ? "active" : ""} onMouseOver={() => setHoverPlan("premium")}>
									✓
                                </div>
                            </>
                        )}
                        <div className={hoverPlan === "custom" ? "active" : ""} onMouseOver={() => setHoverPlan("custom")}>
							✓
                        </div>
                    </div>
                    <div className="table_row last_row pt-2">
                        <div className="feature"></div>
                        <div className="free"></div>
                        {addCollaboratorCondition && (
                            <div>
                                <button onClick={() => choosePricingPlanHandler("basic")}>
                                    <span>Basic Plan</span>+
                                </button>
                            </div>
                        )}
                        {addEventCondition && (
                            <>
                                <div>
                                    <button onClick={() => choosePricingPlanHandler("essential")}>
                                        <span>Essential Plan</span>+
                                    </button>
                                </div>
                                <div>
                                    <button onClick={() => choosePricingPlanHandler("premium")}>
                                        <span>Premium Plan</span>+
                                    </button>
                                </div>
                            </>
                        )}
                        <div>
                            <a
                                className="bg-red contact_btn"
                                target="_blank"
                                rel="no-referrer"
                                href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@planaday.events">
                                <span className="mr-2">Contact us</span>+
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
