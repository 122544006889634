import { FormProvider, useForm } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import questionaire from "@/json/questionaire.json";
import CustomInput from "@/components/form/CustomInput";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import { AsoebiQuestionnaireType, RequestType } from "@/types";
import Textarea from "@/components/form/Textarea";
import { generateQuestionnaireLink, sendMagicLink, uploadQuestionnaireMedia } from "@/axios/post-request";
import Button from "@/components/Button";
import Checkbox from "./Checkbox";
import { AsoebiFormType } from "@/data/types";
import ArrowleftIcon from "@/assets/icon/ArrowleftIcon";

interface Props {
	children: React.ReactNode;
	selectedFiles: File[] | [];
    editOrderForm: () => void;
}

type order_form_type = keyof typeof AsoebiFormType | "";

export default function PreviewQuestionaire({ children, editOrderForm, selectedFiles }: Props) {
    const [previewFormDetails, setPreviewFormDetails] = useState<AsoebiQuestionnaireType | null>(null);
    const [consentToTransactionPolicy, setConsentToTransactionPolicy] =useState(false);
    const [currency, setCurrency] = useState("");
    const [orderFormType, setOrderFormType] = useState<order_form_type>("");
    const navigate = useNavigate();
    const methods = useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const eventDetailsStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.event_details);
        const guestDetailsStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.guest_details);
        const asoebiOptionDetailsStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.asoebi_options);
        const eventDetails = eventDetailsStorage ? JSON.parse(eventDetailsStorage) : null;
        const guestDetails = guestDetailsStorage ? JSON.parse(guestDetailsStorage) : null;
        const asoebiOptionDetails = asoebiOptionDetailsStorage ? JSON.parse(asoebiOptionDetailsStorage) : null;
        const asoebiOrderFormType = window.localStorage.getItem(LOCAL_STORAGE_KEYS.asoebi_order_form_type) as order_form_type;

        setOrderFormType(asoebiOrderFormType);
        if (asoebiOptionDetails?.currency) {
            setCurrency(asoebiOptionDetails.currency);
        }
        if (guestDetails && eventDetails) {
            setPreviewFormDetails({
                eventDetails,
                guestDetails,
                asoebiOptions: asoebiOptionDetails,
            });
        } else {
            navigate("/asoebi-tracker");
        }
    }, []);

    async function uploadQuestionnaireImages(questionnaire_id: string) {
        const uploadAsoebiImagesRequest = selectedFiles.map(async (file) => {
            const formData = new FormData();
            formData.append("file", file);
            await uploadQuestionnaireMedia(formData, questionnaire_id);
        });
        return Promise.all(uploadAsoebiImagesRequest);
    }

    async function generateQuestionnaireLinkHandler(event: ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        if (selectedFiles.length === 0) {
            return toast.error("Please add an asoebi image");
        }
        try {
            setLoading(true);
            if (previewFormDetails) {
                const generateLinkRequest = await generateQuestionnaireLink({...previewFormDetails, consentToTransactionPolicy, orderFormType });
                if (generateLinkRequest.status === 200) {
                    uploadQuestionnaireImages(generateLinkRequest.data.result.id)
                        .then(async () => {
                            const sendMagicLinkRequest = await sendMagicLink(previewFormDetails.eventDetails.email);
                            if (sendMagicLinkRequest.data.type === RequestType.Success) {
                                toast.success(sendMagicLinkRequest.data.message || "Magic link has been sent to your email");
                            }
                            setLoading(false);
                            toast.success(`Congrats ${previewFormDetails.eventDetails.name}, 
                        Asoebi order form created - redirecting you to your form page`);
                            window.localStorage.removeItem(LOCAL_STORAGE_KEYS.asoebi_options);
                            window.localStorage.removeItem(LOCAL_STORAGE_KEYS.event_details);
                            window.localStorage.removeItem(LOCAL_STORAGE_KEYS.asoebi_order_form_type);
                            window.localStorage.removeItem(LOCAL_STORAGE_KEYS.guest_details);
                            return navigate(`/asoebi-tracker/questionnaire-completed?link=${generateLinkRequest.data.result.questionnaire_link}`);
                        })
                        .catch((error) => {
                            const _error = error as { message: string };
                            if (error.status === 413) {
                                return toast.error("File too big");
                            }
                            toast.error(_error?.message || "Oops an error occurred");
                            setLoading(false);
                        });
                }
            }
        } catch (error) {
            const _error = error as { message: string };
            setLoading(false);
            console.log("_error", error);
            toast.error(_error?.message || "Oops an error occurred");
        }
    }

    function updateConsentHandler() {
        setConsentToTransactionPolicy((prev) => !prev);
    }

    const currencySymbol = currency.includes("Dollar") ? "$" : "₦";

    return (
        <section className="preview_questionaire tracker_content px-md-4">
            <div className="mb-5 d-flex items-center">
                <button className="btn btn-primary" onClick={editOrderForm}>
                    <ArrowleftIcon />
                </button>
                <h1>
				Preview your Asoebi Order Form for <span>{previewFormDetails?.eventDetails.event_name}</span>
                </h1>
            </div>
            {previewFormDetails && (
                <FormProvider {...methods}>
                    <form className="col-lg-8 col-md-11 col-11 px-0 mx-auto preview_form" onSubmit={generateQuestionnaireLinkHandler}>
                        {children}
                        {previewFormDetails.guestDetails.name && <CustomInput input={questionaire.name} readOnly />}
                        {previewFormDetails.guestDetails.phone_number && <CustomInput input={questionaire.phone_number} readOnly />}
                        {previewFormDetails.guestDetails.delivery_address && <CustomInput input={questionaire.delivery_address} readOnly />}
                        {previewFormDetails.asoebiOptions.special_note && <Textarea input={questionaire.special_note} readOnly />}
                        <div className="asoebi_options d-flex flex-column">
                            <h3 className="d-flex mb-0">Asoebi Options</h3>
                            {previewFormDetails.asoebiOptions.options.map(({ option, price }, index) => (
                                <div key={index} className="d-flex">
                                    <h4 className="m-0">
                                        {option}
                                        <span className="mx-1">{currencySymbol}{price}</span>
                                    </h4>
                                </div>
                            ))}
                        </div>
                        {orderFormType !== AsoebiFormType.tracker &&
                        <>
                            <Checkbox
                                label="I understand that 5% of each transaction will be deducted before dispensing the money to me"
                                name="consent_to_transaction_debit"
                                active={consentToTransactionPolicy}
                                onClickHandler={updateConsentHandler}

                            />
                            <p className="text-danger mb-0">Note: Payments from purchase will get to client within 24-48 hours on a working day.</p>
                        </>}
                        {(consentToTransactionPolicy || orderFormType === AsoebiFormType.tracker) && <Button
                            type="submit"
                            className="btn btn-primary font-weight-bold text-white my-4 mx-auto d-flex"
                            text="Generate form link"
                            isLoading={loading}
                        />}
                    </form>
                </FormProvider>
            )}
        </section>
    );
}
