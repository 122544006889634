/* eslint-disable max-len */

export default function CircleIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.9 7C13.9 10.8108 10.8108 13.9 7 13.9C3.18924 13.9 0.1 10.8108 0.1 7C0.1 3.18924 3.18924 0.1 7 0.1C10.8108 0.1 13.9 3.18924 13.9 7Z"
                fill="#D9D9D9"
                stroke="#404040"
                strokeWidth="0.2"
            />
        </svg>
    );
}
