/* eslint-disable max-len */
import { useState } from "react";
import DownArrow from "@/assets/icon/DownArrow";

type faqType = number | null;

const FAQDetails = [
    {
        question: "How do I get paid for an event’s asoebi orders?",
        answer: "When setting up your order form, we collect account details for remittance.Payments collected, excluding our charges, will be transferred to your account on a daily basis.",
    },
    {
        question: "Can I track the status of asoebi orders?",
        answer: "Yes, you can. Our user-friendly Order Dashboard allows you to monitor the details of asoebi orders, including payments made and order quantity.",
    },
    {
        question: "What if a guest encounters issues during the asoebi ordering process?",
        answer: "Our support team is ready to assist with any issues they may have! Their details are provided in the order form that your guests will receive.",
    },
];

interface Props {
	index: number;
	FAQ: number | null;
	faqHandler: (index: number) => void;
	item: {
		answer: string | string[];
		question: string;
	};
}

function FAQItem({ index, FAQ, faqHandler, item }: Props) {
    const className = index === FAQ ? "active" : "";
    return (
        <div className={`bg-white shadow-sm faq ${className}`}>
            <button className="py-3 px-md-4 px-3 text-dark" onClick={() => faqHandler(index)}>
                <h6 className="mb-0">{item.question}</h6>
                <DownArrow />
            </button>
            {FAQ === index && (
                <div className="px-md-4 px-3 faq_content">
                    <p>{item.answer}</p>
                    {index === 2 && <p>If you are experiencing technical issues, contact our Support team at <a href="mailto:help@planaday.events" className="link">help@planaday.events</a></p>}
                </div>
            )}
        </div>
    );
}

export default function AsoebiFAQ() {
    const [FAQ, setFAQ] = useState<faqType>(null);

    function faqHandler(faqItem: faqType) {
        const faqState = faqItem !== FAQ ? faqItem : null;
        setFAQ(faqState);
    }
    return (
        <section className="bg-secondary py-5 px-md-0">
            <div className="container">
                <div className="col-lg-10 col-md-11 col-12 mx-auto d-flex flex-column">
                    <h2 className="text-center mb-4 text-dark sub_title">FAQ</h2>
                    {FAQDetails.map((faq, index) => (
                        <FAQItem FAQ={FAQ} key={index} index={index} item={faq} faqHandler={faqHandler} />
                    ))}
                </div>
            </div>
        </section>
    );
}
