import { v4 as uuidv4 } from "uuid";
import { updateAudit } from "@/axios/post-request";
import { auditType, EventdayFlowType, TodoType } from "@/types";
import useUser from "./useUser";

type ChecklistType = Array<{
    id: string;
    name: string;
    note: string;
    due: Date | null;
    priority?: string;
    isDone: boolean;
    ceremony: { name: string; id: string };
}>;

export default function useActivityLog() {
    const { data, selectedEventId } = useUser();

    async function deleteChecklistLog(name: string) {
        try {
            updateAudit(
                [
                    {
                        id: uuidv4(),
                        name: data?.result?.name as string,
                        user_id: data?.result?.id as string,
                        email: data?.result?.email as string,
                        type: "checklist",
                        action: "delete_checklist",
                        title: name,
                        timestamp: new Date(),
                    },
                ],
                selectedEventId,
            );
        } catch (error) {
            console.log("error", error);
        }
    }

    async function deleteEventTaskAuditRequest(name: string) {
        try {
            updateAudit(
                [
                    {
                        id: uuidv4(),
                        name: data?.result?.name as string,
                        user_id: data?.result?.id as string,
                        email: data?.result?.email as string,
                        type: "eventday_task",
                        action: "delete_eventday_task",
                        title: name,
                        timestamp: new Date(),
                    },
                ],
                selectedEventId,
            );
        } catch (error) {
            console.log("error", error);
        }
    }

    async function addChecklistAuditHandler(formValues: ChecklistType, activeCeremonyId: string, ceremonyName: string) {
        try {
            const multipleAuditArray = [] as auditType;
            formValues.map((checklistItem) =>
                multipleAuditArray.push({
                    id: uuidv4(),
                    name: data?.result?.name as string,
                    user_id: data?.result?.id as string,
                    email: data?.result?.email as string,
                    type: "checklist",
                    action: "add_checklist",
                    title: checklistItem.name,
                    changes: {
                        to: {
                            id: checklistItem.id,
                            name: checklistItem.name,
                            note: "",
                            due: checklistItem?.due,
                            ceremony: { id: activeCeremonyId, name: ceremonyName },
                            isDone: checklistItem?.isDone,
                            priority: checklistItem?.priority,
                        },
                    },
                    metadata: {
                        ceremony: ceremonyName,
                        type: "multiple_checklist",
                    },
                    timestamp: new Date(),
                }),
            );
            await updateAudit(multipleAuditArray, selectedEventId);
        } catch (error) {
            console.log("error", error);
        }
    }

    async function addEventdaytaskAuditHandler(formValues: EventdayFlowType, activeCeremonyId: string, ceremonyName: string, eventName: string) {
        try {
            const multipleAuditArray = [] as auditType;
            formValues.map((eventDayTask) =>
                multipleAuditArray.push({
                    id: uuidv4(),
                    name: data?.result?.name as string,
                    user_id: data?.result?.id as string,
                    email: data?.result?.email as string,
                    type: "eventday_task",
                    action: "add_eventday_task",
                    title: eventDayTask.task,
                    eventName: eventName,
                    changes: {
                        to: {
                            id: eventDayTask.id,
                            task: eventDayTask.task,
                            date: eventDayTask?.date,
                            ceremony: { id: activeCeremonyId, name: ceremonyName },
                            isDone: eventDayTask?.isDone,
                            duration: eventDayTask?.duration,
                            start_time: eventDayTask?.start_time,
                            vendor: eventDayTask?.vendor,
                            venue: eventDayTask?.venue,
                        },
                    },
                    metadata: {
                        ceremony: ceremonyName,
                        type: "eventday_task",
                    },
                    timestamp: new Date(),
                }),
            );
            await updateAudit(multipleAuditArray, selectedEventId);
        } catch (error) {
            console.log("error", error);
        }
    }

    async function checklistActivityLog(todoItem: TodoType[0]) {
        try {
            const checklist_action = !todoItem.isDone ? "checklist_completed" : "checklist_not_completed";
            await updateAudit(
                [
                    {
                        id: uuidv4(),
                        name: data?.result?.name as string,
                        user_id: data?.result?.id as string,
                        email: data?.result?.email as string,
                        type: "checklist",
                        action: checklist_action,
                        title: todoItem.name,
                        changes: null,
                        timestamp: new Date(),
                    },
                ],
                selectedEventId,
            );
        } catch (error) {
            console.log("error", error);
        }
    }

    async function editChecklistActivityLog(task: TodoType[0], checklist: TodoType[0]) {
        try {
            await updateAudit(
                [
                    {
                        id: uuidv4(),
                        name: data?.result?.name as string,
                        user_id: data?.result?.id as string,
                        email: data?.result?.email as string,
                        type: "checklist",
                        action: "update_checklist",
                        changes: {
                            from: {
                                id: checklist.id,
                                name: checklist.name,
                                note: checklist?.note ? checklist?.note : null,
                                due: checklist?.due ? checklist?.due : null,
                                ceremony: checklist.ceremony,
                                priority: checklist?.priority,
                                isDone: checklist.isDone,
                            },
                            to: {
                                id: checklist.id,
                                name: task.name,
                                note: task?.note ? task?.note : null,
                                due: task?.due ? task?.due : null,
                                ceremony: task.ceremony,
                                priority: task?.priority,
                                isDone: task.isDone,
                            },
                        },
                        title: task.name,
                        timestamp: new Date(),
                        metadata: {
                            ceremony: task.ceremony.name,
                        },
                    },
                ],
                selectedEventId,
            );
        } catch (error) {
            console.log("error", error);
        }
    }

    async function updateEventdaytaskAuditHandler(eventDayTask: EventdayFlowType[0], newEventDayTask: EventdayFlowType[0], eventName: string) {
        try {
            await updateAudit(
                [
                    {
                        id: uuidv4(),
                        name: data?.result?.name as string,
                        user_id: data?.result?.id as string,
                        email: data?.result?.email as string,
                        type: "eventday_task",
                        action: "eventday_task_edited",
                        title: eventDayTask.task,
                        eventName: eventName,
                        changes: {
                            from: {
                                id: eventDayTask.id,
                                task: eventDayTask.task,
                                date: eventDayTask?.date,
                                ceremony: eventDayTask.ceremony,
                                isDone: eventDayTask?.isDone,
                                duration: eventDayTask?.duration,
                                start_time: eventDayTask?.start_time,
                                vendor: eventDayTask?.vendor,
                                venue: eventDayTask?.venue,
                            },
                            to: {
                                id: eventDayTask.id,
                                task: newEventDayTask.task,
                                date: newEventDayTask?.date,
                                ceremony: newEventDayTask.ceremony,
                                isDone: newEventDayTask?.isDone,
                                duration: newEventDayTask?.duration,
                                start_time: newEventDayTask?.start_time,
                                vendor: newEventDayTask?.vendor,
                                venue: newEventDayTask?.venue,
                            },
                        },
                        metadata: {
                            ceremony: newEventDayTask?.ceremony?.name,
                        },
                        timestamp: new Date(),
                    },
                ],
                selectedEventId,
            );
        } catch (error) {
            console.log("error", error);
        }
    }

    return {
        checklistActivityLog,
        addChecklistAuditHandler,
        deleteChecklistLog,
        editChecklistActivityLog,
        updateEventdaytaskAuditHandler,
        addEventdaytaskAuditHandler,
        deleteEventTaskAuditRequest,
    };
}
