/* eslint-disable @typescript-eslint/no-explicit-any */
import { Group, Path, Rect } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onSelect: () => void;
	onDragStart: (value: itemType) => void;
	onTransformEndHandler: () => void;
}

export default function GiftElement({ refValue, onSelect, item, onDragStart, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            {...item}
            name="floor_plan_element"
            fill={item.fill}
            scaleX={3}
            scaleY={3}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Rect stroke={item.fill} x={3} y={8} height={4} width={18} />
            <Path stroke={item.fill} data="M12 8v13" />
            <Path stroke={item.fill} data="M19 12v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7" />
            <Path stroke={item.fill} data="M7.5 8a2.5 2.5 0 0 1 0-5A4.8 8 0 0 1 12 8a4.8 8 0 0 1 4.5-5 2.5 2.5 0 0 1 0 5" />
        </Group>
    );
}
