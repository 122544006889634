/* eslint-disable max-len */
import { NavLink } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import budgetTrackerIcon from "@/assets/img/money_icon.svg";
import myEventIcon from "@/assets/img/more_icon.svg";
import signOutIcon from "@/assets/img/sign_out_icon.svg";
import useAuth from "@/hooks/useAuth";
import AuditIcon from "@/assets/img/sidebar_search_icon.svg";
import { useFilter } from "@/hooks/useFilter";
import AddUserIcon from "@/assets/icon/AddUserIcon";

const sidebarLinks = [
    {
        text: "Overview",
        link: "/admin/asoebi/dashboard",
        icon: myEventIcon,
    },
    {
        text: "Questionnaires",
        link: "/admin/asoebi/dashboard/questionnaires",
        icon: AuditIcon,
    },
    {
        text: "Payment",
        link: "/admin/asoebi/dashboard/payments",
        icon: budgetTrackerIcon,
    },
];

const plannerAdminSidebarLinks = [
    {
        text: "Orders",
        link: "/asoebi/dashboard",
        icon: myEventIcon,
    }
];

interface Props {
	links: Array<{ text: string; link: string; icon: string }>;
    type: "admin" | "user"
}

function SidebarLink({ links, type }: Props) {
    const { setCeremony } = useFilter();
    const queryClient = useQueryClient();

    function onLinkNavigate(sidebarText: string) {
        if (sidebarText === "Audits") {
            queryClient.resetQueries();
        }

        setCeremony({
            name: "All Ceremonies",
            id: "all",
        });
    }

    return (
        <ul className="sidebar_links">
            {links.map((sidebarItem) => {
                return (
                    <li key={sidebarItem.text}>
                        <NavLink
                            to={sidebarItem.link}
                            className={({ isActive }) => (isActive ? "nav_link active align-items-center" : "nav_link align-items-center")}
                            onClick={() => onLinkNavigate(sidebarItem.text)}
                            end>
                            <img src={sidebarItem.icon} alt={`${sidebarItem.text} link`} />
                            {sidebarItem.text}
                        </NavLink>
                    </li>
                );
            })}
            {type === "admin" &&<li>
                <NavLink
                    to="/admin/asoebi/dashboard/admin"
                    className={({ isActive }) => (isActive ? "nav_link active align-items-center" : "nav_link align-items-center")}
                    end>
                    <AddUserIcon />
					Admin
                </NavLink>
            </li>}
        </ul>
    );
}

interface SidebarButtonProps {
	text: string;
	icon: string;
	onClick?: () => void;
}

function SidebarButton({ text, icon, onClick }: SidebarButtonProps) {
    const alt = `${text} link`;
    return (
        <button className="dashboard_button px-0" onClick={onClick}>
            <img src={icon} alt={alt} />
            {text}
        </button>
    );
}

interface SidebarProps{
    type?: "admin" | "user"
}

export default function AsoebiDashboardSidebar({type = "admin"}:SidebarProps) {
    const adminSidebarLink = type === "admin" ? sidebarLinks : plannerAdminSidebarLinks;
    const { asoebeiAdminSignOut } = useAuth();
    return (
        <aside className="pt-5 bg-white">
            <SidebarLink type={type} links={adminSidebarLink} />
            <div className="divider" />
            <div className="cta">
                <SidebarButton text="Sign Out" icon={signOutIcon} onClick={asoebeiAdminSignOut} />
            </div>
        </aside>
    );
}
