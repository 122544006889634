import CreatableSelect from "react-select/creatable";
import type { ActionMeta, SingleValue, MultiValue } from "react-select";

import onboardingFormContent from "@/json/form/onboarding_form.json";

type multiType = MultiValue<{ [key: string]: string | number }> | SingleValue<{ [key: string]: string | number }>;

interface Props {
    className?: string;
    isMulti?: boolean;
    value?: multiType;
    input: { name: string; label: string; placeholder: string };
    onChange?: (newValue: multiType, actionMeta?: ActionMeta<{ [key: string]: string | number }>) => void;
    options?: Array<{ label: string; value: string }>;
}

export default function OptionsPicker({ isMulti, className = "", input, options, value, onChange, ...rest }: Props) {
    const ceremonyPickerOptions = options ? options : onboardingFormContent.ceremonyOptions;

    return (
        <div className={`form_element search_select ceremony_picker ${className}`}>
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mb-2">
                <label htmlFor={input.name} className="mb-0">
                    {input.label}
                </label>
            </div>
            <div className="d-flex">
                <CreatableSelect
                    {...rest}
                    styles={{
                        option: (providedStyles, props) => ({
                            ...providedStyles, // To keep the default style
                            color: props.data.__isNew__ ? "#cb3050" : "black",
                            backgroundColor: props.data.__isNew__ ? "white" : "white",
                            fontWeight: props.data.__isNew__ ? "bold" : "normal",
                            width: props.data.__isNew__ && "fit-content",
                            borderRadius: props.data.__isNew__ && "10px",
                            marginLeft: props.data.__isNew__ && "10px",
                            marginTop: props.data.__isNew__ && "5px",
                            marginBottom: props.data.__isNew__ && "5px",
                        }),
                    }}
                    id={input.name}
                    isMulti={isMulti}
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder={input.placeholder}
                    value={value}
                    name={input.name}
                    options={ceremonyPickerOptions}
                    onChange={onChange}
                    className="w-100 ceremony_picker_select"
                />
            </div>
        </div>
    );
}
