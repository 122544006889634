import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import type { ChangeEvent } from "react";

import round_plus_icon from "@/assets/img/round-plus.svg";
import round_minus_icon from "@/assets/img/round-minus.svg";
import "@/styles/form.scss";

interface Props {
    input: {
        label: string;
        placeholder: string;
        name: string;
        defaultPlaceholder?: string;
    };
    className?: string;
    items: string[];
    addItems: (item: string[]) => void;
    deleteItems: (items: string[]) => void;
}

export default function InputMore({ input, items, addItems, deleteItems, className }: Props) {
    const inputClassname = className ? className : "";
    const [category, setCategory] = useState("");
    const [inputArray, setInputArray] = useState([
        {
            id: uuidv4(),
            name: input.name,
            placeholder: input.placeholder,
            value: "",
        },
    ]);

    useEffect(() => {
        const inputValues: string[] = [];

        inputArray.map((item) => {
            if (item.value) {
                inputValues.push(item.value);
            }
        });
        addItems(inputValues);
    }, [category, inputArray.length]);

    function addMoreInput() {
        const _inputName = inputArray.length + 1;
        setInputArray((prevValue) => {
            return [
                ...prevValue,
                {
                    id: uuidv4(),
                    name: `${input.name}_${_inputName}`,
                    placeholder: `New ${input.defaultPlaceholder}`,
                    value: "",
                },
            ];
        });
    }

    function deleteInput(idx: number, value: string) {
        const newInputArray = inputArray.filter((_, index) => index !== idx);
        const newItems = items.filter((item) => item !== value);
        setInputArray(newInputArray);
        deleteItems(newItems);
    }

    function moreInputHandler(index: number, value: string) {
        if (index === inputArray.length - 1) {
            addMoreInput();
        } else {
            deleteInput(index, value);
        }
    }

    function updateInput(e: ChangeEvent<HTMLInputElement>, id: string) {
        const inputArrayValue = inputArray.filter((input) => {
            if (input.id === id) {
                input.value = e.target.value;
            }
        });
        setInputArray((prev) => [...prev, ...inputArrayValue]);
        setCategory(e.target.value);
    }

    return (
        <div className={`form_element input input-more ${inputClassname}`}>
            <label htmlFor={input.name}>{input.label}</label>
            <div className="input-list-group">
                {inputArray.map((inputItem, index) => {
                    const icon = index === inputArray.length - 1 ? round_plus_icon : round_minus_icon;
                    const imgAlt= `add more ${input.label}`;

                    return (
                        <div key={inputItem.id} className="input-list">
                            <input
                                name={inputItem.name}
                                placeholder={inputItem.placeholder}
                                className="form-control"
                                onChange={(e) => updateInput(e, inputItem.id)}
                            />
                            {(inputArray.length === 1 || (index !== 0 && inputArray.length > 1)) && (
                                <button onClick={() => moreInputHandler(index, inputItem.value)} type="button">
                                    <img src={icon} alt={imgAlt} />
                                </button>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
