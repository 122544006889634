export default function OnboardingStage4() {
    return (
        <div>
            <h4 className="mb-lg-5 mb-4">
        Congratulations! Your profile setup is complete
            </h4>
            <p>You’re all set to plan an amazing day </p>
            <p>
        Now its time to personalise your profile - set your budget, and explore
        our amazing features to make your dream a reality
            </p>
        </div>
    );
}
