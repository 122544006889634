import { useNavigate } from "react-router-dom";
import OnboardingLayout from "@/layout/OnboardingLayout";

import Button from "@/components/Button";
import leftArrow from "@/assets/img/left_polygon_arrow.svg";
import rightArrow from "@/assets/img/right_polygon_arrow.svg";
import OnboardingStage2 from "@/components/OnboardingStage2";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";

const CURRENTSTAGE = 1;

export default function OnboardingBudgetDetails() {
    const indicatorArray = new Array(4).fill(0);
    const navigate = useNavigate();

    function onBackHandler() {
        navigate("/onboarding/event-details");
    }

    function onSkipHandler() {
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.skip_event_budget, "true");
        navigate("/onboarding/invite-event-collaborator");
    }

    function onNextHandler() {
        navigate("/onboarding/calculate-event-budget");
    }


    return (
        <OnboardingLayout className="onboarding">
            <div className="onboarding_slides">
                <ul className="indicator my-4 mb-5 d-flex align-items-center">
                    {indicatorArray.map((_, index) => {
                        const indicatorStyle = CURRENTSTAGE === index ? "active" : index < CURRENTSTAGE ? "visited" : "indicator_item";
                        return <li key={index} className={indicatorStyle} />;
                    })}
                </ul>
                <div className="onboarding_content">
                    <OnboardingStage2 onNextHandler={onNextHandler} />
                </div>
                <div className="controls d-flex align-items-center justify-content-between">
                    <Button text="BACK" onClick={onBackHandler} icon={leftArrow} iconPosition="left" className="px-0" />
                    <Button text="SKIP" className="px-0" icon={rightArrow} onClick={onSkipHandler} />
                </div>
            </div>
        </OnboardingLayout>
    );
}
