import CardLoader from "@/components/loaders/CardLoader";
import "@/styles/loader.scss";

export default function EventOverviewLoader() {
    const loaderArray = new Array(4).fill(0);
    return (
        <div className="event_loader">
            <CardLoader height={300} width={300} className={`card_event_overview`} />
            <div className="card_group">
                {loaderArray.map((_, index) => (
                    <CardLoader key={index} height={300} width={300} className={`card_${index}`} />
                ))}
            </div>
        </div>
    );
}
