/* eslint-disable max-len */

export default function TrashIcon() {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.52941 3.41463H11.2941C11.2941 2.337 10.4514 1.46341 9.41177 1.46341C8.37214 1.46341 7.52941 2.337 7.52941 3.41463ZM6.11765 3.41463C6.11765 1.52878 7.59247 0 9.41177 0C11.2311 0 12.7059 1.52878 12.7059 3.41463H18.1176C18.5075 3.41463 18.8235 3.74223 18.8235 4.14634C18.8235 4.55045 18.5075 4.87805 18.1176 4.87805H16.8761L15.7731 16.6939C15.598 18.5692 14.0776 20 12.26 20H6.56347C4.74592 20 3.22555 18.5692 3.05048 16.6939L1.94737 4.87805H0.705882C0.316038 4.87805 0 4.55045 0 4.14634C0 3.74223 0.316038 3.41463 0.705882 3.41463H6.11765ZM8 8.04878C8 7.64467 7.68395 7.31707 7.29412 7.31707C6.90427 7.31707 6.58824 7.64467 6.58824 8.04878V15.3659C6.58824 15.77 6.90427 16.0976 7.29412 16.0976C7.68395 16.0976 8 15.77 8 15.3659V8.04878ZM11.5294 7.31707C11.9192 7.31707 12.2353 7.64467 12.2353 8.04878V15.3659C12.2353 15.77 11.9192 16.0976 11.5294 16.0976C11.1396 16.0976 10.8235 15.77 10.8235 15.3659V8.04878C10.8235 7.64467 11.1396 7.31707 11.5294 7.31707ZM4.45568 16.5529C4.56072 17.678 5.47294 18.5366 6.56347 18.5366H12.26C13.3506 18.5366 14.2628 17.678 14.3678 16.5529L15.4578 4.87805H3.36572L4.45568 16.5529Z"
                fill="#cb3050"
            />
        </svg>
    );
}
