import { useState } from "react";
import { Link, } from "react-router-dom";
import type { PropsWithChildren } from "react";

import Footer from "@/components/Footer";
import logo from "@/assets/img/logo.png";
import getFirstChar from "@/utils/getFirstChar";
import Hamburger from "@/assets/icon/Hamburger";
import CancelIcon from "@/assets/icon/Cancel";
import AsoebiDashboardSidebar from "@/components/AsoebiDashboardSidebar";
import "@/styles/dashboard.scss";
import "@/styles/asoebi-dashboard.scss";

interface Props {
	showIcon?: boolean;
	showSidebar?: boolean;
	className?: string;
    name?:string;
    type?: "admin" | "user"
}

function AsoebiDashboardLayout({ children, name, className = "", type="admin" }: PropsWithChildren<Props>) {
    const [mobileMenu, setMobileMenu] = useState(false);

    const mobileAsideClassName = mobileMenu ? "open_menu" : "close_menu";

    function toggleMobileMenu() {
        setMobileMenu((prev) => !prev);
    }

    const nameFirstChar = name ? getFirstChar(name) : "";


    return (
        <>
            <header className="dashboard_header bg-white d-flex flex-column">
                <div className="header_content d-flex align-items-center justify-content-between">
                    <Link to="/">
                        <img src={logo} alt="Planaday logo" className="logo-img" />
                    </Link>
                    <button className="d-sm-none d-flex mobile_menu" onClick={toggleMobileMenu}>
                        {!mobileMenu ? <Hamburger /> : <CancelIcon />}
                    </button>
                    <nav className="d-flex align-items-center">
                        {/* <Link to="/old/dashboard/budget-tracker">Planning Tools</Link> */}
                        <a href="https://www.blog.planaday.events/" target="_blank">
							Community{" "}
                        </a>
                        <a href="https://planaday.events/about.html" target="_blank">
							About
                        </a>
                        <div className="d-flex nav_profile_icon_group">
                            {nameFirstChar && (
                                <div className="profile">{nameFirstChar}</div>
                            )}
                            <button className="mobile_menu tablet_view" onClick={toggleMobileMenu}>
                                {!mobileMenu ? <Hamburger /> : <CancelIcon />}
                            </button>
                        </div>
                    </nav>
                </div>
                <div className="section-bar" />
            </header>
            <main className={`dashboard asoebi ${className} ${mobileAsideClassName}`}>
                <div className="d-flex dashboard_layout_wrapper">
                    <AsoebiDashboardSidebar type={type} />
                    <div className="dashboard_content">{children}</div>
                </div>
                <div className="section-bar" />
            </main>
            <Footer />
        </>
    );
}

export default AsoebiDashboardLayout;
