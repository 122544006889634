import ContentLoader from "react-content-loader";

export function ProfileLoader() {
    return (
        <ContentLoader
            className="mb-1"
            title="Fetching profile details..."
            viewBox="0 0 100% 100%"
            height="70px"
            width="100%"
            backgroundColor="#cbbec0"
            foregroundColor="#dedede">
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="50px" />
        </ContentLoader>
    );
}

export default function AuditsLoader() {
    const eightDummyAudits = Array(8).fill(0);
    return (
        <div className="audits_loader d-flex flex-column mt-5">
            {eightDummyAudits.map((_, index) => (
                <ContentLoader
                    key={index}
                    className="mb-1"
                    title="Fetching events..."
                    // viewBox="0 0 100% 100%"
                    height="70px"
                    backgroundColor="#cbbec0"
                    foregroundColor="#dedede">
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="50px" />
                </ContentLoader>
            ))}
        </div>
    );
}

export function VendorLoader() {
    const fourDummyAudits = Array(4).fill(0);
    return (
        <div className="vendor_loader d-flex flex-column">
            {fourDummyAudits.map((_, index) => (
                <ContentLoader
                    key={index}
                    className="mb-1"
                    title="Fetching events..."
                    // viewBox="0 0 100% 100%"
                    height="25px"
                    backgroundColor="#cbbec0"
                    foregroundColor="#dedede">
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="25px" />
                </ContentLoader>
            ))}
        </div>
    );
}
