import NewDashboardLayout from "@/layout/NewDashboardLayout";
import { lazy, Suspense, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import SiteDropdown from "@/components/Dropdown";
import ThreedotIcon from "@/assets/icon/ThreedotIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import WalletIcon from "@/assets/icon/WalletIcon";
import { getAllVendors } from "@/axios/get-request";
import Spinner from "@/components/Spinner";
import getFirstChar from "@/utils/getFirstChar";
import { generateColorFromId } from "@/helper";
import { VendorType } from "@/types";
import { useEvent } from "@/hooks/useEvent";
import useUser from "@/hooks/useUser";
import { CollaboratorPermissionList } from "@/data/types";
import "@/styles/vendors.scss";

const EditVendorModal = lazy(() => import("../../../components/modals/EditVendorModal"));
const AddVendorModal = lazy(() => import("../../../components/modals/AddVendorModal"));
const DeleteVendorModal = lazy(() => import("../../../components/modals/DeleteVendorModal"));

enum ModalList {
    delete_vendor_modal = "delete_vendor_modal",
    add_vendor_modal = "add_vendor_modal",
    edit_vendor_modal = "edit_vendor_modal",
}

type ModalType = `${ModalList}`;

export default function VendorsPage() {
    const { selectedEventId } = useEvent();
    const [selectedVendorIds, setSelectedVendorIds] = useState<Array<string>>([]);
    const [selectedVendorDetails, setSelectedVendorDetails] = useState<VendorType | null>(null);
    const [modal, setModal] = useState<ModalType | null>(null);
    const { data: userData } = useUser();
    const { data, status } = useQuery({
        queryKey: ["all_event_vendors"],
        queryFn: () => getAllVendors(),
    });

    const vendorsData = data?.data.status ? data?.data : null;
    const userDetails = userData?.result ? userData?.result : null;

    function openModal(modalType: ModalType) {
        setModal(modalType);
    }
    function closeModal() {
        setModal(null);
        resetVendorHandler();
    }

    function onSelectAllVendorsHandler() {
        if (selectedVendorIds.length === 0) {
            if (vendorsData?.result) {
                const selectedVendorsIdArray: Array<string> = [];
                vendorsData?.result.map((item) => {
                    if (item) {
                        selectedVendorsIdArray.push(item.id);
                    }
                });
                setSelectedVendorIds(selectedVendorsIdArray);
            }
        } else {
            setSelectedVendorIds([]);
        }
    }

    function vendorCheckerHandler(id: string) {
        if (selectedVendorIds.length === 0) {
            return setSelectedVendorIds([id]);
        } else {
            const isIdSelected = selectedVendorIds.filter((vendorId) => vendorId === id).length > 0 ? true : false;
            if (isIdSelected) {
                setSelectedVendorIds((prev) => prev.filter((vendorId) => vendorId !== id));
            } else {
                setSelectedVendorIds((prev) => [...prev, id]);
            }
        }
    }

    function vendorActionHandler(vendor: VendorType, modalType: ModalType) {
        if (vendor) setSelectedVendorDetails(vendor);
        setModal(modalType);
    }

    function resetVendorHandler() {
        setSelectedVendorDetails(null);
        setSelectedVendorIds([]);
    }

    function addVendorHandler() {
        if (!selectedEventId) {
            return toast.error("Please create an event");
        }
        openModal(ModalList.add_vendor_modal);
    }

    const hasSubscribed = userData?.result?.subscription_details?.id;

    return (
        <>
            <Suspense>
                {modal === ModalList.add_vendor_modal && <AddVendorModal onClose={closeModal} />}
                {modal === ModalList.delete_vendor_modal && (
                    <DeleteVendorModal selectedVendorDetails={selectedVendorDetails} selectedVendorIds={selectedVendorIds} onClose={closeModal} />
                )}
                {modal === ModalList.edit_vendor_modal && selectedVendorDetails && (
                    <EditVendorModal selectedVendorDetails={selectedVendorDetails} onClose={closeModal} />
                )}
            </Suspense>
            <NewDashboardLayout title="Vendors" description="Manage all vendor related tasks">
                <section className="vendor_view_wrapper">
                    <div className="vendor_view">
                        <div className="top_bar">
                            <h4>Vendors</h4>
                            <div className="right_button_group">
                                {hasSubscribed && userDetails?.permission !== CollaboratorPermissionList.view_only && (
                                    <button className="share_plan" onClick={addVendorHandler}>
                                        <PlusIcon fill="#fff" />
                                        Add
                                    </button>
                                )}

                                {selectedVendorIds.length > 0 && (
                                    <SiteDropdown title={<ThreedotIcon />} className="custom three_dot">
                                        <Dropdown.Item onClick={() => setModal(ModalList.delete_vendor_modal)}>Delete</Dropdown.Item>
                                    </SiteDropdown>
                                )}
                            </div>
                        </div>
                        <div className="vendors_table_view">
                            <div className="table_wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>
                                                <input
                                                    checked={selectedVendorIds.length === vendorsData?.result.length}
                                                    type="checkbox"
                                                    onClick={onSelectAllVendorsHandler}
                                                />
                                            </td>
                                            <td>Name</td>
                                            <td>Service</td>
                                            <td>Email</td>
                                            <td>Phone number</td>
                                            <td>Account no.</td>
                                        </tr>
                                    </thead>
                                    {status === "success" && (
                                        <tbody>
                                            {vendorsData &&
                                                vendorsData?.result.length > 0 &&
                                                vendorsData?.result.map((item, index) => {
                                                    const vendorIconBg = item?.id && item?.name ? generateColorFromId(item.id) : "";
                                                    const styles = { backgroundColor: vendorIconBg };
                                                    const className = index % 2 === 0 ? "even" : "odd";
                                                    const checked =
                                                        selectedVendorIds.length > 0
                                                            ? selectedVendorIds.filter((vendor) => vendor === item?.id).length > 0
                                                                ? true
                                                                : false
                                                            : false;
                                                    return (
                                                        item && (
                                                            <tr key={item.id} className={className}>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={checked}
                                                                        onChange={() => vendorCheckerHandler(item.id)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <span style={styles}>{getFirstChar(item.name)}</span>
                                                                        {item.name}
                                                                    </div>
                                                                </td>
                                                                <td>{item.service}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item.phone_number}</td>
                                                                <td>
                                                                    <h6>{item.bank_name}</h6>
                                                                    <p>{item.account_number}</p>
                                                                </td>
                                                                <td>
                                                                    <SiteDropdown title={<ThreedotIcon />} className="custom three_dot">
                                                                        <Dropdown.Item
                                                                            onClick={() => vendorActionHandler(item, ModalList.edit_vendor_modal)}>
                                                                            Edit
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            onClick={() => vendorActionHandler(item, ModalList.delete_vendor_modal)}>
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    </SiteDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    );
                                                })}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            <div className="card_wrapper">
                                {status === "loading" ? (
                                    <Spinner />
                                ) : (
                                    vendorsData?.result.length === 0 && (
                                        <div className="no_vendor_card">
                                            <div className="icon_wrapper">
                                                <WalletIcon />
                                            </div>
                                            <div className="text">
                                                <h4>You have no saved Vendors.</h4>
                                                {hasSubscribed && <p>Add your vendors details and get access to them whenever.</p>}
                                            </div>
                                            {hasSubscribed && userDetails?.permission !== CollaboratorPermissionList.view_only && (
                                                <button type="button" onClick={addVendorHandler}>
                                                    <PlusIcon fill="#fff" />
                                                    Add vendors
                                                </button>
                                            )}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </NewDashboardLayout>
        </>
    );
}
