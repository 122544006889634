import { Dropdown } from "react-bootstrap";

import DashboardModal from "@/components/modals/DashboardModal";
import Input2 from "@/components/form/Input2";
import CalendarIcon from "@/assets/icon/CalendarIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import SiteDropdown from "@/components/Dropdown";
import AddUserRoundIcon from "@/assets/icon/AddUserRoundIcon";
import SendIcon from "@/assets/icon/SendIcon";
import GalleryIcon from "@/assets/icon/GalleryIcon";
import { getNameInitials } from "@/helper";
import MinusIcon from "@/assets/icon/MinusIcon";
import SelectDateInput from "@/components/form/SelectDateInput";
import Button from "@/components/Button";
import formDetails from "@/json/create_event_form.json";
import useCreateNewEvent from "@/hooks/useCreateNewEvent";
import NewCeremonyPicker from "../form/NewCeremonyPicker";

interface Props {
    onClose: () => void;
}

type CeremonyDetailsType = {
    id: string;
    name: string;
    date: Date | null;
    location: string;
    no_of_guests: string | number;
    checklist: [];
    show_suggestion: boolean;
};

export default function CreateNewEventModal({ onClose }: Props) {
    const { formValues, functions } = useCreateNewEvent();

    const selectedCollaboratorPermission = formDetails.permission.filter((item) => item.name === formValues.collaborator.permission);
    const today = new Date();

    return (
        <DashboardModal title="Create New Event" type="drawer" className="small_width create_new_event" onClose={onClose}>
            <form className="create_new_event_form" onSubmit={(e) => functions.onCreateEventHandler(e, onClose)}>
                <div className="form_row">
                    <div className="input_group">
                        <Input2 input={formDetails.eventName} onChange={functions.updateInputHandler} value={formValues.eventName} />
                        <div className="upload_image">
                            <input onChange={functions.changePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" />{" "}
                            {formValues.previewEventImage ? <img src={formValues.previewEventImage} alt="event image" /> : <GalleryIcon />}
                        </div>
                    </div>
                </div>
                <hr />
                <div className="collaborators_section form_row">
                    <div className="section_title">
                        <div>
                            <CalendarIcon />
                            <h6>Collaborators</h6>
                        </div>
                        {formValues.collaborators.length <= 2 && (
                            <button type="button" onClick={functions.showAddCollaboratorDropdown}>
                                Invite <PlusIcon fill="#000" />
                            </button>
                        )}
                    </div>

                    {formValues.showAddCollaborator && (
                        <section className="add_collaborators">
                            <div className="input_group">
                                <Input2
                                    value={formValues.collaborator.name}
                                    input={formDetails.collaborator}
                                    onChange={functions.updateCollaboratorDetails}
                                />
                                <SiteDropdown className="custom" title={formValues.collaborator.role}>
                                    {formDetails.roleOptions.map((role, index) => (
                                        <Dropdown.Item key={index} type="button" onClick={() => functions.onSelectCollaboratorHandler(role, "role")}>
                                            {role}
                                        </Dropdown.Item>
                                    ))}
                                </SiteDropdown>
                            </div>
                            <div className="top_row">
                                <div className="custom_input">
                                    <AddUserRoundIcon />
                                    <input
                                        className="mb-0"
                                        type="email"
                                        name="email"
                                        value={formValues.collaborator.email}
                                        onChange={functions.updateCollaboratorDetails}
                                        placeholder="Enter email to invite"
                                    />
                                </div>
                                <div className="button_group">
                                    <SiteDropdown className="custom permission" title={selectedCollaboratorPermission[0].label}>
                                        {formDetails.permission.map((permission, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                type="button"
                                                onClick={() => functions.onSelectCollaboratorHandler(permission.name, "permission")}>
                                                {permission.label}
                                            </Dropdown.Item>
                                        ))}
                                    </SiteDropdown>
                                    <button type="button" className="invite_btn" onClick={functions.onInviteCollaboratorRequestHandler}>
                                        <SendIcon />
                                        Invite
                                    </button>
                                </div>
                            </div>
                        </section>
                    )}
                    {formValues.collaborators.length > 0 && (
                        <div className="collaborators_list">
                            {formValues.collaborators.map((collaborator, index) => {
                                return (
                                    collaborator.role !== "Event Owner" && (
                                        <div key={index} className="collaborator">
                                            <div className="collaborator_item">
                                                <div className="image_wrapper">{collaborator?.name ? getNameInitials(collaborator?.name) : ""}</div>
                                                <div className="text_list">
                                                    <h4>{collaborator?.name}</h4>
                                                    <p>{collaborator?.email}</p>
                                                </div>
                                            </div>
                                            <div className="action_group">
                                                <span>{collaborator.role}</span>
                                                <button type="button" onClick={() => functions.removeCollaborator(index)}>
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    )}
                </div>
                <hr />
                <div className="ceremony_section form_row">
                    <div className="section_title">
                        <div>
                            <CalendarIcon />
                            <h6>Ceremonies</h6>
                        </div>
                        <div className="button_action_group">
                            <button type="button" onClick={functions.addMoreCeremonyHandler}>
                                Add <PlusIcon fill="#000" />
                            </button>
                            {formValues.ceremonies.length >= 1 && (
                                <button type="button" onClick={functions.deleteACeremonyHandler}>
                                    Remove <MinusIcon />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="ceremonies">
                        {formValues.ceremonies.map(
                            (ceremonyDetails: CeremonyDetailsType, index: number) => {
                                const counter = index + 1;
                                return (
                                    <div key={index} className="ceremony_input">
                                        <h6>Ceremony {counter}</h6>
                                        <NewCeremonyPicker
                                            key={index}
                                            value={{ label: ceremonyDetails.name }}
                                            defaultValue={{ label: ceremonyDetails.name, value: ceremonyDetails.name }}
                                            options={formValues.ceremonyPickerOptions}
                                            onChange={(option) => functions.updateCeremonyTypeHandler(option, index)}
                                        />
                                        <div className="input_group">
                                            <Input2
                                                className="location_input"
                                                input={formDetails.ceremony.location}
                                                value={ceremonyDetails.location}
                                                onChange={(event) => functions.updateCeremonyHandler(event, index)}
                                                type="text"
                                            />
                                            <SelectDateInput
                                                className="ceremony_date"
                                                label={formDetails.ceremony.date.label}
                                                placeholder={formDetails.ceremony.date.placeholder}
                                                value={ceremonyDetails.date}
                                                containerClassName="input2"
                                                minDate={today}
                                                onClick={(date) => functions.updateCeremonyDateHandler(date, index)}
                                            />
                                            <Input2
                                                className="select_input"
                                                input={formDetails.ceremony.guestNumber}
                                                value={ceremonyDetails.no_of_guests ? ceremonyDetails.no_of_guests.toLocaleString("en-US") : ""}
                                                onChange={(event) => functions.updateCeremonyHandler(event, index)}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                );
                            },
                        )}
                    </div>
                </div>
                <div className="submit_btn">
                    <Button type="submit" text="Save Event" disabled={formValues.loading} className="btn bg_red" isLoading={formValues.loading} />
                </div>
            </form>
        </DashboardModal>
    );
}
