import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Loader from "@/components/loader";
import useEventWeather from "@/hooks/useEventWeather";
import { EventType } from "@/types";

interface Props {
	currentCeremony: EventType["ceremonies"][0];
}

export default function ForecastCeremonyWeather({ currentCeremony }: Props) {
    const weatherDetails = useEventWeather(currentCeremony);

    return (
        <>
            {currentCeremony?.location && currentCeremony?.date && (
                <div className="weather mb-0">
                    {weatherDetails?.weatherStatus ? (
                        <span className="loader">
                            <Loader />
                        </span>
                    ) : (
                        weatherDetails?.forecastRequestStatus && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="weather_description">weather forecast for {currentCeremony.name}</Tooltip>}>
                                <span className="d-flex mb-0 align-items-center">
                                    {weatherDetails?.weatherIcon && (
                                        <img
                                            src={weatherDetails?.weatherIcon}
                                            width="30px"
                                            height="30px"
                                            alt={weatherDetails?.weatherCondition?.description}
                                        />
                                    )}
                                    <h6 className="mr-1 mb-0">{weatherDetails?.eventForecastTemperature}℃</h6>
                                </span>
                            </OverlayTrigger>
                        )
                    )}
                </div>
            )}
        </>
    );
}
