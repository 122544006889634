import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import type { ReactNode } from "react";
import Heart1 from "@/assets/icon/Heart1";
import Heart2 from "@/assets/icon/Heart2";
import heart3 from "@/assets/img/heart3.svg";
import heart4 from "@/assets/img/heart4.svg";
import "@/styles/asoebi.scss";

// toast.configure();

const wallaperStyle = {
    // backgroundImage: `url(${wallpaper})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
};

interface Props {
	children: ReactNode;
    showNav?: boolean
}

export default function AsoebiLayout({ children, showNav=true }: Props) {
    return (
        <>
            {showNav && <Navbar />}
            <div className="section-bar" />
            <main className={`page asoebi_layout`} style={wallaperStyle}>
                <div className="heart1">
                    <Heart1 />
                </div>
                <div className="heart2">
                    <Heart2 />
                </div>
                <div className="content container">{children}</div>
                <div className="heart3">
                    <img src={heart3} alt="Heart shapes" />
                </div>
                <div className="heart4">
                    <img src={heart4} alt="Heart shapes" />
                </div>
            </main>
            <div className="section-bar" />
            {showNav && <Footer />}
        </>
    );
}
