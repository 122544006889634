import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";

import SiteModal from "@/components/modals/SiteModal";
import Input from "@/components/form/Input";
import checklistFormContent from "@/json/form/checklistItem.json";
import Textarea from "@/components/form/Textarea";
import Button from "@/components/Button";
import SelectDate from "@/components/form/SelectDate";
import { updateUserChecklist } from "@/axios/put-request";
import { useChecklist } from "@/hooks/useChecklist";
import { useEvent } from "@/hooks/useEvent";
import { updateAudit } from "@/axios/post-request";
import useUser from "@/hooks/useUser";
import useMyEvents from "@/hooks/useMyEvents";

type taskType = {
    isDone: boolean;
    due?: Date | null;
    name: string;
    note?: string | null;
    id: string;
    ceremony: { name: string; id: string };
};

interface Props {
    checklist: taskType;
    show: boolean;
    onHide: () => void;
}

export default function EditChecklistItemModal({ checklist, show, onHide }: Props) {
    const queryClient = useQueryClient();
    const [task, setTask] = useState<taskType>(checklist);
    const [loading, setIsLoading] = useState(false);
    const { setSelectectedChecklist } = useChecklist();
    const { selectedEventId } = useEvent();
    const [ceremonyId, setCeremonyId] = useState<null | string>(null);

    useEffect(() => {
        if (!ceremonyId) {
            setCeremonyId(checklist.ceremony.id);
        }
    }, []);

    const ceremonyID = ceremonyId as string;

    const { data: userData } = useUser();
    const { activeEvent, status } = useMyEvents();

    const { mutate } = useMutation({
        mutationKey: ["update_checklist"],
        onMutate: () => {
            setIsLoading(true);
        },
        mutationFn: () =>
            updateUserChecklist(
                {
                    ...task,
                },
                ceremonyID,
                selectedEventId,
            ),
        onSuccess: (data) => {
            setIsLoading(false);
            toast.success(data.data.message);
            updateAudit(
                [{
                    id: uuidv4(),
                    name: userData?.result?.name as string,
                    user_id: userData?.result?.id as string,
                    email: userData?.result?.email as string,
                    type: "checklist",
                    action: "update_checklist",
                    changes: {
                        from: {
                            id: checklist.id,
                            name: checklist.name,
                            note: checklist?.note ? checklist?.note : null,
                            due: checklist?.due ? checklist?.due : null,
                            ceremony: checklist.ceremony,
                            isDone: checklist.isDone,
                        },
                        to: {
                            id: checklist.id,
                            name: task.name,
                            note: task?.note ? task?.note : null,
                            due: task?.due ? task?.due : null,
                            ceremony: task.ceremony,
                            isDone: task.isDone,
                        },
                    },
                    title: task.name,
                    timestamp: new Date(),
                    metadata: {
                        ceremony: task.ceremony.name,
                    },
                }],
                selectedEventId,
            );
            setSelectectedChecklist(null);
            queryClient.invalidateQueries({ queryKey: [`get_event_${selectedEventId}`], exact: true });
            queryClient.invalidateQueries({ queryKey: [`get_audits_${selectedEventId}`], exact: true });
            onHide();
        },
        onError: (error: unknown) => {
            setIsLoading(false);
            const _error = error as { message: string };
            toast.error(_error?.message || "Oops unable to update checklist");
        },
    });

    function inputHandler(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setTask({ ...task, [e.target.name]: e.target.value });
    }

    function selectDateHandler(due: Date) {
        setTask({ ...task, due });
    }

    function onSelectChange(e: ChangeEvent<HTMLSelectElement>) {
        if (activeEvent) {
            const ceremonyName = activeEvent[0]?.ceremonies.filter((ceremony) => ceremony.id === e.target.value)[0].name as string;
            setTask({
                ...task,
                ceremony: { id: e.target.value, name: ceremonyName },
            });
        }
    }

    async function onSubmitHandler(e: ChangeEvent<HTMLFormElement>) {
        e.preventDefault();
        mutate();
    }

    const note = task?.note ? task?.note : "";

    return (
        <SiteModal title="Edit Checklist Item" show={show} onHide={onHide}>
            <section>
                <p className="mb-4">Edit ceremony checklist</p>
                <form className="checklist_item_modal mb-3" onSubmit={onSubmitHandler}>
                    <Input input={checklistFormContent.input} value={task?.name} onChange={inputHandler} />
                    <div className="d-flex form_group">
                        <div className="col-md-8 col-12 px-0">
                            <SelectDate value={checklist.due} label="Deadline" onClick={selectDateHandler} />
                        </div>
                        <div className="col-md-4 col-12 px-0">
                            <div className="form_element select d-flex flex-column">
                                <label htmlFor="ceremony_tag">Ceremony</label>
                                {status === "error" ? (
                                    <p>Unable to fetch ceremonies</p>
                                ) : status === "loading" ? (
                                    <p>Fetching ceremonies...</p>
                                ) : (
                                    <select id="ceremony_tag" className="form-control" onChange={onSelectChange} value={task.ceremony.id}>
                                        <option>Select Ceremony</option>
                                        {activeEvent &&
                                            activeEvent[0].ceremonies.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name ? item.name : "Main Wedding"}
                                                </option>
                                            ))}
                                    </select>
                                )}
                            </div>
                        </div>
                    </div>
                    <Textarea value={note} input={checklistFormContent.textarea} onChange={inputHandler} />
                    <div className="d-flex justify-content-end mt-2 align-items-end">
                        <Button text="Save" className="bg_red text-white" type="submit" isLoading={loading} />
                    </div>
                </form>
            </section>
        </SiteModal>
    );
}
