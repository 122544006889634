import { useFormContext, type FieldError } from "react-hook-form";

interface Props {
	input: {
		name: string;
		placeholder: string;
		type: string;
	};
    defaultValue?: string;
    readOnly?:boolean;
	error?: FieldError;
    className?:string;
}

export default function CustomInput({ input, error, defaultValue, readOnly=false, className="" }: Props) {
    const { name, placeholder, type } = input;
    const { register } = useFormContext();
    const errorInput = error?.message ? "error" : "";
    return (
        <div className={`w-full form_element input custom_input ${className}`}>
            <input
                className={`form-control ${errorInput}`}
                defaultValue={defaultValue}
                placeholder={placeholder}
                type={type}
                readOnly={readOnly}
                {...register(name)} />
            {error?.message && <p className="m-0 py-1">{error?.message}</p>}
        </div>
    );
}
