import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";

import Button from "@/components/Button";
import CustomInput from "@/components/form/CustomInput";
import AsoebiLayout from "@/layout/AsoebiLayout";
import { validateEmail } from "@/helper";
import CustomPasswordInput from "@/components/form/CustomPasswordInput";
import useAuth from "@/hooks/useAuth";

const inputDetails = [
    { name: "email", placeholder: "Email address", type: "email" },
    { name: "password", placeholder: "Password", type: "password" }
];

const schema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required"),
});

type formType = { email: string; password: string };

export default function AsoebiAdminLogin() {
    const [loading, setLoading] = useState(false);
    const { signInAsoebiAdminWithEmailAndPassword } = useAuth();
    const methods = useForm<formType>({
        resolver: yupResolver(schema),
    });

    async function onSubmitHandler(data: formType) {
        if (!validateEmail(data.email)) {
            return toast.error("Invalid email address");
        }
        setLoading(true);
        await signInAsoebiAdminWithEmailAndPassword(data.email, data.password);
        setLoading(false);
    }

    const error1 = methods.formState.errors["email"];
    const error2 = methods.formState.errors["password"];

    return (
        <AsoebiLayout>
            <section className="vh-100 ">
                <FormProvider {...methods}>
                    <form
                        className="planner_login col-lg-6 col-md-9 col-11 my-5 p-md-4 py-1 px-3 mx-auto bg-white shadow rounded"
                        onSubmit={methods.handleSubmit(onSubmitHandler)}>
                        <h2 className="mt-5 text-dark text-center">Login</h2>
                        <CustomInput className="my-4" input={inputDetails[0]} error={error1} />
                        <CustomPasswordInput className="my-4" input={inputDetails[1]} error={error2} />
                        <Button
                            type="submit"
                            text="Login"
                            className="btn btn-primary px-4 py-2 font-weight-bold text-white mx-auto mt-md-4 mb-5"
                            isLoading={loading}
                        />
                    </form>
                </FormProvider>
            </section>
        </AsoebiLayout>
    );
}
