/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Rect } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
    x: number;
    y: number;
    id: string;
    width: number;
    height: number;
    fill: string;
    type: FloorPlanObjectListType;
};

interface Props {
    item: itemType;
    refValue?: React.MutableRefObject<undefined | any>;
    onSelect?: () => void;
}

export default function DimensionElement({ item }: Props) {
    return (
        <Rect
            strokeWidth={2}
            stroke="#000"
            height={item.height}
            width={item.width}
            fill={item.fill}
            preventDefault
            // onClick={onSelect}
            draggable={false}
            // ref={refValue}
            strokeScaleEnabled={false}
            x={item.x}
            y={item.y}
        />
    );
}
