import { getAuth } from "firebase/auth";
import axios from "axios";
import { app } from "@/hooks/useAuth";

export const use_budget_tracker_base_url = process.env.REACT_APP_PROD_SERVER_BASE_URL;

export function getUID() {
    const auth = getAuth(app);
    const UID: string = auth?.currentUser?.uid as string;
    return UID;
}

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["API_KEY"] = `${process.env.REACT_APP_API_KEY}`;

axios.interceptors.request.use(function(config: unknown) {
    const _config = config as {headers: {UID:string}};
    if (!_config?.headers?.UID) {
        const UID = getUID();
        _config.headers["UID"] = UID;
    }
    return config;
});

export default axios;
