import SiteModal from "@/components/modals/SiteModal";
import Button from "@/components/Button";
import trash from "@/assets/img/trash_white.svg";

interface Props {
    title: string;
    show: boolean;
    onHide: () => void;
    onDelete: () => void;
    isLoading: boolean;
}

export default function DeleteModal({ title, isLoading, show, onHide, onDelete }: Props) {
    return (
        <SiteModal
            title={`Are you sure you want to delete '${title}'`}
            show={show}
            onHide={onHide}
        >
            <div className="d-flex justify-content-between my-4">
                <Button
                    text="Cancel"
                    className="bg_dark text-white"
                    onClick={onHide}
                />
                <Button
                    text="Delete"
                    className="bg_red text-white"
                    onClick={onDelete}
                    isLoading={isLoading}
                    icon={trash}
                />
            </div>
        </SiteModal>
    );
}
