import { toast } from "react-toastify";
import type { FormEvent } from "react";

import DashboardModal from "@/components/modals/DashboardModal";
import Input2 from "@/components/form/Input2";
import CalendarIcon from "@/assets/icon/CalendarIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import MinusIcon from "@/assets/icon/MinusIcon";
import SelectDateInput from "@/components/form/SelectDateInput";
import Button from "@/components/Button";
import formDetails from "@/json/create_event_form.json";
import useCeremony from "@/hooks/useCeremony";
import NewCeremonyPicker from "../form/NewCeremonyPicker";

interface Props {
	onClose: () => void;
	isUserEventAvailable: boolean;
}

export default function CreateNewCeremonyModal({ onClose, isUserEventAvailable }: Props) {
    const { formValues, functions } = useCeremony();

    function createNewCeremonyHandler(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!isUserEventAvailable) {
            return toast.error("No available event, please create an event");
        }
        functions.onCreateCeremonyHandler(onClose);
    }

    const today = new Date();

    return (
        <DashboardModal title="Create New Ceremony" type="drawer" className="small_width create_new_event" onClose={onClose}>
            <form className="create_new_event_form create_ceremony_form" onSubmit={createNewCeremonyHandler}>
                <div className="ceremony_section form_row">
                    <div className="section_title">
                        <div>
                            <CalendarIcon />
                            <h6>Ceremonies</h6>
                        </div>
                        <div className="button_action_group">
                            <button type="button" onClick={functions.addMoreCeremonyHandler}>
								Add <PlusIcon />
                            </button>
                            {formValues.ceremonies.length >= 1 && (
                                <button type="button" onClick={functions.deleteACeremonyHandler}>
									Remove <MinusIcon />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="ceremonies">
                        {formValues.ceremonies.map(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (ceremonyDetails: any, index: number) => {
                                const counter = index + 1;
                                return (
                                    <div key={index} className="ceremony_input">
                                        <div>
                                            <h6>Ceremony {counter}</h6>
                                            {/* <div className="dropdown_wrapper input_group">
                                                <InputDropdown
                                                    input={formDetails.ceremony.type}
                                                    value={ceremonyDetails.name}
                                                    options={onboardingFormContent.ceremonyOptions}
                                                    use_label
                                                    onSelect={(option) => functions.updateCeremonyTypeHandler(option, index)}
                                                    type="text"
                                                />
                                            </div> */}
                                            <NewCeremonyPicker
                                                key={index}
                                                value={{ label: ceremonyDetails.name }}
                                                defaultValue={{ label: ceremonyDetails.name, value: ceremonyDetails.name }}
                                                options={formValues.ceremonyPickerOptions}
                                                onChange={(option) => functions.updateCeremonyTypeHandler(option, index)}
                                            />
                                        </div>
                                        <div className="input_group">
                                            <Input2
                                                className="location_input"
                                                input={formDetails.ceremony.location}
                                                value={ceremonyDetails.location}
                                                onChange={(event) => functions.updateCeremonyHandler(event, index)}
                                                type="text"
                                            />
                                            <SelectDateInput
                                                className="ceremony_date"
                                                label={formDetails.ceremony.date.label}
                                                placeholder={formDetails.ceremony.date.placeholder}
                                                value={ceremonyDetails.date}
                                                containerClassName="input2"
                                                minDate={today}
                                                onClick={(date) => functions.updateCeremonyDateHandler(date, index)}
                                            />
                                            <Input2
                                                className="select_input"
                                                input={formDetails.ceremony.guestNumber}
                                                value={ceremonyDetails.no_of_guests ? ceremonyDetails.no_of_guests.toLocaleString("en-US") : ""}
                                                onChange={(event) => functions.updateCeremonyHandler(event, index)}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                );
                            },
                        )}
                    </div>
                </div>
                <div className="submit_btn">
                    <Button type="submit" text="Save ceremony" className="btn bg_red" isLoading={formValues.loading} />
                </div>
            </form>
        </DashboardModal>
    );
}
