import Button from "@/components/Button";
import AddBudgetList from "@/components/AddBudgetList";
import arrowdown from "@/assets/img/arrow_chevron_down.svg";
import arrowup from "@/assets/img/arrow-chevron_up.svg";
import { useFilter } from "@/hooks/useFilter";
import { useBudgetDropdown } from "@/hooks/useBudgetDropdown";
import trash from "@/assets/img/trash.svg";
import DeleteBudgetCategoryModal from "./modals/DeleteBudgetCategoryModal";
import type { categoryType } from "@/types";
import { useState } from "react";
import { useModal } from "@/hooks/useModal";

interface Props {
    category: categoryType;
    index: number;
}

export default function BudgetList({ category, index }: Props) {
    const { budgetDropdown, setBudgetDropdown } = useBudgetDropdown();
    const { ceremony } = useFilter();
    const {modal, setModal } = useModal();

    const [selectedCategory, setSelectedCategory] = useState({
        id: "",
        name: ""
    });

    function toggleDropdown() {
        const dropdownValue = budgetDropdown.categoryId === category.id ? null : category.id;
        setBudgetDropdown({
            ...budgetDropdown,
            categoryId: dropdownValue,
        });
    }

    const selectedCostItems = ceremony?.id !== "all" ? category.cost_items.filter((item) => item.tag === ceremony?.id) : category.cost_items;

    const categoryCount = selectedCostItems.length;
    const budgetItem = categoryCount > 1 ? "items" : "item";

    const totalActualCost = selectedCostItems.reduce((a, b) => a + b.actual, 0);
    const totalEstimate = selectedCostItems.reduce((a, b) => a + b.estimate, 0);

    const dropdownIcon = budgetDropdown.categoryId === category.id ? arrowup : arrowdown;
    const dropdownIconClassname = budgetDropdown.categoryId === category.id ? "mt-3 mr-1" : "mt-4 mr-0";
    const categoryEstimate = totalEstimate.toLocaleString("en-US");
    const categoryActual = totalActualCost.toLocaleString("en-US");

    function showDeleteBudgetCategoryModalHandler(id:string, name:string) {
        setModal("delete_budget_category_modal");
        setSelectedCategory({id, name});
    }

    function closeModalHandler() {
        setModal(null);
        setSelectedCategory({id: "", name: ""});
    }

    const showModal = modal === "delete_budget_category_modal";

    return (
        <>
            <DeleteBudgetCategoryModal
                categoryId={category.id}
                selectedCategory={selectedCategory}
                showModal={showModal}
                noOfCostItem={category.cost_items.length}
                closeModal={closeModalHandler}
            />
            <div className="budget_list">
                <div className="d-flex justify-content-between mb-3 flex-row align-items-start align-items-lg-center">
                    <h4 className="mb-0">{category.name}</h4>
                    <div className="d-flex align-items-center">
                        <p className="mb-0 p-0">
                            {categoryCount} {budgetItem}
                        </p>
                        <button
                            className="btn p-0 ml-4 d-md-flex d-none"
                            onClick={() => showDeleteBudgetCategoryModalHandler(category.id, category.name)}>
                            <img src={trash} />
                        </button>
                    </div>
                </div>
                <div className="budget_rate mt-1 d-flex justify-content-between align-items-start align-items-lg-center">
                    <div className="prices  d-flex flex-lg-row flex-column align-items-start align-items-lg-center">
                        <h6 className="mb-0">
                            <span className="mr-1">ESTIMATE:</span>₦{categoryEstimate}
                        </h6>
                        <h6 className="mb-0">
                            <span className="mr-1">ACTUAL:</span>₦{categoryActual}
                        </h6>
                    </div>
                    <div className="cost_item_category_buttons">
                        <Button id={`toggle_budget_breakdown_category_${index}`} icon={dropdownIcon} onClick={toggleDropdown} className="p-0" />
                        <button
                            className={`btn p-0 ${dropdownIconClassname} d-flex d-md-none delete_category_btn`}
                            onClick={() => showDeleteBudgetCategoryModalHandler(category.id, category.name)}>
                            <img src={trash} />
                        </button>
                    </div>
                </div>
                {budgetDropdown.categoryId === category.id && <AddBudgetList category={category} />}
            </div>
        </>
    );
}
