/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Path, Group } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	height: number;
	type: FloorPlanObjectListType;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: (resetScale?: boolean) => void;
}

export default function SofaElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            name="floor_plan_element"
            scaleX={3}
            {...item}
            scaleY={3}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Path data="M0 2C0 1.44772 0.447715 1 1 1H13C13.5523 1 14 1.44772 14 2V14C14 14.5523 13.5523 15 13 15H1C0.447716 15 0 14.5523 0 14V2Z" />
            <Path
                data="M0 2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2C14 2 13.5523 2 13 2H1C0.447715 2 0 2 0 2ZM14 15H0H14ZM0 15V1V15ZM14 1V15V1Z"
                fill="#E845BA"
            />
            <Path
                data="M1 15L13 15C13.5523 15 14 14.5523 14 14L14 2C14 1.44772 13.5523 1 13 1L0.999999 1C0.447715 1 -5.92389e-07 1.44772 -5.68248e-07 2L-4.37114e-08 14C-1.95703e-08 14.5523 0.447716 15 1 15Z"
                fill="#D9D9D9"
            />
            <mask id="path-5-outside-2_11427_19950" maskUnits="userSpaceOnUse" x="14" y="0" width="14" height="15" fill="black">
                <rect fill="white" x="14" width="14" height="15" />
                <Path data="M14 2C14 1.44772 14.4477 1 15 1H27C27.5523 1 28 1.44772 28 2V14C28 14.5523 27.5523 15 27 15H15C14.4477 15 14 14.5523 14 14V2Z" />
            </mask>
            <Path
                data="M14 2C14 0.895431 14.8954 0 16 0H26C27.1046 0 28 0.895431 28 2C28 2 27.5523 2 27 2H15C14.4477 2 14 2 14 2ZM28 15H14H28ZM14 15V1V15ZM28 1V15V1Z"
                fill="#E845BA"
            />
            <Path
                data="M15 15L27 15C27.5523 15 28 14.5523 28 14L28 2C28 1.44772 27.5523 1 27 1L15 1C14.4477 1 14 1.44772 14 2L14 14C14 14.5523 14.4477 15 15 15Z"
                fill="#D9D9D9"
            />

            <Path data="M28 2C28 1.44772 28.4477 1 29 1H41C41.5523 1 42 1.44772 42 2V14C42 14.5523 41.5523 15 41 15H29C28.4477 15 28 14.5523 28 14V2Z" />
            <Path
                data="M28 2C28 0.895431 28.8954 0 30 0H40C41.1046 0 42 0.895431 42 2C42 2 41.5523 2 41 2H29C28.4477 2 28 2 28 2ZM42 15H28H42ZM28 15V1V15ZM42 1V15V1Z"
                fill="#E845BA"
            />
            <Path
                data="M29 15L41 15C41.5523 15 42 14.5523 42 14L42 2C42 1.44772 41.5523 1 41 1L29 1C28.4477 1 28 1.44772 28 2L28 14C28 14.5523 28.4477 15 29 15Z"
                fill="#D9D9D9"
            />
        </Group>
    );
}
