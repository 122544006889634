import { format } from "date-fns";

import SiteModal from "./SiteModal";
import type { AsoebiOrderFormType } from "@/types";
import { Accordion, Card } from "react-bootstrap";

interface Props {
	show: boolean;
	onHide: () => void;
	orderFormDetails?: AsoebiOrderFormType;
}

export default function ViewAsoebiOrderFormModal({ show, orderFormDetails, onHide }: Props) {
    const currency = orderFormDetails?.asoebiOptions.currency && orderFormDetails?.asoebiOptions.currency.includes("Dollar") ? "$" : "₦";
    return (
        <SiteModal title={`${orderFormDetails?.eventDetails.event_name} Orderform`} show={show} onHide={onHide}>
            <section className="view_asoebi_order_foorm_modal">
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle
                                className="text-dark w-full d-flex cursor-pointer font-weight-bold border-0 bg-transparent"
                                eventKey="0">
								Event details
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div className="d-flex flex-column w-full justify-content-between">
                                    <p>Event owner's name: {orderFormDetails?.eventDetails.name ?? "N/A"}</p>
                                    <p>Event name: {orderFormDetails?.eventDetails.event_name ?? "N/A"}</p>
                                    <p>Email: {orderFormDetails?.eventDetails.email ?? "N/A"}</p>
                                    <p>
										Phone number:{" "}
                                        {orderFormDetails?.eventDetails.phone_number ? (
                                            <a href={`tel:${orderFormDetails?.eventDetails.phone_number}`}>
                                                {orderFormDetails?.eventDetails.phone_number}
                                            </a>
                                        ) : (
                                            "N/A"
                                        )}
                                    </p>
                                    <p>
										Created: {orderFormDetails?.created_at ? format(new Date(orderFormDetails.created_at), "dd/MM/yyyy") : "N/A"}
                                    </p>
                                    <p>
                                        {orderFormDetails?.questionnaire_link ? (
                                            <a className="font-weight-bold" href={orderFormDetails?.questionnaire_link}>
												View order form link
                                            </a>
                                        ) : (
                                            "N/A"
                                        )}
                                    </p>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle
                                className="text-dark w-full d-flex cursor-pointer font-weight-bold border-0 bg-transparent"
                                eventKey="1">
								Bank details
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <div className="  d-flex flex-column w-full justify-content-between">
                                    <p>Bank: {orderFormDetails?.eventDetails.bankName ?? "N/A"}</p>
                                    <p>Account name: {orderFormDetails?.eventDetails.accountName ?? "N/A"}</p>
                                    <p>Account number: {orderFormDetails?.eventDetails.accountNumber ?? "N/A"}</p>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle
                                className="text-dark w-full d-flex cursor-pointer font-weight-bold border-0 bg-transparent"
                                eventKey="2">
								Order form details
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <div className="d-flex flex-column w-full justify-content-between">
                                    <p>
										Order deadline:{" "}
                                        {orderFormDetails?.asoebiOptions.order_deadline ?
                                            format(new Date(orderFormDetails.asoebiOptions.order_deadline), "dd/MM/yyyy") :
                                            "N/A"}
                                    </p>
                                    {orderFormDetails?.guestDetails.pickup_address && (
                                        <p>Pickup address: {orderFormDetails?.guestDetails.pickup_address}</p>
                                    )}
                                    {orderFormDetails?.asoebiOptions.email_address_for_communication && (
                                        <p>Email address for communication: {orderFormDetails?.asoebiOptions.email_address_for_communication}</p>
                                    )}
                                    {orderFormDetails?.guestDetails.pickup_phone_number && (
                                        <p>
											Pickup phone number:{" "}
                                            {orderFormDetails?.guestDetails.pickup_phone_number ? (
                                                <a href={`tel:${orderFormDetails?.guestDetails.pickup_phone_number}`}>
                                                    {orderFormDetails?.guestDetails.pickup_phone_number}
                                                </a>
                                            ) : (
                                                "N/A"
                                            )}
                                        </p>
                                    )}
                                    {orderFormDetails?.asoebiOptions.whatsapp_number && (
                                        <p>
											Whatsapp phone number:{" "}
                                            {orderFormDetails?.asoebiOptions.whatsapp_number ? (
                                                <a href={`tel:${orderFormDetails?.asoebiOptions.whatsapp_number}`}>
                                                    {orderFormDetails?.asoebiOptions.whatsapp_number}
                                                </a>
                                            ) : (
                                                "N/A"
                                            )}
                                        </p>
                                    )}
                                    <p>Order notification: {orderFormDetails?.guestDetails.order_update ?? "N/A"}</p>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle
                                className="text-dark w-full d-flex cursor-pointer font-weight-bold border-0 bg-transparent"
                                eventKey="3">
								Asoebi options
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <div className="d-flex flex-column w-full justify-content-between">
                                    <div className="asoebi_images">
                                        {orderFormDetails?.asoebiOptions.images?.map((image, index) => (
                                            <img key={index} src={image} alt="asoebi" />
                                        ))}
                                    </div>
                                    <div>
                                        {orderFormDetails?.asoebiOptions.options.map((item, index) => (
                                            <div key={index} className="option_details border mb-4 pt-3 px-4 pb-1">
                                                <p>Name: {item.option}</p>
                                                <p>Price: {currency}{item.price}</p>
                                                <p>Stock quantity: {item.max_stock_qty}</p>
                                                <p>Sold quantity: {item.stock_bought_qty}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </section>
        </SiteModal>
    );
}
