import { ReactNode } from "react";

import PricingTableView from "@/components/PricingTableView";
import MobilePricingView from "./MobilePricingView";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import type { pricingPlanType } from "@/types";

interface Props {
    selectPricingPlan: "basic" | "essential" | "premium" | "all" | "";
    choosePricingPlan?: (plan: pricingPlanType) => void;
    children?: ReactNode;
    type: "event" | "collaborator" | "all";
}

export default function PricingTable({ choosePricingPlan, type, selectPricingPlan, children }: Props) {
    const tablet = useMediaQuery("(max-width: 768px)");

    function choosePricingPlanHandler(plan: "basic" | "essential" | "premium") {
        if (choosePricingPlan) {
            choosePricingPlan(plan);
        }
    }

    return (
        <>
            {selectPricingPlan === "" ? (
                <>
                    {tablet ? (
                        <MobilePricingView type={type} choosePricingPlanHandler={choosePricingPlanHandler} />
                    ) : (
                        <PricingTableView type={type} choosePricingPlanHandler={choosePricingPlanHandler} />
                    )}
                </>
            ) : (
                children
            )}
        </>
    );
}
