import BuoyIcon from "@/assets/icon/BuoyIcon";
import DrumIcon from "@/assets/icon/DrumIcon";
import JournalIcon from "@/assets/icon/JournalIcon";
import RentalsIcon from "@/assets/icon/RentalsIcon";
import SecurityIcon from "@/assets/icon/SecurityIcon";
import ShirtIcon from "@/assets/icon/ShirtIcon";
import UtensilsIcon from "@/assets/icon/Utensils";

interface Props {
	iconName: string;
}

export default function DisplayBudgetCategoryIcon({ iconName }: Props) {
    switch (iconName) {
    case "Planning & decoration":
        return <JournalIcon />;
    case "Wedding & apparel":
        return <ShirtIcon />;
    case "Entertainment & media":
        return <DrumIcon />;
    case "Food & drink":
        return <UtensilsIcon />;
    case "Security & staffing":
        return <SecurityIcon />;
    case "Rentals":
        return <RentalsIcon />;
    default:
        return <BuoyIcon />;
    }
}
