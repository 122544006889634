import CreatableSelect from "react-select/creatable";
import type { SingleValue, ActionMeta } from "react-select";
import onboardingFormContent from "@/json/form/onboarding_form.json";
import "@/styles/ceremony_picker.scss";

type multiType = { [key: string]: string | number };

interface Props {
    className?: string;
    isMulti?: boolean;
    value?: multiType;
    defaultValue?: Array<{ label: string; value: string }> | { label: string; value: string };
    onChange: (option: SingleValue<{ label: string; value: string }>, actionMeta: ActionMeta<{ label: string; value: string }>) => void;
    options?: Array<{ label: string; value: string }>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function NewCeremonyPicker({ className = "", options, value, onChange, defaultValue, ...rest }: Props) {
    const ceremonyPickerOptions = options ? options : onboardingFormContent.ceremonyOptions;

    return (
        <div className={`form_element search_select new_ceremony_picker ${className}`}>
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
                <label htmlFor="ceremonies" className="mb-0">
                    Ceremony Type
                </label>
            </div>
            <div className="d-flex">
                <CreatableSelect
                    {...rest}
                    styles={{
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        option: (providedStyles, props: any) => ({
                            ...providedStyles, // To keep the default style
                            color: props.data.__isNew__ ? "#e72e50" : "black",
                            backgroundColor: props.data.__isNew__ ? "white" : "white",
                            fontWeight: props.data.__isNew__ ? "bold" : "normal",
                            width: props.data.__isNew__ && "fit-content",
                            borderRadius: props.data.__isNew__ && "10px",
                            marginLeft: props.data.__isNew__ && "10px",
                            marginTop: props.data.__isNew__ && "5px",
                            marginBottom: props.data.__isNew__ && "5px",
                        }),
                    }}
                    defaultValue={defaultValue}
                    classNamePrefix="select"
                    isMulti={false}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Select Ceremony or start typing to create one"
                    name="ceremonies"
                    options={ceremonyPickerOptions}
                    onChange={onChange}
                    className="w-100 ceremony_picker_select"
                />
            </div>
        </div>
    );
}
