import { useState } from "react";
import type { ChangeEvent } from "react";

import eyeIcon from "@/assets/img/eye_icon.svg";
import closedEyeIcon from "@/assets/img/closed_eye.svg";
import "@/styles/form.scss";

interface Props {
    input: {
        label?: string;
        placeholder: string;
        name: string;
    };
    className?: string;
    showCurrency?: boolean;
    value: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}
export default function PasswordInput({ input, onChange, value = "", className }: Props) {
    const inputClassname = className ? className : "";
    const [showPassword, setShowPassword] = useState(false);

    function showPasswordHandler() {
        setShowPassword((prev) => !prev);
    }

    const passwordType = showPassword ? "text" : "password";

    const passwordIcon = showPassword ? eyeIcon : closedEyeIcon;

    return (
        <div className={`form_element password_input ${inputClassname}`}>
            {input?.label && <label htmlFor={input.name}>{input?.label}</label>}
            <div className="position-relative">
                <input
                    name={input.name}
                    placeholder={input.placeholder}
                    className="form-control"
                    type={passwordType}
                    value={value}
                    onChange={onChange}
                    autoComplete=""
                />
                {value.length > 0 && (
                    <button onClick={showPasswordHandler} type="button">
                        <img src={passwordIcon} alt="eye" />
                    </button>
                )}
            </div>
        </div>
    );
}
