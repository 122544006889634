import SiteModal from "@/components/modals/SiteModal";
import { Link } from "react-router-dom";
import logo from "@/assets/img/planaday_logo_small.png";

import ArrowRight from "@/assets/icon/ArrowRight";
import "@/styles/welcome_modal.scss";

interface Props {
    show: boolean;
    onHide: () => void;
}

/**
 * HomepageWelcomeModal
 * @param show
 * @param onHide
 * @returns JSX
 */

export default function HomepageWelcomeModal({ show, onHide }: Props) {
    return (
        <SiteModal className="welcome_modal" backdropClassName="welcome_modal_backdrop" show={show} onHide={onHide}>
            <section className="welcome_modal_content">
                <div className="wrapper">
                    <img src={logo} alt="welcome image" />
                </div>

                <div className="text_content">
                    <h4>Welcome to Planaday ✨</h4>
                    <p className="text_1">You’re all set to supercharge your business!</p>
                    <div className="first_step">First steps;</div>
                    <ul>
                        <Link to="/dashboard" onClick={onHide} className="first">
                            Create your first event 🎪 <ArrowRight />
                        </Link>
                        <Link to="/dashboard/vendors" onClick={onHide}>
                            Setup your vendor list 🚀 <ArrowRight />
                        </Link>
                    </ul>
                </div>
                <div className="button_group">
                    <button className="border_up" onClick={onHide}>
                        Start Planning
                    </button>
                </div>
            </section>
        </SiteModal>
    );
}
