/* eslint-disable max-len */

export default function DoubleOpenDoorIcon() {
    return (
        <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_11634_7304" fill="white">
                <path d="M14.0037 13.9998C14.0037 12.1608 13.6414 10.3399 12.9377 8.64084C12.2339 6.94182 11.2024 5.39806 9.90206 4.0977C8.60169 2.79733 7.05793 1.76582 5.35892 1.06207C3.6599 0.358311 1.83891 -0.003906 -8.39233e-05 -0.00390564L-8.14039e-05 13.9998L14.0037 13.9998Z" />
            </mask>
            <path
                d="M14.0037 13.9998C14.0037 12.1608 13.6414 10.3399 12.9377 8.64084C12.2339 6.94182 11.2024 5.39806 9.90206 4.0977C8.60169 2.79733 7.05793 1.76582 5.35892 1.06207C3.6599 0.358311 1.83891 -0.003906 -8.39233e-05 -0.00390564L-8.14039e-05 13.9998L14.0037 13.9998Z"
                fill="white"
                fillOpacity="0.8"
                stroke="#404040"
                strokeWidth="0.56"
                mask="url(#path-1-inside-1_11634_7304)"
            />
            <mask id="path-2-inside-2_11634_7304" fill="white">
                <path d="M28.0001 -0.00390686C26.1611 -0.0039068 24.3401 0.358311 22.6411 1.06207C20.9421 1.76582 19.3983 2.79733 18.0979 4.0977C16.7976 5.39806 15.7661 6.94183 15.0623 8.64084C14.3586 10.3399 13.9963 12.1608 13.9963 13.9998L28.0001 13.9998L28.0001 -0.00390686Z" />
            </mask>
            <path
                d="M28.0001 -0.00390686C26.1611 -0.0039068 24.3401 0.358311 22.6411 1.06207C20.9421 1.76582 19.3983 2.79733 18.0979 4.0977C16.7976 5.39806 15.7661 6.94183 15.0623 8.64084C14.3586 10.3399 13.9963 12.1608 13.9963 13.9998L28.0001 13.9998L28.0001 -0.00390686Z"
                fill="white"
                fillOpacity="0.8"
                stroke="#404040"
                strokeWidth="0.56"
                mask="url(#path-2-inside-2_11634_7304)"
            />
        </svg>
    );
}
