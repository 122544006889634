import { ChecklistTaskLoader } from "@/components/loaders/ChecklistLoader";
import { useChecklist } from "@/hooks/useChecklist";
import { useFilter } from "@/hooks/useFilter";
import CeremonyFilter from "@/components/CeremonyFilter";
import "@/styles/checklist.scss";

interface Props {
    status: "success" | "loading" | "error";
}

export default function ChecklistSummary({ status }: Props) {
    const { todo: checklist } = useChecklist();
    const { ceremony } = useFilter();

    const ceremonyChecklist = ceremony?.id !== "all" ? checklist.filter((item) => item.ceremony.id === ceremony?.id) : checklist;

    const completedTask = status === "success" && ceremonyChecklist ? ceremonyChecklist.filter((checklistItem) => checklistItem.isDone) : [];
    const totalTask = status === "success" && ceremonyChecklist ? ceremonyChecklist.length : 0;

    const doneTask = completedTask.length;
    const _taskPercentage = completedTask ? (doneTask / totalTask) * 100 : 0;
    const taskPercentage = doneTask !== 0 ? Math.floor(_taskPercentage) : 0;
    const taskText = totalTask <= 1 ? "task" : "tasks";
    return (
        <div className="summary">
            <CeremonyFilter text="Checklist" shouldSearch />
            <div className="task-view bg-white">
                {status === "error" ? (
                    <p>Unable to fetch task</p>
                ) : status === "loading" ? (
                    <ChecklistTaskLoader width={300} height={20} className="mb-4" />
                ) : (
                    <h4>
                        {totalTask !== 0 ? (
                            <>
                                <span className="done">{doneTask}</span> out of <span className="checklist">{totalTask}</span> {taskText} completed
                            </>
                        ) : (
                            <>
                                <span>{totalTask}</span> {taskText} completed
                            </>
                        )}
                    </h4>
                )}
                {status === "error" ? (
                    <p>Error fetching task</p>
                ) : status === "loading" ? (
                    <ChecklistTaskLoader width="100%" height={15} className="" />
                ) : (
                    <div className="bar progress_outer">
                        <div className="bar progress_inner" style={{ width: `${taskPercentage}%` }}></div>
                    </div>
                )}
            </div>
        </div>
    );
}
