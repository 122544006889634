/* eslint-disable max-len */
interface Props {
  fill: string;
}
export default function ArrowDown({ fill = "#545565" }:Props) {
    return (
        <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.517041 1.05806C0.761116 0.813981 1.15685 0.813981 1.40092 1.05806L7.00065 6.65775L12.6004 1.05806C12.8445 0.813981 13.2401 0.813981 13.4842 1.05806C13.7283 1.30213 13.7283 1.69786 13.4842 1.94194L7.44257 7.98358C7.19848 8.22766 6.80282 8.22766 6.55873 7.98358L0.517041 1.94194C0.272966 1.69786 0.272966 1.30213 0.517041 1.05806Z"
                fill={fill}
            />
        </svg>
    );
}
