import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import DashboardModal from "@/components/modals/DashboardModal";
import Spinner from "@/components/Spinner";
import ShieldIcon from "@/assets/icon/ShieldIcon";
import { cancelSubscriptionRequest } from "@/axios/post-request";

interface Props {
	onClose: () => void;
    userId: string;
	subscriptionDetails: {
		subscription_code: string;
		subscription_token: string;
	};
}

export default function CancelSubscriptionModal({ onClose, userId, subscriptionDetails }: Props) {
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();

    async function deleteVendorHandler() {
        try {
            setLoading(true);
            const response = await cancelSubscriptionRequest(subscriptionDetails, userId);
            setLoading(false);

            if (!response.data.status) {
                toast.success(`Error cancelling subscription cancelled - ${response?.data?.message} `);
                return;
            }
            queryClient.resetQueries({ queryKey: ["get_user"] });
            toast.success("Subscription cancelled");
            onClose();
            return;
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }

    return (
        <DashboardModal showHeader={false} className="small_width delete_category_cost_item_modal" onClose={onClose}>
            <section className="delete_category_cost_item">
                <div className="icon_wrapper">
                    <ShieldIcon />
                </div>
                <h4>Are you sure you want to cancel your subscription to Planaday?</h4>

                <button onClick={deleteVendorHandler}>
					Cancel subscription
                    {loading && <Spinner className="ml-2" size={20} />}
                </button>
            </section>
        </DashboardModal>
    );
}
