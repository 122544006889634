import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type budgetDropdownType = {
    categoryId: null | string;
    costItemId: null | string;
};

interface useBudgetDropdownType {
    budgetDropdown: {
        categoryId: null | string;
        costItemId: null | string;
    };
    setBudgetDropdown: (value: budgetDropdownType) => void;
}

export const useBudgetDropdown = create<useBudgetDropdownType>()(
    persist(
        (set) => ({
            budgetDropdown: {
                categoryId: null,
                costItemId: null,
            },
            setBudgetDropdown: (value) =>
                set(() => ({
                    budgetDropdown: value,
                })),
        }),
        { name: "budgetDropdown", storage: createJSONStorage(() => localStorage) },
    ),
);
