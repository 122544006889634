import { Form } from "react-bootstrap";
import { formatCurrency } from "../helper";

interface Props {
    add: () => void;
    minus: () => void;
    no: string;
    placeholder: string;
    setValue: (val: string) => void;
    hasIcon?: boolean;
    maxLength?: number;
}

export default function Counter(props: Props) {
    const { add, minus, no, placeholder, setValue, hasIcon = true, maxLength = 10 } = props;

    return (
        <div className="counter">
            <button onClick={minus}>
                <p>-</p>
            </button>
            <div className={`input-container ${!hasIcon ? "mini-input" : ""}`}>
                {hasIcon && <p>₦</p>}
                <Form.Control
                    value={formatCurrency(no, "")}
                    placeholder={placeholder}
                    type="text"
                    maxLength={maxLength}
                    onChange={({ target: { value } }) => {
                        if (value === "") {
                            setValue("0");
                        } else {
                            setValue(value);
                        }
                    }}
                    aria-label="Budget"
                />
            </div>
            <button onClick={add}>
                <p>+</p>
            </button>
        </div>
    );
}
