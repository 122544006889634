import { useEffect, useState } from "react";
import type { ChangeEvent } from "react";

import Button from "@/components/Button";
import FilterModal from "@/components/modals/FilterModal";
import filterIcon from "@/assets/img/filter.svg";
import Search from "@/assets/icon/Search";
import { useSearchChecklist } from "@/hooks/useSearchChecklist";
import CancelIcon from "@/assets/icon/Cancel";
import Loader from "@/components/loader";
import { useFilter } from "@/hooks/useFilter";

interface Props {
    text?: string;
    shouldSearch?: boolean;
    eventName?: string;
    showFilter?: boolean;
}

export default function EventFilter({ text, shouldSearch, eventName, showFilter = true }: Props) {
    const [modal, setModal] = useState(false);
    const [searchText, setSearchText] = useState("");
    const { search, setSearch, searching } = useSearchChecklist();
    const { ceremony } = useFilter();

    function setSearchHandler(e: ChangeEvent<HTMLInputElement>) {
        setSearchText(e.target.value);
    }

    function toggleModal() {
        setModal((prev) => !prev);
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            searchChecklistHandler();
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    function searchChecklistHandler() {
        const _searchTextValue = !search ? searchText : null;
        if (!_searchTextValue) {
            setSearchText("");
        }
        setSearch(_searchTextValue);
    }

    const mobileFilterClassName = text === "Your Event Budget" ? "budget_tracker" : "";

    const greetingsClassName = shouldSearch ? "col-lg-4" : "col-lg-5";

    return (
        <>
            {modal && <FilterModal show={modal} onHide={toggleModal} />}
            <div className="top event_filter d-flex align-items-center justify-content-between">
                <div className={`greetings ${greetingsClassName}  px-0`}>
                    {eventName ? (
                        <>
                            {eventName && <h1>{eventName}</h1>}
                            <div className="event_overview d-flex align-items-center">
                                <h3>Event Overview</h3>
                            </div>
                        </>
                    ) : (
                        <div className="event_overview d-flex align-items-center">
                            <h3>{text}</h3>
                            <span id="current_ceremony">{ceremony?.name}</span>
                        </div>
                    )}
                </div>
                <div className="filter d-flex flex-column flex-lg-row">
                    {shouldSearch && (
                        <div id="search_checklist" className="position-relative search_form mr-0 md:mr-3 mb-2 mb-lg-0">
                            <input placeholder="Search" className="mr-2 form-control" value={searchText} onChange={setSearchHandler} />
                            {!searching && (
                                <>
                                    {!search ? (
                                        <button className="position-absolute" type="button">
                                            <Search fill="#545565" />
                                        </button>
                                    ) : (
                                        <button className="position-absolute" type="button" onClick={searchChecklistHandler}>
                                            <CancelIcon />
                                        </button>
                                    )}
                                </>
                            )}
                            {searching && (
                                <span className="position-absolute search_spinner">
                                    <Loader mode="dark" />
                                </span>
                            )}
                        </div>
                    )}
                    {showFilter && (
                        <>
                            <Button
                                id="filter_by_ceremony_button"
                                className="bg_dark with_icon d-none d-lg-flex text-left align-items-start mt-1 mt-lg-0  font-base text-white"
                                text="Filter by Ceremony"
                                icon={filterIcon}
                                onClick={toggleModal}
                            />
                            <Button
                                icon={filterIcon}
                                onClick={toggleModal}
                                className={`pr-3 py-2 d-lg-none mobile_filter ${mobileFilterClassName}`}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
