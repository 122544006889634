import { useQuery } from "@tanstack/react-query";
import { getEventTags } from "@/axios/get-request";

export default function TipsAndIdeas() {
    const { data, status } = useQuery({
        queryKey: ["get-event-tags"],
        queryFn: () => getEventTags(),
    });

    return (
        <div className="tips_wrapper">
            <div className="tips_ideas">
                <h4>Tips & Ideas</h4>
                {status === "error" ? (
                    <p>unable to fetch event tips</p>
                ) : status === "loading" ? (
                    <p>fetching event tips...</p>
                ) : (
                    <div className="tips_group">
                        {data.data.tags.slice(0, 14).map((tip, index) => (
                            <a key={index} href={tip.url} target="_blank" className="tips">
                                {tip.name}
                            </a>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
