/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Path, Group, Rect } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function DoubleClosedDoorElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            {...item}
            name="floor_plan_element"
            scaleX={3}
            scaleY={3}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Rect x={18.1} y={7.1} width={11.8} height={0.2} rx={0.1} stroke="#404040" strokeWidth={0.2} />
            <Path strokeWidth={0.4} stroke={item.fill} data="M34 6H30.4C30.1791 6 30 6.17909 30 6.4V8.6C30 8.82091 30.1791 9 30.4 9H34" />
            <Rect x={4.1} y={7.1} width={11.8} height={0.2} rx={0.1} stroke="#404040" strokeWidth={0.2} />
            <Path stroke={item.fill} data="M0 6H3.6C3.82091 6 4 6.17909 4 6.4V8.6C4 8.82091 3.82091 9 3.6 9H0" strokeWidth={0.4} />
        </Group>
    );
}
