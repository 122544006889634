import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Dropdown } from "react-bootstrap";

import CalendarIcon from "@/assets/icon/CalendarIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import Input2 from "@/components/form/Input2";
import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import Loader from "@/components/loader";
import AddUserRoundIcon from "@/assets/icon/AddUserRoundIcon";
import SendIcon from "@/assets/icon/SendIcon";
import SiteDropdown from "@/components/Dropdown";
import CheckIcon2 from "@/assets/icon/CheckIcon2";
import useCollaboratorInvite from "@/hooks/useCollaboratorInvite";
import { getNameInitials } from "@/helper";
import { getEvents } from "@/axios/get-request";
import { useEvent } from "@/hooks/useEvent";
import Spinner from "@/components/Spinner";
import "@/styles/onboarding_stages.scss";
import "@/styles/auth_page.scss";

type roleOptionsType = "Groom 🤵🏻‍♂️" | "Bride 👰" | "Planner 👩🏻‍💻" | "Lead planner 🕵️‍♂️" | "Coordinator 👷‍♀️" | "Relative 👫🏻" | "Other 🧐";
const roleOptions: Array<roleOptionsType> =
["Groom 🤵🏻‍♂️", "Bride 👰", "Planner 👩🏻‍💻", "Lead planner 🕵️‍♂️", "Coordinator 👷‍♀️", "Relative 👫🏻", "Other 🧐"];
const collaboratorPermission = [
    { name: "can_edit", label: "Can Edit" },
    { name: "view_only", label: "View Only" },
];
const collaboratorInputDetails = { name: "name", label: "Name", placeholder: "Collaborator's name" };
type collaboratorType = Array<{
    name: string;
    email: string;
    role: string;
    permission: string;
}>;

export default function OnboardingCelebrantStage2() {
    const navigate = useNavigate();
    const { selectedEventId } = useEvent();
    const [hasInvitedCollaborator, setHasInvitedCollaborator] = useState(false);
    const { status, data, refetch } = useQuery({
        queryKey: [`get_event_${selectedEventId}`],
        queryFn: () => getEvents(),
        onSuccess: (data) => {
            if (data?.data?.result && data?.data?.result?.length > 0) {
                window.localStorage.setItem("COLLABORATOR_INVITED", "TRUE");
                setHasInvitedCollaborator(true);
            }
        },
        enabled: hasInvitedCollaborator,
    });
    const [showAddCollaborator, setShowAddCollaborator] = useState(false);
    const [collaborators, setCollaborators] = useState<collaboratorType>([]);
    const [collaborator, setCollaborator] = useState({
        name: "",
        email: "",
        role: "Planner",
        permission: "can_edit",
    });
    const { inviteCollaboratorRequest, loading } = useCollaboratorInvite();

    useEffect(() => {
        const invitedCollaboratorStorage = window.localStorage.getItem("COLLABORATOR_INVITED");
        if (invitedCollaboratorStorage) {
            window.localStorage.setItem("COLLABORATOR_INVITED", "TRUE");
            setHasInvitedCollaborator(true);
        }
    }, []);

    const activeEvent =
        status === "success" && data?.data?.result
            ? selectedEventId
                ? data?.data?.result?.filter((event) => event.id === selectedEventId)
                : data?.data?.result
            : null;

    const eventCollaborators = activeEvent && Array.isArray(activeEvent) && activeEvent.length > 0 ? activeEvent[0]?.collaborators : [];

    const selectedCollaboratorPermission = collaboratorPermission.filter((item) => item.name === collaborator.permission);

    function updateCollaboratorDetails(event: ChangeEvent<HTMLInputElement>) {
        setCollaborator({
            ...collaborator,
            [event.target.name]: event.target.value,
        });
    }

    function onSelectCollaboratorRoleHandler(role: string) {
        setCollaborator({
            ...collaborator,
            role,
        });
    }

    function onSelectCollaboratorPermissionHandler(permission: string) {
        setCollaborator({
            ...collaborator,
            permission,
        });
    }

    function showAddCollaboratorDropdown() {
        setShowAddCollaborator(true);
    }

    async function onInviteCollaboratorRequestHandler() {
        await inviteCollaboratorRequest(collaborator);
        setCollaborators([...collaborators, collaborator]);
        window.localStorage.setItem("COLLABORATOR_INVITED", "TRUE");
        setHasInvitedCollaborator(true);
        setCollaborator({
            name: "",
            email: "",
            role: "Planner",
            permission: "can_edit",
        });
        refetch();
    }

    const loadingClassName = loading ? "loading" : "";

    return (
        <>
            <NewOnboardingLayout className="onboarding_page_stage" text="Unlock your dream event" text2="in a few steps...">
                <div className="content d-flex flex-column auth">
                    <div className="auth_content onboarding_stage">
                        <ul className="onboarding_stepper">
                            <li className="progress">
                                <p>Step 1</p>
                                <div className="step_done">
                                    <CheckIcon2 height="6" width="6" />
                                </div>
                            </li>
                            <li className="progress">
                                <p>Step 2</p>
                                <div>
                                    <span className="active"></span>
                                </div>
                            </li>
                            <li>
                                <p>Step 3</p>
                                <div>
                                    <span className=""></span>
                                </div>
                            </li>
                            <div className="hr_wrapper">
                                <hr />
                            </div>
                        </ul>
                        <h4 className="mb-0">Invite collaborator 📧</h4>
                        <form className="invite_collaborator_form">
                            <div className="collaborators_section form_row">
                                <div className="section_title">
                                    <div>
                                        <CalendarIcon />
                                        <h6>Collaborators</h6>
                                    </div>
                                    {eventCollaborators.length <= 2 && (
                                        <button type="button" onClick={showAddCollaboratorDropdown}>
                                            Invite <PlusIcon />
                                        </button>
                                    )}
                                </div>
                                {showAddCollaborator && (
                                    <section className="add_collaborators">
                                        <div className="input_group">
                                            <Input2 value={collaborator.name} input={collaboratorInputDetails} onChange={updateCollaboratorDetails} />
                                            <SiteDropdown className="custom add_role" title={collaborator.role}>
                                                {roleOptions.map((role, index) => (
                                                    <Dropdown.Item key={index} type="button" onClick={() => onSelectCollaboratorRoleHandler(role)}>
                                                        {role}
                                                    </Dropdown.Item>
                                                ))}
                                            </SiteDropdown>
                                        </div>
                                        <div className="top_row">
                                            <div className="custom_input">
                                                <AddUserRoundIcon />
                                                <input
                                                    className="mb-0"
                                                    type="email"
                                                    name="email"
                                                    value={collaborator.email}
                                                    onChange={updateCollaboratorDetails}
                                                    placeholder="Enter email to invite"
                                                />
                                            </div>
                                            <div className="button_group">
                                                <SiteDropdown className="custom permission" title={selectedCollaboratorPermission[0].label}>
                                                    {collaboratorPermission.map((permission, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            type="button"
                                                            onClick={() => onSelectCollaboratorPermissionHandler(permission.name)}>
                                                            {permission.label}
                                                        </Dropdown.Item>
                                                    ))}
                                                </SiteDropdown>
                                                <button
                                                    type="button"
                                                    className={`invite_btn ${loadingClassName}`}
                                                    onClick={onInviteCollaboratorRequestHandler}
                                                    disabled={loading}>
                                                    {loading ? (
                                                        <span>
                                                            <Loader />
                                                        </span>
                                                    ) : (
                                                        <SendIcon />
                                                    )}{" "}
                                                    Invite
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </div>
                        </form>
                        {hasInvitedCollaborator && (
                            <>
                                {status === "loading" ? (
                                    <Spinner />
                                ) : (
                                    <div className="collaborators_list">
                                        {eventCollaborators?.map((collaborator, index) => {
                                            return (
                                                collaborator.role !== "Event Owner" && (
                                                    <div key={index} className="collaborator">
                                                        <div className="collaborator_item">
                                                            <div className="image_wrapper">
                                                                {collaborator?.name ? getNameInitials(collaborator?.name) : ""}
                                                            </div>
                                                            <div className="text_list">
                                                                <h4>{collaborator?.name}</h4>
                                                                <p>{collaborator?.email}</p>
                                                            </div>
                                                        </div>
                                                        <span>{collaborator.role}</span>
                                                    </div>
                                                )
                                            );
                                        })}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="button_group">
                        <button type="button" className="prev_btn" onClick={() => navigate("/onboarding-celebrant/stage-1")} disabled={loading}>
                            Previous
                        </button>
                        <a className="underline_text" href="/onboarding-celebrant/stage-3">
                            <span>Skip</span> <br />
                            (I'll Do This Later)
                        </a>
                        <button type="button" className={`next_btn`} onClick={() => navigate("/onboarding-celebrant/stage-3")} disabled={loading}>
                            Next
                        </button>
                    </div>
                </div>
            </NewOnboardingLayout>
        </>
    );
}
