/* eslint-disable max-len */

export default function GiftsIcon() {
    return (
        <svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11634_7415)">
                <path
                    d="M10.9973 2.66681V7.00015M10.9973 2.66681C10.8767 2.16995 10.6691 1.74517 10.4016 1.44787C10.134 1.15057 9.81888 0.994543 9.49731 1.00015C9.2763 1.00015 9.06434 1.08794 8.90806 1.24422C8.75178 1.4005 8.66398 1.61247 8.66398 1.83348C8.66398 2.05449 8.75178 2.26645 8.90806 2.42273C9.06434 2.57901 9.2763 2.66681 9.49731 2.66681M10.9973 2.66681C11.1179 2.16995 11.3255 1.74517 11.5931 1.44787C11.8606 1.15057 12.1758 0.994543 12.4973 1.00015C12.7183 1.00015 12.9303 1.08794 13.0866 1.24422C13.2429 1.4005 13.3306 1.61247 13.3306 1.83348C13.3306 2.05449 13.2429 2.26645 13.0866 2.42273C12.9303 2.57901 12.7183 2.66681 12.4973 2.66681M13.3306 4.00015V6.33348C13.3306 6.51029 13.2604 6.67986 13.1354 6.80488C13.0104 6.92991 12.8408 7.00015 12.664 7.00015H9.33065C9.15384 7.00015 8.98427 6.92991 8.85924 6.80488C8.73422 6.67986 8.66398 6.51029 8.66398 6.33348V4.00015M8.33065 2.66681H13.664C13.8481 2.66681 13.9973 2.81605 13.9973 3.00015V3.66681C13.9973 3.85091 13.8481 4.00015 13.664 4.00015H8.33065C8.14655 4.00015 7.99731 3.85091 7.99731 3.66681V3.00015C7.99731 2.81605 8.14655 2.66681 8.33065 2.66681Z"
                    stroke="#404040"
                    strokeWidth="0.714286"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g clip-path="url(#clip1_11634_7415)">
                <path
                    d="M17.9973 4.66681V9.00015M17.9973 4.66681C17.8767 4.16995 17.6691 3.74517 17.4016 3.44787C17.134 3.15057 16.8189 2.99454 16.4973 3.00015C16.2763 3.00015 16.0643 3.08794 15.9081 3.24422C15.7518 3.4005 15.664 3.61247 15.664 3.83348C15.664 4.05449 15.7518 4.26645 15.9081 4.42273C16.0643 4.57901 16.2763 4.66681 16.4973 4.66681M17.9973 4.66681C18.1179 4.16995 18.3255 3.74517 18.5931 3.44787C18.8606 3.15057 19.1758 2.99454 19.4973 3.00015C19.7183 3.00015 19.9303 3.08794 20.0866 3.24422C20.2429 3.4005 20.3306 3.61247 20.3306 3.83348C20.3306 4.05449 20.2429 4.26645 20.0866 4.42273C19.9303 4.57901 19.7183 4.66681 19.4973 4.66681M20.3306 6.00015V8.33348C20.3306 8.51029 20.2604 8.67986 20.1354 8.80488C20.0104 8.92991 19.8408 9.00015 19.664 9.00015H16.3306C16.1538 9.00015 15.9843 8.92991 15.8592 8.80488C15.7342 8.67986 15.664 8.51029 15.664 8.33348V6.00015M15.3306 4.66681H20.664C20.8481 4.66681 20.9973 4.81605 20.9973 5.00015V5.66681C20.9973 5.85091 20.8481 6.00015 20.664 6.00015H15.3306C15.1466 6.00015 14.9973 5.85091 14.9973 5.66681V5.00015C14.9973 4.81605 15.1466 4.66681 15.3306 4.66681Z"
                    stroke="#404040"
                    strokeWidth="0.714286"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g clip-path="url(#clip2_11634_7415)">
                <path
                    d="M3.99731 4.66681V9.00015M3.99731 4.66681C3.87674 4.16995 3.66913 3.74517 3.40156 3.44787C3.13399 3.15057 2.81888 2.99454 2.49731 3.00015C2.2763 3.00015 2.06434 3.08794 1.90806 3.24422C1.75178 3.4005 1.66398 3.61247 1.66398 3.83348C1.66398 4.05449 1.75178 4.26645 1.90806 4.42273C2.06434 4.57901 2.2763 4.66681 2.49731 4.66681M3.99731 4.66681C4.11789 4.16995 4.3255 3.74517 4.59307 3.44787C4.86064 3.15057 5.17575 2.99454 5.49731 3.00015C5.71833 3.00015 5.93029 3.08794 6.08657 3.24422C6.24285 3.4005 6.33065 3.61247 6.33065 3.83348C6.33065 4.05449 6.24285 4.26645 6.08657 4.42273C5.93029 4.57901 5.71833 4.66681 5.49731 4.66681M6.33065 6.00015V8.33348C6.33065 8.51029 6.26041 8.67986 6.13539 8.80488C6.01036 8.92991 5.84079 9.00015 5.66398 9.00015H2.33065C2.15384 9.00015 1.98427 8.92991 1.85924 8.80488C1.73422 8.67986 1.66398 8.51029 1.66398 8.33348V6.00015M1.33065 4.66681H6.66398C6.84808 4.66681 6.99731 4.81605 6.99731 5.00015V5.66681C6.99731 5.85091 6.84808 6.00015 6.66398 6.00015H1.33065C1.14655 6.00015 0.997314 5.85091 0.997314 5.66681V5.00015C0.997314 4.81605 1.14655 4.66681 1.33065 4.66681Z"
                    stroke="#404040"
                    strokeWidth="0.714286"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_11634_7415">
                    <rect width="8" height="8" fill="white" transform="translate(7)" />
                </clipPath>
                <clipPath id="clip1_11634_7415">
                    <rect width="8" height="8" fill="white" transform="translate(14 2)" />
                </clipPath>
                <clipPath id="clip2_11634_7415">
                    <rect width="8" height="8" fill="white" transform="translate(0 2)" />
                </clipPath>
            </defs>
        </svg>
    );
}
