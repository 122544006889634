import { useState } from "react";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";

import SiteModal from "@/components/modals/SiteModal";
import Input from "@/components/form/Input";
import Button from "@/components/Button";
import SelectDateInput from "@/components/form/SelectDateInput";
import PricingTable from "./PricingTable";
import { PaymentType, pricingPlanType } from "@/types";
import PricingPayment from "./PricingPayment";

interface Props {
    modal: string;
    event: { id: string; name: string } | null
    canAddNewEvent: boolean;
    onHide: () => void;
    mutateData: {
        mutate: ({ id, name }: { id: string; name: string; event_date: Date }) => void;
        isLoading: boolean;
    };
}

const inputName = {
    name: "event_name",
    placeholder: "Event name",
};

export default function DuplicateEventModal({ modal, event, canAddNewEvent, onHide, mutateData }: Props) {
    const [eventDuplicateName, setEventDuplicateName] = useState("");
    const [eventDate, setEventDate] = useState<null | Date>(null);
    const [selectPricingPlan, setPricingPlan] = useState<pricingPlanType>("");

    const { mutate, isLoading } = mutateData;
    const [upgradeAccount, setUpgradeAccount] = useState({
        upgrade: false,
        modal: "",
    });

    const modalClassname = upgradeAccount.upgrade && selectPricingPlan === "" ? "bigger" : "";

    function onInputChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        setEventDuplicateName(event.target.value);
    }

    function onSelectEventDateHandler(value: Date) {
        setEventDate(value);
    }

    const modalTitle = `Duplicate - ${event?.name} `;
    const showModal = modal === "duplicate_event_modal";

    function onSubmitHandler(e: ChangeEvent<HTMLFormElement>) {
        e.preventDefault();
        if (event?.id && eventDuplicateName && eventDate) {
            mutate({
                id: event.id,
                name: eventDuplicateName,
                event_date: eventDate as Date,
            });
        } else {
            toast.error("Ensure all fields are filled appropriately");
        }
    }

    function upgradeAccountHandler() {
        setUpgradeAccount({
            ...upgradeAccount,
            modal: "bigger",
            upgrade: true,
        });
    }

    function onCloseModal() {
        setUpgradeAccount({
            modal: "",
            upgrade: false,
        });
        onHide();
    }

    function choosePricingPlan(plan: pricingPlanType) {
        setPricingPlan(plan);
    }

    function resetPricingPlan() {
        setPricingPlan("");
    }

    const currentDate = new Date();

    return (
        <SiteModal title={modalTitle} show={showModal} onHide={onCloseModal} className={modalClassname}>
            <>
                {!upgradeAccount.upgrade ? (
                    <>
                        {canAddNewEvent ? (
                            <form onSubmit={onSubmitHandler} className="duplicate_event_form">
                                <p>What would you like to call this new event?</p>
                                <Input value={eventDuplicateName} input={inputName} onChange={onInputChangeHandler} />
                                <SelectDateInput
                                    className="mt-3"
                                    minDate={currentDate}
                                    placeholder="Event Date"
                                    value={eventDate}
                                    onClick={onSelectEventDateHandler}
                                />
                                <div className="d-flex justify-content-end">
                                    <Button
                                        className="bg_red mt-5 text-white duplicate_event"
                                        text="Duplicate Event"
                                        type="submit"
                                        isLoading={isLoading}
                                        disabled={!eventDuplicateName}
                                    />
                                </div>
                            </form>
                        ) : (
                            <div className="upgrade_account align-items-center">
                                <button className="mr btn_link btn px-0" onClick={upgradeAccountHandler}>
                                    Upgrade your account
                                </button>
                                {" "}to duplicate this event.
                            </div>
                        )}
                    </>
                ) : (
                    <PricingTable type="event" selectPricingPlan={selectPricingPlan} choosePricingPlan={choosePricingPlan}>
                        <PricingPayment
                            title={selectPricingPlan}
                            onCloseModal={onCloseModal}
                            paymentType={PaymentType.EVENT}
                            resetPricingPlan={resetPricingPlan} />
                    </PricingTable>
                )}
            </>
        </SiteModal>
    );
}
