/* eslint-disable max-len */

interface Props {
    fill?: string;
}
export default function PlusIcon({ fill = "#000" }: Props) {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M2.9165 7.80029H11.0832" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 3.80029V11.967" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
