import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import type { ChangeEvent } from "react";

import SiteModal from "@/components/modals/SiteModal";
import Input from "@/components/form/Input";
import budget_tracker_modal from "@/json/form/budget_tracker_modal.json";
import Button from "@/components/Button";
import InputMore from "@/components/form/InputMore";
import { addCategory } from "@/axios/post-request";
import type { costItemType } from "@/types";
import { useFilter } from "@/hooks/useFilter";
import { useEvent } from "@/hooks/useEvent";
import useAudit from "@/hooks/useAudit";

interface Props {
    show: boolean;
    onHide: () => void;
}

type categoryType = {
    name: string;
    cost_items: string[];
};

function formatCategoryCostItems(cost_items: string[], ceremony: { id: string; name: string } | null) {
    let updatedCostItems: costItemType[] = [];

    cost_items.map((item) => {
        updatedCostItems = [
            ...updatedCostItems,
            {
                id: uuidv4(),
                name: item,
                estimate: 0,
                actual: 0,
                note: "",
                paid: 0,
                balance: 0,
                balance_due: null,
                paid_by_me: "",
                vendor_name: "",
                vendor_ig: "",
                tag: ceremony ? ceremony?.id : "all",
            },
        ];
    });
    return updatedCostItems;
}

export default function AddCategoryModal({ show, onHide }: Props) {
    const queryClient = useQueryClient();
    const { ceremony } = useFilter();
    const {updateAuditHandler} = useAudit();
    const { selectedEventId } = useEvent();
    const [category, setCategory] = useState<categoryType>({
        name: "",
        cost_items: [],
    });

    const { mutate, isLoading } = useMutation({
        mutationKey: ["add_new_category"],
        mutationFn: () => {
            const budget_cost_items = formatCategoryCostItems(category.cost_items, ceremony);
            return addCategory(
                {
                    id: uuidv4(),
                    ...category,
                    cost_items: budget_cost_items,
                    actual_total: 0,
                    estimate_total: 0,
                    paid_total: 0,
                },
                selectedEventId,
            );
        },
        onSuccess: () => {
            queryClient.resetQueries({ queryKey: [`get_categories_${selectedEventId}`] });
            updateAuditHandler("budget_tracker", "add_budget_cost_item_category", category.name);
            onHide();
        },
        onError: () => toast.error("Oops, an error occured"),
    });

    function inputChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        setCategory({
            ...category,
            [e.target.name]: e.target.value,
        });
    }

    function addCostItem(cost_items: string[]) {
        setCategory({
            ...category,
            cost_items,
        });
    }

    function deleteCostItem(cost_items: string[]) {
        setCategory({
            ...category,
            cost_items: cost_items,
        });
    }

    function addBudgetCategory(event: ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!category.name) {
            return toast.error("Budget category name is required");
        } else if (category.cost_items.length === 0) {
            return toast.error("Budget category cost item is required");
        }
        mutate();
    }

    const budget_category = budget_tracker_modal.category;

    return (
        <SiteModal title="Add Category" show={show} onHide={onHide}>
            <section>
                <p className="mt-2">Add a budget category for your event</p>
                <form className="modal_form mt-5" onSubmit={addBudgetCategory}>
                    <Input input={budget_category[0]} value={category.name} onChange={inputChangeHandler} />
                    <InputMore items={category.cost_items} input={budget_category[1]} addItems={addCostItem} deleteItems={deleteCostItem} />
                    <div className="d-flex align-items-end justify-content-end mb-4">
                        <Button
                            text="Save"
                            className="bg_red mt-4 text-white"
                            isLoading={isLoading}
                            type="submit" />
                    </div>
                </form>
            </section>
        </SiteModal>
    );
}
