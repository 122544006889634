import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";

import { updateCostItemName, updateCostItems } from "@/axios/put-request";
import { getUID } from "@/axios/config";
import { useBudgetTracker } from "@/hooks/useBudgetTracker";
import { useModal } from "@/hooks/useModal";
import { useBudgetDropdown } from "@/hooks/useBudgetDropdown";
import { useFilter } from "@/hooks/useFilter";
import type { costItemType } from "@/types";
import { updateAudit } from "@/axios/post-request";
import useUser from "./useUser";

export default function useBudgetlist(costItem: costItemType | null, categoryId: string, categoryName: string) {
    const queryClient = useQueryClient();
    const { budgetDropdown, setBudgetDropdown } = useBudgetDropdown();
    const [budget, setBudget] = useState(costItem);
    const { setModal } = useModal();
    const [budgetTitle, setBudgetTitle] = useState(budget?.name);
    const [inputTimer, setInputTimer] = useState<NodeJS.Timeout | number | undefined>();
    const [editBudgetTitle, setEditBudgetTitle] = useState(false);
    const { setSelectedCostItem } = useBudgetTracker();
    const [errorModal, setErrorModal] = useState(false);
    const { status, data: userData, selectedEventId } = useUser();
    const { ceremony } = useFilter();
    const [formError, setFormError] = useState("");

    const { mutate, isLoading, } = useMutation({
        mutationKey: ["updateCostItems"],
        mutationFn: (autosaveEstimateActuals?: boolean) => updateBudgetCostItem(autosaveEstimateActuals),
        onSuccess: (data, autosaveEstimateActuals) => {
            if (status === "success" && !autosaveEstimateActuals) {
                updateAudit(
                    [{
                        id: uuidv4(),
                        name: userData?.result?.name as string,
                        user_id: userData?.result?.id as string,
                        email: userData?.result?.email as string,
                        type: "budget_tracker",
                        action: "update_budget_cost_item",
                        title: budgetTitle as string,
                        changes: {
                            from: costItem,
                            to: budget,
                        },
                        metadata: {
                            category_name: categoryName,
                        },
                        timestamp: new Date(),
                    }],
                    selectedEventId,
                );
            }
            if (!autosaveEstimateActuals) {
                toast.success(data.data.message);
                return queryClient.invalidateQueries({ queryKey: [`get_categories_${selectedEventId}`], exact: true });
            }
            return queryClient.invalidateQueries({ queryKey: [`get_categories_${selectedEventId}`], exact: true });
        },
    });

    const budgetBalance = Number(budget?.actual) - Number(budget?.paid);

    async function updateBudgetCostItemName() {
        const costItemId = costItem?.id as string;
        const name = budgetTitle as string;
        await updateCostItemName(selectedEventId, costItemId, {name, category_id: categoryId });
        return queryClient.invalidateQueries({ queryKey: [`get_categories_${selectedEventId}`], exact: true });
    }


    function editBudgetTitleHandler() {
        if (budget && budgetTitle) {
            setEditBudgetTitle((prev) => !prev);
            const budgetTitleValue = budgetTitle === "New Cost" ? "" : budgetTitle;
            setBudgetTitle(budgetTitleValue);
            setBudget({
                ...budget,
                name: budgetTitle,
            });
            if (budget.name !== budgetTitle) {
                updateBudgetCostItemName();
            }
        }
    }

    function setBudgetTitleHandler(e: ChangeEvent<HTMLInputElement>) {
        setBudgetTitle(e.target.value);
    }

    function updateBudgetCostItem(autosaveEstimateActuals?: boolean) {
        const _budget: costItemType | null = budget ? { ...budget, tag: "", balance: budget.actual - budget.paid } : null;
        const _budgetData =
            autosaveEstimateActuals && _budget ?
                { ..._budget, tag: ceremony.id } :
                !autosaveEstimateActuals && budget ?
                    { ...budget, balance: budget.actual - budget.paid } :

                    null;
        const budgetData = { ..._budgetData, name: budgetTitle } as costItemType;
        return updateCostItems(categoryId, budgetData, selectedEventId);
    }

    function autoSaveEstimateAndActuals() {
        clearTimeout(inputTimer);
        const newTimer = setTimeout(() => {
            mutate(true);
        }, 500);

        setInputTimer(newTimer);
    }

    function onAmountChange(e: ChangeEvent<HTMLInputElement>) {
        const formattedValue = Number(e.target.value.replace(/\D/g, "")) || "";
        if (budget) {
            setBudget({ ...budget, [e.target.name]: formattedValue });
            resetFormError();
        }
        if (e.target.name === "estimate" || e.target.name === "actual") {
            autoSaveEstimateAndActuals();
        }
    }

    function onFormElementChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (budget) {
            setBudget({ ...budget, [e.target.name]: e.target.value });
        }
    }

    function onSelectDate(value: Date) {
        if (budget) {
            setBudget({ ...budget, balance_due: value });
        }
    }

    function resetFormError() {
        if (formError) {
            setFormError("");
        }
    }

    function onSelectChange(e: ChangeEvent<HTMLSelectElement>) {
        if (budget) {
            setBudget({ ...budget, [e.target.name]: e.target.value });
            resetFormError();
        }
    }

    function toggleDropdown() {
        const costItemId = budgetDropdown.costItemId === budget?.id ? null : (budget?.id as string);
        setBudgetDropdown({
            ...budgetDropdown,
            costItemId,
        });
    }

    function onFocusInputHandler() {
        if (budget?.id) {
            setBudgetDropdown({
                ...budgetDropdown,
                costItemId: budget?.id,
            });
        }
    }

    function showModal() {
        setModal("vendor_assistance_modal");
    }

    function updateCheckBox() {
        if (budget) {
            const budgetValue = budget.paid_by_me ? "" : getUID();
            setBudget(() => ({
                ...budget,
                paid_by_me: budgetValue,
            }));
        }
    }

    function saveCostItem() {
        if (budgetBalance < 0) {
            return setErrorModal(true);
        }
        if (!budget?.actual && budget?.paid) {
            setFormError("actual");
            return toast.error("Actual cost item price is required");
        }
        // if (!budget?.paid) {
        //     setFormError("paid");
        //     return toast.error("Amount paid is required");
        // }
        if (!budget?.tag || budget.tag === "all") {
            setFormError("ceremony");
            return toast.error("Please select a ceremony");
        }

        if (budget.actual && budget.actual <= 0) {
            setFormError("actual");
            return toast.error("Invalid budget budget actual");
        }
        if (budget.paid && budget.paid <= 0) {
            setFormError("actual");
            return toast.error("Invalid budget paid value");
        }
        setFormError("");
        if (budgetBalance >= 0 && budget?.tag) {
            mutate(false);
        }
    }

    function closeModal() {
        setErrorModal(false);
    }

    function showDeleteModal() {
        setModal("delete_modal");
        if (budget) {
            setSelectedCostItem({ ...budget, categoryId });
        }
    }

    return {
        budgetDropdown,
        editBudgetTitle,
        isLoading,
        budgetTitle,
        errorModal,
        formError,
        budget,
        func: {
            editBudgetTitleHandler,
            setBudgetTitleHandler,
            onAmountChange,
            onFormElementChange,
            onSelectDate,
            saveCostItem,
            closeModal,
            showDeleteModal,
            updateCheckBox,
            showModal,
            onSelectChange,
            toggleDropdown,
            autoSaveEstimateAndActuals,
            onFocusInputHandler,
        },
    };
}
