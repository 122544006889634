import { format } from "date-fns";
import { useState } from "react";
import { auditActionType, type auditType, type TodoType } from "@/types";

import arrowdown from "@/assets/img/arrow_chevron_down.svg";
import arrowUp from "@/assets/img/arrow-chevron_up.svg";
import { formatDate } from "@/helper";

interface Props {
    showType: boolean;
    audit: auditType[0];
}

type auditChanges = {
    from?: {
        isDone: boolean;
        due: Date | null;
        id: string;
        name: string;
        note: string | null;
        ceremony: {
            name: string;
            id: string;
        };
        priority?: string;
        date?: Date;
    };
    to?: {
        isDone: boolean;
        due: Date | null;
        id: string;
        name: string;
        note: string | null;
        ceremony: {
            name: string;
            id: string;
        };
        priority?: string;
        date?: Date;
    };
};

type itemKeyType = Pick<TodoType[0], "ceremony" | "due" | "note">;
const trackChecklistChangesArray = ["Name", "Location", "Guest", "Date"];

export default function GeneralAuditDropdown({ audit, showType }: Props) {
    const [dropdown, setDropdown] = useState(false);

    const duplicateChanges = audit?.changes as { duplicate_event: string };

    const duplicate_event = audit?.changes && duplicateChanges.duplicate_event ? duplicateChanges.duplicate_event : "";

    const _audit_date = new Date(audit.timestamp);
    const audit_time = format(_audit_date, "dd-MM-yyyy, kk:mm aa");
    const action =
        audit.action === auditActionType.add_ceremony
            ? "added"
            : audit.action === auditActionType.change_ceremony
                ? "updated"
                : audit.action === auditActionType.invite_collaborator
                    ? "invited as a collaborator"
                    : audit.action === auditActionType.add_event
                        ? "created"
                        : audit.action === auditActionType.duplicate_event
                            ? `duplicated from ${duplicate_event}`
                            : "";

    function toggleDropdown() {
        setDropdown((prev) => !prev);
    }

    const arrow_image_src = dropdown ? arrowUp : arrowdown;

    const auditHeaderClassname = !dropdown ? "mb-3" : "";
    const auditBodyClassname = !dropdown ? "" : "mb-3";

    function formatAuditDate(givenDate?: Date) {
        const formatDateDue = givenDate ? formatDate(givenDate) : "NO DEADLINE";
        return formatDateDue;
    }

    const __auditType = audit.action.includes("ceremony") ? "ceremony" : audit.action === auditActionType.duplicate_event ? "event" : "";
    const auditType = audit.title.toLowerCase().includes(__auditType) ? "" : __auditType;

    function displayChanges(title: string, key: keyof TodoType[0], depthKey: keyof TodoType[0]["ceremony"] | null) {
        const auditChanges = audit?.changes as auditChanges;
        const auditDepthData = auditChanges?.from && depthKey && auditChanges.from.ceremony[depthKey];

        const auditFromData = depthKey && title === "Ceremony" ? auditDepthData : auditChanges?.from && auditChanges?.from[key];

        const auditToData =
            depthKey && title === "Ceremony" ? auditChanges?.to && auditChanges?.to.ceremony[depthKey] : auditChanges?.to && auditChanges?.to[key];
        const dueDateFrom = auditChanges?.from?.due as Date;
        const dueDateTo = auditChanges?.to?.due as Date;

        let auditFromDataResult;
        let auditToDataResult;

        if (title === "Due") {
            auditFromDataResult = formatAuditDate(dueDateFrom);
            auditToDataResult = formatAuditDate(dueDateTo);
        } else if (title === "Date") {
            auditFromDataResult = auditChanges?.from?.date ? formatAuditDate(auditChanges?.from?.date) : "";
            auditToDataResult = auditChanges?.to?.date ? formatAuditDate(auditChanges?.to?.date) : "";
        } else {
            auditFromDataResult = auditFromData as string;
            auditToDataResult = auditToData as string;
        }

        return (
            (auditFromData || auditToData) && (
                <p key={key} className="mb-1">
                    {title}:{" "}
                    {auditChanges?.from && auditFromDataResult && auditFromData !== auditToData && (
                        <span className="underline mr-2">{auditFromDataResult}</span>
                    )}
                    {auditChanges?.to && auditChanges?.to[key] && <span> {auditToDataResult}</span>}
                </p>
            )
        );
    }

    const auditTypeClassname = showType ? "show_type" : "";

    return (
        <>
            <div
                className={`${auditHeaderClassname} ${auditTypeClassname} 
            audit_list_card bg-white p-3 rounded justify-content-between d-flex align-items-lg-center`}>
                <div className="audit_details">
                    <span className="font-weight-bold mr-1">
                        {audit.title} {auditType}
                    </span>
                    was
                    <span className="font-weight-bold mx-1">{action}</span>
                    by {audit?.name ? audit.name : audit.email} at {audit_time}
                </div>
                <div className="audit_type_group">
                    {showType && <div className="type">{audit.type}</div>}

                    {audit.action !== "invite_collaborator" && !audit.action.includes("event") && (
                        <button className="border-0 bg-transparent" onClick={toggleDropdown}>
                            <img src={arrow_image_src} alt="dropdown icon" />
                        </button>
                    )}
                </div>
            </div>
            {dropdown && audit.action !== "invite_collaborator" && audit.action && (
                <div className={`${auditBodyClassname} dropdown checklist_dropdown bg-white px-3 pb-3 mb-3`}>
                    {trackChecklistChangesArray.map((item) => {
                        const _itemKey = item.toLowerCase() as unknown;
                        const itemKey = _itemKey as keyof itemKeyType;
                        const itemDepthKey = item === "Ceremony" ? "name" : null;
                        return displayChanges(item, itemKey, itemDepthKey);
                    })}
                </div>
            )}
        </>
    );
}
