import Button from "@/components/Button";

interface Props{
onNextHandler: () => void
}

export default function OnboardingStage2({onNextHandler}:Props) {
    return (
        <div className="stage_2 onboarding_stage">
            <h4 className="mb-lg-5 mb-4">Event Budget</h4>
            <p>
                <span className="mr-2">
          Planning a wedding and have no idea what your wedding budget should
          be?
                </span>
        Click ‘Next’ to use our Budget Calculator.
            </p>
            <p>
                <span className="mr-2">
          Planning other events or do you have your budget breakdown
          ready to go?
                </span>
        ‘Skip’ this step to complete your profile.
            </p>
            <Button text="Next" testId="next_page" className="bg_red" onClick={onNextHandler} />
        </div>
    );
}
