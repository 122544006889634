import "@/styles/button.scss";
import Loader from "@/components/loader";
import toSlug from "@/utils/toSlug";

interface Props {
    text?: string;
    className?: string;
    icon?: string;
    onClick?: () => void;
    type?: "button" | "submit" | "reset";
    isLoading?: boolean;
    disabled?: boolean;
    iconPosition?: "left" | "right";
    iconClassname?: string;
    id?: string;
    testId?:string;
}

export default function Button({
    text,
    className,
    icon,
    onClick,
    isLoading,
    disabled = false,
    type = "button",
    iconClassname,
    iconPosition = "right",
    id,
    testId
}: Props) {
    const btnClassName = className ? className : "";
    const _iconClassName = icon ? "ml-2" : "";
    const button_id = id ? id : text ? toSlug(text) : "";
    const buttonStyle = isLoading ? "is__loading" : "";
    const testIdObj = testId ? {"data-testid": testId} : {};

    const disableBtn =isLoading ? true : disabled;

    return (
        <button
            {...testIdObj}
            id={button_id}
            className={`button d-flex ${buttonStyle} btn justify-content-center align-items-center ${btnClassName}`}
            onClick={onClick}
            type={type}
            disabled={disableBtn}>
            {icon && iconPosition === "left" && <img src={icon} className={`${_iconClassName} ${iconClassname} mr-2`} alt={text} />}
            <span>{text && text}</span>
            {icon && iconPosition === "right" && <img src={icon} className={`${_iconClassName} ${iconClassname}`} alt={text} />}
            {isLoading && (
                <div className="mb-2 loader">
                    <Loader />
                </div>
            )}
        </button>
    );
}
