import type { ChangeEvent } from "react";

import naira from "@/assets/img/naira_currency.svg";
import "@/styles/form.scss";

interface Props {
	input: {
		label?: string;
		placeholder: string;
		name: string;
	};
	className?: string;
	showCurrency?: boolean;
	value?: string | number;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	type?: string;
	readOnly?: boolean;
	maxLength?: number;
	required?: boolean;
	onFocus?: () => void;
	min?: number;
	error?: string;
	index?: number;
	isLink?: boolean;
	linkTitle?: string;
	testId?: string;
	containerClassName?: string;
}
export default function Input2({
    input,
    onChange,
    className,
    error,
    showCurrency = false,
    value = "",
    containerClassName = "",
    type = "text",
    readOnly = false,
    maxLength = undefined,
    required = false,
    index,
    isLink,
    linkTitle,
    testId,
    ...props
}: Props) {
    const inputClassname = className ? className : "";
    const htmlForText = index ? `${input.name}_${index}` : input.name;
    const testIdData = testId ? { "data-testid": testId } : {};

    return (
        <div className={`custom_input_view ${containerClassName}`}>
            {isLink && (
                <div className="link_bar">
                    <p>{linkTitle ? linkTitle : "https://"}</p>
                </div>
            )}
            <div className={`form_element input2 input ${inputClassname}`}>
                <input
                    {...props}
                    {...testIdData}
                    id={htmlForText}
                    name={input.name}
                    placeholder={input.placeholder}
                    className="form-control"
                    value={value}
                    type={type}
                    readOnly={readOnly}
                    onChange={onChange}
                    maxLength={maxLength}
                    required={required}
                />
                {input?.label && <label htmlFor={htmlForText}>{input?.label}</label>}
                {showCurrency && <img src={naira} alt="Naira" className="icon" />}
                {error && <p className="text-danger small">{error}</p>}
            </div>
        </div>
    );
}
