import EventView from "@/components/EventView";
import DashboardLayout from "@/layout/DashboardLayout";
import "@/styles/profile.scss";

export default function UserProfile() {
    return (
        <DashboardLayout title="User Profile" className="profile_view events" showSidebar={false}>
            <EventView className="user_profile" />
        </DashboardLayout>
    );
}
