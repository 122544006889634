/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Path } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function SquareElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Path
            onClick={onSelect}
            ref={refValue}
            name="floor_plan_element"
            scaleX={4}
            scaleY={4}
            {...item}
            fill={item.fill}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}
            data="M0.1 12L0.1 2C0.1 0.950659 0.950659 0.1 2 0.1L12 0.1C13.0493 0.1 13.9 0.950659 13.9 2L13.9 12C13.9 13.0493 13.0493 13.9 12 13.9L2 13.9C0.950659 13.9 0.1 13.0493 0.1 12Z"
        />
    );
}
