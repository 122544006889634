import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type filterCeremonyType = { name: string; id: string };

interface useFilterType {
    ceremony: filterCeremonyType;
    setCeremony: (value: filterCeremonyType) => void;
}

export const useFilter = create<useFilterType>()(
    persist(
        (set) => ({
            ceremony: { name: "All Ceremonies", id: "all" },
            setCeremony: (value) => set(() => ({ ceremony: value })),
        }),
        { name: "ceremony", storage: createJSONStorage(() => localStorage) },
    ),
);
