import { ChangeEvent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { Dropdown } from "react-bootstrap";

import DashboardModal from "@/components/modals/DashboardModal";
import PlusIcon from "@/assets/icon/PlusIcon";
import Input2 from "@/components/form/Input2";
import TrashIcon2 from "@/assets/icon/TrashIcon2";
import { addMultipleEventChecklist } from "@/axios/post-request";
import { useEvent } from "@/hooks/useEvent";
import Spinner from "@/components/Spinner";
import SelectDateInput from "@/components/form/SelectDateInput";
import SiteDropdown from "@/components/Dropdown";
import { DisplayChecklistStatus } from "../CeremonyChecklistItem";
import useActivityLog from "@/hooks/useActivityLog";
import "@/styles/add_budget_category.scss";

const formDetails = {
    task: { label: "Task", name: "name", placeholder: "Task name" },
    datepicker: { label: "Date", placeholder: format(new Date(), "dd-MM-yy"), name: "due" },
    priority: { label: "Priority", placeholder: "Select priority", name: "priority" },
};

interface Props {
	onClose: () => void;
	ceremonyName: string;
}

type ChecklistType = Array<{
	id: string;
	name: string;
	note: string;
	due: Date | null;
	priority?: string;
	isDone: boolean;
	ceremony: { name: string; id: string };
}>;

const priorityOption = ["Low", "Medium", "High", "Urgent"];

export default function AddChecklistTaskModal({ onClose, ceremonyName }: Props) {
    const { selectedEventId, activeCeremonyId } = useEvent();
    const queryClient = useQueryClient();
    const { addChecklistAuditHandler } = useActivityLog();
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState<ChecklistType>([
        {
            id: uuidv4(),
            name: "",
            due: null,
            isDone: false,
            ceremony: { name: ceremonyName, id: activeCeremonyId },
            priority: "",
            note: "",
        },
    ]);

    async function createMultipleCeremonyTask() {
        try {
            const errorLog = {
                name: "",
            };
            formValues.map((item) => {
                if (!item.name) {
                    errorLog.name = "Task name is required";
                    return;
                } else {
                    errorLog.name = "";
                }
            });
            if (errorLog.name) {
                return toast.error(errorLog.name);
            }
            setLoading(true);
            const createMultipleChecklistResponse = await addMultipleEventChecklist(
                {
                    tag: {
                        id: activeCeremonyId,
                        name: ceremonyName,
                    },
                    checklists: formValues,
                },
                selectedEventId,
            );
            setLoading(false);
            if (!createMultipleChecklistResponse.data.status) {
                return toast.error(createMultipleChecklistResponse?.data?.message);
            }
            // toast.success(createMultipleChecklistResponse?.data?.message);
            await addChecklistAuditHandler(formValues, activeCeremonyId, ceremonyName);
            queryClient.resetQueries({ queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`] });
            return onClose();
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Oops, unable to add checklist");
            onClose();
        }
    }

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        setFormValues((prev) => {
            const updatedCostItems = [...prev];
            updatedCostItems[index] = {
                ...updatedCostItems[index],
                [event.target.name]: event.target.value,
            };
            return updatedCostItems;
        });
    }

    function onSelectHandler(value: Date | string, name: string, index: number) {
        setFormValues((prev) => {
            const updatedCostItems = [...prev];
            updatedCostItems[index] = {
                ...updatedCostItems[index],
                [name]: value,
            };
            return updatedCostItems;
        });
    }

    function addCostItemHandler() {
        setFormValues((prev) => [
            ...prev,
            {
                id: uuidv4(),
                name: "",
                due: null,
                isDone: false,
                ceremony: { name: ceremonyName, id: activeCeremonyId },
                priority: "",
                note: "",
            },
        ]);
    }

    function deleteCostItemHandler(index: number) {
        if (formValues.length <= 1) {
            return toast.error("There should be at least one task");
        }
        const remainingCostItem = formValues.filter((_, idx) => idx !== index);
        setFormValues(remainingCostItem);
    }

    return (
        <DashboardModal
            title="Add task"
            description="Add task as to-do"
            className="small_width add_budget_category_modal add_checklist_modal"
            onClose={onClose}>
            <div className="add_checklist_modal">
                <div className="checklist_group">
                    {formValues.map((item, index) => (
                        <div key={index} className="checklist_row">
                            <Input2
                                containerClassName="task_element"
                                value={item.name}
                                input={formDetails.task}
                                onChange={(event) => updateInputHandler(event, index)}
                            />
                            <SelectDateInput
                                value={item.due}
                                containerClassName="task_element"
                                label={formDetails.datepicker.label}
                                placeholder={formDetails.datepicker.placeholder}
                                onClick={(date) => onSelectHandler(date, "due", index)}
                            />
                            <div className="dropdown_wrapper task_element">
                                <label htmlFor="select_priority">Priority</label>
                                <SiteDropdown
                                    id="select_priority"
                                    title={<DisplayChecklistStatus title={item.priority ? item.priority : "Select priority"} />}
                                    className="custom">
                                    {priorityOption.map((item) => (
                                        <Dropdown.Item key={item} value={item} onClick={() => onSelectHandler(item, "priority", index)}>
                                            {item}
                                        </Dropdown.Item>
                                    ))}
                                </SiteDropdown>
                            </div>
                            <div className="action_button_group">
                                <button type="button" onClick={() => deleteCostItemHandler(index)}>
                                    <TrashIcon2 />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="add_new mb-2">
                    <button onClick={addCostItemHandler}>
						Add New <PlusIcon fill="#000" />
                    </button>
                </div>
            </div>
            <div className="modal_footer">
                <div className="button_group">
                    <button type="button" onClick={onClose} className="cancel_btn">
						Cancel
                    </button>
                    <button type="button" className="btn create_btn d-flex align-items-center" onClick={createMultipleCeremonyTask}>
						Create {loading && <Spinner className="ml-2" size={20} />}
                    </button>
                </div>
            </div>
        </DashboardModal>
    );
}
