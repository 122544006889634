/* eslint-disable max-len */
interface Props {
  fill: string;
}
export default function ArrowUp({ fill = "#545565" }: Props) {
    return (
        <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.515088 7.94183C0.759163 8.18592 1.1549 8.18592 1.39897 7.94183L6.9987 2.34213L12.5984 7.94183C12.8425 8.18592 13.2382 8.18592 13.4823 7.94183C13.7264 7.69775 13.7264 7.30208 13.4823 7.058L7.44061 1.01631C7.19653 0.772233 6.80086 0.772233 6.55678 1.01631L0.515088 7.058C0.271012 7.30208 0.271012 7.69775 0.515088 7.94183Z"
                fill={fill}
            />
        </svg>
    );
}
