import { TodoType } from "@/types";
import { subDays, addDays, isWithinInterval } from "date-fns";

export default function sortTodos(todos: TodoType) {
    const checklistArrayWithDate = todos.filter((item) => item.due);
    const checklistArrayWithNoDate = todos.filter((item) => !item.due);

    const sortChecklistByDate = checklistArrayWithDate.sort((checklist1, checklist2) => {
        const date1 = checklist1?.due ? new Date(checklist1?.due) : checklist1?.due;
        const date2 = checklist2?.due ? new Date(checklist2?.due) : checklist2?.due;

        return Number(date1) - Number(date2);
    });

    return [...sortChecklistByDate, ...checklistArrayWithNoDate];
}

export function range14DaysTodo(todos: TodoType) {
    const checklistArrayWithDate = todos.filter((item) => item.due);
    const date = new Date();

    // recent todods within 14days range
    const recentTodos = checklistArrayWithDate.filter((item) => {
        const dueDate = item?.due as Date;
        const dueDateInstance = new Date(dueDate);

        const isWithinPrevious14days = isWithinInterval(dueDateInstance, {
            start: subDays(date, 14),
            end: date,
        });

        const isWithinIntervalFuture14days = isWithinInterval(dueDateInstance, {
            start: date,
            end: addDays(date, 14),
        });

        return isWithinIntervalFuture14days || isWithinPrevious14days;
    });

    const sortChecklistByDate = recentTodos.sort((checklist1, checklist2) => {
        const date1 = checklist1?.due ? new Date(checklist1?.due) : checklist1?.due;
        const date2 = checklist2?.due ? new Date(checklist2?.due) : checklist2?.due;

        return Number(date1) - Number(date2);
    });
    return sortChecklistByDate;
}
