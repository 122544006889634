export default function getFirstChar(word: string) {
    if (word) {
        const splitWord = word.split("");
        const firstChar = splitWord[0];
        return firstChar.toUpperCase();
    } else {
        return word;
    }
}

export function getFirstTwoChar(word: string) {
    if (word) {
        const splitWord = word.split("");
        const firstChar = splitWord[0].toUpperCase();
        const secondChar = splitWord[1].toUpperCase();
        return `${firstChar} ${secondChar}`;
    } else {
        return word;
    }
}
