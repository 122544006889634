import AsoebiDashboardLayout from "@/layout/AsoebiDashboardLayout";

export default function AsoebiAdminPayments() {
    return (
        <AsoebiDashboardLayout>
            <section>
                <div className="w-full position-relative d-flex title_view">
                    <h1 className="text-center mx-auto">Payments list</h1>
                    <button className="btn btn-primary position-absolute font-weight-bold end-0">Initiate Payment</button>
                </div>
            </section>
        </AsoebiDashboardLayout>
    );
}
