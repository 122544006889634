import type { ChangeEvent } from "react";

interface Props {
	input: {
		label?: string;
		name: string;
		placeholder?: string;
	};
	className?: string;
	value?: string;
    readOnly?:boolean
	onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}
export default function Textarea({ className = "", value = "", input, onChange, readOnly=false }: Props) {
    return (
        <div className={`form_element ${className}`}>
            {input?.label && <label htmlFor={input.name}>{input.label}</label>}
            <textarea placeholder={input?.placeholder} name={input.name} value={value} className="form-control" onChange={onChange}
                readOnly={readOnly}>
            </textarea>
        </div>
    );
}
