interface Props {
    counter: number;
}
export default function EventDayflowInactiveIcon({ counter }: Props) {
    return (
        <svg width="28" height="69" viewBox="0 0 28 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="12" r="11.5" stroke="#545566" strokeOpacity="0.05" />
            <text x="11" y="16" fontFamily="Inter" fontSize="12" fill="#404040">
                {counter}
            </text>
            <path d="M14 68L14 28" stroke="#545566" strokeOpacity="0.05" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}
