import { useState } from "react";

import AddIcon from "@/assets/icon/AddIcon";
import type { ChangeEvent, Dispatch, SetStateAction } from "react";
import type{ AsoebiOptionType } from "@/data/types";
import TrashIcon from "@/assets/icon/TrashIcon";

const options = [
    "Select Asoebi options",
    "Gele only",
    "Cap only",
    "Gele and 3 yards (Women)",
    "Gele and 5 yards (Women)",
    "Cap and 4 yards (Men)",
    "Others",
];

interface Props {
	index: number;
	selectedOptions: AsoebiOptionType;
	placeholder: string;
	onClickHandler: (selectedOptions: AsoebiOptionType) => void;
	setSelectedOptions: Dispatch<SetStateAction<AsoebiOptionType>>;
}

export default function SelectInput({ selectedOptions, index, setSelectedOptions, placeholder, onClickHandler }: Props) {
    const [selectOthers, setSelectOthers] = useState(false);

    function addOptionHandler() {
        let selectedOptionsTemp = selectedOptions;
        selectedOptionsTemp = [...selectedOptionsTemp, { option: "", price: "", max_stock_qty: 0, stock_bought_qty: 0 }];
        setSelectedOptions(selectedOptionsTemp);
        return onClickHandler(selectedOptionsTemp);
    }

    function removeOptionHandler(optionIndex: number) {
        const remainingOptions = selectedOptions.filter((_, idx) => idx !== optionIndex);
        setSelectedOptions(remainingOptions);
        onClickHandler(remainingOptions);
    }

    function inputChangeHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const selectedOptionsTemp = selectedOptions.map((selectedOptn, idx) => {
            if (idx === index) {
                if (event.target.name === "price") {
                    const price = event.target.name === "price" ? event.target.value.replace(/,/g, "") : event.target.value;
                    const value = event.target.name === "price" ? price.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : event.target.value;
                    selectedOptn.price = value;
                } else if (event.target.name === "max_stock_qty") {
                    const value = Number(event.target.value) > 10000 ? 10000 : Number(event.target.value);
                    selectedOptn.max_stock_qty = value;
                } else if (event.target.name === "option") {
                    selectedOptn.option = event.target.value;
                }
                return selectedOptn;
            } else {
                return selectedOptn;
            }
        });
        setSelectedOptions(selectedOptionsTemp);
        onClickHandler(selectedOptionsTemp);
    }

    function selectChangeHandler(event: ChangeEvent<HTMLSelectElement>, index: number) {
        const selectedOptionsTemp = selectedOptions.map((selectedOptn, idx) => {
            if (idx === index) {
                selectedOptn.option = event.target.value;
                if (event.target.value === "Others") {
                    selectedOptn.option = "";
                    setSelectOthers(true);
                }
                return selectedOptn;
            } else {
                return selectedOptn;
            }
        });

        setSelectedOptions(selectedOptionsTemp);
        onClickHandler(selectedOptionsTemp);
    }

    const qty = selectedOptions[index].max_stock_qty === 0 ? "" : Number(selectedOptions[index].max_stock_qty);
    const inputPricePlaceholder = `Price (${placeholder})`;
    return (
        <div className="select_input d-flex justify-content-between flex-row px-0">
            <div className="d-flex px-0">
                {(selectOthers || (selectedOptions[index].option && !options.includes(selectedOptions[index].option))) ? (
                    <input
                        type="text"
                        name="option"
                        placeholder="Others"
                        value={selectedOptions[index].option}
                        onChange={(e) => inputChangeHandler(e, index)}
                        className="col-md-5 col-11 form-control"
                    />
                ) : (
                    <select
                        className="col-md-6 w-fit col-11 form-control"
                        name="option"
                        value={selectedOptions[index].option}
                        onChange={(event) => selectChangeHandler(event, index)}>
                        {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                )}
                <input
                    type="text"
                    name="price"
                    placeholder={inputPricePlaceholder}
                    className="col-md-3 col-11 form-control"
                    onChange={(e) => inputChangeHandler(e, index)}
                    value={selectedOptions[index].price}
                />
                <input
                    type="number"
                    name="max_stock_qty"
                    placeholder="Qty"
                    max="10000"
                    min="0"
                    className="col-md-2 pr-1 col-11 form-control"
                    onChange={(e) => inputChangeHandler(e, index)}
                    value={qty}
                />
            </div>
            <span className="d-flex flex-md-row flex-column">
                {index === selectedOptions.length - 1 &&
                 <button className="btn d-flex btn-secondary align-items-center" onClick={addOptionHandler}>
                     <AddIcon fill="#cb3050" />
                 </button>}
                {selectedOptions.length > 1 &&
                <button className="btn d-flex btn-secondary align-items-center" onClick={() => removeOptionHandler(index)}>
                    { <TrashIcon />}
                </button>}

            </span>
        </div>
    );
}
