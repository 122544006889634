import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import asoebiFormImage from "@/assets/img/guest_details_section.webp";
import asoebiOrderFormGuestIcon from "@/assets/img/asoebi_order_form_guest.webp";
import asoebiOrderFormIcon from "@/assets/img/asoebi_order_form.webp";
import Footer from "@/components/Footer";
import Button from "@/components/Button";
import Heart1 from "@/assets/icon/Heart1";
import AsoebiDashboard from "@/assets/img/asoebi_dashboard.webp";
import Heart2 from "@/assets/icon/Heart2";
import heart3 from "@/assets/img/heart3.svg";
import heart4 from "@/assets/img/heart4.svg";
import StarIcon from "@/assets/icon/StarIcon";
import DownArrow from "@/assets/icon/DownArrow";
import AsoebiFAQ from "@/components/AsoebiFAQ";
import NavBar from "@/components/Navbar";
import "@/styles/asoebi-homepage.scss";


export default function AsoebiPage() {
    return (
        <>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta
                    name="description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta
                    name="keywords"
                    content="nigerian wedding, asoebi, ankara, lagos wedding, wedding budget, wedding cost, Planaday, plan an event, wedding planning,
                    event planning, event budgeting, event manager, event planner, wedding planner, planning assistant"
                />
                <meta property="og:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    property="og:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta property="og:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta property="og:url" content="https://www.planaday.events" />
                <meta property="og:site_name" content="Planaday Events" />
                <meta property="og:type" content="website" />
                <meta property="og:type" content="Planaday Events" />
                <meta name="twitter:title" content="Planaday Events" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    name="twitter:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta name="twitter:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:image:alt" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:site" content="@Planaday_events" />
                <meta name="twitter:creator" content="@Planaday_events" />
                <title>Planaday | Effortless Event Planning & Management</title>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap" rel="stylesheet" />
            </Helmet>
            <NavBar />
            <div className="section-bar" />
            <main className="asoebi_homepage page">
                <div className="heart heart1">
                    <Heart1 />
                </div>
                <div className="heart heart2">
                    <Heart2 />
                </div>
                <section className=" asoebi_hero d-flex mx-auto  py-md-5 pt-5 px-0">
                    <div className="container px-md-0 align-items-center d-flex flex-md-row  flex-column">
                        <div className="col-md-6 col-12 order-2 order-md-1 hero_text">
                            <h1 className="mb-4">The stress-free way to coordinate Asoebi for events </h1>
                            <p className="text-xl">
								Our digital solution helps you manage the Asoebi journey for your event, adding ease to the purchase process for you
								and your guests.
                            </p>
                            <Link to="/asoebi-tracker">
                                <Button text="Start Selling" className="btn  btn-primary font-weight-bold my-5" />
                            </Link>
                        </div>
                        <div className="order-1 order-md-2 asoebi_view">
                            <img src={asoebiOrderFormIcon} alt="asoebi" />
                        </div>
                    </div>
                </section>
                <div className="section-bar" />
                <section className="bg-white pt-5 px-md-0 px-1">
                    <h2 className="text-center text-dark sub_title">How our asoebi digital solution works </h2>
                    <div className="container mt-md-5">
                        <div className="d-flex py-md-5 py-2 align-items-center flex-md-row flex-column">
                            <div className="col-md-6 mt-4 mt-md-0 order-2 order-md-1 col-12">
                                <h6 className="text-dark">Step 1</h6>
                                <div className="d-flex mb-2 align-items-center">
                                    <h4 className="mb-0 text-dark sub_title">Create your asoebi order form</h4>
                                </div>
                                <p>
									Our process starts by creating an asoebi order form for your event. You can customise your order form with details
									of the event, what information you would like to gather from your guests, and the asoebi options for sale. You can
									also set a sale deadline date for the form so you control the entire process.
                                </p>
                            </div>
                            <div className="col-md-6 px-md-0 col-12 order-1 order-md-2 mx-auto d-flex align-items-center justify-content-center">
                                <img src={asoebiFormImage} className="form_img" height="300px" width="400px" />
                            </div>
                        </div>
                        <div className="d-flex py-md-5 py-2 align-items-center flex-md-row flex-column">
                            <div className="col-md-6 col-12 mx-auto d-flex align-items-center image_wrapper justify-content-center">
                                <img src={asoebiOrderFormGuestIcon} className="form_img" height="300px" width="400px" />
                            </div>
                            <div className="col-md-6 mt-4 mt-md-0 col-12">
                                <h6 className="text-dark">Step 2</h6>
                                <div className="d-flex mb-2 align-items-center">
                                    <h4 className="text-dark mb-0 sub_title">Sell your asoebi</h4>
                                </div>
                                <p>
									Once your asoebi order form has been created, we will generate a unique link for you to share with your guests so
									they can purchase asoebi.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex py-md-5 py-4 align-items-center flex-md-row flex-column">
                            <div className="col-md-6 order-2 mt-4 mt-md-0 order-md-1">
                                <h6 className="text-dark">Step 3</h6>
                                <div className="d-flex mb-2 align-items-center">
                                    <h4 className="text-dark mb-0 sub_title">Manage your orders</h4>
                                </div>
                                <p>
									As guests begin to purchase the event’s asoebi, all order details will be displayed in your Order Dashboard. This
									feature allows you to track payments easily, know who has paid and for which items, so you can seamlessly manage
									logistics offline.
                                </p>
                            </div>
                            <div className="col-md-6 order-1 order-md-2 mx-auto d-flex align-items-center justify-content-center">
                                <img src={AsoebiDashboard} className="form_img" width="400px" />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="section-bar" />
                <section className="secondary-bg py-5 px-0">
                    <div className="container pricing">
                        <h2 className="text-center text-dark sub_title">Pricing</h2>
                        <div className="pricing_card mb-4 mt-5 shadow bg-white mx-auto col-lg-4 col-md-6 col-11 px-0">
                            <div className="px-4 py-5">
                                <h6 className="mb-0">Simple, transparent pricing</h6>
                                <div className="my-5 amount">
                                    <h3>5% </h3>
                                    <p>per successful asoebi order</p>
                                </div>
                                <div className="text mb-">
                                    <div className="d-flex mb-3 align-items-center">
                                        <div className="icon_wrapper">
                                            <StarIcon />
                                        </div>
                                        <p className="ml-1">No setup, monthly or hidden fees</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="icon_wrapper">
                                            <DownArrow fill="#cb3050" />
                                        </div>
                                        <p className="ml-1">Pay only for what you use</p>
                                    </div>
                                </div>
                            </div>
                            <Link to="/asoebi-tracker">
                                <button>
								Start selling <DownArrow fill="white" />
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>
                <div className="section-bar" />
                <section className="bg-white py-5 px-md-0">
                    <div className="container">
                        <div className="col-md-8 col-12 mx-auto d-flex flex-column">
                            <h2 className="text-center mb-2 text-dark sub_title">Simplify your asoebi order management with just one click</h2>
                            <p className="text-center">
								Eliminate the hassle of managing asoebi orders and give yourself the gift of one less worry in the event planning
								process.
                            </p>
                            <Link to="/asoebi-tracker">
                                <Button text="Get started" className="btn py-3 px-4 my-4 btn-primary font-weight-bold mx-auto" />
                            </Link>
                        </div>
                    </div>
                </section>
                <div className="section-bar" />
                <AsoebiFAQ />
                <div className="heart heart3">
                    <img src={heart3} alt="Heart shapes" />
                </div>
                <div className="heart heart4">
                    <img src={heart4} alt="Heart shapes" />
                </div>
            </main>
            <div className="section-bar" />
            <Footer />
        </>
    );
}
