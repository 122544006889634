import { useSearchParams } from "react-router-dom";

import AsoebiQuestionnaire from "@/components/AsoebiQuestionnaire";
import AsoebiLayout from "@/layout/AsoebiLayout";

export default function Questionnaire() {
    const [searchParams] = useSearchParams();
    const questionnaireIdFromUrl = searchParams.get("id");
    const questionnaireId = questionnaireIdFromUrl as string;

    return (
        <AsoebiLayout>
            {questionnaireId ? (
                <AsoebiQuestionnaire questionnaireId={questionnaireId} />
            ) : (
                <div className="vh-100 d-flex mx-auto align-items-center justify-content-center">
                    <h1>❌ No questionniare </h1>
                </div>
            )}
        </AsoebiLayout>
    );
}
