/* eslint-disable max-len */

export default function Rectangle8Icon() {
    return (
        <svg width="56" height="26" viewBox="0 0 56 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="56" height="26" rx="2" fill="#FAFAFC" />
            <path
                d="M21.1665 4.8335V7.1665C21.1665 7.62683 21.5397 8 22 8H24.333C24.7933 8 25.1665 7.62683 25.1665 7.1665V4.8335C25.1665 4.37317 24.7933 4 24.333 4L22 4C21.5397 4 21.1665 4.37317 21.1665 4.8335Z"
                fill="#D9D9D9"
            />
            <path
                d="M26 4.8335V7.1665C26 7.62683 26.3732 8 26.8335 8H29.1665C29.6268 8 30 7.62683 30 7.1665V4.8335C30 4.37317 29.6268 4 29.1665 4L26.8335 4C26.3732 4 26 4.37317 26 4.8335Z"
                fill="#D9D9D9"
            />
            <path
                d="M30.8335 4.8335V7.1665C30.8335 7.62683 31.2067 8 31.667 8H34C34.4603 8 34.8335 7.62683 34.8335 7.1665V4.8335C34.8335 4.37317 34.4603 4 34 4L31.667 4C31.2067 4 30.8335 4.37317 30.8335 4.8335Z"
                fill="#D9D9D9"
            />
            <path
                d="M14 12V14C14 14.5523 14.4477 15 15 15H17C17.5523 15 18 14.5523 18 14V12C18 11.4477 17.5523 11 17 11H15C14.4477 11 14 11.4477 14 12Z"
                fill="#D9D9D9"
            />
            <path
                d="M19.1 16V10C19.1 9.50294 19.5029 9.1 20 9.1L36 9.1C36.4971 9.1 36.9 9.50294 36.9 10V16C36.9 16.4971 36.4971 16.9 36 16.9L20 16.9C19.5029 16.9 19.1 16.4971 19.1 16Z"
                fill="#D9D9D9"
                stroke="#404040"
                strokeWidth="0.2"
            />
            <path
                d="M38 12V14C38 14.5523 38.4477 15 39 15H41C41.5523 15 42 14.5523 42 14V12C42 11.4477 41.5523 11 41 11H39C38.4477 11 38 11.4477 38 12Z"
                fill="#D9D9D9"
            />
            <path
                d="M21.1665 18.8335V21.1665C21.1665 21.6268 21.5397 22 22 22H24.333C24.7933 22 25.1665 21.6268 25.1665 21.1665V18.8335C25.1665 18.3732 24.7933 18 24.333 18H22C21.5397 18 21.1665 18.3732 21.1665 18.8335Z"
                fill="#D9D9D9"
            />
            <path
                d="M26 18.8335V21.1665C26 21.6268 26.3732 22 26.8335 22H29.1665C29.6268 22 30 21.6268 30 21.1665V18.8335C30 18.3732 29.6268 18 29.1665 18H26.8335C26.3732 18 26 18.3732 26 18.8335Z"
                fill="#D9D9D9"
            />
            <path
                d="M30.8335 18.8335V21.1665C30.8335 21.6268 31.2067 22 31.667 22H34C34.4603 22 34.8335 21.6268 34.8335 21.1665V18.8335C34.8335 18.3732 34.4603 18 34 18H31.667C31.2067 18 30.8335 18.3732 30.8335 18.8335Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
