import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";

export default function useAuthGuard() {
    const [authStatus, setAuthStatus] = useState<boolean | null>(null);

    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setAuthStatus(true);
        } else {
            setAuthStatus(false);
        }
    });

    return authStatus;
}
