/* eslint-disable @typescript-eslint/no-explicit-any */
import { Rect } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
    x: number;
    y: number;
    id: string;
    width: number;
    type: FloorPlanObjectListType;
    height: number;
    fill: string;
};

interface Props {
    item: itemType;
    refValue: React.MutableRefObject<undefined | any>;
    onChange: (value: itemType) => void;
    onDragStart: (value: itemType) => void;
    onSelect: () => void;
    onTransformEndHandler: (resetScale: boolean) => void;
}

export default function SquareTableElement({ refValue, onSelect, item, onDragStart, onChange, onTransformEndHandler }: Props) {
    return (
        <Rect
            onClick={onSelect}
            ref={refValue}
            name="floor_plan_element"
            {...item}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler(true)}
            cornerRadius={5}
        />
    );
}
