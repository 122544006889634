/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Text, Group, Rect } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
    x: number;
    y: number;
    id: string;
    width: number;
    type: FloorPlanObjectListType;
    height: number;
    fill: string;
    text?: string;
};

interface Props {
    item: itemType;
    refValue: React.MutableRefObject<undefined | any>;
    onChange: (value: itemType) => void;
    onDragStart: (value: itemType) => void;
    onSelect: () => void;
    onTransformEndHandler: (resetScale?: boolean) => void;
}

export default function DefaultElement({ refValue, onSelect, item, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            name="floor_plan_element"
            scaleX={1}
            scaleY={1}
            {...item}
            x={170}
            y={50}
            draggable

            onTransformEnd={() => onTransformEndHandler}>
            <Rect
                x={item.x - 10}
                y={item.y - 7}
                width={Math.max(4, refValue?.current?.getTextWidth() + 20)}
                height={22}
                fill={item.fill}
                cornerRadius={5}
            />
            <Text
                ref={refValue}
                onClick={onSelect}
                {...item}
                text={item.text}
                fontFamily="Inter, sans-serif"
                fontStyle="normal"
                height={12}
                width={Math.max(4, refValue?.current?.getTextWidth() + 20)}
                fill="#000"
                fontSize={10}
            />
        </Group>
    );
}
