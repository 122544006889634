/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Path } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function CircleElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Path
            onClick={onSelect}
            ref={refValue}
            {...item}
            name="floor_plan_element"
            scaleX={4}
            scaleY={4}
            fill={item.fill}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}
            data="M13.9 7C13.9 10.8108 10.8108 13.9 7 13.9C3.18924 13.9 0.1 10.8108 0.1 7C0.1 3.18924 3.18924 0.1 7 0.1C10.8108 0.1 13.9 3.18924 13.9 7Z"
        />
    );
}
