/* eslint-disable max-len */

export default function Rectangle10Icon() {
    return (
        <svg width="56" height="26" viewBox="0 0 56 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="56" height="26" rx="2" fill="#FAFAFC" />
            <path
                d="M21.1665 4.6333V6.9663C21.1665 7.42663 21.5397 7.7998 22 7.7998L24.333 7.7998C24.7933 7.7998 25.1665 7.42663 25.1665 6.9663V4.6333C25.1665 4.17298 24.7933 3.7998 24.333 3.7998L22 3.7998C21.5397 3.7998 21.1665 4.17298 21.1665 4.6333Z"
                fill="#D9D9D9"
            />
            <path
                d="M26 4.6333V6.9663C26 7.42663 26.3732 7.7998 26.8335 7.7998L29.1665 7.7998C29.6268 7.7998 30 7.42663 30 6.9663V4.6333C30 4.17298 29.6268 3.7998 29.1665 3.7998L26.8335 3.7998C26.3732 3.7998 26 4.17298 26 4.6333Z"
                fill="#D9D9D9"
            />
            <path
                d="M30.8335 4.6333V6.9663C30.8335 7.42663 31.2067 7.7998 31.667 7.7998L34 7.7998C34.4603 7.7998 34.8335 7.42663 34.8335 6.9663V4.6333C34.8335 4.17298 34.4603 3.7998 34 3.7998L31.667 3.7998C31.2067 3.7998 30.8335 4.17298 30.8335 4.6333Z"
                fill="#D9D9D9"
            />
            <path
                d="M14 9.7998V11.7998C14 12.3521 14.4477 12.7998 15 12.7998H17C17.5523 12.7998 18 12.3521 18 11.7998V9.7998C18 9.24752 17.5523 8.7998 17 8.7998H15C14.4477 8.7998 14 9.24752 14 9.7998Z"
                fill="#D9D9D9"
            />
            <path
                d="M14 14.1997V16.1997C14 16.752 14.4477 17.1997 15 17.1997H17C17.5523 17.1997 18 16.752 18 16.1997V14.1997C18 13.6474 17.5523 13.1997 17 13.1997H15C14.4477 13.1997 14 13.6474 14 14.1997Z"
                fill="#D9D9D9"
            />
            <path
                d="M19.1 16V10C19.1 9.50294 19.5029 9.1 20 9.1L36 9.1C36.4971 9.1 36.9 9.50294 36.9 10V16C36.9 16.4971 36.4971 16.9 36 16.9L20 16.9C19.5029 16.9 19.1 16.4971 19.1 16Z"
                fill="#D9D9D9"
                stroke="#404040"
                strokeWidth="0.2"
            />
            <path
                d="M38 9.7998V11.7998C38 12.3521 38.4477 12.7998 39 12.7998H41C41.5523 12.7998 42 12.3521 42 11.7998V9.7998C42 9.24752 41.5523 8.7998 41 8.7998H39C38.4477 8.7998 38 9.24752 38 9.7998Z"
                fill="#D9D9D9"
            />
            <path
                d="M38 14.1997V16.1997C38 16.752 38.4477 17.1997 39 17.1997H41C41.5523 17.1997 42 16.752 42 16.1997V14.1997C42 13.6474 41.5523 13.1997 41 13.1997H39C38.4477 13.1997 38 13.6474 38 14.1997Z"
                fill="#D9D9D9"
            />
            <path
                d="M21.1665 19.0337V21.3667C21.1665 21.827 21.5397 22.2002 22 22.2002H24.333C24.7933 22.2002 25.1665 21.827 25.1665 21.3667V19.0337C25.1665 18.5734 24.7933 18.2002 24.333 18.2002H22C21.5397 18.2002 21.1665 18.5734 21.1665 19.0337Z"
                fill="#D9D9D9"
            />
            <path
                d="M26 19.0337V21.3667C26 21.827 26.3732 22.2002 26.8335 22.2002H29.1665C29.6268 22.2002 30 21.827 30 21.3667V19.0337C30 18.5734 29.6268 18.2002 29.1665 18.2002H26.8335C26.3732 18.2002 26 18.5734 26 19.0337Z"
                fill="#D9D9D9"
            />
            <path
                d="M30.8335 19.0337V21.3667C30.8335 21.827 31.2067 22.2002 31.667 22.2002H34C34.4603 22.2002 34.8335 21.827 34.8335 21.3667V19.0337C34.8335 18.5734 34.4603 18.2002 34 18.2002H31.667C31.2067 18.2002 30.8335 18.5734 30.8335 19.0337Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
