import Modal from "react-bootstrap/Modal";
import useFloorPlan from "@/hooks/useFloorPlan";
import type { PropsWithChildren } from "react";
import CancelIcon from "@/assets/icon/Cancel";

interface Props {
	onClose: () => void;
	title?: string;
	description?: string;
	type?: "normal" | "drawer";
	className?: string;
    showHeader?: boolean
}
export default function DashboardModal({ onClose, title, description, children,
    showHeader = true, type = "normal", className = "" }: PropsWithChildren<Props>) {
    const { sidebar } = useFloorPlan();
    const sidebarClassName = sidebar ? "full_sidebar" : "icon_sidebar";
    return (
        <Modal
            backdropClassName={`dashboard_modal_backdrop ${sidebarClassName}`}
            show={true}
            onHide={onClose}
            className={`dashboard_modal ${type} ${sidebarClassName} ${className}`}>
            {showHeader && (
                <Modal.Header>
                    {title && <Modal.Title>{title}</Modal.Title>}
                    {description && <p>{description}</p>}
                    <button onClick={onClose}><CancelIcon /></button>
                </Modal.Header>
            )}

            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
}
