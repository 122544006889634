import { ChangeEvent, FormEvent, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";

import Spinner from "@/components/Spinner";
import CalendarIcon from "@/assets/icon/CalendarIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import Input2 from "@/components/form/Input2";
import GalleryIcon from "@/assets/icon/GalleryIcon";
import SiteDropdown from "@/components/Dropdown";
import { SocialsList, UserList } from "@/types";
import "@/styles/modal.scss";
import { updateUserDetails } from "@/axios/put-request";
import { uploadProfileImage } from "@/axios/post-request";

interface Props {
	name: string;
	userId: string;
	onHide: () => void;
}

/**
 * Plannerform
 * @param {string} name
 * @param {string} userId
 * @param onHide
 * @returns JSX
 */

type socialMediaType = Array<{ type: keyof typeof SocialsList; link: string }>;

const inputGroup = {
    name: { name: "business_name", label: "Business Name", placeholder: "Fantastic Event Planners" },
    phoneNumber: { name: "phone_number", label: "Phone Number", placeholder: "123 456 7890" },
    website: { name: "website", label: "Website (optional)", placeholder: "Fanstasticdeluxe.com" },
    socialMedia: {
        Instagram: { name: "instagram", label: "Instagram", placeholder: "Fanstastic Planners" },
        Tiktok: { name: "tiktok", label: "Tiktok", placeholder: "Fanstastic Planners" },
    },
    platform: { name: "platform", label: "Platform", placeholder: "Enter platform" },
};

export default function Plannerform({ name, userId, onHide }: Props) {
    const [loading, setLoading] = useState(false);
    const [socialMedia, setSocialMedia] = useState<socialMediaType>([]);
    const [file, setFile] = useState<File | null>(null);
    const [previewEventImage, setPreviewEventImage] = useState<null | string>(null);
    const [plannerDetails, setPlannerDetails] = useState({
        business_name: "",
        phone_number: "",
        website: "",
    });

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.name === inputGroup.phoneNumber.name && isNaN(Number(event.target.value))) return;

        setPlannerDetails((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            };
        });
    }

    function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        setPreviewEventImage(url);
        setFile(file);
    }

    function addSocialMediaHandler(social: keyof typeof SocialsList) {
        const socialMediaCheck = socialMedia.filter((item) => item.type === social);
        if (socialMediaCheck.length > 0) {
            return;
        }
        setSocialMedia([
            ...socialMedia,
            {
                type: social,
                link: "",
            },
        ]);
    }

    function updateSocialMediaHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const tempSocialMedia = [...socialMedia];
        tempSocialMedia[index].link = event.target.value;
        setSocialMedia(tempSocialMedia);
    }

    async function updatePlannerDetails(event: FormEvent) {
        try {
            event.preventDefault();
            if (!plannerDetails.business_name) {
                return toast.error("Business name is required");
            }
            if (plannerDetails.business_name.length < 2) {
                return toast.error("Please enter a valid business name");
            }
            if (!plannerDetails.phone_number) {
                return toast.error("Phone number is required");
            }
            if (plannerDetails.phone_number.length < 9 || plannerDetails.phone_number.length > 11) {
                return toast.error("Please enter a valid phone number");
            }
            setLoading(true);
            const updatePlannerRequest = await updateUserDetails(userId, {
                user_type: UserList.planner,
                ...plannerDetails,
                social_media: socialMedia,
            });
            if (!updatePlannerRequest.data.status) {
                setLoading(false);
                toast.error(updatePlannerRequest.data.message);
                return;
            }
            if (file && userId) {
                const formData = new FormData();
                formData.append("file", file);
                await uploadProfileImage(formData, userId);
            }
            setLoading(false);
            toast.success(updatePlannerRequest.data.message);
            onHide();
            return;
        } catch (error) {
            setLoading(false);
            const _error = error as { data: { message: string } };
            setLoading(false);
            toast.error("Oops an error occured" + _error?.data?.message);
        }
    }

    return (
        <div className="text_content">
            <h4>Hello {name} 👋🏼</h4>
            <p className="text_1">We'll love to know more about you.</p>

            <form className="invite_collaborator_form" onSubmit={updatePlannerDetails}>
                <div className="input_list_group mb-4">
                    <div className="input_list">
                        <div className="business_name">
                            <Input2 input={inputGroup.name} onChange={updateInputHandler} type="text" value={plannerDetails.business_name} />
                            <div className="upload_event_banner">
                                <input onChange={changePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" />{" "}
                                {previewEventImage ? <img src={previewEventImage} alt="event image" /> : <GalleryIcon />}
                            </div>
                        </div>
                        <Input2 input={inputGroup.phoneNumber} onChange={updateInputHandler} value={plannerDetails.phone_number} type="text" />
                        <Input2 input={inputGroup.website} onChange={updateInputHandler} value={plannerDetails.website} type="text" isLink />
                    </div>
                </div>
                <div className="form_row social_media">
                    <div className="section_title">
                        <div>
                            <CalendarIcon />
                            <h6>Social Media (Optional)</h6>
                        </div>
                        <div className="button_action_group">
                            <SiteDropdown
                                className="custom"
                                title={
                                    <>
										Add <PlusIcon fill="#000" />
                                    </>
                                }>
                                <Dropdown.Item onClick={() => addSocialMediaHandler(SocialsList.Instagram)}>{SocialsList.Instagram}</Dropdown.Item>
                                <Dropdown.Item onClick={() => addSocialMediaHandler(SocialsList.Tiktok)}>{SocialsList.Tiktok}</Dropdown.Item>
                            </SiteDropdown>
                        </div>
                    </div>
                    <div className="ceremonies">
                        {socialMedia.map((social, index) => {
                            return (
                                <div key={index}>
                                    <Input2
                                        isLink
                                        input={inputGroup.socialMedia[social.type]}
                                        linkTitle={`${social.type}.com/`}
                                        value={social.link}
                                        onChange={(event) => updateSocialMediaHandler(event, index)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="button_group mx-auto justify-content-center">
                    <button className={`next_btn`} type="submit" disabled={loading}>
						Submit {loading && <Spinner size={20} className="mx-2" />}
                    </button>
                </div>
            </form>
        </div>
    );
}
