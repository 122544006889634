import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";
import type { categoryType } from "@/types";

type budgetCostitemType = categoryType["cost_items"][0] & {
    categoryId: string;
};

type useBudgetType = {
    selectedCostItem: null | budgetCostitemType;
    setSelectedCostItem: (value: budgetCostitemType | null) => void;
    budgetBreakdown: Array<categoryType>;
    addBudget: (data: categoryType) => void;
    updateBudgetBreakdown: (data: categoryType) => void;
};

export const useBudgetTracker = create<useBudgetType>((set) => ({
    selectedCostItem: null,
    setSelectedCostItem: (value) => set(() => ({ selectedCostItem: value })),
    budgetBreakdown: [
        {
            id: uuidv4(),
            name: "New cost",
            cost_items: [],
            actual_total: 0,
            estimate_total: 0,
            paid_total: 0,
        },
    ],
    updateBudgetBreakdown: (data: categoryType) => {
        set((state) => {
            const budgetBreakdownValue = state.budgetBreakdown.length === 1 ? [data] : [...state.budgetBreakdown, data];
            return {
                budgetBreakdown: budgetBreakdownValue,
            };
        });
    },
    addBudget: () => {
        return set((state) => ({
            budgetBreakdown: [
                ...state.budgetBreakdown,
                {
                    id: uuidv4(),
                    name: "New cost",
                    cost_items: [],
                    actual_total: 0,
                    estimate_total: 0,
                    paid_total: 0,
                },
            ],
        }));
    },
    deleteBudget: (id: string) =>
        set((state) => {
            const updatedBudgetBreakdown = state.budgetBreakdown.filter((item) => item.id !== id);
            return { budgetBreakdown: updatedBudgetBreakdown };
        }),
}));
