import { useFormContext, type FieldError } from "react-hook-form";

interface Props {
	input: {
		label?: string;
		name: string;
		placeholder?: string;
	};
	className?: string;
    readOnly?:boolean
    error?:FieldError
}
export default function CustomTextarea({ className = "", error, input, readOnly=false }: Props) {
    const { register } = useFormContext();
    const errorClassname = error?.message ? "error" : "";
    return (
        <div className={`form_element ${className}`}>
            {input?.label && <label htmlFor={input.name}>{input.label}</label>}
            <textarea
                placeholder={input?.placeholder}
                className={`form-control ${errorClassname}`}
                readOnly={readOnly}
                {...register(input.name)}
            >
            </textarea>
            {error?.message && <p className="m-0 py-1">{error?.message}</p>}

        </div>
    );
}
