/* eslint-disable @typescript-eslint/no-explicit-any */
import { Group, Rect, Path } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function EntertainmentElement({ refValue, onSelect, item, onDragStart, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            name="floor_plan_element"
            {...item}
            scaleX={3}
            scaleY={3}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Path strokeWidth={1} stroke={item.fill} data="M16 8h.01" />
            <Path strokeWidth={1} stroke={item.fill} data="M16 12h.01" />
            <Path strokeWidth={1} stroke={item.fill} data="M16 16h.01" />
            <Path strokeWidth={1} stroke={item.fill} data="M8 8h.01" />
            <Path strokeWidth={1} stroke={item.fill} data="M8 12h.01" />
            <Path strokeWidth={1} stroke={item.fill} data="M8 16h.01" />
            <Rect stroke={item.fill} x={3} y={3} cornerRadius={2} height={18} width={18} />
        </Group>
    );
}
