import { PropsWithChildren } from "react";

import type { categoryType } from "@/types";
import { useEvent } from "@/hooks/useEvent";

interface Props {
	category: categoryType;
}

export default function BudgetCategoryCard({ category, children }: PropsWithChildren<Props>) {
    const { activeCeremonyId } = useEvent();
    const selectedCategoryCostItem =
		activeCeremonyId === "all" ? category?.cost_items : category?.cost_items?.filter((item) => item.tag === activeCeremonyId);
    const categoryActual = selectedCategoryCostItem?.reduce((a, b) => a + b.actual, 0);
    const categoryEstimate = selectedCategoryCostItem?.reduce((a, b) => a + b.estimate, 0);
    const categoryPaid = selectedCategoryCostItem?.reduce((a, b) => a + b.paid, 0);
    const categoryBalance = categoryActual - categoryPaid;

    return (
        <div className={`budget_category_card_wrapper`}>
            <div className="budget_category_details">
                <div className="budget_category_header">
                    <div>
                        <p>Items</p>
                        <h5>{selectedCategoryCostItem?.length}</h5>
                    </div>
                    <div>
                        <p>Category Estimate</p>
                        <h5 className="amount">
                            {categoryEstimate.toLocaleString("en-US")} <span>NGN</span>
                        </h5>
                    </div>
                    <div>
                        <p>Category Balance</p>
                        <h5>
                            {categoryBalance.toLocaleString("en-US")} <span>NGN</span>
                        </h5>
                    </div>
                </div>
                <div className="categories_table_wrapper">
                    <table className="categories">
                        <thead>
                            <tr className="category_header">
                                <td>
                                    <h6>Cost Item</h6>
                                </td>
                                <td>
                                    <h6>Deliverables</h6>
                                </td>
                                <td className="cost">
                                    <h6>Estimate</h6>
                                    <p>(NGN)</p>
                                </td>
                                <td className="cost">
                                    <h6>Actual Cost</h6>
                                    <p>(NGN)</p>
                                </td>
                                <td>
                                    <h6>Vendor</h6>
                                </td>
                                <td className="cost_btn_group"></td>
                            </tr>
                        </thead>
                        <tbody>{children}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
