import CreatableSelect from "react-select/creatable";
import type { ActionMeta, MultiValue } from "react-select";

import onboardingFormContent from "@/json/form/onboarding_form.json";

type multiType = MultiValue<{ [key: string]: string | number }>;

interface Props {
    className?: string;
    isMulti?: boolean;
    value?: multiType;
    defaultValue?: Array<{ label: string; value: string }> | MultiValue<{ label: string; value: string }>;
    ceremonies: MultiValue<{ label: string; value: string }>;
    onChange?: (newValue: multiType, actionMeta?: ActionMeta<{ [key: string]: string | number }>) => void;
    options?: Array<{ label: string; value: string }>;
}

export default function CeremonyPicker({ className = "", ceremonies, options, value, onChange, defaultValue, ...rest }: Props) {
    const ceremonyPickerOptions = options ? options : onboardingFormContent.ceremonyOptions;

    return (
        <div className={`form_element search_select ceremony_picker ${className}`}>
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mb-2">
                <label htmlFor="ceremonies" className="mb-0">
                    Ceremony
                </label>
                <h6 className="mb-0 small">You can create or select up to seven ceremonies</h6>
            </div>
            <div className="d-flex">
                <CreatableSelect
                    {...rest}
                    styles={{
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        option: (providedStyles, props:any) => ({
                            ...providedStyles, // To keep the default style
                            color: props.data.__isNew__ ? "#cb3050" : "black",
                            backgroundColor: props.data.__isNew__ ? "white" : "white",
                            fontWeight: props.data.__isNew__ ? "bold" : "normal",
                            width: props.data.__isNew__ && "fit-content",
                            borderRadius: props.data.__isNew__ && "10px",
                            marginLeft: props.data.__isNew__ && "10px",
                            marginTop: props.data.__isNew__ && "5px",
                            marginBottom: props.data.__isNew__ && "5px",
                        }),
                    }}
                    isMulti
                    defaultValue={defaultValue}
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Select at least one ceremony or start typing to create one"
                    name="ceremonies"
                    options={ceremonyPickerOptions}
                    onChange={onChange}
                    isOptionDisabled={() => ceremonies.length >= 7}
                    className="w-100 ceremony_picker_select"
                />
            </div>
            {value && value.length >= 7 && <p className="mb-0 ceremony_note_warning">You can only have a maximum of 7 ceremonies</p>}
        </div>
    );
}
