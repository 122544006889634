import { ChangeEvent, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import Button from "@/components/Button";
import SiteModal from "@/components/modals/SiteModal";
import Input from "@/components/form/Input";
import { renewCollaboratorAccountRequest, verifyPayment } from "@/axios/post-request";
import useUser from "@/hooks/useUser";
import payStackConfig from "@/payStackConfig";
import { initializePaymentType, paystackReferenceType } from "@/data/types";
import { getUID } from "@/axios/config";
import { PaymentType } from "@/types";
import { COLLABORATOR_PRICING, PAYSTACK_CURRENCY_CONSTANT } from "@/utils/constant";

const formDetails = {
    duration: {
        label: "Duration (month)",
        placeholder: "4",
        name: "duration",
    },
    pricing: { label: "Total Sum (₦)", name: "pricing", placeholder: "Total Sum (₦)" }
};
interface Props {
    onHide: () => void;
    showModal: boolean;
    collaboratorDetails: {
        name?: string;
        email?: string;
        event_owner_email?: string;
    };
}

export default function RenewCollaboratorAccountModal({ collaboratorDetails, showModal, onHide }: Props) {
    const [loading, setLoading] = useState(false);
    const [renewUserAccount, setRenewUserAccount] = useState(false);
    const [duration, setDuration] = useState(1);
    const { selectedEventId } = useUser();

    const queryClient = useQueryClient();

    const collaborator_email = collaboratorDetails.email as string;
    const amount = duration * COLLABORATOR_PRICING; // each month for a collaborators cost 1,000
    const config = payStackConfig(collaborator_email, amount);

    const renewPricing = duration * COLLABORATOR_PRICING;

    const initializePayment = usePaystackPayment(config) as initializePaymentType;
    const actual_collaborator_renewal_amount = 1 * Number(duration) * COLLABORATOR_PRICING;

    async function onSuccess(reference: paystackReferenceType) {
        const uid = getUID();
        const verifyPaymentRequest = await verifyPayment({
            reference_id: reference.reference,
            event_id: selectedEventId,
            user_id: uid,
            type: PaymentType.COLLABORATOR,
            amount: amount * PAYSTACK_CURRENCY_CONSTANT,
            actual_amount: actual_collaborator_renewal_amount * PAYSTACK_CURRENCY_CONSTANT
        });

        if (verifyPaymentRequest.status === 500 || !verifyPaymentRequest.data.status) {
            return toast.error(verifyPaymentRequest.data.message);
        }
        toast.success("🎉 subscription payment successfully");
        return await renewCollaboratorAccountRequest(duration, collaborator_email, selectedEventId)
            .then(() => {
                toast.success("account updated");
                onHide();
                setLoading(false);
                queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
            })
            .catch(() => {
                toast.error("Oops an error occured");
                onHide();
                setLoading(false);
            });
    }

    function onClose() {
        toast.error("Unable to make payment");
        setLoading(true);
        return onHide();
    }

    function renewCollaboratorAccount() {
        if (duration < 1) {
            return toast.error("Enter a valid duration");
        }
        setLoading(true);
        initializePayment((reference) => onSuccess(reference), onClose);
    }

    function updateDuration(event: ChangeEvent<HTMLInputElement>) {
        setDuration(Number(event.target.value));
    }

    return (
        <SiteModal title="Renew access" show={showModal}>
            {!renewUserAccount ? (
                <div>
                    Hello {collaboratorDetails?.name ? collaboratorDetails.name : collaboratorDetails.email} 👋🏼, your access has expired, reach out to
                    your event owner via
                    <a target="_blank" className="mx-1" href={`mailto:${collaboratorDetails.event_owner_email}`}>
                        {collaboratorDetails.event_owner_email}
                    </a>
                    to renew your access or proceed to renew access.
                    <Button className="bg_dark text-white mt-4 mb-2" text="Proceed to renew access" onClick={() => setRenewUserAccount(true)} />
                </div>
            ) : (
                <div>
                    <h6>You're about to renew this collaborator's account, so as to regain access to this event</h6>
                    <form className="mt-3 mb-3" onSubmit={renewCollaboratorAccount}>
                        <Input
                            value={duration}
                            className="col-12 my-4 px-0"
                            min={1}
                            input={formDetails.duration}
                            required
                            onChange={updateDuration}
                        />
                        <Input
                            value={renewPricing.toLocaleString("en-US")}
                            input={formDetails.pricing}
                            readOnly
                        />
                        <Button className="bg_red mt-5 text-white mx-auto font-weight-bold py-2" type="submit" text="Pay" isLoading={loading} />
                    </form>
                </div>
            )}
        </SiteModal>
    );
}
