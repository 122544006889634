import ContentLoader from "react-content-loader";

export default function AseobiDashboardLoader() {
    const cardLoaderArray = Array(3).fill(0);
    const tableLoaderArray = Array(5).fill(0);

    return (
        <div className="flex flex-col dashboard_loader">
            <div className="card-grid mb-5">
                {cardLoaderArray.map((_, index) => (
                    <ContentLoader
                        key={index}
                        className="mb-1"
                        title="Fetching data"
                        height="150px"
                        backgroundColor="#cbbec0"
                        foregroundColor="#dedede">
                        <rect x="0" y="0" rx="10" ry="10" width="100%" height="140px" />
                    </ContentLoader>
                ))}
            </div>
            <div className="table_loader flex flex-column">
                {tableLoaderArray.map((_, index) => (
                    <ContentLoader
                        key={index}
                        className="mb-1"
                        title="Fetching data"
                        height="70px"
                        backgroundColor="#cbbec0"
                        foregroundColor="#dedede">
                        <rect x="0" y="0" rx="10" ry="10" width="100%" height="50px" />
                    </ContentLoader>
                ))}
            </div>
        </div>
    );
}
