import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import type { MultiValue } from "react-select";
import type { ChangeEvent } from "react";

import Button from "@/components/Button";
import Input from "@/components/form/Input";
import onboardingFormContent from "@/json/form/onboarding_form.json";
import { formatStringToNumber } from "@/helper";
import { useEvent } from "@/hooks/useEvent";
import CeremonyPicker from "@/components/form/CeremonyPicker";
import { createEvent, onboardUserDetails, sendSignupEmail } from "@/axios/post-request";
import useAuth from "@/hooks/useAuth";
import generateRandomColor from "@/utils/generateRandomColor";
import {
    CollaboratorPaymentPlan,
    CollaboratorPlanStatus,
    EventPlanStatus,
    EventPlanType,
    PLAN_PAYMENT_TYPE,
    CeremonyType,
    onboardingDetailsStorageType,
    onboardingFormDetailsType,
} from "@/types";
import { updateOnboardingDetails, updateUserDetails } from "@/axios/put-request";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import { getOnboardingDetails } from "@/axios/get-request";

interface Props {
	nextHandler: () => void;
}

type ceremonyPickerType = MultiValue<{
	label: string;
	value: string;
}>;

export default function OnboardingForm({ nextHandler }: Props) {
    const { setSelectedEventId } = useEvent();
    const { getAuthDetails } = useAuth();
    const [ceremonyPickerOptions] = useState(onboardingFormContent.ceremonyOptions);
    const [userEventId, setUserEventId] = useState("");
    const [onboardingDetails, setOnboardingDetails] = useState<onboardingFormDetailsType>({
        name: "",
        location: "",
        no_of_guests: "",
        event_name: "",
        event_date: "",
        ceremonies: [],
    });

    const { data, status } = useQuery({
        queryKey: ["onboarding_details"],
        queryFn: () => getOnboardingDetails(),
    });

    function onInputHandler(e: ChangeEvent<HTMLInputElement>) {
        setOnboardingDetails({
            ...onboardingDetails,
            [e.target.name]: e.target.value,
        });
    }

    function updateSelectedCeremonies(_ceremonies: unknown) {
        const ceremonies = _ceremonies as ceremonyPickerType;
        setOnboardingDetails({
            ...onboardingDetails,
            ceremonies,
        });
    }

    function updateDefaultOnboardingDetails(onboarding_details: onboardingDetailsStorageType) {
        if (onboarding_details) {
            setOnboardingDetails({
                name: onboarding_details?.name ? onboarding_details.name : "",
                location: onboarding_details?.location ? onboarding_details.location : "",
                no_of_guests: onboarding_details?.no_of_guests ? `${onboarding_details.no_of_guests}` : "",
                event_name: onboarding_details?.event_name ? onboarding_details.event_name : "",
                event_date: onboarding_details?.event_date ? onboarding_details.event_date : "",
                ceremonies: onboarding_details?.ceremonies ? onboarding_details.ceremonies : [],
            });
            setUserEventId(onboarding_details.event_id);
        }
    }

    useEffect(() => {
        const existingOnboardingDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.onboarding_details);
        const onboardingDetailsStorage = existingOnboardingDetails ? JSON.parse(existingOnboardingDetails) : null;
        updateDefaultOnboardingDetails(onboardingDetailsStorage);
    }, []);

    async function onboardUserHandler(e: ChangeEvent<HTMLFormElement>) {
        try {
            e.preventDefault();
            if (!onboardingDetails.name) {
                return toast.error("Name is required");
            }
            if (!onboardingDetails.event_name) {
                return toast.error("Event name is required");
            }
            if (onboardingDetails?.name) {
                const event_bg = generateRandomColor();
                const authDetails = getAuthDetails();
                const userId = authDetails?.currentUser?.uid as string;
                nextHandler();
                await onboardUserDetails(userId, {
                    name: onboardingDetails.name,
                    tour_guide: {
                        user_profile: false,
                        event_overview: false,
                        budget_tracker: false,
                        checklist: false,
                    },
                });
                const eventId = userEventId ? userEventId : uuidv4();
                const ceremoniesArray = [] as CeremonyType;
                onboardingDetails.ceremonies.forEach((item) => {
                    ceremoniesArray.push({
                        id: uuidv4(),
                        name: item.label,
                        location: null,
                        date: null,
                        no_of_guests: null,
                        checklist: [],
                        show_suggestion: true,
                    });
                });
                const createEventRequest = await createEvent({
                    id: eventId,
                    location: onboardingDetails.location,
                    event_name: onboardingDetails.event_name,
                    event_date: onboardingDetails.event_date,
                    no_of_guests: formatStringToNumber(onboardingDetails.no_of_guests),
                    event_bg,
                    ceremonies: ceremoniesArray,
                    collaborators: [
                        {
                            id: authDetails?.currentUser?.uid as string,
                            email: authDetails?.currentUser?.email as string,
                            role: "Event Owner",
                            payment_plan: CollaboratorPaymentPlan.FREE,
                            event_ids: [eventId],
                            plan_duration: null,
                            send_plan_expiration_email: false,
                            plan_status: CollaboratorPlanStatus.ACTIVE,
                            start_plan_date: new Date().toISOString(),
                            end_plan_date: null,
                        },
                    ],
                    event_owner: {
                        email: authDetails?.currentUser?.email as string,
                        id: authDetails?.currentUser?.uid as string,
                        role: "Event Owner",
                        event_id: eventId,
                    },
                    plan: EventPlanType.FREE,
                    plan_amount: null,
                    plan_payment_type: PLAN_PAYMENT_TYPE.ONE_TIME_PAYMENT,
                    plan_duration_month: null,
                    plan_status: EventPlanStatus.ACTIVE,
                    collaborator_per_events: 2,
                    collaborator_ids: [userId],
                    max_events: 3,
                });
                if (createEventRequest.data.status) {
                    setSelectedEventId(eventId);
                    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ceremonies, JSON.stringify(ceremoniesArray));
                    const currentUserEmail = authDetails?.currentUser?.email as string;
                    window.localStorage.setItem(
                        LOCAL_STORAGE_KEYS.onboarding_details,
                        JSON.stringify({
                            event_id: eventId,
                            location: onboardingDetails.location,
                            event_name: onboardingDetails.event_name,
                            event_date: onboardingDetails.event_date,
                            no_of_guests: formatStringToNumber(onboardingDetails.no_of_guests),
                            name: onboardingDetails.name,
                            ceremonies: onboardingDetails.ceremonies,
                        }),
                    );

                    await updateOnboardingDetails(userId, {
                        onboarding_details: {
                            event_id: eventId,
                            location: onboardingDetails.location,
                            event_name: onboardingDetails.event_name,
                            event_date: onboardingDetails.event_date,
                            no_of_guests: formatStringToNumber(onboardingDetails.no_of_guests),
                            name: onboardingDetails.name,
                            ceremonies: onboardingDetails.ceremonies,
                        },
                        onboarding_stage: 1,
                        completed: false,
                    });
                    const sendSignupEmailRequest = await sendSignupEmail(onboardingDetails.name, currentUserEmail);
                    if (sendSignupEmailRequest.status === 200) {
                        await updateUserDetails(userId, {
                            event_ids: [eventId],
                            action: { signup_email_sent: true },
                        });
                    }
                } else {
                    setSelectedEventId("");
                }
            }
        } catch (error) {
            console.log("error", error);
            toast.error("An error occured");
        }
    }

    const disableButton = onboardingDetails.ceremonies.length > 7 || onboardingDetails.ceremonies.length === 0 ? true : false;

    const defaultCeremonyValue = status === "success" ? data?.result?.onboarding_details?.ceremonies : onboardingDetails.ceremonies;
    const defaultCeremony = defaultCeremonyValue as ceremonyPickerType;

    return (
        <>
            <h4 className="mb-lg-5 mb-4">Your Details</h4>
            <form onSubmit={onboardUserHandler}>
                {onboardingFormContent.group1.map((input) => {
                    const inputName = input.name as "name" | "event_name";
                    const inputValue = onboardingDetails[inputName];

                    return <Input key={input.name} input={input} value={inputValue} onChange={onInputHandler} maxLength={50} />;
                })}
                <div className="ceremony_picker">
                    <CeremonyPicker
                        className="col-12 px-0 mt-1"
                        value={onboardingDetails.ceremonies}
                        ceremonies={onboardingDetails.ceremonies}
                        defaultValue={defaultCeremony}
                        onChange={updateSelectedCeremonies}
                        options={ceremonyPickerOptions}
                    />
                </div>
                <Button text="Next" testId="submit_details" type="submit" className="bg_red mx-auto mt-md-5 mt-4" disabled={disableButton} />
            </form>
        </>
    );
}
