import type { costItemType } from "@/types";
import { isWithinInterval, addDays, differenceInCalendarDays } from "date-fns";

export function paymentDeadlineWithinNext14days(costItems: costItemType[]) {
    const costItemsArrayWithDate = costItems.filter((item) => item.balance_due);
    const date = new Date();
    date.setHours(0, 0, 0);
    const upcomingCostItems = costItemsArrayWithDate.filter((item) => {
        const dueDate = item?.balance_due as Date;
        const dueDateInstance = new Date(dueDate);

        const inTheNext14daysRange = isWithinInterval(dueDateInstance, {
            start: date,
            end: addDays(date, 14),
        });
        const diffInDays = differenceInCalendarDays(dueDateInstance, date);
        const sameDay = diffInDays === 0 ? true : false;
        return inTheNext14daysRange || sameDay;
    });

    const sortCostItemByDate = upcomingCostItems.sort((costItem1, costItem2) => {
        const date1 = costItem1?.balance_due ? new Date(costItem1?.balance_due) : costItem1?.balance_due;
        const date2 = costItem2?.balance_due ? new Date(costItem2?.balance_due) : costItem2?.balance_due;

        return Number(date1) - Number(date2);
    });
    return sortCostItemByDate;
}
