import { useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";

import Input from "@/components/form/Input";
import Button from "@/components/Button";
import { sendPricingPlanEmail, updatePricingPlan, verifyPayment } from "@/axios/post-request";
import useUser from "@/hooks/useUser";
import { useQueryClient } from "@tanstack/react-query";
import { PlanType, initializePaymentType, paystackReferenceType } from "@/data/types";
import payStackConfig from "@/payStackConfig";
import { EventPlanType, PaymentType } from "@/types";
import { getUID } from "@/axios/config";
import { COLLABORATOR_PRICING, EVENT_PRICING, PAYSTACK_CURRENCY_CONSTANT } from "@/utils/constant";
import ArrowleftIcon from "@/assets/icon/ArrowleftIcon";
import { capitalizeWord } from "@/helper";


interface Props {
    title: string;
    onCloseModal?: () => void;
    className?: string;
    resetPricingPlan: () => void;
    paymentType: PaymentType.EVENT | PaymentType.COLLABORATOR;
}


export default function PricingPayment({ title, className, onCloseModal, paymentType, resetPricingPlan }: Props) {
    const { data, selectedEventId } = useUser();
    const queryClient = useQueryClient();
    const TITLE = title.toUpperCase();
    const planPricing =
    TITLE === EventPlanType.BASIC ?
        EVENT_PRICING.BASIC : TITLE === EventPlanType.ESSENTIAL ? EVENT_PRICING.ESSENTIAL: EVENT_PRICING.PREMIUM;
    const [errors, setErrors] = useState({
        noOfCollaborators: "",
        duration: "",
        noOfEvent: "",
    });
    const [pricingForm, setPricingForm] = useState({
        noOfCollaborators: 1,
        duration: 1,
        planDuration: 1,
        noOfEvent: 1,
        pricing: planPricing,
    });

    const actual_event_amount = Number(pricingForm.noOfEvent) * planPricing;
    const actual_collaborator_amount = Number(pricingForm.noOfEvent) * Number(pricingForm.duration) * COLLABORATOR_PRICING;

    const actual_amount_to_pay =
    paymentType === PaymentType.EVENT ? actual_event_amount : paymentType === PaymentType.COLLABORATOR ?
        actual_collaborator_amount : 0;

    const email = data?.result?.email as string;
    const config = payStackConfig(email, pricingForm.pricing);
    const _initializePayment: unknown = usePaystackPayment(config);
    const initializePayment = _initializePayment as initializePaymentType;
    function updatePricingFormElement(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const value = Number(event.target.value);
        const validateValue = isNaN(value) ? "Invalid input, please enter digits only." : "";
        setErrors({ ...errors, [event.target.name]: validateValue });
        const basicPricing = planPricing * pricingForm.planDuration * value;
        const otherPricing = planPricing * value;
        const pricing = title === "basic" ? basicPricing : otherPricing;
        setPricingForm({
            ...pricingForm,
            [event.target.name]: event.target.value,
            pricing,
        });
    }

    function updatePricingDurationHandler(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const value = Number(event.target.value);
        const validateValue = isNaN(value) ? "Invalid input, please enter digits only." : "";
        setErrors({ ...errors, [event.target.name]: validateValue });
        const basicPricing = planPricing * pricingForm.noOfCollaborators * value;
        const otherPricing = planPricing * pricingForm.noOfEvent;
        const pricing = title === "basic" ? basicPricing : otherPricing;
        setPricingForm({
            ...pricingForm,
            planDuration: value,
            duration: Number(event.target.value),
            pricing,
        });
    }

    async function onSuccess(reference: paystackReferenceType) {
        const uid = getUID();
        const pricing = Number(pricingForm.pricing);
        const verifyPaymentRequest = await verifyPayment({
            reference_id: reference.reference,
            event_id: selectedEventId,
            user_id: uid,
            type: paymentType,
            amount: pricing * PAYSTACK_CURRENCY_CONSTANT,
            actual_amount: actual_amount_to_pay * PAYSTACK_CURRENCY_CONSTANT
        });

        if (verifyPaymentRequest.status === 500 || !verifyPaymentRequest.data.status) {
            return toast.error(verifyPaymentRequest.data.message);
        }
        toast.success("🎉 Payment successful ");
        const userName = data?.result?.name as string;
        const userEmail = data?.result?.email as string;
        const plan_benefit =
            title === "basic" ?
                "Each event has 2 free collaborators, but you can pay for more collaborators as required." :
                title === "essential" ?
                    "Each event has 2 free collaborators, but you can pay for more collaborators as required." :
                    title === "premium" ?
                        "Each event has 4 free collaborators, but you can pay for more collaborators as required." :
                        "Each event has 2 free collaborators, but you can pay for more collaborators as required.";
        const feature_1 =
            title === "basic" ?
                "3 Free Events" :
                title === "essential" ?
                    "Unlimited Events: Plan to your heart's content" :
                    title === "premium" ?
                        "Unlimited Events: Plan to your heart's content" :
                        "Unlimited Events: Plan to your heart's content";
        const feature_2 =
            title === "basic" ?
                "Duplicate Events: Easily replicate your event structure for similar celebrations" :
                title === "essential" ?
                    "2 Free Collaborators: Invite your dream team to each event" :
                    title === "premium" ?
                        "4 Free Collaborators: Invite your dream team to each event" :
                        "4 Free Collaborators: Invite your dream team to each event";

        const feature_3 = title === "basic" ? "" : "Duplicate Events: Easily replicate your event structure for similar celebrations";

        const pricingPlan = title === "basic" ? "Basic" : title === "essential" ? "Essential" : title === "premium" ? "Premium" : "Custom";

        const plan =
            title === "basic" ?
                PlanType.BASIC :
                title === "essential" ?
                    PlanType.ESSENTIAL :
                    title === "premium" ?
                        PlanType.PREMIUM :
                        title === "custom" ?
                            PlanType.CUSTOM :
                            PlanType.FREE;
        const max_events = title === "basic" ? 3 : Number(pricingForm.noOfEvent) + 3;
        const collaborator_per_events = title === "basic" ? pricingForm.noOfCollaborators : title === "premium" ? 4 : 2;

        await updatePricingPlan({
            plan,
            monthDuration: Number(pricingForm.planDuration),
            amount: Number(pricingForm.pricing),
            max_events,
            collaborator_per_events,
            eventId: selectedEventId,
        });
        await sendPricingPlanEmail({
            name: userName,
            email: userEmail,
            plan: pricingPlan,
            plan_benefit,
            feature_1,
            feature_2,
            feature_3,
        });
        if (onCloseModal) {
            onCloseModal();
        }
        queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
    }

    function onClose() {
        return toast.error("Unable to make payment");
    }

    function onSubmitPayment(event: ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        if (
            !errors.noOfCollaborators &&
            !errors.duration &&
            !errors.noOfEvent &&
            pricingForm.noOfCollaborators&&
            pricingForm.duration &&
            pricingForm.noOfEvent && pricingForm.pricing
        ) {
            return initializePayment((reference) => onSuccess(reference), onClose);
        } else {
            return toast.error("Ensure to fill the form fields properly");
        }
    }

    const pricingDuration = Number(pricingForm.duration);
    const pricingnoOfEvent = Number(pricingForm["noOfEvent"]);
    const collaboratorsError = errors["noOfCollaborators"] && isNaN(pricingForm.noOfCollaborators) ? errors["noOfCollaborators"] : "";
    const durationError = errors["duration"] && isNaN(pricingDuration) ? errors["duration"] : "";
    const noOfEventError = errors["noOfEvent"] && isNaN(pricingnoOfEvent) ? errors["noOfEvent"] : "";
    const pricingPlan = title ? capitalizeWord(title): "";

    return (
        <form className={`mt-3 mb-3 ${className} pricing_payment_form`} onSubmit={onSubmitPayment}>
            <div className="d-flex align-items-center mb-4 plan_title_group position-relative w-100 justify-content-center">
                <button type="button" className="btn position-absolute start-0"
                    onClick={resetPricingPlan}><ArrowleftIcon fill="black" /></button>
                <h5 className="mb-0 capitalize">{pricingPlan} Plan</h5>
            </div>
            {title === "basic" ? (
                <div className="basic_plan my-md-3 mb-1 mb-md-4 d-flex flex-column flex-md-row align-items-end">
                    <Input
                        value={pricingForm.noOfCollaborators}
                        className="col-md-6 pr-md-2  col-12 px-0 mb-0 my-md-0"
                        input={{ label: "Number of Collaborator", name: "noOfCollaborators", placeholder: "1" }}
                        min={1}
                        required
                        onChange={updatePricingFormElement}
                        error={collaboratorsError}
                    />
                    <Input
                        value={pricingForm.duration}
                        className="col-md-6 col-12 pl-md-2 pl-0 px-0 my-md-0 mb-0 my-3"
                        min={1}
                        input={{
                            label: "Duration (month)",
                            placeholder: "4",
                            name: "duration",
                        }}
                        required
                        onChange={updatePricingDurationHandler}
                        error={durationError}
                    />
                </div>
            ) : (
                <div className="mb-4 mt-2">
                    <Input
                        value={pricingForm.noOfEvent}
                        input={{ label: "Number of events", name: "noOfEvent", placeholder: "Number of events" }}
                        type="text"
                        required
                        onChange={updatePricingFormElement}
                        error={noOfEventError}
                    />
                </div>
            )}
            <Input
                value={pricingForm.pricing.toLocaleString("en-US")}
                input={{ label: "Total Sum (₦)", name: "pricing", placeholder: "Total Sum (₦)" }}
                readOnly
            />
            <Button className="bg_red mt-5 text-white mx-auto font-weight-bold py-2" type="submit" text="Pay" />
        </form>
    );
}
