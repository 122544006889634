/* eslint-disable max-len */

export default function Rectangle3Icon() {
    return (
        <svg width="44" height="14" viewBox="0 0 44 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.1665 1.3335V3.6665C15.1665 4.12683 15.5397 4.5 16 4.5L18.333 4.5C18.7933 4.5 19.1665 4.12683 19.1665 3.6665V1.3335C19.1665 0.873171 18.7933 0.5 18.333 0.5L16 0.5C15.5397 0.5 15.1665 0.873171 15.1665 1.3335Z"
                fill="#D9D9D9"
            />
            <path
                d="M20 1.3335V3.6665C20 4.12683 20.3732 4.5 20.8335 4.5L23.1665 4.5C23.6268 4.5 24 4.12683 24 3.6665V1.3335C24 0.873171 23.6268 0.5 23.1665 0.5L20.8335 0.5C20.3732 0.5 20 0.873171 20 1.3335Z"
                fill="#D9D9D9"
            />
            <path
                d="M24.8335 1.3335V3.6665C24.8335 4.12683 25.2067 4.5 25.667 4.5L28 4.5C28.4603 4.5 28.8335 4.12683 28.8335 3.6665V1.3335C28.8335 0.873171 28.4603 0.5 28 0.5L25.667 0.5C25.2067 0.5 24.8335 0.873171 24.8335 1.3335Z"
                fill="#D9D9D9"
            />
            <path
                d="M13.1 12.5L13.1 6.5C13.1 6.00294 13.5029 5.6 14 5.6L30 5.6C30.4971 5.6 30.9 6.00294 30.9 6.5V12.5C30.9 12.9971 30.4971 13.4 30 13.4L14 13.4C13.5029 13.4 13.1 12.9971 13.1 12.5Z"
                fill="#D9D9D9"
                stroke="#404040"
                strokeWidth="0.2"
            />
        </svg>
    );
}
