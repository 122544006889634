import { useQuery } from "@tanstack/react-query";
import { getEventTags } from "@/axios/get-request";

export default function TipsCard() {
    const { data, status } = useQuery({
        queryKey: ["get-event-tags"],
        queryFn: () => getEventTags(),
    });

    return (
        <div className="tips_ideas card">
            <h5>Tips & Ideas</h5>
            {status === "error" ? (
                <p>unable to fetch event tips</p>
            ) : status === "loading" ? (
                <p>fetching event tips...</p>
            ) : (
                <div className="button_group">
                    {data.data.tags.slice(0, 14).map((tip, index) => (
                        <a
                            key={index}
                            href={tip.url}
                            target="_blank"
                            className="bg_dark"
                        >
                            {tip.name}
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
}
