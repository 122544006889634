import { Suspense } from "react";
import type { PropsWithChildren } from "react";

import editIcon from "@/assets/img/edit.svg";
import checkIcon from "@/assets/img/checkIcon.svg";
import Button from "@/components/Button";
import Input from "@/components/form/Input";
import arrowdown from "@/assets/img/arrow_chevron_down.svg";
import trash from "@/assets/img/trash.svg";
import SiteModal from "@/components/modals/SiteModal";
import useBudgetlist from "@/hooks/useBudgetlist";
import budgetBreakdownContent from "@/json/budget_breakdown.json";
import BudgetListDropdownItem from "@/components/BudgetListDropdownItem";
import type { categoryType } from "@/types";

interface Props {
    categoryId: string;
    costItem: categoryType["cost_items"][0] | null;
    index: number;
    categoryName: string;
}

export default function BudgetListDropdown({ costItem, categoryId, index, categoryName }: PropsWithChildren<Props>) {
    const { budgetTitle, formError, budgetDropdown, editBudgetTitle,
        isLoading, errorModal, func, budget } = useBudgetlist(costItem, categoryId, categoryName);

    const { editBudgetTitleHandler, setBudgetTitleHandler, onAmountChange, closeModal, showDeleteModal, toggleDropdown, onFocusInputHandler } = func;

    const editButtonIcon = editBudgetTitle ? checkIcon : editIcon;

    const budgetActualValue = budget?.actual ? budget?.actual.toLocaleString("en-US") : "";

    const budgetEstimateValue = budget?.estimate ? budget?.estimate.toLocaleString("en-US") : "";

    const dropdownClassName = budgetDropdown.costItemId === budget?.id ? "active" : "";
    const actualInputError = formError === "actual" ? "input_error" : "";
    const estimateInputError = formError === "estimate" ? "input_error" : "";

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                {errorModal && (
                    <SiteModal title={`❌ ${budget?.name} balance error`} show={errorModal} onHide={closeModal}>
                        <p>Please update {budget?.name} actual price</p>
                    </SiteModal>
                )}
            </Suspense>
            <div className={`budget_list_dropdown_view ${dropdownClassName}`}>
                <div className="estimate_actual d-flex flex-lg-row flex-column align-items-start align-items-lg-end">
                    <div className="estimate_actual_group mb-2 d-flex align-items-center justify-content-between">
                        <div className="estimate_actual_top justify-content-between col-sm-10 col-lg-12 px-0">
                            <div className="title d-flex align-items-center">
                                {!editBudgetTitle ? (
                                    <p>{budgetTitle}</p>
                                ) : (
                                    <input placeholder="" className="budget_title_input" value={budgetTitle} onChange={setBudgetTitleHandler} />
                                )}
                                <button id={`edit_cost_item_${index}`} className="border-0 bg-transparent" onClick={editBudgetTitleHandler}>
                                    <img src={editButtonIcon} className="ml-1" alt="edit" />
                                </button>
                            </div>
                        </div>
                        <div className="icon_group d-lg-none d-flex col-2 px-0">
                            <Button className="px-0 dropdown_icon" icon={arrowdown} onClick={toggleDropdown} />
                            <Button className="trash px-0" icon={trash} onClick={showDeleteModal} />
                        </div>
                    </div>
                    <div
                        // eslint-disable-next-line max-len
                        className="d-flex align-items-lg-end col-lg-8 pl-0 col-12 estimate_n_actual_input_group flex-column flex-md-row mt-2 mt-lg-0 input_group pr-lg-4 pr-md-3 pr-0">
                        <div className="col-lg-4 col-md-6 col-12 px-0">
                            {index === 0 && (
                                <div className="mx-auto mb-2 d-none d-lg-flex flex-column justify-content-center align-items-center">
                                    <h4 className="mb-0">ESTIMATE</h4>
                                    <p>NGN</p>
                                </div>
                            )}
                            <Input
                                input={budgetBreakdownContent.budgetList.estimate}
                                value={budgetEstimateValue}
                                type="text"
                                className={estimateInputError}
                                maxLength={10}
                                onChange={onAmountChange}
                                onFocus={onFocusInputHandler}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 px-0 pr-lg-0 pr-md-1 pr-0">
                            {index === 0 && (
                                <div className="mx-auto mb-2 d-none d-lg-flex flex-column justify-content-center align-items-center">
                                    <h4 className="mb-0">ACTUAL</h4>
                                    <p>NGN</p>
                                </div>
                            )}
                            <Input
                                input={budgetBreakdownContent.budgetList.actual}
                                value={budgetActualValue}
                                maxLength={10}
                                className={actualInputError}
                                onChange={onAmountChange}
                                onFocus={onFocusInputHandler}
                            />
                        </div>
                        <div className="icon_group d-none mb-2 d-lg-flex">
                            <Button icon={arrowdown} onClick={toggleDropdown} className="px-0 dropdown_icon" />
                            <Button icon={trash} onClick={showDeleteModal} className="px-0 delete_icon" />
                        </div>
                    </div>
                </div>
                {budgetDropdown.costItemId === budget?.id ?
                    <BudgetListDropdownItem formError={formError} budget={budget} func={func} isLoading={isLoading} /> : null}
            </div>
        </>
    );
}
