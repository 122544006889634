interface Props {
	fill?: string;
}
export default function MinusIcon({ fill = "#000" }: Props) {
    return (
        <svg viewBox="0 0 24 24" height="30px" width="30px" stroke="currentColor" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="3"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M6 12L18 12" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>{" "}
            </g>
        </svg>
    );
}
