/* eslint-disable max-len */

export default function AddNewUserIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21" fill="none">
            <path
                d="M24.375 8.625H21.875V6.125C21.875 5.78125 21.5938 5.5 21.25 5.5H20C19.6562 5.5 19.375 5.78125 19.375 6.125V8.625H16.875C16.5312 8.625 16.25 8.90625 16.25 9.25V10.5C16.25 10.8438 16.5312 11.125 16.875 11.125H19.375V13.625C19.375 13.9688 19.6562 14.25 20 14.25H21.25C21.5938 14.25 21.875 13.9688 21.875 13.625V11.125H24.375C24.7188 11.125 25 10.8438 25 10.5V9.25C25 8.90625 24.7188 8.625 24.375 8.625ZM8.75 10.5C11.5117 10.5 13.75 8.26172 13.75 5.5C13.75 2.73828 11.5117 0.5 8.75 0.5C5.98828 0.5 3.75 2.73828 3.75 5.5C3.75 8.26172 5.98828 10.5 8.75 10.5ZM12.25 11.75H11.5977C10.7305 12.1484 9.76562 12.375 8.75 12.375C7.73438 12.375 6.77344 12.1484 5.90234 11.75H5.25C2.35156 11.75 0 14.1016 0 17V18.625C0 19.6602 0.839844 20.5 1.875 20.5H15.625C16.6602 20.5 17.5 19.6602 17.5 18.625V17C17.5 14.1016 15.1484 11.75 12.25 11.75Z"
                fill="#545565"
            />
        </svg>
    );
}
