/* eslint-disable max-len */
type SvgProps = {
    color: string;
};

export default function Anonymous({ color }: SvgProps) {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M26.9981 22.1746L23.4616 20.944C23.2045 20.8533 22.9144 20.9262 22.7288 21.1283C20.9949 23.0203 18.7825 24.0625 16.5 24.0625C14.2175 24.0625 12.0051 23.0203 10.2712 21.1283C10.0843 20.9262 9.79688 20.8547 9.53835 20.944L6.00188 22.1746C2.41177 23.4231 0 26.8153 0 30.6157V31.625C0 32.0045 0.308021 32.3125 0.687521 32.3125H32.3125C32.692 32.3125 33.0001 32.0045 33.0001 31.625V30.6157C33 26.8138 30.5882 23.4217 26.9981 22.1746ZM31.625 30.9375H1.37498V30.6157C1.37498 27.3996 3.4155 24.5286 6.45285 23.4726L9.57135 22.3877C11.5211 24.3581 13.9658 25.4374 16.5 25.4374C19.0342 25.4374 21.4789 24.358 23.4286 22.3877L26.5471 23.4726C29.5845 24.5286 31.625 27.3996 31.625 30.6157V30.9375Z"
                    fill={color}
                />
                <path
                    d="M24.7459 9.11629C24.7487 9.05719 24.75 8.99666 24.75 8.93756C24.75 4.38769 21.0499 0.687561 16.5 0.687561C11.9501 0.687561 8.25001 4.38769 8.25001 8.93756C8.25001 8.99666 8.25136 9.05719 8.25413 9.11629C7.43461 9.56456 6.87503 10.8171 6.87503 12.375C6.87503 14.2877 7.7193 15.741 8.85226 15.8098C10.1021 19.9513 13.112 22.6875 16.5 22.6875C19.888 22.6875 22.8979 19.9513 24.1478 15.8098C25.2808 15.741 26.125 14.2877 26.125 12.375C26.125 10.8171 25.5654 9.56456 24.7459 9.11629ZM24.0199 14.4073C23.8329 14.2918 23.6033 14.2725 23.3984 14.355C23.1962 14.4375 23.045 14.6121 22.9913 14.8253C22.0289 18.7056 19.4205 21.3126 16.5 21.3126C13.5795 21.3126 10.9712 18.7056 10.0086 14.8253C9.95499 14.6121 9.78309 14.454 9.57961 14.3702C9.50401 14.3385 9.42151 14.3248 9.33759 14.3248C9.19734 14.3248 9.05432 14.3647 8.93747 14.4375C8.76563 14.4375 8.25001 13.7061 8.25001 12.375C8.25001 11.1141 8.71336 10.3923 8.90724 10.318C8.91413 10.3194 8.92238 10.3208 8.92786 10.3208C9.12999 10.3359 9.33759 10.2685 9.48338 10.1228C9.62911 9.97564 9.70201 9.77081 9.68003 9.56591L9.66076 9.39402C9.64426 9.24274 9.62499 9.09289 9.62499 8.93749C9.62499 5.14669 12.7091 2.06254 16.5 2.06254C20.2909 2.06254 23.375 5.14668 23.375 8.93756C23.375 9.09296 23.3558 9.24281 23.3393 9.39266L23.32 9.56456C23.2994 9.77081 23.3708 9.97571 23.5166 10.1214C23.6638 10.2686 23.8686 10.3373 24.0722 10.3208C24.0776 10.3194 24.0859 10.3194 24.0928 10.318C24.2867 10.3923 24.75 11.1141 24.75 12.375C24.75 13.7061 24.2454 14.4375 24.0199 14.4073Z"
                    fill={color}
                />
                <path
                    d="M16.5 6.1875C14.8802 6.1875 13.75 7.31775 13.75 8.93752C13.75 9.31702 14.058 9.62504 14.4375 9.62504C14.817 9.62504 15.125 9.31702 15.125 8.93752C15.125 7.69725 16.0861 7.56254 16.5 7.56254C16.9138 7.56254 17.875 7.69732 17.875 8.93752C17.875 10.0912 17.6206 10.6178 16.8286 11.1004C15.2995 12.0354 15.0438 13.2715 15.1332 13.8545C15.1855 14.1928 15.4742 14.4251 15.8069 14.4251C15.8344 14.4251 15.8633 14.4224 15.8922 14.4196C16.2593 14.377 16.5247 14.0374 16.4944 13.6675C16.4931 13.6387 16.4491 12.9443 17.5463 12.2746C18.7563 11.5349 19.2499 10.5683 19.2499 8.93752C19.25 7.31775 18.1198 6.1875 16.5 6.1875Z"
                    fill={color}
                />
                <path
                    d="M15.8125 17.1875C16.1922 17.1875 16.5 16.8797 16.5 16.5C16.5 16.1203 16.1922 15.8124 15.8125 15.8124C15.4328 15.8124 15.125 16.1203 15.125 16.5C15.125 16.8797 15.4328 17.1875 15.8125 17.1875Z"
                    fill="#CB3050"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="33" height="33" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
