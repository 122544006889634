import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import CodeIcon from "@/assets/icon/CodeIcon";
import { getAllVendors } from "@/axios/get-request";
import getFirstChar from "@/utils/getFirstChar";
import { generateColorFromId } from "@/helper";
import { VendorType } from "@/types";
import UserSearchIcon from "@/assets/icon/UserSearchIcon";
import { VendorLoader } from "../loaders/AuditsLoader";

interface Props {
    vendorId: string;
    onSelectEventVendor: (value: VendorType | null) => void;
}

const defaultVendor = {
    name: "Unassigned",
    id: "",
    service: "",
    email: "",
    user_id: "",
    event_id: [],
    phone_number: "",
    account_number: "",
    bank_name: "",
    bank_code: "",
};

export default function VendorDetailsCard({ vendorId, onSelectEventVendor }: Props) {
    const { data, status } = useQuery({
        queryKey: ["all_event_vendors"],
        queryFn: () => getAllVendors(),
    });
    const [activeVendorIndex, setActiveVendorIndex] = useState<number | null>(null);

    const vendors = data?.data?.result ? [defaultVendor, ...data.data.result] : [defaultVendor];

    useEffect(() => {
        if (status === "success") {
            const selectedVendorId = vendorId ? (vendors.findIndex((item) => item.id === vendorId) as number) : 0;
            setActiveVendorIndex(selectedVendorId);
        }
    }, [status, vendorId]);

    useEffect(() => {
        if (activeVendorIndex !== null) {
            const selectedVendor = vendors[activeVendorIndex];
            onSelectEventVendor(selectedVendor);
        }
    }, [activeVendorIndex]);

    const activeVendor = status === "success" && activeVendorIndex ? vendors[activeVendorIndex] : null;
    const className = activeVendor?.name ? "active" : "";

    function onChangeVendor() {
        if (activeVendorIndex !== null && data?.data?.result && vendors.length - 1 > activeVendorIndex) {
            setActiveVendorIndex((prev) => {
                if (prev !== null) {
                    return prev + 1;
                } else {
                    return null;
                }
            });
        } else {
            setActiveVendorIndex(0);
        }
    }

    const mailLink = activeVendor?.email ? `mailto:${activeVendor?.email}` : "#";
    const telLink = activeVendor?.email ? `tel:${activeVendor?.phone_number}` : "#";

    return (
        <div className="vendor_card_details">
            {status === "loading" ? (
                <VendorLoader />
            ) : (
                <>
                    <button className="vendor_row_1" onClick={onChangeVendor}>
                        <div
                            className={`vendor_initials ${className}`}
                            style={{ backgroundColor: activeVendor?.id ? generateColorFromId(activeVendor?.id) : "" }}>
                            {activeVendor?.id ? (
                                getFirstChar(activeVendor.name)
                            ) : (
                                <div className="icon_wrapper">
                                    <UserSearchIcon />
                                </div>
                            )}
                        </div>
                        <div className="name">
                            <h5>{activeVendor?.name ? activeVendor?.name : "Name: N/A"}</h5>
                            <p>{activeVendor?.service ? activeVendor?.service : "Service: N/A"}</p>
                        </div>
                        <CodeIcon />
                    </button>
                    <div className="vendor_row_2">
                        <div className="vendor_contacts">
                            <a href={mailLink}>{activeVendor?.email ? activeVendor?.email : "Email:  N/A"}</a>
                            <a href={telLink}>{activeVendor?.phone_number ? activeVendor?.phone_number : "Phone:  N/A"}</a>
                        </div>
                        <div className="bank_details">
                            <h6>Bank Name</h6>
                            <p>{activeVendor?.bank_name ? activeVendor?.bank_name : "N/A"}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
