import { useFormContext, type FieldError } from "react-hook-form";
import { useState } from "react";

import eyeIcon from "@/assets/img/eye_icon.svg";
import closedEyeIcon from "@/assets/img/closed_eye.svg";
import "@/styles/form.scss";

interface Props {
	input: {
		name: string;
		placeholder: string;
		type?: string;
	};
    defaultValue?: string;
    readOnly?:boolean;
	error?: FieldError;
    className?:string;
}

export default function CustomPasswordInput({ input, error, defaultValue, readOnly=false, className="" }: Props) {
    const { name, placeholder } = input;
    const { register, watch} = useFormContext();
    const errorInput = error?.message ? "error" : "";
    const [showPassword, setShowPassword] = useState(false);

    function showPasswordHandler() {
        setShowPassword((prev) => !prev);
    }

    const passwordType = showPassword ? "text" : "password";
    const passwordIcon = showPassword ? eyeIcon : closedEyeIcon;
    const value = watch(name);

    return (
        <div className={`w-full form_element  password_input input custom_input ${className}`}>
            <div className="position-relative">
                <input
                    className={`form-control ${errorInput}`}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    type={passwordType}
                    readOnly={readOnly}
                    {...register(name)}
                />
                {value?.length > 0 && (
                    <button onClick={showPasswordHandler} type="button">
                        <img src={passwordIcon} alt="eye" />
                    </button>
                )}
            </div>
            {error?.message && <p className="m-0 py-1">{error?.message}</p>}
        </div>
    );
}
