import { Link } from "react-router-dom";

import TipsCard from "@/components/TipsCard";
import Quotes from "@/components/Quotes";
import linkIcon from "@/assets/img/link_icon.svg";
import { useFilter } from "@/hooks/useFilter";
import { paymentDeadlineWithinNext14days } from "@/utils/sortPaymentDeadline";
import { range14DaysTodo } from "@/utils/sortTodos";
import { whenIsBalanceDue } from "@/helper";
import UpcomingTask from "./UpcomingTask";
import useMyEvents from "@/hooks/useMyEvents";
import type { costItemType, EventType } from "@/types";

interface Props {
	event: EventType | null;
}

export default function EventOverview({ event }: Props) {
    const { ceremony, setCeremony } = useFilter();
    const { activeEvent } = useMyEvents();

    const allChecklist =
		activeEvent && activeEvent.length > 0 ? activeEvent[0].ceremonies.filter((_ceremony) => _ceremony.id === ceremony.id)[0]?.checklist : [];

    let allCostItems: costItemType[] = [];

    event?.budget?.categories?.map((category) => {
        allCostItems = [...allCostItems, ...category.cost_items];
    });

    const ceremonyCostItem = ceremony?.id !== "all" ? allCostItems.filter((item) => item.tag === ceremony?.id) : allCostItems;

    const budgetActual = ceremonyCostItem.reduce((a, b) => a + b?.actual, 0);
    const budgetEstimate = ceremonyCostItem.reduce((a, b) => a + b?.estimate, 0);
    const budgetPaid = ceremonyCostItem.reduce((a, b) => a + b?.paid, 0);
    const balance = budgetActual - budgetPaid;

    const paymentDeadlines = ceremonyCostItem.filter((_ceremony) => _ceremony.balance > 0);
    const paymentWithin14daysDeadline = paymentDeadlineWithinNext14days(paymentDeadlines);

    const filteredCeremony = ceremony?.id !== "all" ? allChecklist?.filter((_checklist) => _checklist.ceremony.id === ceremony?.id) : allChecklist;
    const completedTodosCount = filteredCeremony && filteredCeremony?.length ? filteredCeremony.filter((item) => item.isDone).length : 0;
    const todos = event?.ceremonies && filteredCeremony ? filteredCeremony.filter((item) => !item.isDone) : [];
    const sortedTodos = range14DaysTodo(todos);
    const firstTwoUpcomingTask = sortedTodos.slice(0, 2);
    const firstTwoUpcomingPayment = paymentWithin14daysDeadline.slice(0, 2);
    const taskText = sortedTodos.length > 1 ? "tasks" : "task";

    // set ceremony to all ceremony as default
    function setDefaultCeremony() {
        setCeremony({
            name: "All Ceremonies",
            id: "all",
        });
    }

    const upcomingPaymentText = firstTwoUpcomingPayment.length > 1 ? "payments" : "payment";

    return (
        <div className="event_overview_grid pt-3">
            <div className="grid_row_1">
                <div id="budget_card" className="item_card left_card px-0  bg-white">
                    <div className="budget_card px-4  pt-4 pb-3">
                        <h3>Budget</h3>
                        <div className="group d-flex mb-3 mt-4 px-xl-3 justify-content-between">
                            <div className="estimate">
                                <h6>ESTIMATE</h6>
                                <p>₦ {budgetEstimate.toLocaleString()}</p>
                            </div>
                            <div className="estimate">
                                <h6>PAID</h6>
                                <p>₦ {budgetPaid.toLocaleString()}</p>
                            </div>
                            <div className="estimate">
                                <h6>BALANCE</h6>
                                <p>₦ {balance.toLocaleString()}</p>
                            </div>
                        </div>
                        <Link to="/old/dashboard/budget-tracker" onClick={setDefaultCeremony}>
							MANAGE BUDGET <img src={linkIcon} alt="link icon" />{" "}
                        </Link>
                    </div>
                    <hr className="mt-1 mb-3" />
                    <div className="upcoming_payment px-4 py-1">
                        <h3>
                            {firstTwoUpcomingPayment.length} Upcoming {upcomingPaymentText}
                        </h3>
                        {firstTwoUpcomingPayment.length > 0 && (
                            <ul className="d-flex flex-column p-0">
                                {firstTwoUpcomingPayment.map((paymentItem, index) => {
                                    const balance_due = paymentItem.balance_due ? whenIsBalanceDue(paymentItem.balance_due) : "-";
                                    const className = firstTwoUpcomingPayment.length > 1 && index === 0 ? "border_bottom" : "";

                                    return (
                                        <li className={`${className} pb-2 pt-4`} key={paymentItem.id}>
                                            <h6>
												₦{paymentItem.balance.toLocaleString()} due for {paymentItem.name}
                                            </h6>
                                            <p>{balance_due}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </div>
                <div id="checklist_card" className="item_card right_card bg-white pb- px-0">
                    <div className="checklist_card px-4 pt-4 pb-3">
                        <h3>Checklist</h3>
                        {allChecklist && allChecklist.length > 0 ? (
                            <p className="my-4 pb-3 text-center">
                                {completedTodosCount} / {allChecklist.length} complete
                            </p>
                        ) : (
                            <p className="my-4 pb-3 text-center">No available checklist</p>
                        )}
                        <Link to="/old/dashboard/checklist" onClick={setDefaultCeremony}>
							MANAGE CHECKLIST <img src={linkIcon} alt="link icon" />{" "}
                        </Link>
                    </div>
                    <hr className="mt-1 mb-3" />
                    <div className="upcoming_task px-4 py-1">
                        <h3>
                            {sortedTodos.length} Upcoming {taskText}
                        </h3>
                        {firstTwoUpcomingTask.length > 0 && <UpcomingTask firstTwoUpcomingTask={firstTwoUpcomingTask} activeEvent={activeEvent} />}
                        {sortedTodos.length > 2 && (
                            <Link to="/old/dashboard/checklist">
                                <span className="view_all">View All</span>
                                <img src={linkIcon} alt="link icon" className="ml-1" />{" "}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex card_row flex-column flex-md-row">
                <TipsCard />
                <Quotes />
            </div>
        </div>
    );
}
