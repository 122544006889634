/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Rect } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	height: number;
	fill: string;
	type: FloorPlanObjectListType;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: (resetScale?: boolean) => void;
}

export default function LineDimensionElement({ refValue, onSelect, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Rect
            strokeWidth={1}
            stroke="#E72E50"
            {...item}
            width={item.width}
            onClick={onSelect}
            ref={refValue}
            name="floor_plan_element"
            strokeScaleEnabled={false}
            dash={[4, 4]}
            draggable
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}
        />
    );
}
