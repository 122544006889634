import sun_wind_rain from "@/assets/img/sun_wind_rain.svg";
import sunny_partly_cloudy from "@/assets/img/sunny_partly_cloudy.svg";
import haze from "@/assets/img/haze.svg";
import cloud from "@/assets/img/cloud.svg";
import snow from "@/assets/img/snow.svg";
import rain_clouds from "@/assets/img/rain_clouds.svg";
import thunderstorm from "@/assets/img/thunderstorm.svg";
import cloudIcon from "@/assets/img/cloud_icon.svg";

type weatherConditionType = {
    max: number;
    min: number;
};

export default function getWeatherCondition(weatherCondition: weatherConditionType | null) {
    const weatherIcon = weatherCondition ?
        weatherCondition.max < 45 ?
            sunny_partly_cloudy :
            weatherCondition.min >= 45 && weatherCondition.max <= 48 ?
                sun_wind_rain :
                weatherCondition.min >= 51 && weatherCondition.max <= 57 ?
                    haze :
                    weatherCondition.min >= 61 && weatherCondition.max <= 67 ?
                        cloud :
                        weatherCondition.min >= 71 && weatherCondition.max <= 79 ?
                            snow :
                            weatherCondition.min >= 80 && weatherCondition.max <= 86 ?
                                rain_clouds :
                                weatherCondition.min >= 95 && weatherCondition.max <= 99 ?
                                    thunderstorm :
                                    cloudIcon :
        null;

    return weatherIcon;
}
