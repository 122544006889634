import { useEffect, useState } from "react";

import SelectInput from "@/components/SelectInput";
import type { AsoebiOptionType } from "@/data/types";

interface Props {
	onClickHandler: (selectedOptions: AsoebiOptionType) => void;
	placeholder: string;
	value: AsoebiOptionType;
}

export default function SelectInputGroup({ onClickHandler, placeholder, value }: Props) {
    const [selectedOptions, setSelectedOptions] = useState([
        {
            option: "",
            price: "",
            max_stock_qty: 0,
            stock_bought_qty: 0
        },
    ]);

    useEffect(() => {
        if (value) {
            setSelectedOptions(value);
        }
    }, [value[0].option]);

    return (
        <div className="select_input_group d-flex flex-column">
            {selectedOptions.map((_, index) => (
                <SelectInput
                    key={index}
                    index={index}
                    placeholder={placeholder}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    onClickHandler={onClickHandler}
                />
            ))}
        </div>
    );
}
