import { Suspense, useEffect, useState } from "react";
import FuzzySearch from "fuzzy-search";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { ChangeEvent } from "react";

import Button from "@/components/Button";
import addIcon from "@/assets/img/add.svg";
import AddEventModal from "@/components/modals/AddEventModal";
import EventCard from "@/components/EventCard";
import useProfile from "@/hooks/useProfile";
import DuplicateEventModal from "@/components/DuplicateEventModal";
import EventsLoader, { EventsLineLoader } from "@/components/loaders/EventsLoader";
import Select from "@/components/form/Select";
import CancelIcon from "@/assets/icon/Cancel";
import ArrowleftIcon from "@/assets/icon/ArrowleftIcon";
import ArrowRightIcon from "@/assets/icon/ArrowRightIcon";
import { CollaboratorPaymentPlan, CollaboratorPlanStatus, type EventType } from "@/types";
import useUser from "@/hooks/useUser";
import { PlanType } from "@/data/types";
import RenewCollaboratorAccountModal from "./modals/RenewCollaboratorAccountModal";
import { getEvents } from "@/axios/get-request";
import { useEvent } from "@/hooks/useEvent";

const eventLimitOptions = [3, 6, 9, 15, 30];

interface Props {
    className?: string;
}

export default function EventView({ className = "" }: Props) {
    const [limit, setLimit] = useState(3);
    const [pageCounter, setPageCounter] = useState(1);
    const { setSelectedEventId } = useEvent();
    const { data: userData, selectedEventId } = useUser();
    const { status, data } = useQuery({
        queryKey: ["get_all_events"],
        queryFn: () => getEvents(onSuccessHandler),
    });
    const [renewAccessModalVisibility, setRenewAccessModalVisibility] = useState(false);
    const { modal, func, mutateData } = useProfile();
    const { onOpenModalHandler, onCloseModalHandler, showDuplicateEventModal } = func;
    const [searchText, setSearchText] = useState("");
    const [searchResult, setSearchResult] = useState<EventType[]>([]);
    const queryClient = useQueryClient();

    const userEvents = status === "success" && data?.data?.result ? data?.data?.result : null;

    const selectedEvent = userEvents
        ? selectedEventId
            ? userEvents.filter((userEvent) => userEvent.id === selectedEventId)[0]
            : userEvents[0]
        : null;

    useEffect(() => {
        if (userEvents && userEvents.length > 0) {
            const searcher = new FuzzySearch(userEvents, ["event_name"]);
            const result = searcher.search(searchText);
            setSearchResult(result);
        }
    }, [searchText]);

    const eventLimit = status === "success" ? data?.data?.result?.length : 0;
    const userEventsData = searchText ? searchResult : userEvents;

    const collaborator = selectedEvent ? selectedEvent?.collaborators?.filter((collaborator) => collaborator?.id === userData?.result?.id) : null;

    function onSuccessHandler(data: { result: EventType[] }) {
        if (!selectedEventId && data?.result && Array.isArray(data?.result) && data?.result?.length > 0) {
            setSelectedEventId(data.result[0]?.id);
        }
    }

    function showAddEventModal() {
        if (
            collaborator &&
            collaborator[0]?.plan_status === CollaboratorPlanStatus.EXPIRED &&
            collaborator[0]?.payment_plan === CollaboratorPaymentPlan.PAID
        ) {
            return setRenewAccessModalVisibility(true);
        }
        if (collaborator && collaborator[0]?.plan_status === CollaboratorPlanStatus.ACTIVE) {
            onOpenModalHandler();
        }
    }

    const canAddNewEvent =
        selectedEvent?.plan === CollaboratorPaymentPlan.FREE || selectedEvent?.plan === PlanType.BASIC
            ? userEvents && userEvents?.length < 3
                ? true
                : false
            : true;

    function onSelectEventLimit(e: ChangeEvent<HTMLSelectElement>) {
        const selectedLimit = Number(e.target.value);
        setLimit(selectedLimit);
    }

    function setSearchHandler(e: ChangeEvent<HTMLInputElement>) {
        setSearchText(e.target.value);
    }

    function searchChecklistHandler() {
        setSearchText("");
    }

    function incrementPageCounter() {
        setPageCounter((prev) => prev + 1);
    }

    function invalidateQueries() {
        queryClient.invalidateQueries({ queryKey: [`get_audits_${selectedEventId}`], exact: true });
    }

    function decrementPageCounter() {
        if (pageCounter > 1) {
            setPageCounter((prev) => prev - 1);
        }
    }

    const disableLeftArrowBtn = (eventLimit && eventLimit <= limit) || pageCounter === 1 ? true : false;
    const leftArrowColor = disableLeftArrowBtn ? "gray" : "black";
    const disableRightArrowBtn = eventLimit && eventLimit <= limit * pageCounter ? true : false;
    const rightArrowColor = disableRightArrowBtn ? "gray" : "black";
    const eventMin = pageCounter === 1 ? 0 : limit * (pageCounter - 1);
    const eventMax = limit * pageCounter;
    const showingEventCounter = eventLimit && limit * pageCounter < eventLimit ? limit * pageCounter : eventLimit;

    const eventDetails = status === "success" && selectedEvent ? { name: selectedEvent.event_name, id: selectedEvent.id } : null;

    const eventCardDetails =
        status === "success" && data?.data?.result && data?.data?.result?.length > 1
            ? "Click on each card to view event details"
            : "Click on card to view event details";

    const collaboratorDetails = {
        name: userData?.result?.name,
        email: collaborator ? collaborator[0]?.email : "",
        event_owner_email: selectedEvent?.event_owner?.email,
    };

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                {modal === "add_new_event_modal" ? (
                    <AddEventModal show={modal} canAddNewEvent={canAddNewEvent} onHide={onCloseModalHandler} />
                ) : (
                    modal === "duplicate_event_modal" && (
                        <DuplicateEventModal
                            event={eventDetails}
                            modal="duplicate_event_modal"
                            canAddNewEvent={canAddNewEvent}
                            onHide={onCloseModalHandler}
                            mutateData={mutateData}
                        />
                    )
                )}
                {renewAccessModalVisibility && (
                    <RenewCollaboratorAccountModal
                        collaboratorDetails={collaboratorDetails}
                        showModal={renewAccessModalVisibility}
                        onHide={() => setRenewAccessModalVisibility(false)}
                    />
                )}
            </Suspense>
            <section className={`profile ${className}`}>
                <div className="d-flex top_view align-items-start justify-content-between">
                    <div className="">
                        <h4 className="title mb-0">My Events</h4>
                        {status === "error" ? (
                            <p>Error fetching event</p>
                        ) : status === "loading" ? (
                            <EventsLineLoader />
                        ) : (
                            <div className="event_plan_details mt-3">
                                {selectedEvent?.plan && (
                                    <p className="mb-0">
                                        Plan - <span className="current_event_plan">{selectedEvent?.plan}</span>
                                    </p>
                                )}
                                {/* <p className="mb-0">
                                    Events -
                                    <span className="ml-1">
                                        {eventLimit} / {selectedEvent?.max_events}
                                    </span>
                                </p> */}
                                <p className="mb-0 mt-1">{eventCardDetails}</p>
                            </div>
                        )}
                    </div>
                    <div className="d-flex search_user_events align-items-md-center align-items-start flex-md-row flex-column  px-0">
                        <div className="position-relative event_search">
                            <input
                                placeholder="Search"
                                className="search_event_input
                        mr-2 form-control col-8"
                                value={searchText}
                                onChange={setSearchHandler}
                            />
                            {searchText.length > 2 && (
                                <button className="position-absolute bg-transparent border-0" type="button" onClick={searchChecklistHandler}>
                                    <CancelIcon />
                                </button>
                            )}
                        </div>
                        <Button
                            id="add_new_events"
                            text="Add Event"
                            className="bg_dark add_new_events text-white ml-md-3 mt-3 mt-md-0"
                            iconPosition="right"
                            iconClassname="ml-4"
                            icon={addIcon}
                            onClick={showAddEventModal}
                        />
                    </div>
                </div>
                <>
                    {status === "error" ? (
                        <p>unable to fetch events</p>
                    ) : status === "loading" ? (
                        <EventsLoader />
                    ) : (
                        <>
                            <div className="pb-5 events_view w-full">
                                {userEventsData && userEventsData.length > 0 ? (
                                    userEventsData.slice(eventMin, eventMax).map((event, index) => {
                                        let isCurrentUserACollaborator = false;

                                        if (event?.collaborators) {
                                            const userCollaboratorAccount = event?.collaborators.filter(
                                                (collaborator) => collaborator.email === userData?.result?.email,
                                            )[0];

                                            isCurrentUserACollaborator = userCollaboratorAccount ? true : false;
                                        }

                                        return (
                                            <EventCard
                                                key={index}
                                                isCurrentUserACollaborator={isCurrentUserACollaborator}
                                                index={index}
                                                event={event}
                                                userEmail={userData?.result?.email}
                                                showDuplicateEventModal={showDuplicateEventModal}
                                                invalidateQueries={invalidateQueries}
                                            />
                                        );
                                    })
                                ) : (
                                    <p>
                                        No event for this search <b>{searchText}</b>
                                    </p>
                                )}
                            </div>
                            <div className="paginate_event mt-5 pt-5 w-full d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <Select
                                        className="mb-0"
                                        value={limit}
                                        onChange={onSelectEventLimit}
                                        input={{
                                            label: "",
                                            name: "events",
                                            options: eventLimitOptions,
                                        }}
                                    />
                                    <p className="mb-0 ml-md-3 ml-2">
                                        Showing {showingEventCounter} of {eventLimit}
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <button className="border-0 bg-transparent mr-3" disabled={disableLeftArrowBtn} onClick={decrementPageCounter}>
                                        <ArrowleftIcon fill={leftArrowColor} />
                                    </button>
                                    <button className="border-0 bg-transparent ml-3" disabled={disableRightArrowBtn} onClick={incrementPageCounter}>
                                        <ArrowRightIcon fill={rightArrowColor} />
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </>
            </section>
        </>
    );
}
