import { toast } from "react-toastify";
import { useState } from "react";
import type { ChangeEvent } from "react";

import useAuth from "@/hooks/useAuth";
import SiteModal from "@/components/modals/SiteModal";
import Button from "@/components/Button";
import Input from "@/components/form/Input";

interface Props {
	show: boolean;
	onHide: () => void;
}

const inputData = {
    name: "email",
    label: "Email Address",
    placeholder: "",
};

export default function ForgotPasswordModal({ show, onHide }: Props) {
    const { forgotPassword, loading } = useAuth();
    const [email, setEmail] = useState("");

    function updateInputValue(e: ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value);
    }

    async function onSubmitHandler(e: ChangeEvent<HTMLFormElement>) {
        e.preventDefault();
        if (email) {
            await forgotPassword(email).then(() => {
                setEmail("");
                onHide();
            });
        } else {
            return toast.error("Email is required");
        }
    }

    return (
        <SiteModal title="Forgot Password" show={show} onHide={onHide}>
            <form className="mt-4 forgot_password_form" onSubmit={onSubmitHandler}>
                <h6 className="mb-3">We will send a reset password link to your email</h6>
                <Input input={inputData} value={email} onChange={updateInputValue} />
                <Button text="Reset Password" className="d-flex bg_red text-white mx-auto my-4 font-bold" type="submit" isLoading={loading} />
            </form>
        </SiteModal>
    );
}
