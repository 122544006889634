import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import React from "react";
import "@/styles/policy.scss";

const links = [
    { link: "#general_information", text: "General Information" },
    { link: "#service_description", text: "Service Description" },
    { link: "#user_responsibilities", text: "User Responsibilities" },
    { link: "#payments_and_subscription_details", text: "Payment and Subscription Details" },
    { link: "#intellectual_property", text: "Intellectual Property" },
    { link: "#privacy_and_data_handling", text: "Privacy and Data Handling" },
    { link: "#disclimers", text: "Disclaimers and Limitation of Liability" },
    { link: "#termination_of_service", text: "Terimination of Service" },
    { link: "#governing_law", text: "Governing Law" },
    { link: "#amendments", text: "Amendments" },
    { link: "#contact_information", text: "Contact Information" },
];

function SidebarPolicyLink() {
    return (
        <ul className="policy_sidebar_link">
            {links.map((item, index) => (
                <li key={index}>
                    <a href={item.link}>{item.text}</a>
                </li>
            ))}
        </ul>
    );
}

export default function TermOfService() {
    return (
        <NewOnboardingLayout text="Terms of service" className="policy_page" sidebarContent={<SidebarPolicyLink />}>
            <div className="content d-flex flex-column py-5 px-4 policy align-items-start justify-content-start">
                <h1>Effective Date: 4 September 2024</h1>
                <p>
					Welcome to https://planaday.events ("Website"), operated by Planaday Technologies Nigeria Limited ("Planaday", "we", "us", or
					"our"). By accessing or using our event planning technology platform, you agree to comply with and be bound by the following terms
					and conditions ("Terms"). Please read them carefully. If you do not agree to these Terms, please do not use our Website.
                </p>
                <div id="general_information">
                    <h5>1. General Information</h5>
                    <ul>
                        <li>
                            <b>Website Name</b>: Planaday
                        </li>
                        <li>
                            <b>Registered Name</b>: Planaday Technologies Nigeria Limited
                        </li>
                        <li>
                            <b>Location</b>: Nigeria
                        </li>
                        <li>
                            <b>Address</b>: Block 7, Plot 23, Fyin Dammy Kasim Street, Magodo Isheri, Lagos, Nigeria
                        </li>
                        <li>
                            <b>Contact Email</b>: <a href="mailto:support@planaday.events">support@planaday.events</a>
                        </li>
                    </ul>
                </div>
                <div id="service_description">
                    <h5>2. Service Description</h5>
                    <p>
						Planaday is an event planning technology platform that provides tools to assist users in organising and managing events. By
						using our platform, you gain access to various features designed to streamline the event planning process.
                    </p>
                </div>
                <div id="user_responsibilities">
                    <h5>3. User Responsibilities</h5>
                    <h6>3.1 Account Security</h6>
                    <p>
						Users are responsible for maintaining the confidentiality of their login credentials. You are also responsible for any
						activities that occur under your account. You must notify us immediately of any unauthorised use of your account or any other
						breach of security.
                    </p>
                    <h6>3.2 Prohibited Activities</h6>
                    <p>
						Users are prohibited from engaging in activities that include, but are not limited to: Attempting to hack or disrupt the
						Website’s functionality. Using the Website for fraudulent purposes, including impersonating another person or entity.
                    </p>
                    <h6>3.3 Content Ownership and Rights</h6>
                    <p>
						Users should not upload or share content that they do not own or do not have the right to distribute. By submitting content to
						the Website, you grant Planaday a worldwide, non-exclusive, royalty-free licence to use, reproduce, and distribute your
						content for the purpose of operating the platform.
                    </p>
                    <h6>3.4 Accuracy of Information</h6>
                    <p>
						Users are responsible for providing accurate and truthful information when creating accounts, making transactions, or sharing
						content on the platform. Misleading or false information may result in suspension or termination of your account.
                    </p>
                    <h6>3.5 Use of Services</h6>
                    <p>
						Users must use the Website’s services only for their intended purpose. You may not use the platform for any unauthorised
						commercial activities or for purposes that violate any laws or regulations.
                    </p>
                    <h6>3.6 Reporting Violations</h6>
                    <p>
						Users are encouraged to report any violations of these Terms or suspicious activities to Planaday’s administration via{" "}
                        <a href="mailto:support@planaday.events">support@planaday.events.</a>{" "}
                    </p>
                </div>
                <div id="payments_and_subscription_details">
                    <h5>4. Payment and Subscription Details</h5>
                    <p>
						Planaday operates on a monthly subscription basis. Users can subscribe to access the full range of services provided by the
						platform. Subscription payments are due monthly and can be cancelled at any time through your account settings. Refunds are
						not provided for partial months.
                    </p>
                </div>
                <div id="intellectual_property">
                    <h5>5. Intellectual Property</h5>
                    <p>
						At this time, Planaday does not have any declared intellectual property, including copyrights or trademarks. However, users
						are expected to respect any intellectual property rights of others when using the platform.
                    </p>
                </div>
                <div id="privacy_and_data_handling">
                    <h5>6. Privacy and Data Handling</h5>
                    <p>
						Planaday is committed to protecting your privacy and handling your personal data with care. Any personal information collected
						through your use of the Website will be managed in accordance with our Privacy Policy, which outlines how we collect, use, and
						protect your data.
                    </p>
                </div>
                <div id="disclimers">
                    <h5>7. Disclaimers and Limitation of Liability</h5>
                    <p>
						Planaday provides its services "as is" and makes no representations or warranties of any kind, either express or implied,
						regarding the operation of the platform or the information, content, or materials included. To the fullest extent permissible
						by applicable law, Planaday disclaims all warranties, express or implied, including but not limited to implied warranties of
						merchantability and fitness for a particular purpose.
                    </p>
                    <p>
						Planaday shall not be liable for any damages of any kind arising from the use of this site or from any information, content,
						materials, products, or services included on or otherwise made available to you through this site, including, but not limited
						to direct, indirect, incidental, punitive, and consequential damages.
                    </p>
                </div>
                <div id="termination_of_service">
                    <h5>8. Terimination of Service</h5>
                    <p>
						Planaday reserves the right to suspend or terminate user accounts for any breach of these Terms, illegal activity, or other
						conduct deemed harmful to the platform or other users.
                    </p>
                </div>
                <div id="governing_law">
                    <h5>9. Governing Law</h5>
                    <p>
						These Terms shall be governed by and construed in accordance with the laws of Nigeria. Any disputes arising under or in
						connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Nigeria.
                    </p>
                </div>
                <div id="amendments">
                    <h5>10. Amendments</h5>
                    <p>
						Planaday reserves the right to modify these Terms at any time. If changes are made, we will notify users via email and update
						the Terms on our Website. Continued use of the platform after any such changes constitutes your acceptance of the new Terms.
                    </p>
                </div>
                <div id="contact_information">
                    <h5>11. Contact Information</h5>
                    <p>
						If you have any questions or concerns regarding these Terms, please contact us at{" "}
                        <a href="support@planaday.events">support@planaday.events</a>
                    </p>
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
