import AsoebiDashboardLayout from "@/layout/AsoebiDashboardLayout";

export default function AsoebiAdminQuestionnaires() {
    return (
        <AsoebiDashboardLayout>
            <section>
                <div className="w-full relative title_view">
                    <h1 className="text-center">Questionnaire list</h1>
                </div>
            </section>
        </AsoebiDashboardLayout>
    );
}
