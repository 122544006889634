import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Loader from "@/components/loader";
import useEventWeather from "@/hooks/useEventWeather";
import { TodoType } from "@/types";

interface Props {
	currentCeremony: {
		id: string;
		name: string;
		location: string | null;
		no_of_guests: string | number | null;
		date: Date | null;
		checklist?: TodoType | undefined;
		show_suggestion: boolean;
	};
}

export default function Eventweather({ currentCeremony }: Props) {
    const weatherDetails = useEventWeather(currentCeremony);

    return (
        <>
            {currentCeremony?.location && currentCeremony?.date && (
                <div className="weather mb-0">
                    {weatherDetails?.weatherStatus ? (
                        <span className="loader">
                            <Loader />
                        </span>
                    ) : (
                        weatherDetails?.forecastRequestStatus && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="weather_description">weather forecast for the day of your event</Tooltip>}>
                                <span className="d-flex mb-0 align-items-center">
                                    <h6 className="text-white mr-1 mb-0">{weatherDetails?.eventForecastTemperature}℃</h6>
                                    {weatherDetails?.weatherIcon && (
                                        <img
                                            src={weatherDetails?.weatherIcon}
                                            width="30px"
                                            height="30px"
                                            alt={weatherDetails?.weatherCondition?.description}
                                        />
                                    )}
                                </span>
                            </OverlayTrigger>
                        )
                    )}
                </div>
            )}
        </>
    );
}
