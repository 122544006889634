/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Image } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";
import useImage from "use-image";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
	image?: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function ImageElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    const [image] = useImage(item.image as string);
    return (
        <Image
            ref={refValue}
            {...item}
            draggable
            name="floor_plan_element"
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}
            onClick={onSelect}
            image={image}
        />
    );
}
