import BudgetAmountInfo from "@/components/BudgetAmountInfo";

interface Props {
    budgetActualCost: number;
    budgetEstimate?: number;
    budgetPaid: number;
}

export default function BudgetSummary({ budgetActualCost, budgetPaid, budgetEstimate }: Props) {
    const _budgetActualCost = budgetActualCost ? budgetActualCost?.toLocaleString("en-US") : 0;
    const _budgetEstimate = budgetEstimate ? budgetEstimate?.toLocaleString("en-US") : 0;
    const _budgetPaid = budgetPaid ? budgetPaid?.toLocaleString("en-US") : 0;
    const balance = budgetActualCost - budgetPaid;
    const _balance = balance ? balance.toLocaleString("en-US") : 0;

    return (
        <div className="event_budget py-0 col-12 mx-auto d-flex px-0">
            <div className="event_budget_view py-3 col-12 d-flex  mx-auto justify-content-between">
                <BudgetAmountInfo title="Actual" info="Sum of total costs agreed with event vendors" amount={`₦${_budgetActualCost}`} />
                <BudgetAmountInfo title="Estimate" info="An estimate of the total cost of your event" amount={`₦${_budgetEstimate}`} />
                <BudgetAmountInfo title="Paid" info="Amount paid out" amount={`₦${_budgetPaid}`} />
                <BudgetAmountInfo title="Balance" info="your remaining balance" amount={`₦${_balance}`} />
            </div>
        </div>
    );
}
