import OnboardingStage4 from "@/components/OnboardingStage4";
import OnboardingLayout from "@/layout/OnboardingLayout";

import Button from "@/components/Button";
import useOnboarding from "@/hooks/useOnboarding";

const CURRENTSTAGE = 3;

export default function SaveOnboardingProfile() {
    const indicatorArray = new Array(4).fill(0);
    const { saveToProfileHandler, loading } = useOnboarding();

    return (
        <OnboardingLayout className='onboarding'>
            <div className="onboarding_slides">
                <ul className="indicator my-4 mb-5 d-flex align-items-center">
                    {indicatorArray.map((_, index) => {
                        const indicatorStyle = CURRENTSTAGE === index ? "active" : index < CURRENTSTAGE ? "visited" : "indicator_item";
                        return <li key={index} className={indicatorStyle} />;
                    })}
                </ul>
                <div className="onboarding_content">
                    <OnboardingStage4 />
                </div>
                <Button
                    text="Go to Profile"
                    className="mt-lg-5 mt-4 bg_red profile px-4"
                    isLoading={loading}
                    onClick={saveToProfileHandler}
                />
            </div>
        </OnboardingLayout>
    );
}
