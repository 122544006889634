import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import { jsPDF } from "jspdf";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import FuzzySearch from "fuzzy-search";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { ExpandIcon, PencilIcon, PlusIcon, RedoIcon, ShrinkIcon, UndoIcon } from "lucide-react";

import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fillSpaceElements, fillSpaceObjectElements, floorPlanTableAndChairsElements } from "@/utils/draw_floor_plan_icons";
import { FloorPlanObjectList, FloorPlanObjectListType } from "@/data/types";
import useFloorPlan from "@/hooks/useFloorPlan";
import useMyEvents from "@/hooks/useMyEvents";
import Spinner from "@/components/Spinner";
import Search from "@/assets/icon/Search";
import UploadImageIcon from "@/assets/icon/UploadImageIcon";
import TextIcon from "@/assets/icon/TextIcon";
import LinesIcon from "@/assets/icon/LinesIcon";
import SquareDashedIcon from "@/assets/icon/SquareDashedIcon";
import { createFloorPlanRequest, uploadFloorPlanElementRequest } from "@/axios/post-request";
import { useEvent } from "@/hooks/useEvent";
import SiteDropdown from "@/components/Dropdown";
import ThreeDotsIcon from "@/assets/icon/ThreeDotsIcon";
import useToast from "@/hooks/useToast";
import DownloadIcon from "@/assets/icon/DownloadIcon";
import logo from "@/assets/img/logo_full.png";
import { deleteFloorplanRequest } from "@/axios/delete-request";
import { getUserDetails } from "@/axios/get-request";

interface FloorPlanElementProps {
    elementGroup: "chairs" | "square_tables" | "round_tables" | "rectangle_tables";
    addFloorPlanElementHandler: (elementType: FloorPlanObjectListType, url?: string, id?: string) => void;
}

interface Props {
    fullViewHandler: (value: boolean) => void;
}

type FloorplanGroupType = Array<{ type: FloorPlanObjectListType; text: string; icon: JSX.Element; url?: string; id?: string }>;

function FloorPlanElement({ elementGroup, addFloorPlanElementHandler }: FloorPlanElementProps) {
    return (
        <div className="floor_plan_element">
            <h6>{floorPlanTableAndChairsElements[elementGroup].title}</h6>
            <div className="table_chair_group mt-2">
                {floorPlanTableAndChairsElements[elementGroup].elements.map((item: FloorplanGroupType[0], index) => (
                    <button key={index} onClick={() => addFloorPlanElementHandler(item.type, item?.url, item?.id)}>
                        {item.icon}
                    </button>
                ))}
            </div>
        </div>
    );
}

const searchGroup = [
    ...fillSpaceObjectElements.table_chairs__category,
    ...fillSpaceObjectElements.entertainment__category,
    ...fillSpaceObjectElements.food_drinks__category,
    ...fillSpaceObjectElements.stage__category,
    ...fillSpaceObjectElements.doors__category,
    ...fillSpaceObjectElements.gifts__category,
    ...fillSpaceObjectElements.shapes__category,
];

export default function FloorplanCanvas({ fullViewHandler }: Props) {
    const { editor, onReady } = useFabricJSEditor();
    const [pickElementStage, setPickElementStage] = useState(fillSpaceElements[0].type);
    const { activeEvent } = useMyEvents();
    const { selectedEventId, activeCeremonyId } = useEvent();
    const { loadingToast, updateToast } = useToast();
    const toastId = useRef("loading_floorplan_id");
    const {
        floorPlanStage,
        activeFloorPlanStageId,
        sidebar,
        updateActiveFloorPlanStageId,
        addFloorPlanStageObjectHandler,
        deleteFloorPlanHandler,
        updateFloorPlanName,
        addDefaultFloorPlanHandler,
        updateFloorPlanElementHandler,
    } = useFloorPlan();
    const [searchText, setSearchText] = useState("");
    const [searchResult, setSearchResult] = useState<FloorplanGroupType>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [stageScale, setStageScale] = useState({
        scale: 1,
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
    });
    const history: fabric.Object[] = [];
    const { data } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => getUserDetails(),
    });
    const currentFloorPlanDetail = activeFloorPlanStageId ? floorPlanStage.filter((item) => item.id === activeFloorPlanStageId)[0] : null;
    const pickElementStageValue = pickElementStage as
        | "entertainment__category"
        | "food_drinks__category"
        | "stage__category"
        | "doors__category"
        | "gifts__category"
        | "shapes__category";

    const floorPlanGroupElements = fillSpaceObjectElements[pickElementStageValue] as Array<{
        title?: string;
        type: FloorPlanObjectListType;
        icon: JSX.Element;
        url?: string;
    }>;

    useEffect(() => {
        if (!editor || !fabric) {
            return;
        }

        editor.canvas.renderAll();
    }, [editor]);

    const handleDeleteKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Delete" || event.key === "Backspace") {
            removeSelectedObject();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleDeleteKeyPress);

        return () => {
            window.removeEventListener("keydown", handleDeleteKeyPress);
        };
    }, [editor]);

    useEffect(() => {
        if (searchText) {
            setIsSearching(true);
            const searcher = new FuzzySearch(searchGroup, ["text"], {
                caseSensitive: false,
            });
            const result = searcher.search(searchText);
            setSearchResult(result);
            if (result) {
                setIsSearching(false);
            }
        }
    }, [searchText.length]);

    const currentFloorPlan = floorPlanStage.filter((item) => item.id === activeFloorPlanStageId);

    async function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        if (activeFloorPlanStageIndex !== null) {
            const floorPlanElementId = uuidv4();
            addFloorPlanStageObjectHandler(activeFloorPlanStageIndex, FloorPlanObjectList.image_element, floorPlanElementId, "", url);
            const formData = new FormData();
            formData.append("file", file);
            const uploadImageResult = await uploadFloorPlanElementRequest(formData, currentFloorPlan[0].id, floorPlanElementId);
            updateFloorPlanElementHandler(activeFloorPlanStageIndex, floorPlanElementId, uploadImageResult?.data?.result.url);
        }
    }

    useEffect(() => {
        if (!editor || !fabric) {
            return;
        }
        editor.canvas.setHeight(500);
        editor.canvas.setWidth(1000);
        editor.canvas.renderAll();
    }, []);

    const undoHandler = () => {
        if (editor) {
            if (editor?.canvas._objects.length > 0) {
                history.push(editor.canvas._objects.pop() as fabric.Object);
            }
            editor.canvas.renderAll();
        }
    };
    const redoHandler = () => {
        if (history.length > 0) {
            editor?.canvas.add(history.pop() as fabric.Object);
        }
    };

    // const clear = () => {
    //     editor?.canvas._objects.splice(0, editor.canvas._objects.length);
    //     history.splice(0, history.length);
    //     editor?.canvas.renderAll();
    // };

    function removeSelectedObject() {
        if (editor) {
            const activeObject = editor.canvas.getActiveObject();
            if (activeObject) {
                if (activeObject.type === "activeSelection") {
                    (activeObject as fabric.ActiveSelection).forEachObject((obj) => {
                        editor?.canvas.remove(obj);
                    });
                } else {
                    editor?.canvas.remove(activeObject);
                }
                editor?.canvas.discardActiveObject();
                editor?.canvas.renderAll();
            }
        }
    }

    function addLineHandler() {
        if (editor) {
            editor.canvas.isDrawingMode = true;
        }
    }

    function addLine() {
        if (editor) {
            const startPoint = { x: 100, y: 100 }; // Starting point
            const endPoint = { x: 300, y: 100 }; // Ending point
            const line = new fabric.Line([startPoint.x, startPoint.y, endPoint.x, endPoint.y], {
                selectable: true,
                strokeWidth: 1,
                stroke: "#000",
                strokeUniform: true,
            });
            editor?.canvas.add(line);
            editor.canvas.isDrawingMode = false;
            // editor?.addCircle();
        }
    }

    const onAddCircle = () => {
        if (editor) {
            const circle = new fabric.Circle({
                selectable: true,
                strokeWidth: 1,
                fill: "transparent",
                radius: 50,
                stroke: "#000",
                left: 100,
                strokeUniform: true,
                top: 100,
            });
            editor?.canvas.add(circle);
            editor.canvas.isDrawingMode = false;

            // editor?.addCircle();
        }
    };
    const onAddRectangle = () => {
        if (editor) {
            const rect = new fabric.Rect({
                selectable: true,
                strokeWidth: 1,
                width: 200, // Width of rectangle
                height: 100,
                fill: "transparent",
                stroke: "#000",
                left: 100,
                strokeUniform: true,
                top: 100,
            });
            editor?.canvas.add(rect);
            editor.canvas.isDrawingMode = false;
        }
    };

    const addText = () => {
        if (editor) {
            editor?.addText("inset text");
            editor.canvas.isDrawingMode = false;
        }
    };

    function addElement(url?: string, element_id?: string) {
        if (editor) {
            if (url) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                fabric.Image.fromURL(url, function(img: any) {
                    const oImg = img.set({ left: 100, top: 100 }).scale(2);
                    editor?.canvas.add(oImg);
                });
            }
            if (element_id) {
                if (element_id === "add_circle") {
                    onAddCircle();
                } else if (element_id === "add_rectangle") {
                    onAddRectangle();
                }
            }
            editor.canvas.isDrawingMode = false;
        }
    }

    const activeFloorPlanStageIndex = activeFloorPlanStageId ? floorPlanStage.findIndex((item) => item.id === activeFloorPlanStageId) : null;

    async function addFloorPlanElementHandler(elementType: FloorPlanObjectListType, url?: string, id?: string) {
        addElement(url, id);
        if (activeFloorPlanStageIndex !== null) {
            const floorPlanElementId = uuidv4();
            addFloorPlanStageObjectHandler(activeFloorPlanStageIndex, elementType, floorPlanElementId);
            // const stageText = title ? { text: title } : {};
            // const stage = floorPlanStage[activeFloorPlanStageIndex].stage.filter((item) => item.type === FloorPlanObjectList.regular__dimensions);
            // const floorPlanObjectId = `${elementType}__${floorPlanElementId}`;

            // await addFloorplanElementRequest(currentFloorPlan[0].id, floorPlanElementId, selectedEventId, {
            // ...stageText,
            // height: 50,
            // width: 50,
            // x: stage[0].x + stage[0].width / 2 + floorPlanStage[activeFloorPlanStageIndex].stage.length * 5,
            // y: stage[0].y + stage[0].height / 2.5 + floorPlanStage[activeFloorPlanStageIndex].stage.length * 5,
            // fill: "#D9D9D9",
            // id: floorPlanObjectId,
            // type: elementType,
            // });
        }
    }

    function searchTextHandler(event: ChangeEvent<HTMLInputElement>) {
        setSearchText(event.target.value);
    }

    function addFloorPlanHandler(name: string, id: string) {
        const stageWidth = window.innerWidth;
        const stageHeight = window.innerHeight;
        const dimensionElementSizes = {
            height: 400,
            width: 1000,
        };
        const divider = sidebar ? 10 : 5;
        const rectX = (stageWidth - dimensionElementSizes.width) / divider;
        const rectY = (stageHeight - dimensionElementSizes.height) / 10;
        const floorPlanObjectId = `${FloorPlanObjectList.regular__dimensions}__${id}`;

        return {
            id,
            floor_plan_name: name ? name : "New Floor Plan",
            floor_plan_icon: "",
            activeFloorPlanElementId: null,
            stage: [
                {
                    ...dimensionElementSizes,
                    fill: "white",
                    id: floorPlanObjectId,
                    x: rectX,
                    y: rectY,
                    type: FloorPlanObjectList.regular__dimensions,
                },
            ],
        };
    }

    function exportFloorPlanAsPDF() {
        try {
            if (currentFloorPlanDetail && currentFloorPlanDetail.stage.length === 0) {
                return toast.error("Floor plan is empty");
            }
            const uri = editor?.canvas.toSVG() as string;
            console.log("uri", uri);
            // const uri = stageRef?.current?.toDataURL();
            const pdfInstance = new jsPDF({
                orientation: "landscape",
                unit: "px",
                format: [595, 842],
            });

            const pdf = pdfInstance;
            pdf.setFont("helvetica", "bold");
            pdf.addImage(uri, "PNG", 0, 70, 842, 350);
            pdf.setFontSize(18);
            const logoImg = new Image();
            logoImg.src = logo;
            logoImg.height = 20;
            logoImg.width = 100;
            pdf.addImage(logoImg, "PNG", 20, 20, 100, 20);
            pdf.text("Event Floor Plan", 725, 30);
            const today = new Date();
            const todayDate = format(today, "dd-MM-yyyy");
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(16);
            pdf.text(`${todayDate}`, 768, 580);
            if (activeEvent && activeEvent[0]) {
                pdf.text(`Floor plan name: ${currentFloorPlan[0]?.floor_plan_name}`, 20, 540);
                pdf.text(`Event: ${activeEvent[0]?.event_name}`, 20, 560);
                if (data?.result?.business_name || data?.result?.name) {
                    const plannerName = data?.result?.business_name ? data?.result?.business_name : data?.result?.name;
                    pdf.text(`Event planner: ${plannerName}`, 20, 580);
                    if (activeEvent[0]?.event_date) {
                        pdf.text(`Event date: ${format(new Date(activeEvent[0]?.event_date), "dd-MM-yyyy")}`, 40, 580);
                    }
                }
            }

            pdf.save(`${currentFloorPlanDetail?.floor_plan_name}-floor-plan.pdf`);
        } catch (error) {
            console.log("error", error);
        }
    }

    const floorPlanStageCount = floorPlanStage.length > 0 ? floorPlanStage.length + 1 : "";
    const newFloorplanName = `New Floor plan ${floorPlanStageCount}`;

    function renameFloorPlanHandler(event: ChangeEvent<HTMLInputElement>) {
        if (activeFloorPlanStageIndex !== null) {
            updateFloorPlanName(event.target.value, activeFloorPlanStageIndex);
        }
    }

    async function addNewFloorPlanHandler() {
        try {
            const floorPlanId = uuidv4();
            addDefaultFloorPlanHandler(newFloorplanName, floorPlanId);
            const floorPlanDetails = addFloorPlanHandler(newFloorplanName, floorPlanId);
            await createFloorPlanRequest(selectedEventId, {
                ...floorPlanDetails,
                event_id: selectedEventId,
                ceremony_id: activeCeremonyId,
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    function resetWheelHandler() {
        setStageScale({
            scale: 1,
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        });
    }

    async function onDeleteFloorPlanHandler() {
        try {
            if (activeFloorPlanStageId) {
                loadingToast(toastId);
                await deleteFloorplanRequest(activeFloorPlanStageId, selectedEventId);
                deleteFloorPlanHandler(activeFloorPlanStageId);
                updateToast(toastId, "success", "Floor plan deleted");
            }
        } catch (error) {
            console.log("error", error);
            updateToast(toastId, "success", "Error deleting floor plan");
        }
    }

    const __stageScalePercentage = stageScale.scale * 100;
    const stageScalePercentage = __stageScalePercentage.toFixed(0);

    return (
        <section className="floor_plan">
            <div className="floor_plan_group">
                <div className="added_floor_plans">
                    {floorPlanStage.map((item, index) => {
                        const className = activeFloorPlanStageIndex === index ? "current" : "";
                        return (
                            <button key={index} onClick={() => updateActiveFloorPlanStageId(item.id)} className={className}>
                                <SquareDashedIcon /> <span> {item.floor_plan_name ? item.floor_plan_name : "New Floor Plan"} </span>
                            </button>
                        );
                    })}
                </div>
                <button onClick={addNewFloorPlanHandler}>
                    <SquareDashedIcon /> Add new
                </button>
            </div>
            <div className="floor_plan_view">
                {floorPlanStage?.length > 0 && (
                    <div className="floor_plan_top_bar">
                        <div className="title">
                            <SquareDashedIcon />
                            <input
                                value={currentFloorPlan[0]?.floor_plan_name ?? ""}
                                placeholder="New Floor Plan"
                                onChange={renameFloorPlanHandler}
                            />
                        </div>
                        <div className="floor_plan_button_group">
                            <div className="left_button_group">
                                <button onClick={undoHandler}>
                                    <UndoIcon />
                                </button>
                                <button onClick={redoHandler}>
                                    <RedoIcon />
                                </button>
                                {/* <button className="edit">
                                Edit <EditIcon />
                            </button> */}
                            </div>
                            <hr />
                            <div className="right_button_group">
                                {/* <button className="share_plan">
                                Save <SaveIcon />
                            </button> */}
                                {/* <button>
                                Comments <CommentIcon />
                            </button> */}
                                <SiteDropdown title={<ThreeDotsIcon />} className="custom three_dot no_hover_tick">
                                    <Dropdown.Item className="no_hover_tick" onClick={exportFloorPlanAsPDF}>
                                        Download Floor Plan • (PDF) <DownloadIcon />
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item className="no_hover_tick" onClick={exportFloorPlanAsPNG}>
                                    Download Floor Plan • (PNG) <DownloadIcon />
                                </Dropdown.Item> */}
                                    {/* <Dropdown.Item>Share via email</Dropdown.Item> */}
                                    {/* <Dropdown.Item>Print</Dropdown.Item> */}
                                    <Dropdown.Item onClick={onDeleteFloorPlanHandler}>Delete Floor plan</Dropdown.Item>
                                </SiteDropdown>
                            </div>
                        </div>
                    </div>
                )}
                {floorPlanStage?.length === 0 ? (
                    <div className="floor_plan_canvas_view">
                        <div className="no_floor_plan_card">
                            <div className="image_wrapper">
                                <SquareDashedIcon />
                            </div>
                            <div className="floor_plan_text">
                                <h4>You have no Floor Plan yet :(</h4>
                                <p>Draw your floor plan from scratch using our custom objects and elements and your creativity.</p>
                            </div>
                            <div className="button_group">
                                {/* <button className="btn_bordered">Request a Plan</button> */}
                                <button className="btn_red" onClick={addNewFloorPlanHandler}>
                                    Create Floor Plan
                                    <PlusIcon fill="#fff" />
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="canvas_wrapper" style={{ height: "unset" }}>
                            <div className="canvas_board" style={{ height: "unset", width: "100%" }}>
                                <div className="draw_actions_top">
                                    <button onClick={addText}>
                                        <TextIcon />
                                    </button>
                                    <button onClick={addLine}>
                                        <LinesIcon />
                                    </button>
                                    <button onClick={addLineHandler}>
                                        <PencilIcon />
                                    </button>
                                </div>
                                <div
                                    style={{
                                        border: `2px solid black`,
                                        width: "100%",
                                        height: "500px",
                                    }}>
                                    <FabricJSCanvas className="sample-canvas" onReady={onReady} />
                                </div>
                                <div className="draw_actions_bottom">
                                    <button className="percentage" onClick={resetWheelHandler}>
                                        {stageScalePercentage}%
                                    </button>
                                    <button onClick={() => fullViewHandler(true)}>
                                        <ExpandIcon />
                                    </button>
                                    <button onClick={() => fullViewHandler(false)}>
                                        <ShrinkIcon />
                                    </button>
                                    <div className="upload_wrapper">
                                        <div className="floorplan_upload_image">
                                            <UploadImageIcon />
                                            <input onChange={changePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pick_elements">
                            <div className="pick_elements_top_bar">
                                <div className="top_bar_header">
                                    {fillSpaceElements.map((item) => {
                                        const className = item.type === pickElementStage ? "active" : "";
                                        return (
                                            <button
                                                key={item.type}
                                                className={`pick_element ${className}`}
                                                onClick={() => setPickElementStage(item.type)}>
                                                {item.icon} {item.text}
                                            </button>
                                        );
                                    })}
                                </div>
                                <div className="right_view">
                                    <div className="search_tools_element">
                                        <Search fill="#D9D9D9" /> <input placeholder="Search" value={searchText} onChange={searchTextHandler} />
                                        {isSearching && (
                                            <div className="spinner_element_wrapper">
                                                <Spinner className="mx-0" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="elements_group">
                                {searchText ? (
                                    searchResult.length > 0 ? (
                                        searchResult.map((item, index) => (
                                            <button key={index} onClick={() => addElement(item.url, item?.id)}>
                                                {item.icon}
                                            </button>
                                        ))
                                    ) : (
                                        <p className="no_element">No element for this search query</p>
                                    )
                                ) : (
                                    <>
                                        {pickElementStage === "table_chairs__category" ? (
                                            <div className="table_chairs_elements">
                                                <FloorPlanElement elementGroup="chairs" addFloorPlanElementHandler={addFloorPlanElementHandler} />
                                                <FloorPlanElement
                                                    elementGroup="square_tables"
                                                    addFloorPlanElementHandler={addFloorPlanElementHandler}
                                                />
                                                <FloorPlanElement
                                                    elementGroup="round_tables"
                                                    addFloorPlanElementHandler={addFloorPlanElementHandler}
                                                />
                                                <FloorPlanElement
                                                    elementGroup="rectangle_tables"
                                                    addFloorPlanElementHandler={addFloorPlanElementHandler}
                                                />
                                            </div>
                                        ) : (
                                            floorPlanGroupElements.map((item, index) => (
                                                <button key={index} onClick={() => addElement(item.url)}>
                                                    {item.icon}
                                                </button>
                                            ))
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
}
