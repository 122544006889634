import ContentLoader from "react-content-loader";

interface Props {
  width: number | string;
  height: number;
  className?: string;
}

export default function CardLoader({ height, width, className }: Props) {
    return (
        <ContentLoader
            width={width}
            height={height}
            // viewBox="0 0 100% 100%"
            backgroundColor="#cbbec0"
            foregroundColor="#dedede"
            className={className}
        >
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
        </ContentLoader>
    );
}
