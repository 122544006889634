import Select, { SingleValue } from "react-select";
import { useQuery } from "@tanstack/react-query";

import { getListOfBanks } from "@/axios/get-request";
import Input from "./Input";

type selectBankType = {
        bankSlug: string;
        bankName: string;
        bankCode: string;
    }
interface Props {
	selectedBank: selectBankType;
	setSelectBank: React.Dispatch<React.SetStateAction<selectBankType>>;
}

export default function SelectBank({selectedBank, setSelectBank}:Props) {
    const { data, status } = useQuery({
        queryFn: () => getListOfBanks(),
        queryKey: ["bank_list"],
    });

    const bankOptions: Array<{ value: string; label: string }> = [];
    const defaultBankValue = selectedBank.bankName ?
        { value: `${selectedBank.bankSlug}__${selectedBank.bankCode}`, label: selectedBank.bankName } : {value: "", label: ""};

    if (status === "success" && bankOptions.length === 0) {
        data.result.map((bank) => bankOptions.push({ value: `${bank.slug}__${bank.code}`, label: bank.name }));
    }

    function onSelectBankHandler(selectedValue: SingleValue<{value:string, label:string}>) {
        const bankSlug = selectedValue ? selectedValue?.value.split("__")[0] : "";
        const bankCode = selectedValue ? selectedValue?.value.split("__")[1] : "";

        setSelectBank({
            bankSlug,
            bankCode,
            bankName: selectedValue ? selectedValue?.label: ""
        });
    }

    return (
        <>
            {status === "error" ? (
                <Input input={{ label: "Account number", name: "account_number", placeholder: "Account number" }} />
            ) : (
                <Select
                    placeholder="Select bank..."
                    classNamePrefix="select_bank"
                    isLoading={status === "loading"}
                    options={bankOptions}
                    value={defaultBankValue || "Select"}
                    onChange={onSelectBankHandler}
                />
            )}
        </>
    );
}
