import { ChangeEvent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { format, getHours, getMinutes } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { LucideTrash2, PlusIcon } from "lucide-react";

import DashboardModal from "@/components/modals/DashboardModal";
import Input2 from "@/components/form/Input2";
import { useEvent } from "@/hooks/useEvent";
import Spinner from "@/components/Spinner";
import SelectDateInput from "@/components/form/SelectDateInput";
import SiteDropdown from "@/components/Dropdown";
import { EventdayFlowType, VendorType } from "@/types";
import VendorPicker from "@/components/modals/VendorPicker";
import SelectTimeInput from "@/components/form/SelectTimeInput";
import { addEventDayFlowRequest } from "@/axios/post-request";
import useActivityLog from "@/hooks/useActivityLog";
import "@/styles/add_budget_category.scss";

const formDetails = {
    task: { label: "Task", name: "task", placeholder: "Task" },
    datepicker: { label: "Date", placeholder: format(new Date(), "dd-MM-yy"), name: "date" },
    startTime: { label: "Start time", placeholder: format(new Date(), "kk:mm aa"), name: "start_time" },
    duration: { label: "Duration", placeholder: "", name: "duration", options: ["10m", "20m", "30m", "40m", "50m", "1hr"] },
    venue: { label: "Venue", placeholder: "Enter venue", name: "venue" },
};

interface Props {
	onClose: () => void;
	ceremonyName: string;
	eventName: string;
}

type FormValuesType = EventdayFlowType[0] & {
    hour: string;
    mins: string;
};

export default function AddEventdayTaskModal({ onClose, eventName, ceremonyName }: Props) {
    const { selectedEventId, activeCeremonyId } = useEvent();
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const { addEventdaytaskAuditHandler } = useActivityLog();
    const [formValues, setFormValues] = useState<Array<FormValuesType>>([
        {
            id: uuidv4(),
            task: "",
            date: null,
            start_time: null,
            duration: "",
            isDone: false,
            venue: "",
            ceremony: { name: ceremonyName, id: activeCeremonyId },
            vendor: null,
            hour: "",
            mins: "",
        },
    ]);

    function addCostItemHandler() {
        setFormValues((prev) => [
            ...prev,
            {
                id: uuidv4(),
                task: "",
                date: null,
                start_time: null,
                duration: "",
                isDone: false,
                venue: "",
                ceremony: { name: ceremonyName, id: activeCeremonyId },
                vendor: null,
                hour: "",
                mins: "",
            },
        ]);
    }

    function inputHourHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        if (isNaN(Number(event.target.value)) || Number(event.target.value) > 24) return;

        setFormValues((prev) => {
            const updatedFormValues = [...prev];
            updatedFormValues[index] = {
                ...updatedFormValues[index],
                hour: event.target.value,
            };
            return updatedFormValues;
        });
    }

    function inputMinuteHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        if (isNaN(Number(event.target.value)) || Number(event.target.value) > 60) return;
        setFormValues((prev) => {
            const updatedFormValues = [...prev];
            updatedFormValues[index] = {
                ...updatedFormValues[index],
                mins: event.target.value,
            };
            return updatedFormValues;
        });
    }

    async function createEventDayFlowTask() {
        try {
            const errorLog = {
                name: "",
            };
            formValues.map((item) => {
                if (!item.task) {
                    errorLog.name = "Task name is required";
                    return;
                } else {
                    errorLog.name = "";
                }
            });
            if (errorLog.name) {
                return toast.error("Task is required");
            }
            setLoading(true);
            const event_day_flow = formValues.map((item) => {
                let eventDate = item?.date ? new Date(item.date) : "";
                if (item.start_time && item.date) {
                    eventDate = new Date(item.date);
                    const eventStartTime = new Date(item.start_time);
                    const hour = getHours(eventStartTime);
                    const minute = getMinutes(eventStartTime);
                    eventDate.setHours(hour, minute);
                }

                const hour = item.hour ? `${item.hour}h` : "";
                const minutes = item.mins ? `${item.mins}m` : "";
                const period = hour && minutes ? `${hour}:${minutes}` : hour ? hour : minutes;

                return {
                    ...item,
                    start_time: item.start_time ? format(new Date(item.start_time), "hh:mm aa") : item.start_time,
                    date: eventDate ? new Date(eventDate).toISOString() : item.date,
                    duration: period,
                };
            });
            const response = await addEventDayFlowRequest(
                {
                    event_day_flow: event_day_flow,
                    ceremony_id: activeCeremonyId,
                },
                selectedEventId,
            );
            setLoading(false);
            if (!response.data.status) {
                return toast.error(response.data.message || "Oops an error occured");
            }
            toast.success(response?.data?.message);
            onClose();
            await addEventdaytaskAuditHandler(event_day_flow, activeCeremonyId, ceremonyName, eventName);
            queryClient.invalidateQueries({ queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`] });
            return;
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Oops, unable to add event day task");
            onClose();
        }
    }

    function deleteCostItemHandler(index: number) {
        if (formValues.length <= 1) {
            return toast.error("There should be at least one task");
        }
        const remainingEventTaskItem = formValues.filter((_, idx) => idx !== index);
        setFormValues(remainingEventTaskItem);
    }

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        setFormValues((prev) => {
            const updatedFormValues = [...prev];
            updatedFormValues[index] = {
                ...updatedFormValues[index],
                [event.target.name]: event.target.value,
            };
            return updatedFormValues;
        });
    }

    function onSelectHandler(value: Date | string, name: string, index: number) {
        setFormValues((prev) => {
            const updatedFormValues = [...prev];
            updatedFormValues[index] = {
                ...updatedFormValues[index],
                [name]: value,
            };
            return updatedFormValues;
        });
    }

    function onSelectVendor(vendorDetails: VendorType | null, index: number) {
        setFormValues((prev) => {
            const updatedFormValues = [...prev];
            updatedFormValues[index] = {
                ...updatedFormValues[index],
                vendor: vendorDetails,
            };
            return updatedFormValues;
        });
    }

    return (
        <DashboardModal
            title="Add event day task"
            description="Add task as to-do"
            className="add_checklist_modal add_event_day_task_modal"
            onClose={onClose}>
            <div className="add_checklist_modal">
                <div className="checklist_group">
                    {formValues.map((item, index) => {
                        const hour = item.hour ? `${item.hour}h` : "";
                        const minutes = item.mins ? `${item.mins}m` : "";
                        const period = hour && minutes ? `${hour}:${minutes}` : hour ? hour : minutes;
                        const title = hour || minutes ? period : "Enter duration";
                        return (
                            <div key={index} className="checklist_row">
                                <div className="element_group">
                                    <Input2
                                        containerClassName="task_element"
                                        value={item.task}
                                        input={formDetails.task}
                                        onChange={(event) => updateInputHandler(event, index)}
                                    />
                                    <SelectDateInput
                                        value={item.date as Date | null}
                                        label={formDetails.datepicker.label}
                                        placeholder={formDetails.datepicker.placeholder}
                                        onClick={(date) => onSelectHandler(date, formDetails.datepicker.name, index)}
                                    />
                                    <SelectTimeInput
                                        value={item.start_time as Date | null}
                                        label={formDetails.startTime.label}
                                        placeholder={formDetails.startTime.placeholder}
                                        onClick={(date) => onSelectHandler(date, formDetails.startTime.name, index)}
                                    />
                                    <div className="dropdown_wrapper select_duration">
                                        <label htmlFor="select_priority ml-1">Duration</label>
                                        <SiteDropdown id="select_priority" title={title} className="ml-1 custom">
                                            <div className="flex timepicker align-items-center">
                                                <Input2
                                                    onChange={(event) => inputHourHandler(event, index)}
                                                    className="mr-1"
                                                    value={item.hour}
                                                    input={{ placeholder: "5", name: "hour", label: "HH" }}
                                                />{" "}
                                                <Input2
                                                    onChange={(event) => inputMinuteHandler(event, index)}
                                                    className="ml-1"
                                                    value={item.mins}
                                                    input={{ placeholder: "30", name: "minute", label: "MM" }}
                                                />
                                            </div>
                                        </SiteDropdown>
                                    </div>
                                    <Input2 value={item.venue} input={formDetails.venue} onChange={(event) => updateInputHandler(event, index)} />
                                    <div className="vendor_input">
                                        <div className="vendor_input_wrapper">
                                            <label>Vendor</label>
                                            <VendorPicker
                                                vendor={item.vendor ? { id: item.vendor.id, name: item.vendor.name } : { id: "", name: "" }}
                                                onSelectEventVendor={(__vendorDetails) => onSelectVendor(__vendorDetails, index)}
                                                addNew={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="action_button_group">
                                    <button type="button" onClick={() => deleteCostItemHandler(index)}>
                                        <LucideTrash2 />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="add_new mb-2">
                    <button onClick={addCostItemHandler}>
                        Add New <PlusIcon fill="#000" />
                    </button>
                </div>
            </div>
            <div className="modal_footer mt-3">
                <div className="button_group">
                    <button type="button" onClick={onClose} className="cancel_btn">
                        Cancel
                    </button>
                    <button type="button" className="btn create_btn d-flex align-items-center" disabled={loading} onClick={createEventDayFlowTask}>
                        Create {loading && <Spinner className="ml-2" size={20} />}
                    </button>
                </div>
            </div>
        </DashboardModal>
    );
}
