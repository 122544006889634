/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { ChangeEvent, FormEvent, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { formatStringToNumber } from "@/helper";
import { CollaboratorPaymentPlan, CollaboratorPlanStatus, EventPlanStatus, EventPlanType, EventType, PLAN_PAYMENT_TYPE, UserList } from "@/types";
import { createEvent, updateEventPictureRequest } from "@/axios/post-request";
import { generateRandomLinearGradientColor } from "@/utils/generateRandomColor";
import useUser from "./useUser";
import { inviteCollaborator } from "@/axios/put-request";
import onboardingFormContent from "@/json/form/onboarding_form.json";

type CollaboratorType = Array<{
	name: string;
	email: string;
	role: string;
	permission: string;
}>;

type CeremoniesType = Array<{
	id: string;
	name: string;
	date: Date | null;
	location: string;
	no_of_guests: string | number;
	checklist: [];
	show_suggestion: boolean;
}>;

export default function useCreateNewEvent() {
    const [file, setFile] = useState<File | null>(null);
    const { data } = useUser();
    const [previewEventImage, setPreviewEventImage] = useState<null | string>(null);
    const [showAddCollaborator, setShowAddCollaborator] = useState(false);
    const [eventName, setEventName] = useState("");
    const [loading, setLoading] = useState(false);
    const [collaborators, setCollaborators] = useState<CollaboratorType>([]);
    const [ceremonies, setCeremonies] = useState<CeremoniesType>([]);
    const queryClient = useQueryClient();
    const [ceremonyPickerOptions, setCeremonyPickerOptions] = useState(onboardingFormContent.ceremonyOptions);
    const [eventFeatures, setEventFeatures] = useState({
        budget_tracker: false,
        floor_plan: false,
        checklist: false,
    });
    const [collaborator, setCollaborator] = useState({
        name: "",
        email: "",
        role: "Planner",
        permission: "can_edit",
    });

    function updateCeremonyTypeHandler(__option: unknown, index: number) {
        const option = __option as { label: string; value: string; __isNew__?: boolean };
        const tempCeremonies = [...ceremonies];
        const ceremonyIndex = ceremonies.findIndex((_, itemIndex) => itemIndex === index);
        tempCeremonies[ceremonyIndex] = {
            ...tempCeremonies[ceremonyIndex],
            name: option?.label ? option?.label : "",
        };
        setCeremonies(tempCeremonies);
        if (option?.__isNew__) {
            setCeremonyPickerOptions([{ label: option?.label, value: option.value }, ...ceremonyPickerOptions]);
        }
    }

    function generateCollaboratorPayload(eventId: string) {
        const collaboratorsPayloadArray: EventType["collaborators"] = [];
        if (collaborators.length === 0) {
            return [];
        }
        collaborators.map((collaboratorItem) => {
            if (collaboratorItem.email) {
                collaboratorsPayloadArray.push({
                    ...collaboratorItem,
                    payment_plan: CollaboratorPaymentPlan.FREE,
                    event_ids: [eventId],
                    plan_duration: null,
                    send_plan_expiration_email: false,
                    plan_status: CollaboratorPlanStatus.ACTIVE,
                    start_plan_date: new Date().toISOString(),
                    end_plan_date: null,
                });
            }
        });
        return collaboratorsPayloadArray;
    }

    function updateCollaboratorDetails(event: ChangeEvent<HTMLInputElement>) {
        setCollaborator({
            ...collaborator,
            [event.target.name]: event.target.value,
        });
    }

    function showAddCollaboratorDropdown() {
        setShowAddCollaborator(true);
    }

    function onSelectCollaboratorHandler(value: string, name: string) {
        setCollaborator({
            ...collaborator,
            [name]: value,
        });
    }

    async function onInviteCollaboratorRequestHandler() {
        if (!collaborator.name) {
            return toast.error("Collaborator's name is required");
        }
        if (!collaborator.email) {
            return toast.error("Collaborator's email is required");
        }
        if (!collaborator.role) {
            return toast.error("Collaborator's planner is required");
        }
        setCollaborators([...collaborators, collaborator]);
        setCollaborator({
            ...collaborator,
            name: "",
            email: "",
            role: "Planner",
            permission: "can_edit",
        });
        setShowAddCollaborator(false);
    }

    function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        setPreviewEventImage(url);
        setFile(file);
    }

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>) {
        setEventName(event.target.value);
    }

    // function updateCeremonyTypeHandler(option: string, index: number) {
    //     setCeremonies((prev) => {
    //         return [...prev.slice(0, index), { ...prev[index], name: option }, ...prev.slice(index + 1)];
    //     });
    // }

    function addMoreCeremonyHandler() {
        const ceremonyId = uuidv4();
        setCeremonies((prev) => {
            return [
                ...prev,
                {
                    id: ceremonyId,
                    name: "",
                    location: "",
                    date: null,
                    no_of_guests: "",
                    checklist: [],
                    show_suggestion: true,
                },
            ];
        });
    }

    function deleteACeremonyHandler() {
        if (ceremonies.length >= 1) {
            setCeremonies((prev) => {
                const tempCeremony = [...prev];
                const updatedCeremony = tempCeremony.slice(0, tempCeremony.length - 1);
                return updatedCeremony;
            });
        }
    }

    function updateCeremonyHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const updateCeremony = ceremonies.map((formDetails, idx: number) => {
            if (event.target.name === "no_of_guests" && index === idx) {
                if (event.target.value && !formatStringToNumber(event.target.value)) {
                    return formDetails;
                } else {
                    return {
                        ...formDetails,
                        no_of_guests: event.target.value ? formatStringToNumber(event.target.value) : "",
                    };
                }
            }
            if (index === idx) {
                return {
                    ...formDetails,
                    [event.target.name]: event.target.value,
                };
            } else return formDetails;
        });
        setCeremonies(updateCeremony);
    }

    function updateCeremonyDateHandler(date: Date, index: number) {
        const updateCeremony = ceremonies.map((formDetails, idx: number) => {
            if (index === idx) {
                return {
                    ...formDetails,
                    date,
                };
            } else return formDetails;
        });
        setCeremonies(updateCeremony);
    }

    function removeCollaborator(index: number) {
        const tempCollaborators = [...collaborators];
        const remainingCollaborators = tempCollaborators.filter((_, idx) => index !== idx);
        setCollaborators(remainingCollaborators);
    }

    async function onCreateEventHandler(event: FormEvent<HTMLFormElement>, onCloseModal: () => void) {
        try {
            event.preventDefault();
            if (!eventName) {
                return toast.error("Event name is required");
            }
            const ceremoniesTypeErrors = [];
            const ceremoniesDateErrors = [];
            const ceremoniesLocationErrors = [];
            const ceremoniesGuestErrors = [];

            if (ceremonies.length === 0) {
                return toast.error("Add at least one ceremony");
            }

            if (ceremonies.length > 0) {
                ceremonies.map((ceremony, index) => {
                    if (!ceremony.name) {
                        ceremoniesTypeErrors.push(index);
                    }
                    if (!ceremony.date) {
                        ceremoniesDateErrors.push(index);
                    }
                    if (!ceremony.location) {
                        ceremoniesLocationErrors.push(index);
                    }
                    if (!ceremony.no_of_guests) {
                        ceremoniesGuestErrors.push(index);
                    }
                });
                if (ceremoniesTypeErrors.length > 0) {
                    return toast.error("Ceremony name is required");
                }
                if (ceremoniesLocationErrors.length > 0) {
                    return toast.error("Ceremony location is required");
                }
                if (ceremoniesDateErrors.length > 0) {
                    return toast.error("Ceremony date is required");
                }
                if (ceremoniesGuestErrors.length > 0) {
                    return toast.error("Ceremony guest number is required");
                }
            }
            setLoading(true);
            const eventId = uuidv4();
            const eventBg = generateRandomLinearGradientColor();
            const eventCollaboarators = generateCollaboratorPayload(eventId);
            // Create Event
            const createEventResult = await createEvent({
                id: eventId,
                event_name: eventName,
                ceremonies,
                event_bg: eventBg,
                event_owner: {
                    email: data?.result?.email as string,
                    id: data?.result?.id as string,
                    role: "Event Owner",
                    user_type: data?.result?.user_type,
                    event_id: eventId,
                },
                collaborators: [],
                plan: EventPlanType.FREE,
                plan_amount: null,
                plan_payment_type: PLAN_PAYMENT_TYPE.ONE_TIME_PAYMENT,
                plan_duration_month: null,
                plan_status: EventPlanStatus.ACTIVE,
                collaborator_per_events: 2,
                collaborator_ids: [data?.result?.id as string],
                max_events: 3,
            });

            if (!createEventResult.data.status) {
                setLoading(false);
                return toast.error(createEventResult.data.message);
            }

            if (collaborators.length > 0 && createEventResult.data.status) {
                const _inviteeName = data?.result?.user_type === UserList.planner ? data?.result?.business_name : data?.result?.name;
                const inviteeName = _inviteeName as string;
                return await inviteCollaborator(data?.result?.id as string, {
                    eventId,
                    collaborators: eventCollaboarators,
                    inviteeEmail: data?.result?.email ?? "",
                    inviteeName,
                    inviteeRole: data?.result?.role ? data?.result?.role : "Admin",
                });
            }
            // If event image is added upload event image
            if (file) {
                const eventPictureFormData = new FormData();
                eventPictureFormData.append("file", file);
                await updateEventPictureRequest(eventPictureFormData, eventId);
            }
            setLoading(false);
            toast.success("Event added");
            queryClient.invalidateQueries();
            onCloseModal();
        } catch (error) {
            setLoading(false);
            toast.error("Oops an error occured");
            console.log("error", error);
            onCloseModal();
        }
    }

    function onSelectEventFeatureHandler(name: keyof typeof eventFeatures) {
        setEventFeatures({
            ...eventFeatures,
            [name]: !eventFeatures[name],
        });
    }

    return {
        formValues: {
            file,
            previewEventImage,
            showAddCollaborator,
            eventName,
            loading,
            eventFeatures,
            collaborators,
            ceremonies,
            collaborator,
            ceremonyPickerOptions,
        },
        functions: {
            onSelectEventFeatureHandler,
            onCreateEventHandler,
            removeCollaborator,
            updateCeremonyDateHandler,
            updateCeremonyHandler,
            deleteACeremonyHandler,
            addMoreCeremonyHandler,
            updateCeremonyTypeHandler,
            updateInputHandler,
            changePhotoHandler,
            onInviteCollaboratorRequestHandler,
            onSelectCollaboratorHandler,
            showAddCollaboratorDropdown,
            updateCollaboratorDetails,
        },
    };
}
