/* eslint-disable max-len */
export default function UnionIcon() {
    return (
        <svg width="2" height="6" viewBox="0 0 2 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.65234 1.62097C1.71484 1.5116 1.75 1.38464 1.75 1.24988C1.75 0.835815 1.41406 0.499878 1 0.499878C0.585937 0.499878 0.25 0.835815 0.25 1.24988C0.25 1.66394 0.585937 1.99988 1 1.99988C1.2793 1.99988 1.52344 1.84753 1.65234 1.62097ZM0.5 2.49988H0.75H1.25C1.52637 2.49988 1.75 2.72351 1.75 2.99988V3.49988V5.49988C1.75 5.77625 1.52637 5.99988 1.25 5.99988C0.973633 5.99988 0.75 5.77625 0.75 5.49988V3.87488C0.75 3.66785 0.582031 3.49988 0.375 3.49988C0.167969 3.49988 0 3.33191 0 3.12488V2.99988C0 2.8241 0.0908203 2.66882 0.227539 2.57996C0.305664 2.52917 0.399414 2.49988 0.5 2.49988Z"
                fill="white"
            />
        </svg>
    );
}
