import logo from "@/assets/img/logo.png";
import heart1 from "@/assets/img/heart1.svg";
import heart2 from "@/assets/img/heart2.svg";
import heart3 from "@/assets/img/heart3.svg";
import heart4 from "@/assets/img/heart4.svg";
import "@/styles/loading.scss";

export default function Loading() {
    return (
        <div className="loading">
            <div className="heart1">
                <img src={heart1} alt="Heart shapes" />
            </div>
            <div className="heart2">
                <img src={heart2} alt="Heart shapes" />
            </div>
            <img src={logo} alt="Planaday logo" className="logo-img col-lg-3 col-6" />
            <div className="heart3">
                <img src={heart3} alt="Heart shapes" />
            </div>
            <div className="heart4">
                <img src={heart4} alt="Heart shapes" />
            </div>
        </div>
    );
}
