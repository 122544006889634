import { useState } from "react";

import DashboardLayout from "@/layout/DashboardLayout";
import PricingTable from "@/components/PricingTable";
import PricingPayment from "@/components/PricingPayment";
import { PaymentType, type pricingPlanType } from "@/types";

export default function PricingPage() {
    const [selectPricingPlan, setPricingPlan] = useState<pricingPlanType>("");

    function choosePricingPlan(plan: pricingPlanType) {
        setPricingPlan(plan);
    }

    function resetPricingPlan() {
        setPricingPlan("");
    }

    const className = selectPricingPlan ? "col-md-8 col-12 mx-auto" : "";

    return (
        <DashboardLayout title="Pricing">
            <section className={`bg-white px-md-4 px-3 py-4 ${className}`}>
                <PricingTable type="all" selectPricingPlan={selectPricingPlan} choosePricingPlan={choosePricingPlan}>
                    <PricingPayment title={selectPricingPlan} paymentType={PaymentType.EVENT} resetPricingPlan={resetPricingPlan} />
                </PricingTable>
            </section>
        </DashboardLayout>
    );
}
