import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type suggestedChecklistType = Array<{
    tags: string;
    suggestedChecklist: string[];
}>;

interface useFilterType {
    suggestedChecklist: suggestedChecklistType;
    setSuggestedChecklist: (value: suggestedChecklistType) => void;
}

export const useSuggestedChecklist = create<useFilterType>()(
    persist(
        (set) => ({
            suggestedChecklist: [],
            setSuggestedChecklist: (value) => set(() => ({ suggestedChecklist: value })),
        }),
        {
            name: "suggestedChecklist",
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);
