import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { isFuture } from "date-fns";

import { useChecklist } from "@/hooks/useChecklist";
import useUser from "@/hooks/useUser";
import { updateUserChecklist } from "@/axios/put-request";
import type { EventType } from "@/types";
import { updateAudit } from "@/axios/post-request";

type todoItemType = {
    isDone: boolean;
    due: Date | null;
    id: string;
    name: string;
    ceremony: { name: string; id: string };
    note: string | null;
};

export default function useChecklistItem(todoItem: todoItemType, activeEvent: EventType[] | null) {
    const [selected, setSelected] = useState("");
    const { data: userData, selectedEventId } = useUser();
    const { todo, setTodo, setChecklistModal, setSelectectedChecklist } = useChecklist();
    const queryClient = useQueryClient();

    async function onCompleteTaskHandler() {
        const _selected = selected ? "" : todoItem.id;
        setSelected(_selected);
        const taskIndex = todo.findIndex((item) => item.id === todoItem.id);

        if (taskIndex !== -1) {
            todo[taskIndex].isDone = !todo[taskIndex].isDone;
            setTodo(todo);
        }
        await updateUserChecklist(
            {
                ...todoItem,
            },
            todoItem.ceremony.id,
            selectedEventId,
        )
            .then(() => {
                const checklist_action = todoItem.isDone ? "checklist_completed" : "checklist_not_completed";
                updateAudit(
                    [
                        {
                            id: uuidv4(),
                            name: userData?.result?.name as string,
                            user_id: userData?.result?.id as string,
                            email: userData?.result?.email as string,
                            type: "checklist",
                            action: checklist_action,
                            title: todoItem.name,
                            changes: null,
                            timestamp: new Date(),
                        },
                    ],
                    selectedEventId,
                );
            })
            .catch((error: unknown) => {
                const _error = error as { message: string };
                console.log("error", _error);
            });
        queryClient.invalidateQueries({ queryKey: [`get_audits_${selectedEventId}`], exact: true });
    }

    function deleteModalHandler() {
        setSelectectedChecklist(todoItem);
        setChecklistModal("delete_checklist_modal");
    }

    function editModalHandler() {
        setSelectectedChecklist(todoItem);
        setChecklistModal("edit_checklist_modal");
    }

    const ceremoniesDateAndId: Array<{ id: string; date: Date | null }> = [];
    const eventCeremonies = activeEvent ? activeEvent[0].ceremonies : [];
    eventCeremonies.map((item) => ceremoniesDateAndId.push({ date: item?.date, id: item.id }));

    const isDateInFuture = todoItem?.due ? isFuture(new Date(todoItem?.due)) : false;

    return { onCompleteTaskHandler, ceremoniesDateAndId, editModalHandler, isDateInFuture, deleteModalHandler };
}
