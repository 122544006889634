export default function ThreeDotsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <circle
                cx="7.0013"
                cy="6.99992"
                r="0.583333"
                transform="rotate(-90 7.0013 6.99992)"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <circle
                cx="7.0013"
                cy="10.4999"
                r="0.583333"
                transform="rotate(-90 7.0013 10.4999)"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <circle
                cx="7.0013"
                cy="3.49992"
                r="0.583333"
                transform="rotate(-90 7.0013 3.49992)"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}
