import { Asoebi_order_status } from "@/types";
import SiteModal from "./SiteModal";
import Button from "../Button";

interface Props {
	show: boolean;
	onHide: () => void;
	orderStatus: keyof typeof Asoebi_order_status | null;
	eventName: string | null;
	onUpdateStatusHandler: () => void
}

export default function UpdateAsoebiOrderModal({ show, orderStatus, eventName, onHide, onUpdateStatusHandler }: Props) {
    return (
        <SiteModal title="Update Asoebi order status" show={show} onHide={onHide}>
            <section>
                <h5>
					Do you want to update {eventName} event order status to <b>{orderStatus}</b>{" "}
                </h5>
                <div className="d-flex mt-5 w-full justify-content-between mb-4">
                    <Button text="Proceed" className="btn btn-primary" onClick={onUpdateStatusHandler} />
                    <Button text="Cancel" className="btn text-primary font-weight-bold" onClick={onHide} />
                </div>
            </section>
        </SiteModal>
    );
}
