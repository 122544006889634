import type { ChangeEvent } from "react";
import "@/styles/form.scss";

interface Props {
    input: {
        label?: string;
        name: string;
    };
    className?: string;
    checked?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}
export default function Checkbox({ input, checked = false, onChange, className }: Props) {
    const inputClassname = className ? className : "";
    return (
        <div className={`form_element checkbox ${inputClassname}`}>
            {input?.label && <label htmlFor={input.name}>{input?.label}</label>}
            <input name={input.name} className="form-control" type="checkbox" checked={checked} onChange={onChange} />
            {/* {input?.error && <p>{input.error}</p>} */}
        </div>
    );
}
