import EventView from "@/components/EventView";
import DashboardLayout from "@/layout/DashboardLayout";
import "@/styles/profile.scss";

export default function Profile() {
    return (
        <DashboardLayout title="Profile" className="profile_view">
            <EventView />
        </DashboardLayout>
    );
}
