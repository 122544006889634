import ContentLoader from "react-content-loader";

import { useMediaQuery } from "@/hooks/useMediaQuery";

export default function AsoebiOrderformLoader() {
    const sixDummyAsoebiForm = Array(6).fill(0);
    const mobileDevice = useMediaQuery("(max-width: 560px)");
    const mediaDummyLength = mobileDevice ? 1 : 2;
    const twoDummyAsoebiForm = Array(mediaDummyLength).fill(0);

    return (
        <div className="audits_loader d-flex flex-column mt-5 col-lg-10 col-12 px-0 mx-auto">
            <div className="d-flex mx-auto justify-content-center">
                {twoDummyAsoebiForm.map((_, index) => (
                    <ContentLoader
                        key={index}
                        className="mb-4 mx-2"
                        title="Fetching asoebi order form..."
                        height="250px"
                        width="100%"
                        backgroundColor="#cbbec0"
                        foregroundColor="#dedede">
                        <rect x="0" y="0" rx="10" ry="10" width="100%" height="250px" />
                    </ContentLoader>
                ))}
            </div>
            {sixDummyAsoebiForm.map((_, index) => (
                <ContentLoader
                    key={index}
                    className="mb-1"
                    title="Fetching asoebi order form..."
                    // viewBox="0 0 100% 100%"
                    height="70px"
                    backgroundColor="#cbbec0"
                    foregroundColor="#dedede">
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="50px" />
                </ContentLoader>
            ))}
        </div>
    );
}
