import { useState } from "react";

import allPricingTable from "@/json/pricing_table.json";
import eventPricingTable from "@/json/event_pricing_table.json";
import collaboratorPricingTable from "@/json/collaborator_pricing_table.json";
import ArrowRightIcon from "@/assets/icon/ArrowRightIcon";
import ArrowleftIcon from "@/assets/icon/ArrowleftIcon";

type planType = "basic" | "essential" | "premium";

interface Props {
    choosePricingPlanHandler: (plan: "basic" | "essential" | "premium") => void;
    type: "event" | "collaborator" | "all";
}

export default function MobilePricingView({ choosePricingPlanHandler, type }: Props) {
    const [carouselCounter, setCarouselCounter] = useState(0);

    function incrementCounterHandler() {
        if (carouselCounter <= 3) {
            setCarouselCounter((prev) => prev + 1);
        }
    }

    function decrementCounterHandler() {
        if (carouselCounter >= 1) {
            setCarouselCounter((prev) => prev - 1);
        }
    }

    const disableLeftControl = carouselCounter === 0;
    const pricingTable = type === "all" ? allPricingTable : type === "collaborator" ? collaboratorPricingTable : eventPricingTable;
    const disableRightControl = carouselCounter === pricingTable.length - 1;
    return (
        <div>
            <p>Start planning for free, then add a plan to unlock additional planning features</p>
            <div className="pricing_carousel_view my-3">
                <button onClick={decrementCounterHandler} className="left_control controls" disabled={disableLeftControl}>
                    <ArrowleftIcon />
                </button>
                <button onClick={incrementCounterHandler} className="right_control controls" disabled={disableRightControl}>
                    <ArrowRightIcon />
                </button>
                {pricingTable.map((item, index) => {
                    const plan = item.type.toLowerCase() as planType;

                    return (
                        index === carouselCounter && (
                            <div key={item.type} className={`pricing_carousel_view_item ${item.type}`}>
                                <h4>{item.type}</h4>
                                {item.description && <p className="description">{item.description}</p>}
                                {item.budget_tracker === "✓" && (
                                    <p>
                                        <span>Budget Tracker</span>
                                    </p>
                                )}
                                {item.checklist === "✓" && (
                                    <p>
                                        <span>Checklist</span>
                                    </p>
                                )}
                                {item.event_audit === "✓" && (
                                    <p>
                                        <span>Event audit</span>
                                    </p>
                                )}
                                <p className="collaborator ">
                                    <span>Number of Collaborator:</span>
                                    {item.no_of_collaborator}
                                </p>
                                <p className="events">
                                    <span>Number of Events:</span>
                                    {item.no_of_events}
                                </p>
                                {item.duplicate_of_events === "✓" && (
                                    <p>
                                        <span>Duplicate of Events</span>
                                    </p>
                                )}
                                {item.premium_support === "✓" && (
                                    <p className="last">
                                        <span>Premium Support</span>
                                    </p>
                                )}
                                {item.type !== "Free" && item.type !== "Custom" && (
                                    <button
                                        className="mt-4 bg_red border-0 text-white add_plan py-2 px-3"
                                        onClick={() => choosePricingPlanHandler(plan)}>
                                        <span>Add {item.type} Plan</span>+
                                    </button>
                                )}
                                {item.type === "Custom" && (
                                    <a
                                        className="bg-red contact_btn"
                                        target="_blank"
                                        rel="no-referrer"
                                        href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@planaday.events">
                                        <span className="mr-3">Contact us</span>+
                                    </a>
                                )}
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
}
