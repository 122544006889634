import { ChangeEvent, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { toast } from "react-toastify";

import Input2 from "@/components/form/Input2";
import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import GoogleIcon from "@/assets/icon/GoogleIcon";
import { validateEmail } from "@/helper";
import useAuth from "@/hooks/useAuth";
import Spinner from "@/components/Spinner";
import "@/styles/auth_page.scss";
import PasswordInput2 from "@/components/form/PasswordInput2";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";

enum tabList {
    signup = "signup",
    login = "login",
}
type tabState = keyof typeof tabList;
type loading_type = "email_and_password" | "google" | null;
type userType = "planner" | "celebrant";

const formDetails = {
    email: { name: "email", label: "Email address", placeholder: "anemail@domain.com" },
    password: { name: "password", label: "Password", placeholder: "***********************" },
};

const tabs = [
    { text: "Log in", id: "login" },
    { text: "Sign up", id: "signup" },
] as Array<{ text: string; id: tabState }>;

export default function AuthPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const mode = searchParams.get("mode") as tabState;
    const [loadingType, setLoadingType] = useState<loading_type>(null);
    const [authMode, setAuthMode] = useState<tabState>(mode);
    const { loading, signInWithUserEmailAndPassword, googleAuth, createNewUserWithEmailAndPassword } = useAuth();
    const [form, setForm] = useState({
        email: "",
        password: "",
    });
    const user_type = searchParams.get("type") as userType;

    function onToggleTabHandler(tabId: tabState) {
        setAuthMode(tabId);
        setSearchParams({ ["type"]: user_type, ["mode"]: tabId });
    }

    function onInputChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    const titleText = authMode === "signup" ? "Hi, Welcome 🤗" : "Hi, Welcome back 👋";
    const btnText = authMode === "signup" ? "Sign up with email" : "Sign in with email";

    const onboardingRoute = user_type === "celebrant" ? "onboarding-celebrant" : "onboarding-event-planner";

    async function onSubmitHandler(e: ChangeEvent<HTMLFormElement>) {
        try {
            e.preventDefault();
            const { email, password } = form;
            if (!email) {
                return toast.error("Email address is required.");
            }
            if (!validateEmail(email)) {
                return toast.error("Please enter a valid email address.");
            }
            if (!password) {
                return toast.error("Password is required.");
            }
            if (password.length < 6) {
                return toast.error("Password must be at least 6 characters long.");
            }
            const emailAddress = email.toLowerCase();
            setLoadingType("email_and_password");
            if (mode === tabList.login) {
                return await signInWithUserEmailAndPassword(emailAddress, password, { user_type }, "/dashboard");
            } else if (mode === tabList.signup) {
                return await createNewUserWithEmailAndPassword(emailAddress, password, { user_type }, `/${onboardingRoute}/stage-1`);
            }
            const checkOnboardingDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.onboarding_details);
            if (checkOnboardingDetails) {
                window.localStorage.removeItem(LOCAL_STORAGE_KEYS.onboarding_details);
            }
            setLoadingType(null);
        } catch (error) {
            console.log("auth_error", error);
            setLoadingType(null);
        }
    }

    async function googleAuthHandler() {
        setLoadingType("google");
        await googleAuth({ user_type }, `/${onboardingRoute}/stage-1`);
        setLoadingType(null);
    }

    return (
        <NewOnboardingLayout className="auth_page" text="Ready to plan the perfect event?">
            <div className="content auth">
                <div className="auth_tab">
                    {tabs.map((tab) => {
                        const activeClassname = tab.id === authMode ? "active" : "";
                        return (
                            <button key={tab.id} className={activeClassname} onClick={() => onToggleTabHandler(tab.id)}>
                                {tab.text}
                            </button>
                        );
                    })}
                </div>
                <div className="auth_content">
                    <h4>{titleText}</h4>
                    <form onSubmit={onSubmitHandler}>
                        <Input2 input={formDetails.email} type="email" value={form.email} onChange={onInputChangeHandler} />
                        <PasswordInput2 input={formDetails.password} value={form.password} onChange={onInputChangeHandler} />
                        <button type="submit">{loading && loadingType === "email_and_password" ? <Spinner size={26} /> : btnText}</button>
                        <div className="divider">
                            <hr /> <span>or continue with</span>
                        </div>
                        <button type="button" onClick={googleAuthHandler} className="google_btn">
                            <span>
                                <GoogleIcon />
                            </span>
                            {loading && loadingType === "google" ? <Spinner size={26} /> : "Google"}
                        </button>
                    </form>
                    {mode === "signup" && (
                        <p className="privacy_note">
                            By clicking sign up, you agree to our{" "}
                            <Link target="_blank" to="/term-of-service">
                                Terms of Service
                            </Link>{" "}
                            and <Link to="/privacy-policy">Privacy Policy</Link>
                        </p>
                    )}
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
