import testimonial1 from "@/assets/img/testimonial__1.png";
import testimonial2 from "@/assets/img/testimonial__2.png";
import testimonial3 from "@/assets/img/testimonial__3.png";
import testimonial4 from "@/assets/img/testimonial__4.png";
import testimonial5 from "@/assets/img/testimonial__5.png";
import testimonial6 from "@/assets/img/testimonial__6.png";

export const testimonials = [
    {
        img: testimonial1,
        name: "Adebayo Success",
        role: "Corporate Event Planner",
        text: "This platform exceeded my expectations, making corporate event planning a breeze. Highly recommended!",
    },
    {
        img: testimonial2,
        name: "Chibuzor Kalu",
        role: "Wedding Coordinator",
        text: "The events planner is a game-changer for my wedding planning business. It's intuitive and versatile!",
    },
    {
        img: testimonial3,
        name: "Hazyam Khalif",
        role: "Small Business Owner",
        text: "Thanks to this platform, organising events for my small business is stress-free. It's user-friendly and saves me time and money.",
    },
    {
        img: testimonial4,
        name: "Aisha Upmisnter",
        role: "Corporate Event Planner",
        text: "This platform exceeded my expectations, making corporate event planning a breeze. Highly recommended!",
    },
    {
        img: testimonial5,
        name: "Maggie Benson",
        role: "Event Manager",
        text: "This SaaS solution is incredibly flexible and efficient for managing multiple events. The support team is responsive and helpful.",
    },
    {
        img: testimonial6,
        name: "Toluwa Ajobiowe",
        role: "Freelance Event Planner",
        // eslint-disable-next-line max-len
        text: "This platform offers everything I need and more as a freelance event planner. It's powerful, affordable, and indispensable for my business.",
    },
];

export const testimonials2 = [
    {
        img: testimonial3,
        name: "Hazyam Khalif",
        role: "Small Business Owner",
        text: "Thanks to this platform, organising events for my small business is stress-free. It's user-friendly and saves me time and money.",
    },
    {
        img: testimonial4,
        name: "Aisha Upmisnter",
        role: "Corporate Event Planner",
        text: "This platform exceeded my expectations, making corporate event planning a breeze. Highly recommended!",
    },
    {
        img: testimonial5,
        name: "Maggie Benson",
        role: "Event Manager",
        text: "This SaaS solution is incredibly flexible and efficient for managing multiple events. The support team is responsive and helpful.",
    },
    {
        img: testimonial6,
        name: "Toluwa Ajobiowe",
        role: "Freelance Event Planner",
        // eslint-disable-next-line max-len
        text: "This platform offers everything I need and more as a freelance event planner. It's powerful, affordable, and indispensable for my business.",
    },
    {
        img: testimonial1,
        name: "Adebayo Success",
        role: "Corporate Event Planner",
        text: "This platform exceeded my expectations, making corporate event planning a breeze. Highly recommended!",
    },
    {
        img: testimonial2,
        name: "Chibuzor Kalu",
        role: "Wedding Coordinator",
        text: "The events planner is a game-changer for my wedding planning business. It's intuitive and versatile!",
    },
];
