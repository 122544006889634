/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Path, Group } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function SingleOpenDoorElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            name="floor_plan_element"
            {...item}
            scaleX={3}
            scaleY={3}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Path data="M14.0037 13.9998C14.0037 12.1608 13.6414 10.3399 12.9377 8.64084C12.2339 6.94182 11.2024 5.39806 9.90206 4.0977C8.60169 2.79733 7.05793 1.76582 5.35892 1.06207C3.6599 0.358311 1.83891 -0.003906 -8.39233e-05 -0.00390564L-8.14039e-05 13.9998L14.0037 13.9998Z" />
            <Path
                stroke={item.fill}
                data="M14.0037 13.9998C14.0037 12.1608 13.6414 10.3399 12.9377 8.64084C12.2339 6.94182 11.2024 5.39806 9.90206 4.0977C8.60169 2.79733 7.05793 1.76582 5.35892 1.06207C3.6599 0.358311 1.83891 -0.003906 -8.39233e-05 -0.00390564L-8.14039e-05 13.9998L14.0037 13.9998Z"
                strokeWidth={0.56}
            />
        </Group>
    );
}
