import { toast } from "react-toastify";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Checkbox from "@/components/Checkbox";
import RadioGroup from "@/components/RadioGroup";
import AsoebiLayout from "@/layout/AsoebiLayout";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import Input from "@/components/form/Input";

const updateOrdersOptns = ["Daily", "Weekly"];
const formDetails = {
    delivery_address: { placeholder: "Delivery Address", name: "delivery_address" },
    pickup_address: { placeholder: "Pickup address", name: "pickup_address" },
    pickup_phone_number: { placeholder: "Pickup phone to call", name: "pickup_phone_number" },
};

type questionaireType = {
	name: boolean;
	phone_number: boolean;
	collection_option: Array<"Pickup" | "Delivery">;
	delivery_address: boolean;
	pickup_address: string;
	pickup_phone_number: string;
	order_update: string;
};

export default function CreateQuestionaire() {
    const navigate = useNavigate();
    const [form, setForm] = useState<questionaireType>({
        name: true,
        phone_number: true,
        collection_option: [],
        delivery_address: false,
        pickup_address: "",
        pickup_phone_number: "",
        order_update: "",
    });

    useEffect(() => {
        const guestDetailsStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.guest_details);
        const eventDetailsStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.event_details);
        const guestDetails = guestDetailsStorage ? JSON.parse(guestDetailsStorage) : null;
        const eventDetails = eventDetailsStorage ? JSON.parse(eventDetailsStorage) : null;

        if (guestDetails && typeof guestDetails.collection_option !== "string") {
            setForm(guestDetails);
        }
        if (eventDetails && eventDetails.phone_number) {
            setForm({ ...form, pickup_phone_number: eventDetails.phone_number });
        }
    }, []);

    function updateForm(name: string, value: string | boolean) {
        setForm({
            ...form,
            [name]: value,
        });
    }

    function updateCollectionOption(value: "Pickup" | "Delivery") {
        const collectionOption = [...form.collection_option];
        const result = collectionOption.includes(value) ? collectionOption.filter((item) => item !== value) : [...collectionOption, value];
        let delivery_address;

        if (value === "Delivery") {
            if (collectionOption.includes(value)) {
                delivery_address= { delivery_address: false};
            } else {
                delivery_address= { delivery_address: true};
            }
        }

        setForm({
            ...form,
            collection_option: result,
            ...delivery_address,
        });
    }

    function onInputChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    }


    function onNextHandler() {
        if ( form.collection_option.length === 0) {
            return toast.error("Please select a collection option");
        }
        if (!form.pickup_address && form.collection_option.includes("Pickup")) {
            return toast.error("Please enter your pickup address");
        }
        if (!form.pickup_phone_number && form.collection_option.includes("Pickup")) {
            return toast.error("Please enter your pickup phone number");
        }
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.guest_details, JSON.stringify(form));
        navigate("/asoebi-tracker/complete-questionnaire");
    }

    const buttonClassname = form.name && form.phone_number && form.collection_option && form.order_update ? "d-flex" : "d-none";

    return (
        <AsoebiLayout>
            <section className="guest_questionaire tracker_content">
                <h1 className="text-center">
					What details do you want to collect from your <span>guests</span>
                </h1>
                <h4 className="text-lg my-2">Select the ones you want to add</h4>
                <div className="col-lg-8 px-0 col-md-11 col-12 mx-auto pb-5">
                    <h6 className="font-bold mb-4 mt-4">A. Guest Details</h6>
                    <div className="guest_details">
                        <Checkbox label="Name" name="name" active={form.name} onClickHandler={updateForm} />
                        <Checkbox label="Phone number" active={form.phone_number} name="phone_number" onClickHandler={updateForm} />
                        <div className="d-flex align-items-md-center flex-md-row flex-column justify-content-between">
                            <label htmlFor="collection_option">Collection Option</label>
                            <div id="collection_option" className="d-flex col-9 px-0 collection_option">
                                <Checkbox
                                    label="Delivery"
                                    name="Delivery"
                                    active={form.collection_option?.includes("Delivery")}
                                    onClickHandler={() => updateCollectionOption("Delivery")}
                                />
                                <Checkbox
                                    label="Pickup"
                                    name="Pickup"
                                    active={form.collection_option?.includes("Pickup")}
                                    onClickHandler={() => updateCollectionOption("Pickup")}
                                />
                            </div>
                        </div>
                        {form.collection_option.includes("Pickup") && (
                            <>
                                <Input input={formDetails.pickup_address} value={form.pickup_address} onChange={onInputChangeHandler} />
                                <Input input={formDetails.pickup_phone_number} value={form.pickup_phone_number} onChange={onInputChangeHandler} />
                            </>
                        )}
                        <RadioGroup
                            label="How often do you want us to update you about your orders"
                            value={form.order_update}
                            options={updateOrdersOptns}
                            name="order_update"
                            className="w-full d-flex flex-column flex-md-row col-12 px-0"
                            onClickHandler={updateForm}
                            containerClassName="d-flex flex-column align-items-start"
                        />
                        <button className={`mt-2 mx-auto py-2 px-4 font-weight-bold btn btn-primary ${buttonClassname}`} onClick={onNextHandler}>
							Next
                        </button>
                    </div>
                </div>
            </section>
        </AsoebiLayout>
    );
}
