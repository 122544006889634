/* eslint-disable max-len */

interface Props{
    color: string
}
export default function Couple({color}:Props) {
    return (
        <svg
            height="11px"
            width="14px"
            className="couple"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            fill={color}
            stroke={color}
            strokeWidth="15">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="3.072"></g>
            <g id="SVGRepo_iconCarrier">
                <g>
                    {" "}
                    <path
                        className="st0"
                        fill="#fff"
                        d="M134.383,201.118c21.212,0,38.398-17.195,38.398-38.389c0-21.22-17.186-38.398-38.398-38.398 c-21.211,0-38.389,17.178-38.389,38.398C95.994,183.922,113.172,201.118,134.383,201.118z"></path>{" "}
                    <path
                        className="st0"
                        fill="#fff"
                        d="M321.759,162.729c0,21.194,17.187,38.389,38.398,38.389c21.203,0,38.389-17.195,38.389-38.389 c0-21.22-17.186-38.398-38.389-38.398C338.946,124.331,321.759,141.509,321.759,162.729z"></path>{" "}
                    <path
                        className="st0"
                        fill="#fff"
                        d="M429.027,384.303c2.232-2.715,3.62-6.09,3.62-9.806v-122.54c0-17.652-16.186-36.878-34.787-36.878h-37.703 h-21.668c-20.376,0-32.08,13.136-41.183,28.873l-50.032,78.324l-50.04-78.324c-11.317-17.46-21.281-28.873-41.658-28.873h-21.194 H96.197c-18.602,0-34.304,19.225-34.304,36.878v122.54c0,8.83,7.53,16,16.836,16c4.578,0,0,0,11.756,0l6.397,104.492 c0,9.392,8.031,17.011,17.933,17.011c4.174,0,11.872,0,19.568,0c7.706,0,15.412,0,19.586,0c9.903,0,17.934-7.618,17.934-17.011 l6.387-104.492l2.821-98.28l54.548,70.022c2.268,2.512,4.956,5.552,11.467,5.552c6.836,0,9.006-2.17,11.757-5.552l54.548-70.022 l1.616,56.253c-7.626,8.857-42.176,51.156-46.438,94.501h55.778l8.427,54.319c0,6.845,5.851,12.416,13.065,12.416 c3.049,0,8.664,0,14.278,0c5.614,0,11.221,0,14.27,0c7.213,0,13.065-5.57,13.065-12.416l8.426-54.319h54.188 C448.024,421.945,438.825,401.164,429.027,384.303z"></path>{" "}
                    <path
                        className="st0"
                        fill="#fff"
                        d="M248.206,148.284c1.178,1.125,0.905,1.107,0.905,1.107c0.553,0.668,1.344,1.08,2.179,1.08c0,0,0,0,0.009,0l0,0 c0.835,0,1.634-0.413,2.179-1.08c0,0-0.264,0.018,0.905-1.107c33.697-32.538,82.964-52.65,82.964-96.224 c0-33.925-19.99-52.518-44.154-52.052c-26.219,0.5-39.048,19.049-41.894,35.919c-2.847-16.87-15.676-35.419-41.896-35.919 c-24.172-0.466-44.162,18.127-44.162,52.052C165.242,95.634,214.518,115.746,248.206,148.284z"></path>{" "}
                </g>{" "}
            </g>
        </svg>
    );
}
