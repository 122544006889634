import React from "react";

import logo from "@/assets/img/logo.png";
import instagram from "@/assets/img/instagram.svg";
import twitter from "@/assets/img/twitter.svg";
import tiktok from "@/assets/img/TikTok.svg";
import facebook from "@/assets/img/facebook.svg";
import email from "@/assets/img/Email.svg";
import { SOCIAL_LINKS } from "@/utils/constant";

const footerIcons = [
    {
        icon: instagram,
        alt: "Planaday Instagram",
        link: SOCIAL_LINKS.instagram
    },
    {
        icon: tiktok,
        alt: "Planaday TikTok",
        link: SOCIAL_LINKS.tiktok
    },
    {
        icon: facebook,
        alt: "Planaday Facebook",
        link: SOCIAL_LINKS.facebook
    },
    {
        icon: twitter,
        alt: "Planaday Twitter",
        link: SOCIAL_LINKS.twitter
    },
    {
        icon: email,
        alt: "Planaday Email",
        link: SOCIAL_LINKS.email
    },
];

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer">
            <div className="container">
                <div className="logo top text-center">
                    <img src={logo} alt="Planaday logo" />
                </div>
                <div className="row no-gutters footer-content justify-content-between">
                    <a
                        href="https://www.blog.planaday.events/"
                        target="_blank"
                        className="footer-link"
                    >
            Join our community
                    </a>

                    <p>
            © Copyright {currentYear} Planaday. All rights
            Reserved.
                    </p>

                    <div className="footer-social mx-0">
                        {footerIcons.map((item) => (
                            <div className="social" key={item.icon}>
                                <a href={item.link} target="_blank">
                                    <img src={item.icon} alt={item.alt} />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="logo bottom text-center mt-3">
                    <img src={logo} alt="Planaday logo" />
                </div>

                <div className="bottom row footer-content">
                    <div className="col-md-4">
                        <a
                            href="https://community.planaday.events/"
                            target="_blank"
                            className="footer-link"
                        >
              Join our community
                        </a>
                    </div>

                    <div className="col-md-4">
                        <p>
              © Copyright {new Date().getFullYear()} Planaday. All rights
              Reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
