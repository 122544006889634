import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { toast } from "react-toastify";

import Input2 from "@/components/form/Input2";
import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import { validateEmail } from "@/helper";
import useAuth from "@/hooks/useAuth";
import Spinner from "@/components/Spinner";
import PasswordInput2 from "@/components/form/PasswordInput2";
import "@/styles/auth_page.scss";

const formDetails = {
    email: { name: "email", label: "Email address", placeholder: "anemail@domain.com" },
    password: { name: "password", label: "Password", placeholder: "***********************" },
};

export default function CollaboratorLoginPage() {
    const [searchParams] = useSearchParams();
    const invitee_role = searchParams.get("role");
    const inviter_email = searchParams.get("inviter_email");
    const inviter_id = searchParams.get("id");
    const event_id = searchParams.get("event_id");
    const email = searchParams.get("email");

    const [isLoading, setIsLoading] = useState(false);
    const { loading, signInWithUserEmailAndPassword } = useAuth();
    const [form, setForm] = useState({
        email: "",
        password: "",
    });

    function onInputChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    useEffect(() => {
        if (email) {
            setForm({
                ...form,
                email,
            });
        }
    }, [email]);

    async function onSubmitHandler(e: ChangeEvent<HTMLFormElement>) {
        try {
            e.preventDefault();
            const { email, password } = form;
            if (!email) {
                return toast.error("Email address is required.");
            }
            if (!validateEmail(email)) {
                return toast.error("Please enter a valid email address.");
            }
            if (!password) {
                return toast.error("Password is required.");
            }
            if (password.length < 6) {
                return toast.error("Password must be at least 6 characters long.");
            }
            const emailAddress = email.toLowerCase();
            setIsLoading(true);
            await signInWithUserEmailAndPassword(
                emailAddress,
                password,
                { inviter_id, invitee_role, inviter_email, event_id },
                "/dashboard/event-overview",
            );

            return setIsLoading(false);
        } catch (error) {
            console.log("auth_error", error);
            setIsLoading(false);
        }
    }

    return (
        <NewOnboardingLayout className="auth_page" text="Ready to plan the perfect event?">
            <div className="content auth">
                <div className="auth_content">
                    <h4>Hi, Collaborator 🤗</h4>
                    <form onSubmit={onSubmitHandler}>
                        <Input2 input={formDetails.email} type="email" value={form.email} onChange={onInputChangeHandler} />
                        <PasswordInput2 input={formDetails.password} value={form.password} onChange={onInputChangeHandler} />
                        <button type="submit">{loading && isLoading ? <Spinner size={26} /> : "Login"}</button>
                    </form>
                    <p className="privacy_note">
                        By clicking sign up, you agree to our{" "}
                        <Link target="_blank" to="/term-of-service">
                            Terms of Service
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacy-policy" target="_blank">
                            Privacy Policy
                        </Link>
                    </p>
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
