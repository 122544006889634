import PlusIcon from "@/assets/icon/PlusIcon";
import ShieldIcon from "@/assets/icon/ShieldIcon";
import Spinner from "./Spinner";
import "@/styles/default_view.scss";

interface Props {
    title: string;
    description: string;
    buttonText?: string;
    onClickHandler?: () => void;
    isLoading?: boolean;
    icon?: JSX.Element
}

export default function DefaultView({ buttonText, title, icon, description, isLoading, onClickHandler }: Props) {
    return (
        <div className="default_view">
            <div className="icon_wrapper">
                {icon ? icon : <ShieldIcon />}
            </div>
            <div className="text">
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
            {buttonText && (
                <button onClick={onClickHandler}>
                    <PlusIcon fill="#fff" /> {buttonText} {isLoading && <Spinner size={20} className="mx-1" />}
                </button>
            )}
        </div>
    );
}
