/* eslint-disable @typescript-eslint/no-explicit-any */
import { Group, Path } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function StageElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            stroke={item.fill}
            scaleX={3}
            {...item}
            name="floor_plan_element"
            scaleY={3}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Path stroke={item.fill} data="M2 10s3-3 3-8" />
            <Path stroke={item.fill} data="M22 10s-3-3-3-8" />
            <Path stroke={item.fill} data="M10 2c0 4.4-3.6 8-8 8" />
            <Path stroke={item.fill} data="M14 2c0 4.4 3.6 8 8 8" />
            <Path stroke={item.fill} data="M2 10s2 2 2 5" />
            <Path stroke={item.fill} data="M22 10s-2 2-2 5" />
            <Path stroke={item.fill} data="M8 15h8" />
            <Path stroke={item.fill} data="M2 22v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1" />
            <Path stroke={item.fill} data="M14 22v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1" />
        </Group>
    );
}
