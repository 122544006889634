import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import type { budgetType, categoryType } from "@/types";

type useBudgetType = {
    budget: budgetType | null;
    setBudget: (data: budgetType) => void;
    budgetCategoryId: string;
    setBudgetCategoryId: (value: string) => void;
    costItemId: string;
    setCostItemId: (value: string) => void;
    addBudgetCategory: () => void;
    updateBudgetCategory: (data: Partial<categoryType>) => void;
    updateBudgetCategoryName: (categoryId: string, name: string) => void;
    deleteBudgetCategory: (categoryId: string) => void;
    addBudgetCategoryCostItem: (data: categoryType["cost_items"][0], categoryId: string) => void;
    updateBudgetCategoryCostItem: (data: categoryType["cost_items"][0], categoryId: string, costItemId: string) => void;
    deleteBudgetCategoryCostItem: (categoryId: string, costItemId: string) => void;
};

function getBudgetCostItemTotals(budgetCategories: budgetType["categories"]) {
    const costItemTotals = {
        estimate: 0,
        actual_cost: 0,
        paid: 0,
    };

    if (budgetCategories?.length > 0) {
        budgetCategories.forEach((category) => {
            category.cost_items.forEach((item) => {
                costItemTotals.estimate += item.estimate;
                costItemTotals.actual_cost += item.actual;
                costItemTotals.paid += item.paid;
            });
        });
    }
    return costItemTotals;
}

export const useBudget = create<useBudgetType>((set) => ({
    budget: null,
    setBudget: (budgetValue) =>
        set(() => ({
            budget: budgetValue,
        })),
    budgetCategoryId: "",
    setBudgetCategoryId: (value: string) => set(() => ({ budgetCategoryId: value })),
    costItemId: "",
    setCostItemId: (value: string) => set(() => ({ costItemId: value })),
    addBudgetCategory: () =>
        set(({ budget }) => {
            if (budget) {
                const defaultbudgetCategory = {
                    id: uuidv4(),
                    name: "Item",
                    cost_items: [],
                    actual_total: 0,
                    estimate_total: 0,
                    paid_total: 0,
                };
                return {
                    budget: {
                        ...budget,
                        categories: budget?.categories ? [...budget.categories, defaultbudgetCategory] : [defaultbudgetCategory],
                    },
                };
            } else {
                return { budget: null };
            }
        }),
    updateBudgetCategory: (budgetCategory: Partial<categoryType>) =>
        set(({ budget }) => {
            if (budget) {
                const selectedBudgetCategoryIndex = budget.categories.findIndex((item) => item.id === budgetCategory.id);
                budget.categories[selectedBudgetCategoryIndex] = {
                    ...budget.categories[selectedBudgetCategoryIndex],
                    ...budgetCategory,
                };
                const categoryTotals = getBudgetCostItemTotals(budget.categories);
                return {
                    budget: {
                        ...budget,
                        categories: budget.categories,
                        ...categoryTotals,
                    },
                };
            } else {
                return { budget };
            }
        }),
    updateBudgetCategoryName: (budgetCategoryId: string, name: string) =>
        set(({ budget }) => {
            if (budget) {
                const selectedBudgetCategoryIndex = budget.categories.findIndex((item) => item.id === budgetCategoryId);
                budget.categories[selectedBudgetCategoryIndex] = {
                    ...budget.categories[selectedBudgetCategoryIndex],
                    name,
                };
                return {
                    budget: {
                        ...budget,
                        categories: budget.categories,
                    },
                };
            } else {
                return { budget };
            }
        }),
    deleteBudgetCategory: (budgetCategoryId: string) =>
        set(({ budget }) => {
            if (budget) {
                const categoryTotals = getBudgetCostItemTotals(budget.categories);

                return {
                    budget: {
                        ...budget,
                        categories: budget.categories.filter((item) => item.id !== budgetCategoryId),
                        ...categoryTotals,
                    },
                };
            } else {
                return { budget };
            }
        }),
    addBudgetCategoryCostItem: (costItem: categoryType["cost_items"][0], categoryId: string) =>
        set(({ budget }) => {
            if (budget) {
                const selectedBudgetCategoryIndex = budget.categories.findIndex((item) => item.id === categoryId);
                budget.categories[selectedBudgetCategoryIndex] = {
                    ...budget.categories[selectedBudgetCategoryIndex],
                    cost_items: budget.categories[selectedBudgetCategoryIndex].cost_items
                        ? [...budget.categories[selectedBudgetCategoryIndex].cost_items, costItem]
                        : [costItem],
                };
                const categoryTotals = getBudgetCostItemTotals(budget.categories);

                return {
                    budget: {
                        ...budget,
                        categories: budget.categories,
                        ...categoryTotals,
                    },
                };
            } else {
                return { budget };
            }
        }),
    updateBudgetCategoryCostItem: (costItem: categoryType["cost_items"][0], categoryId: string, costItemId: string) =>
        set(({ budget }) => {
            if (budget) {
                const selectedBudgetCategoryIndex = budget.categories.findIndex((item) => item.id === categoryId);

                if (selectedBudgetCategoryIndex !== -1) {
                    const selectedCostItemIndex = budget.categories[selectedBudgetCategoryIndex].cost_items.findIndex(
                        (item) => item.id === costItemId,
                    );
                    if (selectedCostItemIndex !== -1) {
                        budget.categories[selectedBudgetCategoryIndex].cost_items[selectedCostItemIndex] = {
                            ...budget.categories[selectedBudgetCategoryIndex].cost_items[selectedCostItemIndex],
                            ...costItem,
                        };
                    }
                }
                const categoryTotals = getBudgetCostItemTotals(budget.categories);
                return {
                    budget: {
                        ...budget,
                        categories: budget.categories,
                        ...categoryTotals,
                    },
                };
            } else {
                return { budget };
            }
        }),
    deleteBudgetCategoryCostItem: (categoryId: string, costItemId: string) =>
        set(({ budget }) => {
            if (budget) {
                const selectedBudgetCategoryIndex = budget.categories.findIndex((item) => item.id === categoryId);
                if (selectedBudgetCategoryIndex !== -1) {
                    const remainingCostItem = budget.categories[selectedBudgetCategoryIndex].cost_items.filter((item) => item.id !== costItemId);

                    if (remainingCostItem) {
                        budget.categories[selectedBudgetCategoryIndex] = {
                            ...budget.categories[selectedBudgetCategoryIndex],
                            cost_items: remainingCostItem,
                        };
                    }
                }
                const categoryTotals = getBudgetCostItemTotals(budget.categories);

                return {
                    budget: {
                        ...budget,
                        categories: budget.categories,
                        ...categoryTotals,
                    },
                };
            } else {
                return { budget };
            }
        }),
}));
