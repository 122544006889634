import { v4 as uuidv4 } from "uuid";
import { formatBudgetCategoryId } from "@/helper";

import type { categoryType, costItemType, onboardingBudgetEstimateType } from "@/types";

export default function formatOnboardingBudgetEstimate(onboardingBudgetEstimate: onboardingBudgetEstimateType | null, ceremony: string) {
    const onboardingBudgetEstimateCategories: string[] = [];
    const onboardingBudgetEstimateCostItem: costItemType[] = [];

    const onboardingCategory = `cost_${onboardingBudgetEstimate?.budget_category}` as "cost_standard" | "cost_mid_luxury" | "cost_luxurious";

    if (onboardingBudgetEstimate) {
        onboardingBudgetEstimate.breakdown.map((item) => {
            if (!onboardingBudgetEstimateCategories.includes(item.category)) {
                onboardingBudgetEstimateCategories.push(item.category);
            }
            const formattedCategoryItem = {
                vendor_ig: "",
                actual: 0,
                note: "",
                balance: 0,
                name: item.item,
                estimate: Math.round(item[onboardingCategory]),
                paid: 0,
                vendor_name: "",
                id: `${item.category}__${uuidv4()}`,
                balance_due: null,
                tag: ceremony,
                paid_by_me: "",
            };
            onboardingBudgetEstimateCostItem.push(formattedCategoryItem);
        });
    }

    let onboardingBudgetEstimateData: categoryType[] = [];

    if (onboardingBudgetEstimateCategories.length > 0 && onboardingBudgetEstimate) {
        onboardingBudgetEstimateCategories.map((item) => {
            const categoryEstimate = onboardingBudgetEstimateCostItem.filter((categoryItem) => {
                const categoryItemId = categoryItem.id.split("__")[0];
                return categoryItemId === item;
            });
            const estimate_total: number = categoryEstimate.reduce((a, b) => a + b.estimate, 0);

            onboardingBudgetEstimateData = [
                ...onboardingBudgetEstimateData,
                {
                    id: uuidv4(),
                    name: formatBudgetCategoryId(item),
                    paid_total: 0,
                    actual_total: 0,
                    estimate_total,
                    cost_items: categoryEstimate,
                },
            ];
        });
    }

    return onboardingBudgetEstimateData;
}

export function onboardingBudgetCategories(onboardinBudgetCostItems: categoryType[]) {
    let _onboardingBudgetCategories;

    const actual_cost = onboardinBudgetCostItems.reduce((a: number, b: categoryType) => a + b.actual_total, 0);
    const estimate = onboardinBudgetCostItems.reduce((a: number, b: categoryType) => a + b.estimate_total, 0);

    const paid = onboardinBudgetCostItems.reduce((a: number, b: categoryType) => a + b.paid_total, 0);

    let categories: categoryType[] = [];
    onboardinBudgetCostItems.map((item) => {
        categories = [...categories, item];
        _onboardingBudgetCategories = {
            actual_cost,
            estimate,
            paid,
            categories,
        };
    });

    return _onboardingBudgetCategories;
}
