/* eslint-disable @typescript-eslint/no-explicit-any */
import { Rect } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onDragStart: (value: itemType) => void;
	onChange: (value: itemType) => void;
	onTransformEndHandler: (resetScale: boolean) => void;
	onSelect: () => void;
}

export default function RoundtableElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Rect
            onClick={onSelect}
            ref={refValue}
            {...item}
            name="floor_plan_element"
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler(true)}
            cornerRadius={500}
        />
    );
}
