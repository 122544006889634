import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";

interface Props {
    title: string;
    info: string;
    amount: string;
}

type textWithInfoType = {
    info: string;
    text: string;
    placement?: Placement;
};

export function TextWithInfo({ text, info, placement = "right" }: textWithInfoType) {
    return (
        <div className="text_with_info">
            {text}
            <OverlayTrigger placement={placement} overlay={<Tooltip id="budget">{info}</Tooltip>}>
                <div className="question">
                    <span>?</span>
                </div>
            </OverlayTrigger>
        </div>
    );
}

export default function BudgetAmountInfo({ title, info, amount }: Props) {
    return (
        <div className="align-items-start budget_amount_info_group my-0 py-0  d-flex flex-column">
            <div className="title p-0 m-0 budget_amount_info">
                <Form.Label>
                    <span>{title}</span>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="budget">{info}</Tooltip>}>
                        <div className="question">
                            <span>?</span>
                        </div>
                    </OverlayTrigger>
                </Form.Label>
            </div>
            <p className="amount py-0 my-0">{amount}</p>
        </div>
    );
}
