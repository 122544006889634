import ContentLoader from "react-content-loader";

export function EventsLineLoader() {
    return (
        <ContentLoader
            className="mt-3"
            title="Fetching events..."
            // viewBox="0 0 100% 100%"
            height="70px"
            backgroundColor="#cbbec0"
            foregroundColor="#dedede">
            <rect x="0" y="0" rx="10" ry="10" width="25%" height="20px" />
            <rect x="0" y="25" rx="10" ry="10" width="45%" height="20px" />
            <rect x="0" y="50" rx="10" ry="10" width="60%" height="20px" />
        </ContentLoader>
    );
}

export default function EventsLoader() {
    const threeDummyEvent = Array(3).fill(0);
    return (
        <div className="events_loader">
            {threeDummyEvent.map((_, index) => (
                <ContentLoader
                    key={index}
                    title="Fetching events..."
                    // viewBox="0 0 100% 100%"
                    backgroundColor="#cbbec0"
                    foregroundColor="#dedede">
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
            ))}
        </div>
    );
}
