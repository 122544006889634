import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "@tanstack/react-query";

import SuggestedChecklistItem from "@/components/SuggestedChecklistItem";
import suggestedChecklistContent from "@/json/suggested_checklist.json";
import cancelIcon from "@/assets/img/cancel_icon.svg";
import { useFilter } from "@/hooks/useFilter";
import { useChecklist } from "@/hooks/useChecklist";
import { addChecklist, addMultipleEventChecklist, updateAudit } from "@/axios/post-request";
import useToast from "@/hooks/useToast";
import { useSuggestedChecklist } from "@/hooks/useSuggestedChecklist";
import { updateCeremony } from "@/axios/put-request";
import type { auditType, TodoType } from "@/types";
import useUser from "@/hooks/useUser";

interface Props {
	ceremonyId: string;
	eventId: string;
}

export default function SuggestedChecklist({ eventId, ceremonyId }: Props) {
    const { ceremony } = useFilter();
    const { data, selectedEventId } = useUser();
    const queryClient = useQueryClient();
    const { loadingToast, updateToast } = useToast();
    const { todo, setTodo } = useChecklist();
    const { suggestedChecklist, setSuggestedChecklist } = useSuggestedChecklist();
    const toastId = useRef("add_multiple_checklists");
    const [selectedChecklist, setSelectedChecklist] = useState("");
    const [allChecklistSuggestions, setAllChecklistSuggestions] = useState<string[]>([]);

    let allChecklistSuggestionsArray: string[] = [];

    useEffect(() => {
        const result = suggestedChecklistContent.filter((item) => {
            if (ceremony?.name) {
                const ceremonyName = ceremony.name.toLowerCase();
                const event =
					ceremonyName.includes("wedding") || ceremonyName.includes("ceremony") ? ceremonyName.split(" ")[0].trim() : ceremonyName;
                return item.tags.includes(event);
            }
        });
        setSuggestedChecklist(result);
    }, [ceremony.name]);

    /**
	 * If suggested checklist is available
	 * check that the suggested checklist does not exist in the current todo
	 * if it does not exist, suggest the checklist
	 * if it exist, remove the suggestion
	 */
    useEffect(() => {
        if (suggestedChecklist.length > 0 && suggestedChecklist[0]?.suggestedChecklist.length > 0) {
            suggestedChecklist[0]?.suggestedChecklist.map((item) => {
                const existingTodo = todo.filter((todoItem) => todoItem.name === item);
                if (item === existingTodo[0]?.name && existingTodo.length > 0) {
                    const suggestionIndex = allChecklistSuggestionsArray.findIndex((sugg) => sugg === item);
                    if (suggestionIndex >= 0) {
                        allChecklistSuggestionsArray.splice(suggestionIndex, 1);
                    }
                    setAllChecklistSuggestions(allChecklistSuggestionsArray);
                } else {
                    allChecklistSuggestionsArray.push(item);
                    allChecklistSuggestionsArray = [...suggestedChecklist[0].suggestedChecklist];
                    setAllChecklistSuggestions(suggestedChecklist[0].suggestedChecklist);
                }
            });
        }
    }, [todo.length]);

    async function onCancelAllSuggestionHandler() {
        await updateCeremony({ show_suggestion: false }, ceremonyId, eventId).then(() => {
            queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
        });
    }

    // updateToast(toastId, "success", response.data.message);
    async function addChecklistDetailsToAudit(allChecklist: TodoType) {
        setTodo([...todo, ...allChecklist]);
        setSuggestedChecklist([
            {
                tags: suggestedChecklist[0].tags,
                suggestedChecklist: [],
            },
        ]);
        updateCeremony({ show_suggestion: false }, ceremonyId, eventId);
        const multipleAuditArray = [] as auditType;
        allChecklist.map((checklistItem) =>
            multipleAuditArray.push({
                id: uuidv4(),
                name: data?.result?.name as string,
                user_id: data?.result?.id as string,
                email: data?.result?.email as string,
                type: "checklist",
                action: "add_checklist",
                title: checklistItem.name,
                changes: {
                    to: {
                        id: uuidv4(),
                        name: checklistItem.name,
                        note: "",
                        due: null,
                        ceremony: ceremony,
                        isDone: false,
                    },
                },
                metadata: {
                    ceremony: ceremony.name,
                    type: "suggested",
                },
                timestamp: new Date(),
            }),
        );
        await updateAudit(multipleAuditArray, selectedEventId);
        queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
    }

    async function addAllChecklistItemHandler() {
        try {
            let allChecklist: TodoType = [];
            if (ceremony && suggestedChecklist.length > 0) {
                loadingToast(toastId);
                allChecklistSuggestions.map((item) => {
                    allChecklist = [
                        ...allChecklist,
                        {
                            id: uuidv4(),
                            name: item,
                            note: "",
                            due: null,
                            ceremony,
                            isDone: false,
                        },
                    ];
                });
                const addMultipleChecklistRequest = await addMultipleEventChecklist(
                    {
                        tag: ceremony,
                        checklists: allChecklist,
                    },
                    selectedEventId,
                );
                setAllChecklistSuggestions([]);
                if (addMultipleChecklistRequest.status === 200) {
                    updateToast(toastId, "success", addMultipleChecklistRequest.data.message);
                    addChecklistDetailsToAudit(allChecklist);
                }
            }
        } catch (error: unknown) {
            const _error = error as { response: { message: string } };
            updateToast(toastId, "error", _error?.response?.message || "oops an error occured");
        }
    }

    async function addAChecklist(name: string) {
        if (ceremony) {
            const checklist = {
                id: uuidv4(),
                name: name,
                note: "",
                due: null,
                ceremony,
                isDone: false,
            };
            loadingToast(toastId);
            setSelectedChecklist(name);
            await addChecklist(checklist, selectedEventId, ceremony.id)
                .then((response) => {
                    setTodo([...todo, checklist]);
                    const otherChecklist = suggestedChecklist[0]?.suggestedChecklist.filter((checklist) => checklist !== name);
                    setSuggestedChecklist([
                        {
                            tags: suggestedChecklist[0].tags,
                            suggestedChecklist: otherChecklist,
                        },
                    ]);
                    updateAudit(
                        [
                            {
                                id: uuidv4(),
                                name: data?.result?.name as string,
                                user_id: data?.result?.id as string,
                                email: data?.result?.email as string,
                                type: "checklist",
                                action: "add_checklist",
                                title: name,
                                changes: {
                                    to: {
                                        id: uuidv4(),
                                        name,
                                        note: "",
                                        due: null,
                                        ceremony: ceremony,
                                        isDone: false,
                                    },
                                },
                                metadata: {
                                    ceremony: ceremony.name,
                                    type: "suggested",
                                },
                                timestamp: new Date(),
                            },
                        ],
                        selectedEventId,
                    );
                    updateToast(toastId, "success", response.data.message);
                    queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
                })
                .catch((error) => {
                    updateToast(toastId, "error", error?.response?.message || "unable to add checklist");
                });
        }
    }

    const suggestedChecklistWrapperClassname = allChecklistSuggestions.length > 0 ? "suggested_checklist" : "";

    return (
        <>
            {suggestedChecklist.length > 0 && suggestedChecklist[0]?.suggestedChecklist.length > 0 && (
                <div className={suggestedChecklistWrapperClassname}>
                    {allChecklistSuggestions.length > 1 && (
                        <div className="title">
                            <h6>
								Suggested checklist items:
                                <span>{ceremony?.name}</span>
                            </h6>
                            <div>
                                {ceremony.id !== "all" && <button onClick={addAllChecklistItemHandler}>Add All</button>}
                                <button onClick={onCancelAllSuggestionHandler}>
                                    <img src={cancelIcon} alt="Cancel All" />
                                </button>
                            </div>
                        </div>
                    )}
                    {allChecklistSuggestions.map((item, index) => {
                        const isSuggestionATodo = todo.filter((todoItem) => todoItem.name === item);
                        return (
                            isSuggestionATodo.length === 0 && (
                                <SuggestedChecklistItem key={index} name={item} selectedChecklist={selectedChecklist} addChecklist={addAChecklist} />
                            )
                        );
                    })}
                </div>
            )}
        </>
    );
}
