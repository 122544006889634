/* eslint-disable max-len */

export default function TableOfTenIcon() {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.99993 5.04286C8.63308 5.04286 9.95701 6.36685 9.95701 8C9.95701 9.63315 8.63308 10.9571 6.99993 10.9571C5.36679 10.9571 4.04286 9.63315 4.04286 8C4.04286 6.36685 5.36679 5.04286 6.99993 5.04286Z"
                fill="#D9D9D9"
                stroke="#404040"
                strokeWidth="0.0857133"
            />
            <path
                d="M9.4535 3.60231L10.4243 4.30764C10.603 4.43749 10.8532 4.39787 10.983 4.21915L11.6884 3.24828C11.8183 3.06955 11.7786 2.8194 11.5999 2.68955L10.6291 1.98421C10.4504 1.85436 10.2002 1.89398 10.0704 2.07271L9.365 3.04357C9.23515 3.2223 9.27477 3.47245 9.4535 3.60231Z"
                fill="#D9D9D9"
            />
            <path
                d="M2.40027 13.3103L3.37108 14.0156C3.54981 14.1455 3.79996 14.1059 3.92981 13.9272L4.63519 12.9563C4.76504 12.7776 4.72542 12.5274 4.54669 12.3976L3.57589 11.6922C3.39716 11.5624 3.14701 11.602 3.01716 11.7807L2.31178 12.7516C2.18193 12.9303 2.22155 13.1805 2.40027 13.3103Z"
                fill="#D9D9D9"
            />
            <path
                d="M7.77358 12.9754L6.5743 13.0173C6.35352 13.025 6.18078 13.2102 6.18849 13.431L6.23033 14.6303C6.23803 14.8511 6.42326 15.0238 6.64404 15.0161L7.84331 14.9742C8.0641 14.9665 8.23683 14.7813 8.22913 14.5605L8.18729 13.3612C8.17958 13.1404 7.99436 12.9677 7.77358 12.9754Z"
                fill="#D9D9D9"
            />
            <path
                d="M7.35536 0.983198L6.15609 1.02508C5.93531 1.03279 5.76257 1.21802 5.77027 1.4388L5.81212 2.63811C5.81982 2.8589 6.00505 3.03163 6.22583 3.02392L7.4251 2.98204C7.64588 2.97433 7.81862 2.7891 7.81092 2.56831L7.76907 1.369C7.76137 1.14822 7.57615 0.975488 7.35536 0.983198Z"
                fill="#D9D9D9"
            />
            <path
                d="M10.5515 11.5705L9.60584 12.3093C9.43176 12.4453 9.40089 12.6967 9.53689 12.8708L10.2757 13.8164C10.4117 13.9905 10.663 14.0214 10.8371 13.8854L11.7828 13.1466C11.9569 13.0106 11.9877 12.7592 11.8517 12.5851L11.113 11.6394C10.977 11.4653 10.7256 11.4345 10.5515 11.5705Z"
                fill="#D9D9D9"
            />
            <path
                d="M3.16355 2.11394L2.21791 2.85276C2.04382 2.98877 2.01295 3.24016 2.14895 3.41425L2.88772 4.35989C3.02373 4.53398 3.2751 4.56485 3.44919 4.42884L4.39483 3.69002C4.56892 3.55401 4.59979 3.30262 4.46379 3.12853L3.72502 2.18289C3.58901 2.0088 3.33764 1.97793 3.16355 2.11394Z"
                fill="#D9D9D9"
            />
            <path
                d="M11.9406 8.97467L11.5698 10.116C11.5015 10.3261 11.6165 10.5518 11.8266 10.62L12.9678 10.9908C13.1779 11.0591 13.4036 10.9441 13.4719 10.734L13.8427 9.5927C13.911 9.3826 13.796 9.15693 13.5859 9.08866L12.4446 8.71785C12.2345 8.64958 12.0089 8.76456 11.9406 8.97467Z"
                fill="#D9D9D9"
            />
            <path
                d="M0.528002 5.26666L0.157164 6.40798C0.0888966 6.61809 0.203879 6.84375 0.413984 6.91202L1.55524 7.28284C1.76534 7.3511 1.99101 7.23612 2.05928 7.02602L2.43012 5.88469C2.49838 5.67459 2.3834 5.44892 2.17329 5.38066L1.03204 5.00984C0.821935 4.94157 0.59627 5.05655 0.528002 5.26666Z"
                fill="#D9D9D9"
            />
            <path
                d="M11.5697 5.88385L11.9405 7.02515C12.0088 7.23526 12.2344 7.35024 12.4445 7.28196L13.5858 6.9111C13.7959 6.84283 13.9109 6.61716 13.8426 6.40705L13.4718 5.26575C13.4035 5.05564 13.1778 4.94067 12.9677 5.00894L11.8265 5.3798C11.6164 5.44807 11.5014 5.67374 11.5697 5.88385Z"
                fill="#D9D9D9"
            />
            <path
                d="M0.157056 9.59283L0.527889 10.7341C0.596156 10.9442 0.82182 11.0592 1.03192 10.9909L2.17318 10.6201C2.38329 10.5518 2.49827 10.3261 2.43 10.116L2.05917 8.97473C1.9909 8.76463 1.76524 8.64965 1.55513 8.71792L0.413873 9.08878C0.20377 9.15706 0.088789 9.38273 0.157056 9.59283Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
