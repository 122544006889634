import { Suspense, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import DeleteModal from "@/components/modals/DeleteModal";
import useToast from "@/hooks/useToast";
import { deleteCostItem } from "@/axios/delete-request";
import { useBudgetTracker } from "@/hooks/useBudgetTracker";
import { useModal } from "@/hooks/useModal";
import { useEvent } from "@/hooks/useEvent";
import { updateAudit } from "@/axios/post-request";
import useUser from "@/hooks/useUser";

interface Props {
	deleteCategoryCostItem: (id: string) => void;
	categoryName: string;
}

export default function DeleteBudgetCostItemModal({ categoryName, deleteCategoryCostItem }: Props) {
    const queryClient = useQueryClient();
    const { loadingToast, updateToast } = useToast();
    const toastId = useRef("deleteCostItem");
    const { modal, setModal } = useModal();
    const { data } = useUser();
    const { selectedEventId } = useEvent();
    const { selectedCostItem, setSelectedCostItem } = useBudgetTracker();

    function closeDeleteModal() {
        setModal(null);
        setSelectedCostItem(null);
    }

    async function deleteCostItemHandler() {
        return await deleteCostItem(selectedCostItem?.id as string, selectedCostItem?.categoryId as string, selectedEventId);
    }

    const { mutate, isLoading } = useMutation({
        mutationKey: ["deleteCostItem"],
        mutationFn: () => deleteCostItemHandler(),
        onMutate: () => loadingToast(toastId),
        onSuccess: ({ data: resultData }) => {
            if (selectedCostItem) {
                deleteCategoryCostItem(selectedCostItem?.id);
                updateAudit([{
                    id: uuidv4(),
                    name: data?.result?.name as string,
                    user_id: data?.result?.id as string,
                    email: data?.result?.email as string,
                    type: "budget_tracker",
                    action: "delete_budget_cost_item",
                    title: selectedCostItem.name,
                    timestamp: new Date(),
                    metadata: {
                        category: categoryName
                    }
                }], selectedEventId);
                updateToast(toastId, "success", resultData?.message);
                queryClient.resetQueries({ queryKey: [`get_categories_${selectedEventId}`] });
                return closeDeleteModal();
            }
        },
        onError: (error: unknown) => {
            const _error = error as { message: string };
            const _errorMessage = _error?.message || "oops an error occured, unable to delete cost item";
            updateToast(toastId, "error", _errorMessage);
        },
    });

    function onDeleteHandler() {
        if (selectedCostItem) {
            mutate();
        }
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {selectedCostItem && (
                <DeleteModal
                    show={modal === "delete_modal"}
                    isLoading={isLoading}
                    onHide={closeDeleteModal}
                    title={selectedCostItem?.name}
                    onDelete={onDeleteHandler}
                />
            )}
        </Suspense>
    );
}
