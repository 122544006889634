import { useEvent } from "@/hooks/useEvent";
import NewDashboardLayout from "@/layout/NewDashboardLayout";
import DefaultView from "@/components/DefaultView";
import ViewActivityLog from "@/components/ViewActivityLog";
import "@/styles/audits.scss";

export default function ActivityLog() {
    const { selectedEventId } = useEvent();

    return (
        <NewDashboardLayout title="Activity log" description="View activity logs across checklist, budget tracker and floor-plan">
            {selectedEventId ? <ViewActivityLog /> : <DefaultView title="No Activity Log" description="Start planning to begin tracking activity." />}
        </NewDashboardLayout>
    );
}
