import ReactConfetti from "react-confetti";
import { Link, useSearchParams } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";
import { useEffect, useState } from "react";

import AsoebiLayout from "@/layout/AsoebiLayout";

export default function QuestionnaireCompleted() {
    const { width, height } = useWindowSize();
    const [showConfetti, setShowConfetti] = useState(true);
    const [searchParams] = useSearchParams();
    const link = searchParams.get("link");

    function stopConfetti() {
        setTimeout(() => setShowConfetti(false), 9000);
    }

    useEffect(() => {
        if (showConfetti) {
            stopConfetti();
        }
    }, []);

    return (
        <AsoebiLayout>
            { showConfetti &&<ReactConfetti
                width={width}
                height={height}

            />}
            <section className="questionnaire__completed mx-auto vh-55 d-flex flex-column my-5">
                <div className="text-center">
                    <h1 className="text-center mb-4">Congrats 🎉</h1>
                    <h4>Asoebi Order Form Created</h4>
                    <p>Proceed to share the link to your event guest</p>
                    {link && <a href={link} className="font-weight-bold my-4 justify-content-center d-flex mx-auto">Asoebi Order Form Link</a>}
                    <p>Link also sent to your 📧 email</p>
                </div>
                <p>
                    <Link
                        to="/asoebi-tracker"
                        className="font-weight-bold d-flex mx-auto justify-content-center">← Create new asoebi order form</Link>
                </p>
            </section>
        </AsoebiLayout>
    );
}
