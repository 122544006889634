import { format } from "date-fns";
import { ChangeEvent, useEffect, useState, Fragment } from "react";
import FuzzySearch from "fuzzy-search";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";

import ArrowleftIcon from "@/assets/icon/ArrowleftIcon";
import ArrowRightIcon from "@/assets/icon/ArrowRightIcon";
import Select from "@/components/form/Select";
import Search from "@/assets/icon/Search";
import CancelIcon from "@/assets/icon/Cancel";
import Loader from "@/components/loader";
import { type AsoebiOrderType, Asoebi_order_status } from "@/types";
import SiteDropdown from "@/components/Dropdown";
import DownArrow from "@/assets/icon/DownArrow";

const selectLimitDetails = {
    label: "",
    name: "events",
    options: [5, 10, 20, 30, 50],
};

const dropdownOptions = [
    { text: "Shipped", value: "SHIPPED" },
    { text: "Delivered", value: "DELIVERED" },
    { text: "Pending", value: "PENDING" },
] as Array<{ text: string; value: keyof typeof Asoebi_order_status }>;

const filterInput = { name: "selectStatus", label: "Filter By Status" };
type filterOptionsType = "All" | keyof typeof Asoebi_order_status | "Pending";
const filterOptions = [
    { text: "All", value: "All" },
    { text: "Shipped", value: "SHIPPED" },
    { text: "Delivered", value: "DELIVERED" },
    { text: "Pending", value: "PENDING" },
];
type orderStatusType = keyof typeof Asoebi_order_status | null;

interface Props {
	updateOrderStatusHandler: (value: orderStatusType, event_name: string, id: string) => void;
	orders: AsoebiOrderType[];
}
export default function AsoebiOrdersTable({updateOrderStatusHandler, orders }:Props) {
    const [limit, setLimit] = useState(5);
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [pageCounter, setPageCounter] = useState(1);
    const [activeCollapsedTableIndex, setActiveCollapsedTableIndex] = useState<null | number>(null);
    const [searchOrders, setSearchOrders] = useState<AsoebiOrderType[]>([]);
    const [filter, setFilter] = useState<filterOptionsType>("All");


    useEffect(() => {
        if (searchText) {
            setIsSearching(true);
            const searcher = new FuzzySearch(searchOrders, ["event_name", "name", "email"]);
            const _searchText = searchText.replace(/\W+/g, "");
            const result = searcher.search(_searchText);
            setSearchOrders(result);
            if (result) {
                setIsSearching(false);
            }
        } else {
            setSearchOrders(orders);
        }
    }, [searchText.length]);

    const ordersInDollar = [];

    const currency = ordersInDollar.length > 0 ? "$" : "₦";
    const amountTableText = currency === "$" ? "Amount" : "Amount paid";
    const pageMin = pageCounter === 1 ? 0 : limit * (pageCounter - 1);
    const pageMax = pageCounter === 1 ? limit * pageCounter : limit * pageCounter;
    const filterOrderArray =
            filter !== "All" ?
                searchOrders.filter((item) => {
                    const orderStatus = item.status ? item.status : "PENDING";
                    return orderStatus === filter;
                }) :
                searchOrders;
    const paginatedDetails = filterOrderArray.slice(pageMin, pageMax);
    const showingDataType = filter === "All" ? searchOrders : filterOrderArray;
    const showingOrderCounter = limit * pageCounter < showingDataType.length ? limit * pageCounter : showingDataType.length;
    const totalOrders = filterOrderArray.length;
    const disableLeftArrowBtn = (totalOrders && totalOrders <= limit) || pageCounter === 1 ? true : false;
    const leftArrowColor = disableLeftArrowBtn ? "gray" : "black";
    const disableRightArrowBtn = totalOrders && totalOrders < (limit * pageCounter) ? true : false;
    const rightArrowColor = disableRightArrowBtn ? "gray" : "black";

    function onSelectEventLimit(e: ChangeEvent<HTMLSelectElement>) {
        setLimit(Number(e.target.value));
    }

    function incrementPageCounter() {
        setPageCounter((prev) => prev + 1);
    }

    function decrementPageCounter() {
        if (pageCounter > 1) {
            setPageCounter((prev) => prev - 1);
        }
    }

    function cancelSearchHandler() {
        setSearchText("");
        setIsSearching(false);
        setSearchOrders([]);
    }

    function setSearchHandler(e: ChangeEvent<HTMLInputElement>) {
        setSearchText(e.target.value);
    }

    function filterOrders(event: ChangeEvent<HTMLSelectElement>) {
        const eventValue = event.target.value as filterOptionsType;
        setFilter(eventValue);
    }

    function showTableDetails(tableIndex: number) {
        const tableIndexValue = tableIndex === activeCollapsedTableIndex ? null : tableIndex;
        setActiveCollapsedTableIndex(tableIndexValue);
    }

    function copyToClipboardHandler(textToCopy: string, name: string) {
        navigator.clipboard.writeText(textToCopy);
        toast.success(`${name} copied!`);
    }

    return (
        <>
            <div className="bg-white px-4 py-3 mt-5 shadow-sm rounded">
                <h4 className="text-dark">Orders</h4>

                {paginatedDetails.length > 0 &&
                <div className="d-flex flex-lg-row flex-column w-full align-items-lg-center mb-3 justify-content-lg-between">
                    <div className="filter_by_status px-0 mb-4 mb-lg-0 col-4">
                        <Select input={filterInput} value={filter} className="filter_audit_select w-full" onChange={filterOrders}>
                            {filterOptions.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.text}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className="search">
                        <div id="search_audits" className="p-0 search_audits position-relative search_form mr-0 mb-2 mb-lg-0">
                            <input placeholder="Search Event" className="form-control" value={searchText} onChange={setSearchHandler} />
                            {!isSearching && (
                                <div className="search_cta">
                                    {!searchText ? (
                                        <button className="position-absolute border-0 bg-transparent" type="button">
                                            <Search fill="#545565" />
                                        </button>
                                    ) : (
                                        <button className="position-absolute border-0 bg-transparent" type="button" onClick={cancelSearchHandler}>
                                            <CancelIcon />
                                        </button>
                                    )}
                                </div>
                            )}
                            {isSearching && (
                                <span className="position-absolute search_spinner">
                                    <Loader mode="dark" />
                                </span>
                            )}
                        </div>
                    </div>
                </div>}
                {paginatedDetails.length === 0 ? (
                    <h5 className="text-center font-weight-bold mt-5 mb-4">You don't have any order yet</h5>
                ) : (
                    <table className="table table-sm table-responsive">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Event name</th>
                                <th>{amountTableText}</th>
                                <th>Created</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedDetails?.map((order, index) => {
                                const createdAt = format(new Date(order.created_at), "dd/MM/yyyy");
                                const orderDeadlineDate = order?.order_deadline ? format(new Date(order.order_deadline), "dd/MM/yyyy") : "N/A";
                                const amountPaid = order?.amount_paid ? order?.amount_paid : "N/A";
                                const orderText =
                                    order.status === Asoebi_order_status.DELIVERED ?
                                        "Delivered" :
                                        order.status === Asoebi_order_status.PENDING ?
                                            "Pending" :
                                            order.status === Asoebi_order_status.SHIPPED ?
                                                "Shipped" :
                                                "Pending";

                                const tableBtnClassname = activeCollapsedTableIndex === index ? "active_table_row" : "";

                                return (
                                    <Fragment key={order.id}>
                                        <tr key={order.id}>
                                            <td className="text-truncate">
                                                <button className={`btn p-0 ${tableBtnClassname}`} onClick={() => showTableDetails(index)}>
                                                    {order.name}
                                                    <DownArrow />
                                                </button>
                                            </td>
                                            <td className="text-truncate">{order.email}</td>
                                            <td className="text-truncate ">{order.event_name}</td>
                                            <td>{amountPaid}</td>
                                            <td>{createdAt}</td>
                                            <td>
                                                <SiteDropdown
                                                    id="status_dropdown"
                                                    title={orderText}
                                                    variant="clear"
                                                    arrow_fill="black"
                                                    className="d-flex">
                                                    {dropdownOptions.map((item) => {
                                                        return (
                                                            item.value !== order.status && (
                                                                <Dropdown.Item
                                                                    key={item.text}
                                                                    onClick={() => updateOrderStatusHandler(item.value, order.event_name, order.id)}>
                                                                    {item.text}
                                                                </Dropdown.Item>
                                                            )
                                                        );
                                                    })}
                                                </SiteDropdown>
                                            </td>
                                        </tr>
                                        {activeCollapsedTableIndex === index && (
                                            <tr>
                                                <td className="w-full min-w-full table_details" colSpan={7}>
                                                    <div className="table_list_details">
                                                        <div>
                                                            <div className="w-fit" onClick={() => copyToClipboardHandler(order.name, "Name")}>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="asoebi_tooltip">click to copy name</Tooltip>}>
                                                                    <div>
                                                                        <strong>Name: </strong>
                                                                        {order.name}
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="w-fit">
                                                                <strong>Email: </strong>
                                                                {order.email}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {order?.phone_number ? (
                                                                <div
                                                                    className="w-fit"
                                                                    onClick={() => copyToClipboardHandler(order.phone_number, "Phone number")}>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id="asoebi_tooltip">click to copy phone number</Tooltip>}>
                                                                        <div>
                                                                            <strong>Phone number: </strong>
                                                                            {order?.phone_number ?? "N/A"}
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <strong>Phone number: </strong>
                                                                    {order?.phone_number ?? "N/A"}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <div className="w-fit">
                                                                <strong>{amountTableText}: </strong>
                                                                {order.amount_paid}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {order?.delivery_address ? (
                                                                <div
                                                                    className="w-fit"
                                                                    onClick={() =>
                                                                        copyToClipboardHandler(order?.delivery_address as string, "Address")
                                                                    }>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="asoebi_tooltip">click to copy delivery address</Tooltip>
                                                                        }>
                                                                        <div>
                                                                            <strong>Delivery address: </strong>
                                                                            {order?.delivery_address ?? "N/A"}
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <strong>Delivery address: </strong>
                                                                    {order?.delivery_address ?? "N/A"}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {order?.special_note ? (
                                                                <div
                                                                    onClick={() => copyToClipboardHandler(order.special_note, "Special note")}
                                                                    className="w-fit">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id="asoebi_tooltip">click to copy special note</Tooltip>}>
                                                                        <div>
                                                                            <strong>Special note: </strong>
                                                                            {order?.special_note ?? "N/A"}
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <strong>Special note: </strong>
                                                                    {order?.special_note ?? "N/A"}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="table_asoebi_view">
                                                            <strong>Asoebi options: </strong>
                                                            <div className="list flex flex-column">
                                                                {order?.options?.map((item, idx) => (
                                                                    <div key={idx} className="group_list">
                                                                        <p className="my-0">Item: {item.option}</p>
                                                                        <p className="my-0">Price: {item.price}</p>
                                                                        <p className="my-0">Qty: {item.qty}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {order?.questionnaire_link ? (
                                                                <div
                                                                    onClick={() =>
                                                                        copyToClipboardHandler(order.questionnaire_link, "Order form link")
                                                                    }
                                                                    className="w-fit">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="asoebi_tooltip">click to copy order form link</Tooltip>
                                                                        }>
                                                                        <div>
                                                                            <strong>Order form link: </strong>
                                                                            <a
                                                                                target="_blank"
                                                                                href={order.questionnaire_link}
                                                                                className="font-weight-bold">
                                                                                View Order form
                                                                            </a>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            ) : (
                                                                <a target="_blank" href={order.questionnaire_link} className="font-weight-bold">
                                                                    View Order form
                                                                </a>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {order?.paid_at ? (
                                                                <div
                                                                    onClick={() => copyToClipboardHandler(order.paid_at, "Paid At")}
                                                                    className="w-fit">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id="asoebi_tooltip">click to copy paid at</Tooltip>}>
                                                                        <div>
                                                                            <strong>Paid At: </strong>
                                                                            {format(new Date(order.paid_at), "dd/MM/yyyy")}
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <strong>Paid At: </strong>
                                                                    {format(new Date(order.paid_at), "dd/MM/yyyy")}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div>
                                                            <strong>Status:</strong>
                                                            <SiteDropdown
                                                                id="status_dropdown"
                                                                title={orderText}
                                                                variant="clear"
                                                                arrow_fill="black"
                                                                className="d-flex">
                                                                {dropdownOptions.map((item) => {
                                                                    return (
                                                                        item.value !== order.status && (
                                                                            <Dropdown.Item
                                                                                key={item.text}
                                                                                onClick={() =>
                                                                                    updateOrderStatusHandler(item.value, order.event_name, order.id)
                                                                                }>
                                                                                {item.text}
                                                                            </Dropdown.Item>
                                                                        )
                                                                    );
                                                                })}
                                                            </SiteDropdown>
                                                        </div>
                                                        <div>
                                                            <strong>Order deadline:</strong>
                                                            {orderDeadlineDate}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            {paginatedDetails.length > 0 && <div className="paginate_event mt-5 pt-5 w-full d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <Select value={limit} onChange={onSelectEventLimit} input={selectLimitDetails} />
                    <p className="mb-0 ml-2">
                        Showing {showingOrderCounter} of {totalOrders}
                    </p>
                </div>
                <div>
                    <button className="border-0 bg-transparent mr-3" disabled={disableLeftArrowBtn} onClick={decrementPageCounter}>
                        <ArrowleftIcon fill={leftArrowColor} />
                    </button>
                    <button className="border-0 bg-transparent ml-3" disabled={disableRightArrowBtn} onClick={incrementPageCounter}>
                        <ArrowRightIcon fill={rightArrowColor} />
                    </button>
                </div>
            </div>}
        </>
    );
}
