/* eslint-disable max-len */

export default function Rectangle6Icon() {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.1665 1.66553L2.1665 3.99853C2.1665 4.45886 2.53967 4.83203 3 4.83203L5.333 4.83203C5.79333 4.83203 6.1665 4.45886 6.1665 3.99853V1.66553C6.1665 1.2052 5.79333 0.832031 5.333 0.832031L3 0.832031C2.53967 0.832031 2.1665 1.2052 2.1665 1.66553Z"
                fill="#D9D9D9"
            />
            <path
                d="M7 1.66553L7 3.99853C7 4.45886 7.37317 4.83203 7.8335 4.83203L10.1665 4.83203C10.6268 4.83203 11 4.45886 11 3.99853V1.66553C11 1.2052 10.6268 0.832031 10.1665 0.832031L7.8335 0.832031C7.37317 0.832031 7 1.2052 7 1.66553Z"
                fill="#D9D9D9"
            />
            <path
                d="M11.8335 1.66553V3.99853C11.8335 4.45886 12.2067 4.83203 12.667 4.83203L15 4.83203C15.4603 4.83203 15.8335 4.45886 15.8335 3.99853V1.66553C15.8335 1.2052 15.4603 0.832031 15 0.832031L12.667 0.832031C12.2067 0.832031 11.8335 1.2052 11.8335 1.66553Z"
                fill="#D9D9D9"
            />
            <path
                d="M0.244043 12.832L0.244043 6.83203C0.244043 6.33498 0.646987 5.93203 1.14404 5.93203L17.144 5.93203C17.6411 5.93203 18.044 6.33498 18.044 6.83203V12.832C18.044 13.3291 17.6411 13.732 17.144 13.732L1.14404 13.732C0.646987 13.732 0.244043 13.3291 0.244043 12.832Z"
                fill="#D9D9D9"
                stroke="#404040"
                strokeWidth="0.2"
            />
            <path
                d="M2.1665 16.0015L2.1665 18.3345C2.1665 18.7948 2.53967 19.168 3 19.168H5.333C5.79333 19.168 6.1665 18.7948 6.1665 18.3345V16.0015C6.1665 15.5411 5.79333 15.168 5.333 15.168H3C2.53967 15.168 2.1665 15.5411 2.1665 16.0015Z"
                fill="#D9D9D9"
            />
            <path
                d="M7 16.0015L7 18.3345C7 18.7948 7.37317 19.168 7.8335 19.168H10.1665C10.6268 19.168 11 18.7948 11 18.3345V16.0015C11 15.5411 10.6268 15.168 10.1665 15.168H7.8335C7.37317 15.168 7 15.5411 7 16.0015Z"
                fill="#D9D9D9"
            />
            <path
                d="M11.8335 16.0015V18.3345C11.8335 18.7948 12.2067 19.168 12.667 19.168H15C15.4603 19.168 15.8335 18.7948 15.8335 18.3345V16.0015C15.8335 15.5411 15.4603 15.168 15 15.168H12.667C12.2067 15.168 11.8335 15.5411 11.8335 16.0015Z"
                fill="#D9D9D9"
            />
        </svg>
    );
}
