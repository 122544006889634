import type { ChangeEvent } from "react";

import Input from "@/components/form/Input";

interface InputGroupProps {
    inputArray: Array<{
        name: string;
        label: string;
        placeholder: string;
        showCurrency?: boolean;
    }>;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: { [key: string]: string | number };
    maxLength?: number;
}

export default function InputGroup({ inputArray, value, onChange, maxLength }: InputGroupProps) {
    return (
        <div className="input_group d-flex justify-content-between">
            {inputArray.map((inputItem, index) => {
                const inputClassName = index === 0 ? "pr-" : "pl-4";
                const inputValue =
                    inputItem.name === "actual" || inputItem.name === "paid" ? value[inputItem.name].toLocaleString("en-US") : value[inputItem.name];

                return (
                    <Input
                        key={inputItem.name}
                        className={`col-6 px-0 ${inputClassName}`}
                        input={inputItem}
                        showCurrency={inputItem.showCurrency}
                        value={inputValue}
                        maxLength={maxLength}
                        onChange={onChange}
                    />
                );
            })}
        </div>
    );
}
