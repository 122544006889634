import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface useFilterType {
    selectedEventId: string;
    activeCeremonyId: string;
    setSelectedEventId: (value: string) => void;
    setActiveCeremonyId: (value: string) => void;
}

export const useEvent = create<useFilterType>()(
    persist(
        (set) => ({
            selectedEventId: "",
            activeCeremonyId: "",
            setSelectedEventId: (value) => set(() => ({ selectedEventId: value })),
            setActiveCeremonyId: (value) => set(() => ({ activeCeremonyId: value })),
        }),
        { name: "selected_event", storage: createJSONStorage(() => localStorage) },
    ),
);
