import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { usePaystackPayment } from "react-paystack";

import NewDashboardLayout from "@/layout/NewDashboardLayout";
import { getUserDetails } from "@/axios/get-request";
import { CurrencyList, SubscriptionStatus } from "@/types";
import { subscribeToPlan } from "@/axios/post-request";
import { payStackSubscriptionConfig } from "@/payStackConfig";
import { initializePaymentType } from "@/data/types";
import CheckIcon2 from "@/assets/icon/CheckIcon2";
import CancelSubscriptionModal from "@/components/modals/CancelSubscriptionModal";
import { ProfileLoader } from "@/components/loaders/AuditsLoader";
import "@/styles/settings.scss";

const menu = [
    { text: "Profile", link: "/dashboard/settings" },
    { text: "Account", link: "/dashboard/settings/account", active: true },
    { text: "Vendors", link: "/dashboard/vendors" },
];

type countryDetailsType = {
    currency: `${CurrencyList}` | "";
    country: string;
};

export default function AccountPage() {
    const [modal, setModal] = useState(false);
    const [countryDetails, setCountryDetails] = useState<countryDetailsType>({
        country: "",
        currency: "",
    });
    const { data, status } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => {
            return getUserDetails();
        },
    });

    useEffect(() => {
        setCountryDetails({
            ...countryDetails,
            country: data?.result?.country ?? "",
            currency: data?.result?.currency ?? "",
        });
    }, [status]);

    const config = payStackSubscriptionConfig(data?.result?.email as string);
    const initializePayment = usePaystackPayment(config) as initializePaymentType;

    async function makePaymentHandler() {
        try {
            initializePayment(() => {
                return subscribeToPlanHandler();
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    async function subscribeToPlanHandler() {
        const today = new Date().toDateString();
        await subscribeToPlan({
            email: data?.result?.email as string,
            startDate: today,
        });
    }

    function showModal() {
        setModal(true);
    }

    function closeModal() {
        setModal(false);
    }

    const __accountStatus = data?.result?.subscription_status ? data?.result?.subscription_status : "";

    const accountStatusClassname = data?.result?.subscription_details ? "free_trial" : "";

    const accountStatus = __accountStatus.includes("_") ? __accountStatus.replace(/_/g, " ") : __accountStatus;

    return (
        <>
            {modal && data?.result?.subscription_details && (
                <CancelSubscriptionModal
                    onClose={closeModal}
                    userId={data?.result?.id}
                    subscriptionDetails={{
                        subscription_code: data?.result?.subscription_details?.subscription_code,
                        subscription_token: data?.result?.subscription_details?.email_token,
                    }}
                />
            )}
            <NewDashboardLayout title="Settings" description="Manage your profile, workspace and more.">
                <section className="settings_page">
                    <aside>
                        <ul>
                            {menu.map((item, index) => {
                                const className = item?.active ? "active" : "";
                                return (
                                    <li key={index} className={className}>
                                        <Link to={item.link}>{item.text}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </aside>
                    <div className="settings_view">
                        <h4>Subscription</h4>
                        <div className="subscription_benefit">
                            <h4>Subscribe to Planaday Pro</h4>
                            <ul>
                                <li>
                                    <CheckIcon2 fill="#000" /> Budget Tracker
                                </li>
                                <li>
                                    <CheckIcon2 fill="#000" /> Checklist
                                </li>
                                <li>
                                    <CheckIcon2 fill="#000" /> Event Timeline
                                </li>
                                <li>
                                    <CheckIcon2 fill="#000" /> Floor Plan
                                </li>
                                <li>
                                    <CheckIcon2 fill="#000" /> New Features e.g Event Day Flow
                                </li>
                            </ul>
                            <h6>
                                <span>₦15,000</span> / month
                            </h6>
                        </div>
                        {status === "loading" ? (
                            <ProfileLoader />
                        ) : (
                            <div className="profile_row">
                                <div className="text_content">
                                    <p>Status</p>
                                    <h6>
                                        Planday Pro - Monthly{" "}
                                        {status === "success" && accountStatus && <sup className={accountStatusClassname}>{accountStatus}</sup>}
                                    </h6>
                                </div>
                                {data?.result && (
                                    <>
                                        {((data?.result?.subscription_status !== SubscriptionStatus.TRIAL &&
                                        data?.result?.subscription_status !== SubscriptionStatus.ACTIVE) ||
                                            !data?.result?.subscription_details) && <button onClick={makePaymentHandler}>Subscribe</button>}
                                        {data?.result?.subscription_status &&
                                            (data?.result?.subscription_status === SubscriptionStatus.ACTIVE ||
                                                data?.result?.subscription_status === SubscriptionStatus.TRIAL) && (
                                            <button onClick={showModal}>Cancel</button>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </section>
            </NewDashboardLayout>
        </>
    );
}
