import { format } from "date-fns";
import { useState } from "react";
import type { auditType, EventdayFlowType, EventDayTaskAuditChanges } from "@/types";

import arrowdown from "@/assets/img/arrow_chevron_down.svg";
import arrowUp from "@/assets/img/arrow-chevron_up.svg";
import { formatDate } from "@/helper";

interface Props {
    audit: auditType[0];
    showType: boolean;
}

type itemKeyType = keyof EventdayFlowType[0];
const trackChecklistChangesArray = ["Task", "Venue", "Ceremony", "Vendor", "Duration", "Start_time", "Date"];

export default function EventDayTaskAuditDropdown({ audit, showType }: Props) {
    const [dropdown, setDropdown] = useState(false);

    const _audit_date = new Date(audit.timestamp);
    const audit_time = format(_audit_date, "dd-MM-yyyy, KK:mm aa");
    const action =
        audit.action === "delete_eventday_task"
            ? "deleted"
            : audit.action === "eventday_task_done"
                ? "marked as completed"
                : audit.action === "eventday_task_undone"
                    ? "changed from done to available"
                    : audit.action === "add_eventday_task"
                        ? "added"
                        : audit.action === "eventday_task_edited"
                            ? "updated"
                            : "";

    function toggleDropdown() {
        setDropdown((prev) => !prev);
    }

    const arrow_image_src = dropdown ? arrowUp : arrowdown;

    const dropdownAudit = action !== "deleted";

    const auditClassname = !dropdownAudit ? "my-3" : "mb-";
    const auditHeaderClassname = !dropdown ? "mb-3" : "";
    const auditBodyClassname = !dropdown ? "" : "mb-3";

    function formatAuditDate(givenDate: Date) {
        const formatDateDue = givenDate ? formatDate(givenDate) : "NO DEADLINE";
        return formatDateDue;
    }

    function displayChanges(title: string, key: keyof EventdayFlowType[0], depthKey: "name" | null) {
        const auditChanges = audit?.changes as EventDayTaskAuditChanges;
        const auditTitle = title.toLowerCase() as "vendor" | "ceremony";
        const auditFromDetails = auditChanges?.from && auditChanges?.from[auditTitle] ? auditChanges.from[auditTitle] : null;
        const auditFromData =
            auditChanges?.from && depthKey && (title === "Ceremony" || title === "Vendor")
                ? auditFromDetails
                    ? auditFromDetails[depthKey]
                    : ""
                : auditChanges?.from && auditChanges?.from[key];

        const auditToDetails = auditChanges?.to && auditChanges?.to[auditTitle] ? auditChanges.to[auditTitle] : null;
        const auditToData =
            depthKey && (title === "Ceremony" || title === "Vendor")
                ? auditToDetails
                    ? auditToDetails[depthKey]
                    : ""
                : auditChanges?.to && auditChanges?.to[key];
        const dueDateFrom = auditChanges?.from?.date ? new Date(auditChanges?.from?.date) : null;
        const dueDateTo = auditChanges?.to?.date ? new Date(auditChanges?.to?.date) : null;

        const auditFromDataResultData = title === "Date" && dueDateFrom ? formatAuditDate(dueDateFrom) : auditFromData;
        const auditFromDataResult = auditFromDataResultData as string;

        const auditToDataResultData = title === "Date" && dueDateTo ? formatAuditDate(dueDateTo) : auditToData;
        const auditToDataResult = auditToDataResultData as string;

        return (
            (auditFromData || auditToData) && (
                <p key={key} className="mb-1">
                    {title}: {auditChanges?.from && auditFromData !== auditToData && <span className="underline mr-2">{auditFromDataResult}</span>}
                    {auditChanges?.to && auditChanges?.to[key] && <span> {auditToDataResult}</span>}
                </p>
            )
        );
    }

    const auditTypeClassname = showType ? "show_type" : "";

    return (
        <>
            <div
                className={`${auditClassname} ${auditHeaderClassname} ${auditTypeClassname}
            audit_list_card bg-white p-3 rounded justify-content-between d-flex align-items-lg-center`}>
                <div className="audit_details">
                    <span className="font-weight-bold mr-1">{audit.title}</span> was
                    <span className="font-weight-bold mx-1">{action}</span>
                    {action === "added" && <>to {audit?.eventName} event</>} by {audit?.name ? audit.name : audit.email} at {audit_time}
                </div>
                <div className="audit_type_group">
                    {showType && <div className="type">Eventday Task</div>}
                    {dropdownAudit && (
                        <button className="border-0 bg-transparent" onClick={toggleDropdown}>
                            <img src={arrow_image_src} alt="dropdown icon" />
                        </button>
                    )}
                </div>
            </div>
            {dropdown && (
                <div className={`${auditBodyClassname} dropdown checklist_dropdown bg-white px-3 pb-2 mb-3`}>
                    {trackChecklistChangesArray.map((item) => {
                        const _itemKey = item === "Event name" ? "eventName" : (item.toLowerCase() as unknown);
                        const itemKey = _itemKey as itemKeyType;
                        const itemDepthKey = item === "Ceremony" || item === "Vendor" ? "name" : null;
                        return displayChanges(item, itemKey, itemDepthKey);
                    })}
                </div>
            )}
        </>
    );
}
