/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from "react";

import DoubleChairElement from "@/assets/icon/FloorPlanIcon/DoubleChairElement";
import DraggableElement from "@/assets/icon/FloorPlanIcon/DraggableElement";
import RoundtableElement from "@/assets/icon/FloorPlanIcon/RoundtableElement";
import SinglechairElement from "@/assets/icon/FloorPlanIcon/SinglechairElement";
import SofaElement from "@/assets/icon/FloorPlanIcon/SofaElement";
import SquareTableElement from "@/assets/icon/FloorPlanIcon/SquareTableElement";
import TableOfEightElement from "@/assets/icon/FloorPlanIcon/TableOfEightElement";
import TableOfTenElement from "@/assets/icon/FloorPlanIcon/TableOfTenElement";
import EntertainmentElement from "@/assets/icon/FloorPlanIcon/EntertainmentElement";
import FoodDrinksElement from "@/assets/icon/FloorPlanIcon/FoodDrinksElement";
import StageElement from "@/assets/icon/FloorPlanIcon/StageElement";
import GiftElement from "@/assets/icon/FloorPlanIcon/GiftElement";
import ShapesElement from "@/assets/icon/FloorPlanIcon/ShapesElement";
import { FloorPlanObjectList, FloorPlanObjectListType } from "@/data/types";
import LineDimensionElement from "@/assets/icon/FloorPlanIcon/LineDimensionElement";
import ResizeableText from "./FloorplanElements/ResizeableText";
import RectangleElement from "@/assets/icon/FloorPlanIcon/RectangleElement";
import Rectangle3Element from "@/assets/icon/FloorPlanIcon/Rectangle3Element";
import Rectangle6Element from "@/assets/icon/FloorPlanIcon/Rectangle6Element";
import Rectangle8Element from "@/assets/icon/FloorPlanIcon/Rectangle8Element";
import Rectangle10Element from "@/assets/icon/FloorPlanIcon/Rectangle10Element";
import CircleElement from "@/assets/icon/FloorPlanIcon/CircleElement";
import SquareElement from "@/assets/icon/FloorPlanIcon/SquareElement";
import DoubleOpenDoorElement from "@/assets/icon/FloorPlanIcon/DoubleOpenDoorElement";
import DoubleClosedDoorElement from "@/assets/icon/FloorPlanIcon/DoubleClosedDoorElement";
import SingleOpenDoorElement from "@/assets/icon/FloorPlanIcon/SingleOpenDoorElement";
import SingleCloseDoorElement from "@/assets/icon/FloorPlanIcon/SingleCloseDoorElement";
import DefaultElement from "./FloorplanElements/DefaultElement";
import ImageElement from "@/assets/icon/FloorPlanIcon/ImageElement";

type valueType = {
    x: number;
    y: number;
    id: string;
    width: number;
    height: number;
    fill: string;
    text?: string;
    title?: string;
    type: FloorPlanObjectListType;
};

interface Props {
    item: valueType;
    onChange: (value: valueType) => void;
    onDragStart: (value: valueType) => void;
    isSelected: boolean;
    // trRef: React.MutableRefObject<null | any>;
    onSelect: () => void;
    updateFloorPlanTextHandler: (textId: string, text: string) => void;
}

export default function DisplayFloorPlanElement({ item, onSelect, onDragStart, updateFloorPlanTextHandler, isSelected, onChange }: Props) {
    const shapeRef = useRef() as React.MutableRefObject<undefined | any>;
    const trRef = useRef() as React.MutableRefObject<undefined | any>;
    const objectType = item.type.includes(FloorPlanObjectList.stage_wall) ? FloorPlanObjectList.stage_wall : item.type;

    function onTransformEndHandler() {
        const node = shapeRef.current as unknown as {
            scaleX: (value?: number) => number;
            scaleY: (value?: number) => number;
            width: () => number;
            height: () => number;
            x: () => number;
            y: () => number;
            moveToTop: () => void;
        };
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        // if (resetScale) {
        node.scaleX(1);
        node.scaleY(1);
        // }

        onChange({
            ...item,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
        });
    }
    switch (objectType) {
    case FloorPlanObjectList.sofa__table_chairs__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <SofaElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.single_chair__table_chairs__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <SinglechairElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.double_chair__table_chairs__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <DoubleChairElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.round_table__table_chairs__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <RoundtableElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onChange={onChange}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.table_of_10__table_chairs__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <TableOfTenElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.table_of_8__table_chairs__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <TableOfEightElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.rectangle__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <RectangleElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.rectangle_3__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <Rectangle3Element
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.rectangle_6__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <Rectangle6Element
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.rectangle_8__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <Rectangle8Element
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.rectangle_10__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <Rectangle10Element
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.square_table__table_chairs__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <SquareTableElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onSelect={onSelect}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.circle__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <CircleElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.element_with_text:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <DefaultElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.square__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <SquareElement
                    refValue={shapeRef}
                    item={item}
                    onSelect={onSelect}
                    onChange={onChange}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.double_door_open__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <DoubleOpenDoorElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.double_door_closed__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <DoubleClosedDoorElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.single_door_open__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <SingleOpenDoorElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.single_door_closed__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <SingleCloseDoorElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.entertainment__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <EntertainmentElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.food_drinks__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <FoodDrinksElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.stage__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <StageElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.gifts__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <GiftElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.image_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <ImageElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.shapes__floor_plan_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <ShapesElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDragStart={onDragStart}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    case FloorPlanObjectList.stage__text:
        return (
            <ResizeableText
                onSelect={onSelect}
                shapeRef={shapeRef}
                item={item}
                isSelected={isSelected}
                onChange={onChange}
                onTransformEndHandler={onTransformEndHandler}
                trRef={trRef}
                updateFloorPlanTextHandler={updateFloorPlanTextHandler}
            />
        );
    case FloorPlanObjectList.lines_element:
        return (
            <DraggableElement item={item} elementRef={shapeRef} trRef={trRef} isSelected={isSelected}>
                <LineDimensionElement
                    refValue={shapeRef}
                    item={item}
                    onChange={onChange}
                    onSelect={onSelect}
                    onTransformEndHandler={onTransformEndHandler}
                />
            </DraggableElement>
        );
    default:
        return <></>;
    }
}
