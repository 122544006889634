export default function DoubleClosedDoorIcon() {
    return (
        <svg width="34" height="14" viewBox="0 0 34 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="18.1" y="7.1" width="11.8" height="0.2" rx="0.1" stroke="#404040" strokeWidth="0.2" />
            <path d="M34 6H30.4C30.1791 6 30 6.17909 30 6.4V8.6C30 8.82091 30.1791 9 30.4 9H34" stroke="#404040" strokeWidth="0.4" />
            <rect x="4.1" y="7.1" width="11.8" height="0.2" rx="0.1" stroke="#404040" strokeWidth="0.2" />
            <path d="M0 6H3.6C3.82091 6 4 6.17909 4 6.4V8.6C4 8.82091 3.82091 9 3.6 9H0" stroke="#404040" strokeWidth="0.4" />
        </svg>
    );
}
