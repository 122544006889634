import { PropsWithChildren } from "react";
import { format } from "date-fns";

import ChartIcon from "@/assets/icon/ChartIcon";
import GripIcon from "@/assets/icon/GripIcon";
import PencilIcon from "@/assets/icon/PencilIcon";
import TrashIcon2 from "@/assets/icon/TrashIcon2";
import CalendarDateIcon from "@/assets/icon/CalendarDateIcon";
import { TodoType } from "@/types";
import "@/styles/checklist_new.scss";

interface Props {
	title: string;
}

enum ModalList {
	add_checklist_task_modal = "add_checklist_task_modal",
	edit_checklist_task_modal = "edit_checklist_task_modal",
	delete_checklist_task_modal = "delete_checklist_task_modal",
}

export function DisplayChecklistStatus({ title }: Props) {
    const status = title.toLowerCase();
    return (
        <div className="display_status">
            {status === "urgent" ? (
                <div className="icon urgent">
                    <span>!</span> {title}
                </div>
            ) : (
                <div className={`icon ${status}`}>
                    <ChartIcon /> {title}
                </div>
            )}
        </div>
    );
}

interface ChecklistItemProps {
	checklist: TodoType[0];
	modalActionHandler: (modalAction: keyof typeof ModalList) => void;
	index?: number;
	moveCard?: (dragIndex: number, hoverIndex: number) => void;
}

export default function CeremonyChecklistItem({ checklist, children, modalActionHandler }: PropsWithChildren<ChecklistItemProps>) {
    const className = checklist.isDone ? "checklist_completed" : "";

    return (
        <div className={`checklist_item ${className}`}>
            {children}
            <div className="checklist_wrapper">
                <div className="checklist_text_view">
                    <h6>{checklist?.name}</h6>
                    <div className="period">
                        {checklist?.due && (
                            <>
                                <CalendarDateIcon />
                                <span className="date">{checklist?.due ? `${format(new Date(checklist?.due), "EEE do MMM")}` : "--"}</span>
                            </>
                        )}
                        {checklist?.priority ? (
                            <>
                                <hr />
                                <DisplayChecklistStatus title={checklist.priority as string} />
                            </>
                        ) : (
                            <div className="no_priority"> --- No priority</div>
                        )}
                    </div>
                </div>
                <div className="checklist_actions">
                    {!checklist.isDone && (
                        <button onClick={() => modalActionHandler(ModalList.edit_checklist_task_modal)}>
                            <PencilIcon />
                        </button>
                    )}
                    <button onClick={() => modalActionHandler(ModalList.delete_checklist_task_modal)}>
                        <TrashIcon2 />
                    </button>
                    {!checklist.isDone && (
                        <button>
                            <GripIcon />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
