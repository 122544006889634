import type { ChangeEvent } from "react";

import naira from "@/assets/img/naira_currency.svg";
import "@/styles/form.scss";

interface Props {
    input: {
        label?: string;
        placeholder: string;
        name: string;
    };
    className?: string;
    showCurrency?: boolean;
    value?: string | number;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    type?: string;
    readOnly?: boolean;
    maxLength?: number;
    required?: boolean;
    onFocus?: () => void;
    min?: number;
    error?: string;
    index?:number;
    testId?: string;
}
export default function Input({
    input,
    onChange,
    className,
    error,
    showCurrency = false,
    value = "",
    type = "text",
    readOnly = false,
    maxLength = undefined,
    required = false,
    index,
    testId,
    ...props
}: Props) {
    const inputClassname = className ? className : "";
    const htmlForText= index ? `${input.name}_${index}` : input.name;
    const testIdData = testId ? {"data-testid": testId} : {};
    return (
        <div className={`form_element input ${inputClassname}`}>
            {input?.label && <label htmlFor={htmlForText}>{input?.label}</label>}
            <input
                {...props}
                {...testIdData}
                id={htmlForText}
                name={input.name}
                placeholder={input.placeholder}
                className="form-control"
                value={value}
                type={type}
                readOnly={readOnly}
                onChange={onChange}
                maxLength={maxLength}
                required={required}
            />
            {showCurrency && <img src={naira} alt="Naira" className="icon" />}
            {error && <p className="text-danger small">{error}</p>}
        </div>
    );
}
