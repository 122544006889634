import OnboardingLayout from "@/layout/OnboardingLayout";
import { useNavigate } from "react-router-dom";

import OnboardingForm from "@/components/form/OnboardingForm";

const CURRENTSTAGE = 0;

export default function OnboardingEventDetails() {
    const indicatorArray = new Array(5).fill(0);
    const navigate = useNavigate();


    function onNextHandler() {
        navigate("/onboarding/budget-details");
    }


    return (
        <OnboardingLayout className="onboarding__form">
            <div className="onboarding_slides">
                <ul className="indicator my-4 mb-5 d-flex align-items-center">
                    {indicatorArray.map((_, index) => {
                        const indicatorStyle = CURRENTSTAGE === index ? "active" : index < CURRENTSTAGE ? "visited" : "indicator_item";
                        return <li key={index} className={indicatorStyle} />;
                    })}
                </ul>
                <div className="onboarding_content">
                    <OnboardingForm nextHandler={onNextHandler} />
                </div>
            </div>
        </OnboardingLayout>
    );
}
