import DatePicker from "react-datepicker";
import { forwardRef, useState } from "react";
import type { ForwardedRef } from "react";

import "react-datepicker/dist/react-datepicker.css";

interface Props {
    value?: Date | null;
    onClick?: () => void;
    resetDateHandler?: () => void;
}

function formatDate(date: Date) {
    const _formatDate = new Date(date);
    const _date = _formatDate.toLocaleDateString("en-GB", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });
    return _date;
}

const SelectDateButton = forwardRef(({ value, onClick, resetDateHandler }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    const formattedDate = value ? formatDate(value) : value;
    return (
        <div className="d-flex align-items-center">
            <button className="btn select_data_button mr-3" onClick={onClick} ref={ref} type="button">
                Select Date
            </button>
            {value && (
                <button className="btn clear_date text-white" type="button" onClick={resetDateHandler}>
                    <p className="mb-0">{formattedDate}</p>
                    <span className="ml-3">+</span>
                </button>
            )}
        </div>
    );
});

interface SelectDateProps {
    value?: Date | null;
    label: string;
    onClick: (date: Date) => void;
    maxDate?: Date | null;
    className?: string;
}

export default function SelectDate({ label, value, className = "", onClick, maxDate }: SelectDateProps) {
    const defaultDate = value ? new Date(value) : null;

    const [selectDate, setSelectDate] = useState<Date | null>(defaultDate);

    function resetDateHandler() {
        setSelectDate(null);
    }

    function onSelectDateHandler(date: Date) {
        setSelectDate(date);
        onClick(date);
    }

    return (
        <div className={`select_date ${className}`}>
            <div className="label">{label}</div>
            <DatePicker
                selected={selectDate}
                maxDate={maxDate}
                onChange={onSelectDateHandler}
                customInput={<SelectDateButton resetDateHandler={resetDateHandler} />}
            />
        </div>
    );
}
