import ContentLoader from "react-content-loader";
import CardLoader from "@/components/loaders/CardLoader";

export default function ChecklistLoader() {
    const loaderArray = new Array(3).fill(0);

    return (
        <div className="checklist_loader d-flex flex-column">
            {loaderArray.map((_, index) => (
                <CardLoader key={index} height={100} width={600} className="mb-4" />
            ))}
        </div>
    );
}

interface Props {
  width: number | string;
  height: number;
  className?: string;
}

export function ChecklistTaskLoader({ width, height, className }: Props) {
    return (
        <ContentLoader
            width={width}
            height={height}
            // viewBox="0 0 100% 100%"
            backgroundColor="#cbbec0"
            foregroundColor="#dedede"
            className={className}
        >
            <rect x="0" y="0" rx="10" ry="5" width="100%" height="100%" />
        </ContentLoader>
    );
}
