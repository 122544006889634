import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import useTourGuide from "@/hooks/useTourGuide";
import { duplicateEvent } from "@/axios/post-request";
import generateRandomColor from "@/utils/generateRandomColor";
import useUser from "./useUser";
import useAudit from "./useAudit";

type modalType = "add_new_event_modal" | "delete_event_modal" | "duplicate_event_modal";

type eventDetailsType = {
	id: string;
	name: string;
};

export default function useProfile() {
    const [modal, setModal] = useState<null | modalType>(null);
    const [event, setEvent] = useState({
        name: "",
        id: "",
    });
    const {updateAuditHandler} = useAudit();
    const queryClient = useQueryClient();
    const { status, data, selectedEventId } = useUser();

    const mutateData = useMutation({
        mutationFn: ({ id, name, event_date }: { id: string; name: string; event_date: Date }) => {
            const event_bg = generateRandomColor();
            return duplicateEvent({ id, name, event_date, event_bg });
        },
        onSuccess: (_, {name}) => {
            updateAuditHandler("general", "duplicate_event", name, {duplicate_event: event.name});
            queryClient.resetQueries([`get_event_${selectedEventId}`]);
            toast.success("Event duplicated");
        },
        onError: () => {
            toast.error("Error creating event");
        },
        onSettled: () => {
            onCloseModalHandler();
        },
    });
    const _no_of_events = status === "success" ? data?.result?.event_ids?.length : 0;
    const no_of_events = _no_of_events as number;

    useTourGuide(status, "profile", data?.result?.tour_guide?.user_profile, no_of_events);

    function onCloseModalHandler() {
        setModal(null);
        setEvent({
            id: "",
            name: "",
        });
    }

    function onOpenModalHandler() {
        setModal("add_new_event_modal");
    }

    function showDuplicateEventModal(eventDetails: eventDetailsType) {
        setEvent({
            ...event,
            ...eventDetails,
        });
        setModal("duplicate_event_modal");
    }

    return {
        modal,
        data,
        status,
        event,
        mutateData,
        func: {
            onOpenModalHandler,
            onCloseModalHandler,
            showDuplicateEventModal,
        },
    };
}
