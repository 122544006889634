import { Suspense } from "react";
import type { ReactNode } from "react";

import AddCategoryModal from "@/components/modals/AddCategoryModal";
import AddPaymentModal from "@/components/modals/AddPaymentModal";
import SiteDropdown from "@/components/Dropdown";
import VendorassistanceModal from "@/components/modals/VendorAssistanceModal";
import { useModal } from "@/hooks/useModal";
import type { costItemObjectType } from "@/types";

type BudgetBreakdownType = {
    children: ReactNode;
    costItems: costItemObjectType[];
};

export default function BudgetBreakdown({ children, costItems }: BudgetBreakdownType) {
    const { modal, setModal } = useModal();

    function onCloseModal() {
        setModal(null);
    }

    function openCategoryModal() {
        setModal("category_modal");
    }

    function openPaymentModal() {
        setModal("payment_modal");
    }
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                {modal === "category_modal" && <AddCategoryModal show={true} onHide={onCloseModal} />}
                {modal === "payment_modal" && <AddPaymentModal show={true} costItems={costItems} onHide={onCloseModal} />}
                {modal === "vendor_assistance_modal" && <VendorassistanceModal show={true} onHide={onCloseModal} />}
            </Suspense>
            <div className="event_budget mt-5">
                <div className="section_title d-flex flex-md-row flex-column align-items-start align-items-md-center justify-content-between">
                    <h3 className="mb-lg-0 order-2 order-md-1 mt-lg-0 mt-4">Budget Breakdown</h3>
                    <SiteDropdown
                        id="budget_breakdown_actions"
                        title="Actions"
                        variant="clear"
                        arrow_fill="white"
                        className="order-lg-2 order-1"
                        options={[
                            {
                                text: "Add New Category",
                                method: openCategoryModal,
                            },
                            {
                                text: "Add New Payment",
                                method: openPaymentModal,
                            },
                        ]}
                    />
                </div>
                {children}
            </div>
        </>
    );
}
