/* eslint-disable max-len */
export default function InfoIconFill() {
    return (
        <svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5.00018" r="4" fill="#C0C0C0" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.65222 3.62097C5.71472 3.5116 5.74988 3.38464 5.74988 3.24988C5.74988 2.83582 5.41394 2.49988 4.99988 2.49988C4.58582 2.49988 4.24988 2.83582 4.24988 3.24988C4.24988 3.66394 4.58582 3.99988 4.99988 3.99988C5.27917 3.99988 5.52332 3.84753 5.65222 3.62097ZM4.49988 4.49988H4.74988H5.24988C5.52624 4.49988 5.74988 4.72351 5.74988 4.99988V5.49988V7.49988C5.74988 7.77625 5.52624 7.99988 5.24988 7.99988C4.97351 7.99988 4.74988 7.77625 4.74988 7.49988V5.87488C4.74988 5.66785 4.58191 5.49988 4.37488 5.49988C4.16785 5.49988 3.99988 5.33191 3.99988 5.12488V4.99988C3.99988 4.8241 4.0907 4.66882 4.22742 4.57996C4.30554 4.52917 4.39929 4.49988 4.49988 4.49988Z"
                fill="white"
            />
        </svg>
    );
}
