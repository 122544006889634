import { Suspense, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import DeleteModal from "@/components/modals/DeleteModal";
import useToast from "@/hooks/useToast";
import { deleteBudgetCategory } from "@/axios/delete-request";
import { useEvent } from "@/hooks/useEvent";
import { updateAudit } from "@/axios/post-request";
import useUser from "@/hooks/useUser";

interface Props {
    selectedCategory: { id: string; name: string };
    categoryId: string;
    closeModal: () => void;
    showModal: boolean;
    noOfCostItem: number;
}

export default function DeleteBudgetCategoryModal({ selectedCategory, showModal, closeModal, noOfCostItem, categoryId }: Props) {
    const queryClient = useQueryClient();
    const { loadingToast, updateToast } = useToast();
    const toastId = useRef("deleteCostItemCategory");
    const { selectedEventId } = useEvent();
    const { data } = useUser();

    async function deleteCostItemCategoryHandler() {
        return await deleteBudgetCategory(selectedCategory.id, selectedEventId);
    }

    const { mutate, isLoading } = useMutation({
        mutationKey: ["deleteCostItem"],
        mutationFn: () => deleteCostItemCategoryHandler(),
        onMutate: () => loadingToast(toastId),
        onSuccess: ({ data: resultData }) => {
            updateAudit(
                [
                    {
                        id: uuidv4(),
                        name: data?.result?.name as string,
                        user_id: data?.result?.id as string,
                        email: data?.result?.email as string,
                        type: "budget_tracker",
                        action: "delete_budget_cost_item_category",
                        title: selectedCategory.name,
                        timestamp: new Date(),
                        metadata: {
                            noOfCostItem,
                        },
                    },
                ],
                selectedEventId,
            );
            updateToast(toastId, "success", resultData?.message);
            queryClient.resetQueries({ queryKey: [`get_categories_${selectedEventId}`] });
            return closeModal();
        },
        onError: (error: unknown) => {
            const _error = error as { message: string };
            const _errorMessage = _error?.message || "Oops an error occured, unable to delete cost item category";
            updateToast(toastId, "error", _errorMessage);
        },
    });

    function onDeleteHandler() {
        if (selectedCategory.id) {
            mutate();
        }
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {showModal && selectedCategory.id === categoryId && (
                <DeleteModal show={showModal} onHide={closeModal} isLoading={isLoading} title={selectedCategory.name} onDelete={onDeleteHandler} />
            )}
        </Suspense>
    );
}
