import { Link } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
// import { set } from "date-fns";

import NewDashboardLayout from "@/layout/NewDashboardLayout";
import { getUserDetails } from "@/axios/get-request";
import ProfileRow from "@/components/ProfileRow";
import { updateUserDetails } from "@/axios/put-request";
import { uploadProfileImage } from "@/axios/post-request";
import { UserList } from "@/types";
import useAuth from "@/hooks/useAuth";
import "@/styles/settings.scss";

const menu = [
    { text: "Profile", link: "/dashboard/settings", active: true },
    { text: "Account", link: "/dashboard/settings/account" },
    { text: "Vendors", link: "/dashboard/vendors" },
];

const formInput = {
    name: { name: "name", label: "Name", placeholder: "Enter your name" },
    phone: { name: "phone", label: "Phone number", placeholder: "Enter your phone number" },
    password: { name: "password", label: "Password", placeholder: "*******************", type: "password" },
};

type loadingType = "name" | "phone" | "password";

// const goLiveDate = set(new Date(), {year: 2024, month: 9, date: 2 });
export default function SettingsPage() {
    const [loading, setLoading] = useState<loadingType | null>(null);
    const [previewImage, setPreviewImage] = useState("");
    const [image, setImage] = useState<File | null>(null);
    const { updateUserProfile, signUserOut } = useAuth();
    const { data, status, refetch } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => {
            return getUserDetails();
        },
    });
    const [profile, setProfile] = useState({
        name: "",
        phone: "",
        password: "",
    });

    useEffect(() => {
        if (status === "success" && (!profile.name || !profile.phone)) {
            const _userName = data?.result?.business_name ? data?.result?.business_name : data?.result?.name;
            const userName = _userName ? _userName : "";
            const profileImg = data?.result?.profile_photo ? data?.result?.profile_photo : data?.result?.business_image;

            setProfile({
                ...profile,
                name: userName,
                phone: data?.result?.phone_number as string,
            });
            setPreviewImage(profileImg ? profileImg : "");
        }
    }, [status]);

    function changeProfilePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        setPreviewImage(url);
        setImage(file);
    }

    function onInputChangeEventHandler(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.name === "phone" && isNaN(Number(event.target.value))) return;
        setProfile((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            };
        });
    }

    async function updateNameHandler(onProfileSaveHandler: () => void) {
        try {
            if (!profile.name) {
                return toast.error("Name is required");
            }
            if (profile.name.length < 2) {
                return toast.error("Please enter valid name");
            }
            if (!data?.result?.id) return;
            setLoading("name");
            const userDetails = data.result.user_type === UserList.celebrant ? { name: profile.name } : { business_name: profile.name };
            await updateUserDetails(data.result.id, userDetails);
            if (image) {
                const formData = new FormData();
                formData.append("file", image);
                await uploadProfileImage(formData, data.result.id);
            }
            setLoading(null);
            onProfileSaveHandler();
            return refetch();
        } catch (error) {
            setLoading(null);
            return toast.error("Oops an error occured");
        }
    }

    async function updatePhoneHandler(onProfileSaveHandler: () => void) {
        try {
            if (!profile.phone) {
                return toast.error("Phone is required");
            }
            if (profile.phone.length < 9 || profile.phone.length > 11) {
                return toast.error("Invalid phone number");
            }
            if (!data?.result?.id) return;
            setLoading("phone");
            const result = await updateUserDetails(data.result.id, { phone_number: profile.phone });
            setLoading(null);
            if (!result.data.status) {
                return toast.error("Oops an error occured, unable to update your phone number");
            }
            toast.success("Phone number updated");
            onProfileSaveHandler();
            return refetch();
        } catch (error) {
            setLoading(null);
            return toast.error("Oops an error occured, unable to update your phone number");
        }
    }

    async function updatePasswordHandler(onProfileSaveHandler: () => void) {
        try {
            if (!profile.password) {
                return toast.error("Phone is required");
            }
            if (profile.password.length < 6) {
                return toast.error("Password is too short");
            }
            if (!data?.result?.id) return;
            setLoading("password");
            await updateUserProfile(profile.password);
            setLoading(null);
            onProfileSaveHandler();
            signUserOut("/new");
            return toast.success("Password updated");
        } catch (error) {
            setLoading(null);
            return toast.error("Oops an error occured");
        }
    }

    return (
        <NewDashboardLayout title="Settings" description="Manage your profile, workspace and more.">
            <section className="settings_page">
                <aside>
                    <ul>
                        {menu.map((item, index) => {
                            const className = item?.active ? "active" : "";
                            return (
                                <li key={index} className={className}>
                                    <Link to={item.link}>{item.text}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </aside>
                <div className="settings_view">
                    <h4>Profile</h4>
                    <ProfileRow
                        withPhoto
                        index={1}
                        loading={loading}
                        formInput={formInput.name}
                        value={profile.name}
                        placeholder={formInput.name.label}
                        previewImage={previewImage}
                        onChange={onInputChangeEventHandler}
                        changeProfilePhotoHandler={changeProfilePhotoHandler}
                        onSaveHandler={updateNameHandler}
                    />
                    <ProfileRow
                        index={2}
                        loading={loading}
                        formInput={formInput.phone}
                        value={profile.phone}
                        placeholder={formInput.phone.label}
                        onChange={onInputChangeEventHandler}
                        onSaveHandler={updatePhoneHandler}
                    />
                    <ProfileRow
                        index={3}
                        loading={loading}
                        formInput={formInput.password}
                        value={profile.password}
                        placeholder={formInput.password.label}
                        onChange={onInputChangeEventHandler}
                        onSaveHandler={updatePasswordHandler}
                    />
                    {/* <div className="manage_collaborators">
                        <div className="group_row">
                            <h4>Manage Collaborators</h4>
                            <button>View Collaborators</button>
                        </div>
                        <div className="collaborators">
                            <div className="left_side">
                                <p>Collaborator 1</p>
                                <h6>user@planaday.com</h6>
                            </div>
                            <div className="right_side">
                                <button>Manage Access</button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </NewDashboardLayout>
    );
}
