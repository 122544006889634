import { MutableRefObject, useEffect } from "react";

type closeDropdownType = () => void;

export default function useClickOutside(_ref: MutableRefObject<unknown>, closeDropdown: closeDropdownType) {
    const ref = _ref as { current?: { contains: (value: string) => boolean } };
    useEffect(() => {
        function handleClickOutside(_event: unknown) {
            const event = _event as { target: string };
            if (ref.current && !ref.current?.contains(event.target)) {
                closeDropdown();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, closeDropdown]);
}
