import { PropsWithChildren } from "react";
import logo from "@/assets/img/logo_small.png";
import "@/styles/onboarding_layout.scss";

interface Props {
	text: string;
	className?: string;
	text2?: string;
	sidebarContent?: JSX.Element;
}

export default function NewOnboardingLayout({ children, sidebarContent, text, text2, className = "" }: PropsWithChildren<Props>) {
    return (
        <div className={`onboarding_layout ${className}`}>
            <aside>
                <div className="aside_shadow" />
                <div className="aside_content">
                    <div className="logo_wrapper">
                        <img src={logo} alt="logo" className="logo" />
                    </div>
                    {sidebarContent && sidebarContent}
                    <p className="title">
                        {text} {text2 && <span>{text2}</span>}
                    </p>
                </div>
            </aside>
            <main>{children}</main>
        </div>
    );
}
