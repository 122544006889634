/* eslint-disable max-len */

export default function PlannerIcon() {
    return (
        <svg
            viewBox="-22.6 0 383.719 383.719"
            xmlns="http://www.w3.org/2000/svg"
            className="planner_icon"
            fill="#fff"
            stroke="#CB3050"
            strokeWidth="5">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <defs></defs>
                <path
                    className="a"
                    d="M167.263,84.683a7.548,7.548,0,0,1,6.039-1.767,7.123,7.123,0,0,1,5.124,3.407,10.06,10.06,0,0,1,1.055,4.053,30.189,30.189,0,0,1-3.079,16,19.166,19.166,0,0,1-4.962,6.513,12.715,12.715,0,0,1-5.917,2.667l-1.013.157c-.486,1.817-1.031,3.348-1.3,4.161-1.82,4.94-12.475,22.718-25.572,27.655a67.554,67.554,0,0,0,1.066,11.639c.6,3.439.414,6.512,5.195,8.239.316.114,13.875,6.564,14.359,6.764,0,0-2.4,35.867-42.1,35.867S71.8,174.912,71.8,174.912c.921-.314,16.042-7.386,16.358-7.5,4.781-1.727,4.592-4.8,5.2-8.239a66.832,66.832,0,0,0,1.062-12.2A43.993,43.993,0,0,1,79.427,134.96a51.361,51.361,0,0,1-9.786-19.1l-1.929-.3A12.732,12.732,0,0,1,61.8,112.9a19.182,19.182,0,0,1-4.963-6.514,30.2,30.2,0,0,1-3.079-16,10.076,10.076,0,0,1,1.055-4.053,7.127,7.127,0,0,1,5.124-3.407,7.548,7.548,0,0,1,6.039,1.767s1-42.936,49.593-42.936S167.263,84.683,167.263,84.683Z"></path>
                <path
                    className="a"
                    d="M86.21,163.658c1.706,5.742,3.71,14.685,8.264,27.732,7.335,21.015,17.387,44.468,22.8,56.15l.371.8a593.2,593.2,0,0,0,20.9-58.363,203.17,203.17,0,0,0,6.144-27.062l-58.471.741"></path>
                <path
                    className="b"
                    d="M91.861,148.484A80.234,80.234,0,0,1,90.5,161.03a10.338,10.338,0,0,1-.447,1.787,3.376,3.376,0,0,1-.78,1.168,8.984,8.984,0,0,1-2.678,1.445c-3.787,1.681-7.521,3.487-11.285,5.22q-1.59.732-3.185,1.456-.508.229-1.018.455c-.712.317.344-.118-.09.037a2.463,2.463,0,0,0-1.676,2.952,2.422,2.422,0,0,0,2.952,1.677c1.569-.563,3.084-1.351,4.6-2.045q3.623-1.663,7.236-3.345c1.63-.755,3.274-1.483,4.893-2.262a.873.873,0,0,0,.171-.076c-.028.023-.924.376-.344.153.284-.109.565-.226.845-.347a10.2,10.2,0,0,0,2.764-1.728,8.47,8.47,0,0,0,2.454-4.23,54.255,54.255,0,0,0,.963-5.71,73.471,73.471,0,0,0,.8-9.153c.063-3.09-4.737-3.089-4.8,0Z"></path>
                <path
                    className="b"
                    d="M135.1,148.484a96.844,96.844,0,0,0,1.448,13.284c.512,3.214,2.025,5.907,5.07,7.336.181.085,1.349.394,1.5.622-.009-.014-.783-.358-.406-.168.211.107.431.2.645.3q1.19.556,2.377,1.119,3.262,1.542,6.524,3.087c1.544.731,3.075,1.514,4.646,2.185a2.48,2.48,0,0,0,3.283-.861,2.417,2.417,0,0,0-.861-3.284c-3.289-1.4-6.49-3.059-9.723-4.587-1.456-.689-2.905-1.4-4.374-2.059a10.946,10.946,0,0,1-2.52-1.275,3.835,3.835,0,0,1-1.338-2.625,70.177,70.177,0,0,1-1.468-13.073c-.063-3.084-4.863-3.095-4.8,0Z"></path>
                <path
                    className="c"
                    d="M58.576,42.026C65.857,18.919,89.29,2.45,116.27,2.45c30.905,0,51.531,17.073,58.469,44.254,1.914,7.5,3.747,24.929.125,36.63a7.132,7.132,0,0,0-1.562-.418,7.645,7.645,0,0,0-4.835.906A74.393,74.393,0,0,1,150.491,68.9c-6.186-6.852-11.445-15.667-14.223-20.556C127.6,55.673,121.4,59.117,107.124,66.774,89.713,76.117,68.165,82.969,65.491,83.228l-1.634.158a7.631,7.631,0,0,0-3.924-.47l-.193.031-2.4-8.311c-2.4-5.444-1.9-18.553-.1-27.454A50.864,50.864,0,0,1,58.576,42.026Z"></path>
                <path
                    className="c"
                    d="M55.912,118.954c1.6-2.766,4.037-5.042,7.611-4.886a12.222,12.222,0,0,0,4.189,1.494l1.93.3a51.347,51.347,0,0,0,9.785,19.1,43.83,43.83,0,0,0,15.19,12.107c-6.054,7.4-20.591,10.348-30.024,4.735-8.235-4.9-12.5-15.462-11.243-25.143A22.288,22.288,0,0,1,55.912,118.954Z"></path>
                <path
                    className="b"
                    d="M59.415,73.424c.162.38-.145-.441-.175-.534-.107-.331-.2-.667-.285-1a22.738,22.738,0,0,1-.45-2.283,42.708,42.708,0,0,1-.373-5.15,80.072,80.072,0,0,1,.673-12.087,51.852,51.852,0,0,1,6.6-19.79C72.609,20.33,84.774,11.5,98.31,7.462a65.591,65.591,0,0,1,43.125,2.069,52.021,52.021,0,0,1,26.8,26,63.215,63.215,0,0,1,5.451,18.49,79.627,79.627,0,0,1,.2,22.724,43.853,43.853,0,0,1-1.235,5.619c-.87,2.97,3.761,4.239,4.628,1.276,2.348-8.021,2.409-16.737,1.708-25.005a73.75,73.75,0,0,0-4.456-20.508c-5.09-13.185-14.309-24.474-26.969-31.007C133.755,0,116.97-1.655,101.862,1.6A63.163,63.163,0,0,0,63.787,26.255a53.556,53.556,0,0,0-8.855,20.289c-1.619,8-2.342,16.7-.983,24.788a19.613,19.613,0,0,0,1.321,4.515,2.415,2.415,0,0,0,3.284.861,2.465,2.465,0,0,0,.861-3.284Z"></path>
                <path
                    className="b"
                    d="M61.693,111.79c-5.133.7-8.164,5.588-9.676,10.093a27.852,27.852,0,0,0-.455,15.146A27.246,27.246,0,0,0,60.3,151.665a24.6,24.6,0,0,0,16.54,5.453,28.023,28.023,0,0,0,17.872-6.672c2.308-2.064-1.1-5.449-3.394-3.394-6.818,6.1-18.773,7.229-26.412,2.11a21.472,21.472,0,0,1-8.133-11.312,24.146,24.146,0,0,1-.688-12.757c.934-3.822,2.8-8.118,6.884-8.675a2.413,2.413,0,0,0,1.676-2.952,2.464,2.464,0,0,0-2.952-1.676Z"></path>
                <path
                    className="b"
                    d="M100.51,56.22c-.134.407-.273.812-.423,1.214-.089.24-.183.478-.277.717-.028.071-.15.363-.053.134-.349.817-.742,1.617-1.166,2.4q-.6,1.111-1.3,2.167-.381.579-.787,1.14-.149.2-.3.4c-.484.646.271-.313-.243.309a33.036,33.036,0,0,1-3.467,3.64,25.967,25.967,0,0,1-3.989,2.931,2.4,2.4,0,0,0,2.422,4.145A33.173,33.173,0,0,0,105.138,57.5a2.4,2.4,0,1,0-4.628-1.276Z"></path>
                <path
                    className="b"
                    d="M168.96,86.38a5.042,5.042,0,0,1,7.209.845c.939,1.385.97,3.314,1.011,4.925a26.7,26.7,0,0,1-.366,5.335c-1.164,6.561-4.624,14.5-11.929,15.762a2.421,2.421,0,0,0-1.677,2.953,2.456,2.456,0,0,0,2.953,1.676c7.675-1.327,12.247-8.515,14.389-15.434a32,32,0,0,0,1.38-11.4c-.241-3.784-1.4-7.377-4.9-9.334a10.063,10.063,0,0,0-11.467,1.279,2.42,2.42,0,0,0,0,3.394,2.45,2.45,0,0,0,3.394,0Z"></path>
                <path
                    className="b"
                    d="M163.045,109.943a25.445,25.445,0,0,1-1.217,6.43c-.229.765-.48,1.524-.734,2.281-.078.232-.159.464-.238.7-.161.468-.1.238-.032.1a51.519,51.519,0,0,1-3.332,6.115A60.191,60.191,0,0,1,144.1,141.036a25.475,25.475,0,0,1-9.935,5.037c-3.009.693-1.736,5.322,1.276,4.629,7.553-1.74,13.882-7.165,18.871-12.86a64.92,64.92,0,0,0,10.5-15.63,33.3,33.3,0,0,0,3.028-12.269c.1-3.09-4.7-3.086-4.8,0Z"></path>
                <path
                    className="b"
                    d="M67.669,82.986a10,10,0,0,0-10.036-1.928c-3.755,1.467-5.7,4.576-6.187,8.484A31.161,31.161,0,0,0,56.875,111.1a16.327,16.327,0,0,0,10.2,6.78,2.471,2.471,0,0,0,2.953-1.676,2.421,2.421,0,0,0-1.677-2.953c-7.3-1.263-10.764-9.2-11.928-15.762a26.7,26.7,0,0,1-.367-5.335c.04-1.606.064-3.549,1.011-4.925a5.042,5.042,0,0,1,7.209-.845,2.463,2.463,0,0,0,3.394,0,2.42,2.42,0,0,0,0-3.394Z"></path>
                <path
                    className="b"
                    d="M65.7,109.252c2.752,15.867,11.353,31.4,26.046,39.077a35.5,35.5,0,0,0,12.861,3.855,2.415,2.415,0,0,0,2.4-2.4,2.454,2.454,0,0,0-2.4-2.4c-15.051-1.566-26.8-15.073-31.538-28.629a67.5,67.5,0,0,1-2.74-10.779,2.42,2.42,0,0,0-2.953-1.676,2.455,2.455,0,0,0-1.676,2.952Z"></path>
                <path
                    className="b"
                    d="M168.924,81.3A74.524,74.524,0,0,1,150.5,65.255a121.607,121.607,0,0,1-12.159-18.127c-1.528-2.685-5.676-.269-4.145,2.423,8.079,14.2,18.133,27.331,32.305,35.9,2.651,1.6,5.066-2.547,2.423-4.145Z"></path>
                <path
                    className="b"
                    d="M67.557,145.864a13.434,13.434,0,0,0,11.818.793,2.4,2.4,0,0,0,1.1-1.434,2.454,2.454,0,0,0-.242-1.85,2.425,2.425,0,0,0-1.434-1.1,2.812,2.812,0,0,0-1.849.242c.039-.017.561-.214.255-.112-.074.024-.147.053-.221.079q-.258.087-.522.154-.242.064-.488.112c-.105.021-.211.039-.316.058-.346.063.219-.007.25-.029-.1.07-.412.044-.532.053q-.306.023-.613.03-.594.014-1.187-.031c-.187-.013-.377-.049-.565-.054.088,0,.547.083.215.028l-.282-.046a11.949,11.949,0,0,1-1.181-.272c-.386-.109-.752-.263-1.131-.39.085.029.479.22.191.076-.1-.052-.208-.1-.31-.151-.183-.093-.361-.193-.537-.3a2.4,2.4,0,0,0-2.422,4.145Z"></path>
                <path
                    className="b"
                    d="M65.491,85.628a43.246,43.246,0,0,0,8.617-2.346c5.307-1.75,10.547-3.711,15.716-5.833a201.882,201.882,0,0,0,34.012-17.59A137.715,137.715,0,0,0,137.188,49.7a2.421,2.421,0,0,0,0-3.394,2.452,2.452,0,0,0-3.395,0c-17.155,14.5-37.953,24.518-59.147,31.736-2.1.715-4.211,1.4-6.342,2.024-.7.2-1.408.4-2.119.577-.232.057-.466.107-.7.161-.528.122-.429.068,0,.021a2.471,2.471,0,0,0-2.4,2.4,2.415,2.415,0,0,0,2.4,2.4Z"></path>
                <path
                    className="b"
                    d="M92.023,187.847a84.549,84.549,0,0,0,17.728,1.056,2.456,2.456,0,0,0,2.4-2.4,2.421,2.421,0,0,0-2.4-2.4,78.8,78.8,0,0,1-16.452-.885,2.48,2.48,0,0,0-2.953,1.677,2.418,2.418,0,0,0,1.677,2.952Z"></path>
                <path
                    className="a"
                    d="M333.419,209.411c-2.189-1.006-6.275-1.931-8.485-.977-.715-.022-1.849-.888-3.045-.852-4.394.132-9.243,2.2-13.475,3.39-2.189.616-3.951,1.628-6.265,1.764s-14.54.209-18.824.4c.23-.374.374-.619.374-.619,2.414-4.076,8.415-7.4,11.425-12.121,1.616-2.535,3.009-4.817.643-7.08-2.127-2.035-4.262-1.129-6.483.37a142.793,142.793,0,0,0-14.113,10.572,32.639,32.639,0,0,0-9.331,11.912c-1.123,2.344-1.322,5.134-2.725,7.342a39.749,39.749,0,0,1-4.341,5.878c4.6,4.695,12.671,12.335,17.976,17.736,2.4-2.328,5.333-4.942,7.271-5.86a93.473,93.473,0,0,1,14.643-3.218,50.331,50.331,0,0,0,13.516-3.862c3.963-1.588,9.475-4.758,13.194-6.758C330.769,224.531,340.982,214.56,333.419,209.411Z"></path>
                <path
                    className="b"
                    d="M260.477,231.086a39.331,39.331,0,0,0,4.4-5.884c1.45-2.259,1.88-4.776,2.8-7.247a28.589,28.589,0,0,1,7.719-10.7,118.722,118.722,0,0,1,13.33-10.3c1.278-.874,3.787-3.36,5.34-1.939,1.472,1.346-.761,3.912-1.651,5.078-3.165,4.147-8.034,6.714-10.794,11.213-1.62,2.641,2.53,5.055,4.144,2.423a24.735,24.735,0,0,1,4.968-5.2,40.287,40.287,0,0,0,5.715-5.861c1.645-2.2,3.548-5.16,2.816-8.034a6.554,6.554,0,0,0-7.176-4.786c-2.823.445-5.283,2.6-7.56,4.193-2.636,1.841-5.222,3.754-7.735,5.761-4.257,3.4-8.349,7.025-11.194,11.73a31.99,31.99,0,0,0-2.25,4.386c-.631,1.539-.971,3.165-1.519,4.732a16.06,16.06,0,0,1-2.028,3.547,26.817,26.817,0,0,1-2.726,3.5c-2.124,2.243,1.265,5.643,3.394,3.4Z"></path>
                <path
                    className="b"
                    d="M282.84,215.569c6.133-.345,12.29-.2,18.428-.395a17.31,17.31,0,0,0,5.446-1.1c2.4-.872,4.867-1.578,7.3-2.354a29.53,29.53,0,0,1,7.46-1.727c.984-.044,1.756.526,2.69.737,1.134.256,2.115-.307,3.228-.3,1.544.012,3.93.358,5.064,1.226a2.843,2.843,0,0,1,1.238,2.806c-.023.442.125-.545-.026.21-.032.165-.07.329-.112.491a8.367,8.367,0,0,1-.323.987,17.035,17.035,0,0,1-1.133,2.017,21.767,21.767,0,0,1-3.386,3.84c-2.551,2.362-5.6,3.916-8.75,5.631a87.8,87.8,0,0,1-12.531,5.809c-5.608,2-11.474,2.353-17.236,3.7-2.808.656-6.136,1.131-8.577,2.728a45.835,45.835,0,0,0-6.569,5.55c-2.225,2.151,1.171,5.543,3.394,3.395,2.191-2.118,4.579-4.792,7.583-5.632a97.969,97.969,0,0,1,10.934-2.5,65.363,65.363,0,0,0,10.62-2.22,74.061,74.061,0,0,0,8.755-3.53c2.966-1.431,5.842-3.04,8.73-4.619a35.559,35.559,0,0,0,5.814-3.837c3.579-2.974,7.6-7.43,7.628-12.377a7.831,7.831,0,0,0-4.141-6.887c-2.822-1.4-7.06-2.272-10.078-1.1h1.276a17.926,17.926,0,0,1-2.065-.712,7.822,7.822,0,0,0-3.071-.121,34.8,34.8,0,0,0-5.765,1.211c-3.67,1.077-7.256,2.47-10.929,3.519a22.07,22.07,0,0,1-5.668.423c-2.613.045-5.227.079-7.84.124-2.463.042-4.931.062-7.39.2-3.071.172-3.091,4.973,0,4.8Z"></path>
                <path
                    className="b"
                    d="M293.837,221.975c-6.6-.023-13.556-1.265-19.5,2.419-2.621,1.624-.214,5.778,2.422,4.145,5.043-3.125,11.33-1.784,17.079-1.764,3.089.011,3.093-4.789,0-4.8Z"></path>
                <path
                    className="b"
                    d="M321.125,209.23a32.784,32.784,0,0,1-8.37,4.236,54.713,54.713,0,0,1-9.195,2.436,2.42,2.42,0,0,0-1.676,2.953,2.456,2.456,0,0,0,2.952,1.676A54.762,54.762,0,0,0,314.3,218a37.2,37.2,0,0,0,9.245-4.622,2.471,2.471,0,0,0,.861-3.284,2.419,2.419,0,0,0-3.284-.861Z"></path>
                <path
                    className="b"
                    d="M325.447,214.565c-.054.046-.539.432-.223.193s-.242.163-.3.2c-.31.21-.629.407-.951.6-.818.479-1.665.907-2.521,1.313-1.913.907-3.53,1.548-5.665,2.336a68.958,68.958,0,0,1-9.878,3.087,2.4,2.4,0,0,0,1.276,4.629,70.235,70.235,0,0,0,10.368-3.27c3.847-1.441,8.145-2.944,11.287-5.691a2.4,2.4,0,0,0-3.394-3.394Z"></path>
                <path
                    className="d"
                    d="M256.064,226.491c-3.947,4.179-12.929,14.733-19.709,21.986-1.556,1.664-4.518,4.947-7.7,8.483l-3.871-1.449c-3.8-2.126-6.217-9.942-7.862-14.083-3.644-9.178-6.512-18.485-10.032-27.8-2.184-5.777-5.576-20.886-9.256-23.135-2.152-1.718-4.572-2.581-6.991-3.9a391.475,391.475,0,0,0-38.682-18.946c-3.172-2.653-6.031-4.592-7.278-4.736a203.341,203.341,0,0,1-6.144,27.062,593.224,593.224,0,0,1-20.9,58.363l-.371-.8c-5.41-11.682-15.462-35.135-22.8-56.15-4.554-13.047-6.558-21.99-8.264-27.732-1.619.079-6.232,3.716-10.622,8-10.4,4.715-19.425,9.477-29.473,14.942-2.494,1.357-5.417,2.548-7.582,4.354C34.241,192.871,28.2,211.59,23.65,224.3,20.4,233.39,9.392,267.541,6.289,277.156c-3.64,11.279-6.815,18.85.6,28.647,3.528,4.664,8.638,8.485,13.084,11.449,5.5,3.67,20.06,13.136,31,19.674-1.664,12.293-3.274,23.905-3.981,30.31,4.793.626,9.938,1.171,15.176,1.584,6.693.528,13.54.841,20.006.834l35.453-6.175,6.8,4.921c3.67,3.262,8.391,4.1,13.981,4.093,14.244-.012,40.619-2.626,51.34-5.35-1.87-16.9-9.9-69.295-8.889-76.1,1.032-6.977,2.123-14.1,3.228-21.213,4.835,9.95,10.017,18.925,17.528,27.042,6.346,6.859,11.8,10.788,21.532,10.769,9.037-.017,13.982-6.153,19.564-12.267,6.44-7.053,34.858-39.151,38.329-43.611C276.889,246.65,259.988,231.113,256.064,226.491ZM55.9,295.264c-4.534-3.165-8.823-6.327-11.831-8.645l.131-.943c.488-3.512,3.287-10.322,6.759-17.679q.53-1.121,1.078-2.256c1.311,8.414,2.623,16.934,3.861,25.3A24.919,24.919,0,0,1,55.9,295.264Z"></path>
                <path
                    className="d"
                    d="M115.306,253.3c6.4-14.154,16.594-41.046,23.231-63.324a203.17,203.17,0,0,0,6.144-27.062c3.246.374,17.431,12.925,19.6,18.547.143.372-6.416,6.812-9.384,9.475,1.373,2.011,7.043,8.866,7.14,9.511.064.425-16.851,28.19-29.41,48.817l-5.429,8.925Z"></path>
                <path
                    className="b"
                    d="M118.026,253.383c5.143-11.393,9.541-23.136,13.707-34.915,4.786-13.529,9.454-27.231,12.644-41.238,1.032-4.531,1.832-9.1,2.618-13.675l-2.952,1.677c.271.039,1.062.392.389.064.389.19.771.388,1.144.61a30.664,30.664,0,0,1,2.806,1.93,76.439,76.439,0,0,1,6.568,5.753,51.97,51.97,0,0,1,5.629,6.268c.316.444.622.9.9,1.367.131.221.257.445.374.674.613,1.2-.325-.368.3-1.5-.189.344.394-.419.107-.124-.145.15-.272.321-.411.476-.392.442-.8.869-1.212,1.294-2.4,2.482-4.879,4.9-7.442,7.2a2.411,2.411,0,0,0-.375,2.908c2.218,3.207,4.655,6.126,6.914,9.082.05.064.095.218.159.267-.35-.265-.16-1.732-.184-1.648.263-.927.277-.572.131-.338-.171.273-.312.573-.471.854-.617,1.089-1.256,2.166-1.894,3.243-5.259,8.875-10.641,17.678-16,26.492q-5.459,8.98-10.926,17.954c-1.61,2.646,2.539,5.06,4.145,2.423q9.82-16.131,19.615-32.277c2.414-3.991,4.827-7.982,7.206-11.994.655-1.1,1.31-2.208,1.945-3.323a5.26,5.26,0,0,0,.972-2.435,4.325,4.325,0,0,0-1.11-2.44c-.752-1.035-1.545-2.041-2.332-3.049-1.482-1.9-2.752-3.392-4.026-5.234l-.375,2.909c2.5-2.25,4.919-4.6,7.261-7.016a16.1,16.1,0,0,0,2.384-2.707c.837-1.4.181-2.751-.561-4.026a38.642,38.642,0,0,0-5.839-7.2,78.815,78.815,0,0,0-7.624-6.908c-1.884-1.489-4.4-3.817-6.892-4.176-1.181-.17-2.711.275-2.952,1.676a250.258,250.258,0,0,1-7.509,31.56Q128.636,214,121.007,233.7c-2.242,5.806-4.564,11.586-7.125,17.259a2.473,2.473,0,0,0,.861,3.284,2.418,2.418,0,0,0,3.283-.861Z"></path>
                <path
                    className="b"
                    d="M82.175,367.254a273.662,273.662,0,0,1-35.182-2.418l2.4,2.4c.916-8.264,2.04-16.012,3.143-24.129,1.393-10.249,2.8-20.5,4.043-30.767.5-4.116.977-8.237,1.356-12.366a63.447,63.447,0,0,0,.438-7.926,63.358,63.358,0,0,0-.989-7.179q-.891-5.921-1.8-11.837-1.824-11.826-3.689-23.646c-2.16-13.788-4.349-27.576-6.229-41.406q-.275-2.026-.535-4.056a2.479,2.479,0,0,0-2.4-2.4,2.414,2.414,0,0,0-2.4,2.4c1.733,13.461,3.832,26.873,5.925,40.282q3.671,23.5,7.262,47.017c.16,1.052,0-.5.03.253.008.191.024.381.03.572.017.486.011.972,0,1.457-.033,1.32-.122,2.639-.223,3.956-.3,3.855-.726,7.7-1.173,11.54-1.158,9.949-2.507,19.857-3.844,29.71-1.19,8.765-2.437,17.524-3.484,26.307q-.132,1.109-.255,2.218c-.156,1.4,1.236,2.249,2.4,2.4a273.662,273.662,0,0,0,35.182,2.418c3.089,0,3.094-4.8,0-4.8Z"></path>
                <path
                    className="b"
                    d="M192.029,201.4c-1.689,13.277-3.785,26.5-5.855,39.726-2.453,15.674-4.956,31.34-7.294,47.031a37.258,37.258,0,0,0-.338,8.432c.265,3.77.677,7.53,1.107,11.284,1.173,10.247,2.567,20.469,3.952,30.689,1.219,9,2.417,17.353,3.49,26.344q.133,1.119.258,2.237l1.762-2.314a160.387,160.387,0,0,1-22.076,3.435c-8.574.917-17.2,1.617-25.82,1.8-4.946.1-10.118.154-14.355-2.763a2.422,2.422,0,0,0-3.283.861,2.448,2.448,0,0,0,.861,3.284c4.289,2.953,9.576,3.479,14.648,3.447,4.068-.025,8.136-.219,12.195-.477,9.178-.582,18.346-1.494,27.449-2.808a115.1,115.1,0,0,0,11.657-2.147,2.412,2.412,0,0,0,1.762-2.315c-.942-8.477-2.163-16.927-3.311-25.378-1.379-10.151-2.763-20.252-3.988-30.473-.777-6.485-1.882-13.183-1.643-19.735.028-.794-.083.419,0-.1.158-.974.29-1.952.436-2.927q.441-2.949.888-5.9.9-5.942,1.827-11.88,1.827-11.762,3.687-23.519c2.175-13.852,4.367-27.706,6.231-41.6q.284-2.115.553-4.231a2.412,2.412,0,0,0-2.4-2.4,2.461,2.461,0,0,0-2.4,2.4Z"></path>
                <path
                    className="b"
                    d="M196.421,192.569c.417.27-.388-.4-.018-.02.076.078.161.144.233.226.1.11.194.218.29.328.239.277-.314-.481.027.049a20.2,20.2,0,0,1,1.187,2.077,43.181,43.181,0,0,1,2.01,5.09c1.337,3.905,2.415,7.891,3.687,11.816,1.75,5.4,3.84,10.716,5.707,16.083,1.928,5.547,4.006,11.028,6.08,16.522,1.692,4.483,3.57,10.252,7.946,12.843,2.664,1.577,5.082-2.57,2.423-4.145a6.514,6.514,0,0,1-1.7-1.6,18.739,18.739,0,0,1-1.57-2.606,54.1,54.1,0,0,1-2.521-5.914c-2.111-5.6-4.222-11.187-6.186-16.84-1.839-5.293-3.913-10.527-5.624-15.861-1.25-3.9-2.327-7.851-3.659-11.724-1.208-3.51-2.573-8.321-5.884-10.469-2.6-1.686-5.008,2.468-2.423,4.145Z"></path>
                <path
                    className="b"
                    d="M218.888,271.281c4.749-5.017,9.3-10.23,13.926-15.358,5.385-5.967,10.839-11.866,16.155-17.9,2.908-3.3,5.777-6.639,8.792-9.84h-3.394c2.778,3.25,5.97,6.179,9.026,9.161,3.884,3.792,7.8,7.553,11.607,11.425,1.491,1.517,3,3.04,4.344,4.689v-3.395c-3.38,4.327-7.134,8.392-10.748,12.521q-8.376,9.573-16.83,19.079c-4.394,4.947-8.781,9.9-13.261,14.77-3.486,3.79-7.275,7.529-12.514,8.558a21.338,21.338,0,0,1-14.365-2.626c-5.222-3.077-9.487-8.119-13.111-12.886A117.374,117.374,0,0,1,187.3,270.932c-1.385-2.762-5.527-.334-4.145,2.422a122.266,122.266,0,0,0,10.067,17,68.563,68.563,0,0,0,12.412,13.654,26.863,26.863,0,0,0,14.792,5.928,22.423,22.423,0,0,0,8.944-.867,23.505,23.505,0,0,0,7.379-4.223c3.375-2.743,6.2-6.143,9.1-9.362q3.194-3.539,6.358-7.1,8.658-9.72,17.245-19.5c4.463-5.084,9.118-10.082,13.285-15.415a2.5,2.5,0,0,0,0-3.395,121.858,121.858,0,0,0-9.189-9.513c-3.85-3.78-7.748-7.512-11.562-11.327-1.442-1.442-2.9-2.883-4.226-4.434a2.418,2.418,0,0,0-3.394,0c-5.99,6.36-11.571,13.1-17.463,19.553q-7.254,7.941-14.46,15.925c-2.3,2.551-4.588,5.119-6.95,7.615-2.123,2.242,1.265,5.643,3.394,3.394Z"></path>
                <path
                    className="b"
                    d="M50.111,264.222c-1.938,4.01-3.8,8.058-5.476,12.187a38.466,38.466,0,0,0-2.751,8.629,2.479,2.479,0,0,0,1.676,2.952,2.417,2.417,0,0,0,2.952-1.676,37.539,37.539,0,0,1,2.627-8.322c1.547-3.85,3.312-7.613,5.117-11.347a2.464,2.464,0,0,0-.861-3.284,2.422,2.422,0,0,0-3.284.861Z"></path>
                <path
                    className="a"
                    d="M176.054,231.183H151.028c0,1.814.04,6.6.04,8.786,4.177,0,21.843.04,24.986.04C176.054,236.986,176.023,231.544,176.054,231.183Z"></path>
                <path
                    className="b"
                    d="M176.054,229.183H151.028a2.029,2.029,0,0,0-2,2c0,2.929.038,5.857.04,8.786a2.03,2.03,0,0,0,2,2c8.329,0,16.657.039,24.986.04a2.03,2.03,0,0,0,2-2c0-2.93-.163-5.9,0-8.826.143-2.572-3.857-2.564-4,0-.163,2.926,0,5.9,0,8.826l2-2c-8.329,0-16.657-.04-24.986-.04l2,2c0-2.929-.038-5.857-.04-8.786l-2,2h25.026C178.628,233.183,178.632,229.183,176.054,229.183Z"></path>
                <path
                    className="b"
                    d="M38.591,284.728a75.025,75.025,0,0,0,7.931,6.238q6.726,5,13.69,9.673c7.267,4.854,14.856,9.221,22.284,13.822,3.334,2.065,6.8,4.053,9.928,6.426l-.919-2.259c-.083.274-.177.542-.277.81-.061.161-.125.32-.189.48a1.909,1.909,0,0,1-.122.289q-.578,1.308-1.207,2.591c-1.108,2.267-2.29,4.5-3.5,6.715-2.656,4.88-5.429,9.706-8.386,14.411-.634,1.009-1.277,2.012-1.945,3-.268.395-.539.788-.818,1.175-.119.166-.241.329-.362.494-.283.383.2-.25-.129.161l1.414-.585c.6.077.316.057-.007-.066-.179-.068-.939-.368-.5-.18-.731-.31-1.448-.655-2.161-1-2.081-1.016-4.13-2.1-6.171-3.191q-6.3-3.376-12.5-6.957c-10.284-5.958-20.281-12.445-30.216-18.962-5.7-3.736-11.624-7.543-15.827-13.016a2.065,2.065,0,0,0-2.736-.718,2.012,2.012,0,0,0-.717,2.736c5.954,7.755,14.647,12.787,22.741,18.02,8.24,5.328,16.514,10.644,25.012,15.553,6.458,3.732,12.987,7.538,19.775,10.644a10.207,10.207,0,0,0,2.977,1.1c1.686.185,2.573-1.581,3.4-2.789,1.985-2.884,3.787-5.9,5.563-8.915,2.3-3.912,4.532-7.871,6.643-11.89a52.335,52.335,0,0,0,4.088-8.838,2.064,2.064,0,0,0-.919-2.259c-2.655-2.013-5.574-3.716-8.4-5.475-3.873-2.413-7.77-4.789-11.668-7.161-8.209-5-16.214-10.226-23.97-15.908A80.789,80.789,0,0,1,41.42,281.9c-1.841-1.8-4.672,1.023-2.829,2.828Z"></path>
                <path
                    className="b"
                    d="M84.819,165.064q-12.574,5.221-24.708,11.4c-3.8,1.938-7.572,3.935-11.329,5.959-3.93,2.118-8.217,3.931-11.757,6.67a2.413,2.413,0,0,0,0,3.394,2.464,2.464,0,0,0,3.4,0,33.157,33.157,0,0,1,5.415-3.042c1.94-.984,3.838-2.053,5.753-3.084,3.63-1.953,7.273-3.881,10.946-5.753,7.714-3.933,15.564-7.6,23.561-10.919a2.479,2.479,0,0,0,1.677-2.952,2.415,2.415,0,0,0-2.953-1.676Z"></path>
                <path
                    className="b"
                    d="M151.322,169.973c7.743,3.233,15.346,6.791,22.822,10.6q5.343,2.722,10.616,5.573c1.825.981,3.631,2,5.475,2.951a35.457,35.457,0,0,1,5.7,3.1,2.481,2.481,0,0,0,3.394,0,2.416,2.416,0,0,0,0-3.4,33.531,33.531,0,0,0-5.865-3.448c-2-.985-3.935-2.091-5.894-3.145-3.648-1.963-7.31-3.9-11-5.78q-11.774-6-23.969-11.084a2.414,2.414,0,0,0-2.952,1.677,2.462,2.462,0,0,0,1.676,2.952Z"></path>
                <path
                    className="d"
                    d="M117.271,247.54c-5.41-11.682-15.462-35.135-22.8-56.15-4.554-13.047-6.558-21.99-8.264-27.732-3.143.153-17.562,13.714-19.741,19.371-.143.372,6.415,5.768,9.384,8.431-1.637,1.9-7.043,8.345-7.14,8.99-.053.354,18.063,22.69,31.808,39.726l13.951,14.738Z"></path>
                <path
                    className="b"
                    d="M102.218,238.479q-10.374-12.858-20.752-25.713-3.915-4.868-7.806-9.755-.619-.783-1.237-1.57l-.519-.663c-.062-.08-.124-.161-.187-.241-.28-.359.346.458-.042-.055-.2-.267-.395-.542-.6-.8-.676-.847.311-.462-.033,1.364-.136.722-.123.323.149-.074.219-.32.455-.629.69-.937-.223.292.029-.038.063-.081q.326-.417.656-.83.539-.674,1.084-1.342,1.907-2.334,3.869-4.623a2.437,2.437,0,0,0,0-3.394c-2.364-2.113-4.811-4.131-7.183-6.234-.446-.4-.891-.791-1.323-1.2-.166-.158-.325-.322-.49-.48-.325-.31-.133-.286.03.086.68,1.562-.261,2.663.3,1.553.1-.194.2-.384.309-.572.3-.523.626-1.027.968-1.521a52.246,52.246,0,0,1,5.286-6.189,82.3,82.3,0,0,1,6.67-6.213,26.105,26.105,0,0,1,2.881-2.15c.419-.27.851-.5,1.286-.743.518-.285-.288.108.011,0,.251-.095.824-.111-.08-.037L83.9,164.3c1.275,4.31,2.356,8.676,3.593,13,1.821,6.358,3.892,12.633,6.132,18.855,4.529,12.583,9.533,25,14.793,37.289,2.2,5.133,4.44,10.247,6.784,15.314a2.421,2.421,0,0,0,3.284.861,2.456,2.456,0,0,0,.861-3.284c-4.62-9.987-8.911-20.132-13.017-30.34-5.044-12.54-9.886-25.208-13.691-38.185-1.439-4.909-2.66-9.879-4.111-14.784a2.431,2.431,0,0,0-2.314-1.762c-2.374.194-4.417,1.914-6.221,3.32a75.947,75.947,0,0,0-7.311,6.588,61.309,61.309,0,0,0-6.534,7.616,16.767,16.767,0,0,0-1.69,2.891c-.736,1.677-.084,2.824,1.127,3.983,2.741,2.625,5.746,4.972,8.575,7.5v-3.394a86.734,86.734,0,0,0-7.2,8.909c-1.531,2.351.466,4.111,1.855,5.888,5.674,7.258,11.506,14.4,17.293,21.564Q92.465,234,98.824,241.873a2.415,2.415,0,0,0,3.394,0,2.456,2.456,0,0,0,0-3.394Z"></path>
                <path
                    className="a"
                    d="M66.915,233.578c7.831,1.958,75,28.95,88.706,35.15C152.8,279.1,114.58,375.764,111.97,381.311c-9.983-3.4-74.131-29.688-87.836-35.887C25.521,340.107,64.3,239.125,66.915,233.578Z"></path>
                <path
                    className="b"
                    d="M66.277,235.892a102.143,102.143,0,0,1,10.546,3.673c6.345,2.373,12.658,4.831,18.963,7.308q21.835,8.574,43.524,17.519,2.445,1.01,4.887,2.029l2.178.912.675.284,1.022.433c2.12.9,4.239,1.8,6.338,2.75l-1.1-2.711c-1.354,4.947-3.357,9.766-5.149,14.563-3.288,8.8-6.664,17.572-10.057,26.334q-5.888,15.2-11.867,30.372-5.179,13.149-10.425,26.273-1.836,4.584-3.694,9.16-.651,1.6-1.312,3.2c-.086.207-.171.414-.258.621-.095.228-.438,1-.032.079-.125.284-.242.572-.366.856-.082.186-.163.372-.249.556l2.71-1.1c-10.194-3.483-20.149-7.8-30.127-11.847q-21.223-8.6-42.346-17.445c-4.574-1.922-9.841-4.117-14.79-6.354l1.1,2.711c1.136-4.3,3-8.492,4.547-12.648q4.832-12.966,9.776-25.889,5.847-15.358,11.754-30.693Q57.736,263.3,63,249.788q1.866-4.776,3.756-9.543.651-1.638,1.312-3.273.251-.617.5-1.232.1-.236.2-.471c.128-.313-.3.677-.042.1.085-.193.168-.388.257-.58a2.468,2.468,0,0,0-.861-3.284,2.42,2.42,0,0,0-3.283.862c-2,4.271-3.582,8.778-5.3,13.165Q54.45,258.51,49.45,271.524q-5.937,15.394-11.817,30.813-5.16,13.53-10.251,27.084c-1.837,4.907-3.809,9.8-5.393,14.8-.059.187-.119.374-.169.564a2.428,2.428,0,0,0,1.1,2.71c10.994,4.968,22.206,9.483,33.356,14.086C70.151,367.309,84.044,372.99,98,378.517c4.421,1.752,8.835,3.571,13.336,5.108a2.4,2.4,0,0,0,2.71-1.1c1.951-4.163,3.547-8.523,5.26-12.786q5.067-12.6,10.04-25.246,5.971-15.135,11.864-30.3,5.31-13.676,10.505-27.4c2.06-5.473,4.23-10.946,5.961-16.536.092-.3.181-.595.264-.894a2.427,2.427,0,0,0-1.1-2.711c-11.225-5.071-22.724-9.583-34.142-14.195-14.115-5.7-28.254-11.356-42.5-16.735a119.1,119.1,0,0,0-12.642-4.461C64.557,230.506,63.279,235.134,66.277,235.892Z"></path>
                <path
                    className="a"
                    d="M140.548,332.065c-1.157-2.24-8.825-3.748-12.617-4.832-5.669-1.62-10.19-5.2-16.2-4.43-5.624.718-10.745,1.557-16.521,2.407L91.6,323.472c-2.939,6.071-8.525,15.919-12.413,22.042l7.345,4.395c4.443,6.238,13.924,14.683,21.5,17.148,2.119.824,8.385.851,11.225-.912,1.086-.573,1.95-2.708,1.9-4.623a6.17,6.17,0,0,0,6.681-7.306c5.041,2.275,8.834-4.164,8.089-7.808-.4-3.153-4.861-5.544-8.5-7.574C130.2,339.7,141.88,340.592,140.548,332.065Z"></path>
                <path
                    className="d"
                    d="M92.853,320.729a16.33,16.33,0,0,0,.581-1.569c-3.476-2.658-20.943-13.139-26.169-16.355-10.631-6.543-25.319-17.559-27.259-19.491L22.7,319.055c6.985,4.593,19.962,12.956,29.577,18.641,5.3,3.132,21.671,12.267,23.716,12.472C79.362,346.044,89.974,327.612,92.853,320.729Z"></path>
                <path
                    className="b"
                    d="M38.308,285.011a75.853,75.853,0,0,0,8.012,6.3q6.727,5,13.69,9.672c7.267,4.855,14.856,9.222,22.284,13.823,3.334,2.065,6.8,4.052,9.928,6.425l-1.1-2.71c-.082.273-.176.542-.277.809-.061.161-.127.32-.189.481-.2.514.169-.36-.081.193-.393.869-.789,1.735-1.208,2.591-1.108,2.267-2.29,4.5-3.5,6.715-2.655,4.88-5.428,9.706-8.385,14.411-.634,1.009-1.278,2.012-1.946,3-.267.395-.538.788-.817,1.175-.12.166-.243.328-.363.494-.318.441.312-.386-.066.08l1.7-.7c1.056.137.183-.021-.111-.126-.164-.059-.325-.128-.487-.191.571.223-.009-.007-.236-.109-1.09-.49-2.159-1.023-3.224-1.563-2.756-1.4-5.476-2.867-8.185-4.353q-8.215-4.507-16.207-9.39c-6.925-4.248-13.774-8.621-20.58-13.056C20.761,314.942,14.289,311,9.5,305.254a18.7,18.7,0,0,1-4.663-10.386c-.263-3.754.711-7.407,1.8-10.965,1.64-5.351,3.435-10.662,5.157-15.988,3.946-12.205,7.883-24.416,11.971-36.574,1.867-5.552,3.833-11.07,5.8-16.588,1.371-3.849,2.77-7.692,4.32-11.473a50.316,50.316,0,0,1,4.107-8.356c.159-.244.326-.482.491-.721.225-.326-.189.176.227-.285a7.662,7.662,0,0,1,.93-.907,2.413,2.413,0,0,0,0-3.394,2.459,2.459,0,0,0-3.394,0c-2.563,2.027-4.054,5.661-5.352,8.551-2.085,4.642-3.849,9.425-5.566,14.213-2.5,6.978-4.959,13.972-7.3,21.006-4.746,14.256-9.346,28.56-13.962,42.858-1.584,4.9-3.394,9.794-3.945,14.95A21.631,21.631,0,0,0,3.675,305.4C9.106,313.7,18,318.886,26.142,324.169c14.54,9.436,29.266,18.873,44.862,26.5a31.506,31.506,0,0,0,3.7,1.639,3.46,3.46,0,0,0,1.6.216c1.159-.161,1.691-1.03,2.327-1.889,1.833-2.477,3.427-5.145,5.014-7.782a193.256,193.256,0,0,0,10.807-19.837,26.862,26.862,0,0,0,1.293-3.219,2.479,2.479,0,0,0-1.1-2.71c-2.655-2.013-5.574-3.716-8.4-5.476-3.873-2.413-7.77-4.788-11.668-7.16-8.209-5-16.214-10.227-23.97-15.908a76.786,76.786,0,0,1-8.906-6.927c-2.209-2.163-5.606,1.228-3.4,3.394Z"></path>
                <path
                    className="b"
                    d="M95.851,327.525c4.983-.736,9.956-1.545,14.95-2.2a14.908,14.908,0,0,1,6.471.4,64.479,64.479,0,0,1,6.132,2.416c3.525,1.463,7.23,2.179,10.867,3.288a14.892,14.892,0,0,1,3.992,1.646c.407.266.108.059.026.009a1.323,1.323,0,0,1,.271.265c0-.009-.4-.579-.332-.606,0,0,.026.556.025.639a2.654,2.654,0,0,1-.415,1.615,1.413,1.413,0,0,1-.374.451,1.446,1.446,0,0,1-.669.5,5.919,5.919,0,0,1-1.784.681,14.564,14.564,0,0,1-4.481.3c-3.086-.179-3.076,4.621,0,4.8,3.465.2,7.492-.262,10.154-2.715a7.619,7.619,0,0,0,2.361-5.32,4.988,4.988,0,0,0-2.763-5c-3.471-2-7.662-2.628-11.471-3.7-4.947-1.389-9.348-4.346-14.586-4.665-3.141-.192-6.431.6-9.53,1.053-3.375.5-6.745,1.023-10.12,1.521a2.416,2.416,0,0,0-1.676,2.952,2.462,2.462,0,0,0,2.952,1.677Z"></path>
                <path
                    className="b"
                    d="M125.554,340.531a45,45,0,0,1,5.963,3.618,7.361,7.361,0,0,1,1.719,1.783,2.273,2.273,0,0,1,.381,1.641,6.16,6.16,0,0,1-1.539,3.573,3.345,3.345,0,0,1-1.511,1.047,2.771,2.771,0,0,1-2.092-.291c-1.85-.776-3.251,1.386-2.953,2.952.364,1.906-1.533,4.738-3.728,4.354a2.437,2.437,0,0,0-3.038,2.314,4.135,4.135,0,0,1-.585,2.384,2.253,2.253,0,0,1-1.036.6,14.45,14.45,0,0,1-4.413.688,11.8,11.8,0,0,1-3.7-.335,20.463,20.463,0,0,1-4.054-1.764A50.27,50.27,0,0,1,88.6,348.7a2.467,2.467,0,0,0-3.283-.861,2.418,2.418,0,0,0-.861,3.283,58.2,58.2,0,0,0,15.089,14.314,29.526,29.526,0,0,0,8.388,4.109,20.3,20.3,0,0,0,8.838.016,9.235,9.235,0,0,0,4.6-2.025,8.491,8.491,0,0,0,2.181-6.012l-3.038,2.314a8.26,8.26,0,0,0,7.387-2.48,8.585,8.585,0,0,0,2.246-7.778L127.2,356.53c5.578,2.339,10.525-2.995,11.15-8.2.751-6.266-5.813-9.368-10.373-11.939-2.694-1.519-5.116,2.626-2.422,4.145Z"></path>
                <path
                    className="e"
                    d="M135.49,168.371a29.374,29.374,0,0,1-10.641,3.78c-10.239,1.66-22.808,1.073-27.8.818-6.663-.341-9.375-3.786-8.83-7.466,1.347-4.3,2.88-7.677,4.211-11.557a2.946,2.946,0,0,1,3.022-2.119,143.984,143.984,0,0,0,28.563.581c4.821-.434,9.809-2.633,13.834-2.019,2.623.4,2.735,3.249,3.662,7.754C142.3,162.017,139.816,165.694,135.49,168.371Z"></path>
                <path
                    className="b"
                    d="M134.279,166.3c-7.447,4.52-17.041,4.543-25.494,4.58-3.481.016-6.962-.08-10.439-.246a16.013,16.013,0,0,1-4.775-.716,8.455,8.455,0,0,1-1.55-.78,5.821,5.821,0,0,1-.667-.618,1.58,1.58,0,0,1-.424-.593,4.269,4.269,0,0,1-.216-.42c.047.11.116.348-.008-.08-.045-.158-.179-.907-.137-.5a4.557,4.557,0,0,1,.4-2.137c.743-2.216,1.6-4.39,2.439-6.572.384-1,.761-2,1.118-3.013.244-.691.263-.9.923-.976a18.025,18.025,0,0,1,3.407.328q1.912.172,3.827.313a139.725,139.725,0,0,0,14.585.351c2.539-.081,5.1-.195,7.622-.508,2.434-.3,4.8-.912,7.189-1.439a22.337,22.337,0,0,1,3.483-.566,9.8,9.8,0,0,1,1.542,0c1.326.13.414.052.343.04.532.085-.177-.176.246.078-.447-.268.34.391.031.023.333.4.189.242.361.77a23.276,23.276,0,0,1,.651,2.808c.342,1.776.841,3.425.1,5.161a11.208,11.208,0,0,1-4.556,4.711c-2.6,1.648-.2,5.8,2.422,4.145,3.96-2.507,7.814-6.575,7.31-11.639a31.683,31.683,0,0,0-1.3-6.462,5.883,5.883,0,0,0-3.66-4.128c-2.334-.739-5.071-.226-7.4.248a76.491,76.491,0,0,1-7.64,1.545,118.425,118.425,0,0,1-17.283.307c-2.7-.132-5.4-.335-8.1-.581s-5.311-.779-7.327,1.478a9.5,9.5,0,0,0-1.624,3.328c-.484,1.337-1,2.662-1.512,3.988-.893,2.316-2.262,4.956-2.4,7.454-.23,4.051,2.617,7.1,6.225,8.452,3.528,1.318,7.743,1.095,11.468,1.188a138.18,138.18,0,0,0,16.266-.429c5.93-.552,11.816-1.615,16.98-4.749C139.338,168.843,136.929,164.691,134.279,166.3Z"></path>
                <path
                    className="e"
                    d="M135.527,158.875c-.809.348-1.363.754-1.516,1.34l-1.26.055c-3.912.073-12.842,1.059-16.4,3.3-4.791,3.013-3.228,6.325-1.181,11.751,1.049,2.781,1.959,5.794,3.564,7.713l-.219.269a2.391,2.391,0,0,0-.589,3.894c3.9,3.5,10.382,6.156,12.658,7.445a2.863,2.863,0,0,0,3.573-.6,29.2,29.2,0,0,0,3.4-5.378c2.335-4.882,4.228-8.314,6.227-12.311l.891-1.782,1.851,1.41a126,126,0,0,0,11.988,6.214,3.687,3.687,0,0,0,3.72-.7,46.675,46.675,0,0,0,9.481-8.526c1.538-1.771-.081-3.55-2.349-4.151l-.332-.206a3.76,3.76,0,0,0,.081-.9c.283-5.8-1.6-15.4-6.659-17.356-5.105-.74-13.024,4.3-18.382,6.986l-.539.42c-1.117-.725-3.67-.259-5.7.339A21.75,21.75,0,0,0,135.527,158.875Z"></path>
                <path
                    className="b"
                    d="M132.751,157.87a52.958,52.958,0,0,0-13.526,1.934c-4.258,1.246-8.763,4.1-8.409,9.126a25.442,25.442,0,0,0,2.037,7.028,53.294,53.294,0,0,0,2.633,6.5,9.144,9.144,0,0,0,4.931,4.579c2.576.884,5.566.091,8.048-.749a32.3,32.3,0,0,0,8.487-4,66.546,66.546,0,0,0,5.691-4.665c2.31-2.06-1.094-5.445-3.394-3.394-2.837,2.531-5.675,4.937-9.238,6.372-2.187.881-4.6,1.929-6.986,2.019a3.1,3.1,0,0,1-2.954-1.817,25.411,25.411,0,0,1-2.138-4.9c-.72-1.975-1.587-3.924-2.107-5.965a5.215,5.215,0,0,1-.24-1.836,2.482,2.482,0,0,1,.631-1.372,7.955,7.955,0,0,1,3.987-2.213,46.9,46.9,0,0,1,12.547-1.851c3.084-.068,3.095-4.868,0-4.8Z"></path>
                <path
                    className="b"
                    d="M137.194,155.792c-2.4.711-5.669,1.711-5.626,4.8a23.566,23.566,0,0,0,.966,5.439,28.845,28.845,0,0,0,2.131,5.871,8.165,8.165,0,0,0,1.5,2.141,5.837,5.837,0,0,0,3.518,1.388,12.7,12.7,0,0,0,3.863-.331,15.17,15.17,0,0,0,4.1-1.407,3.816,3.816,0,0,0,2.155-2.2,5.175,5.175,0,0,0,.2-1.809,28.859,28.859,0,0,0-2.337-9.223,12.261,12.261,0,0,0-2.313-4.249c-2.081-2.03-5.7-1.131-8.16-.418a2.4,2.4,0,0,0,1.276,4.629c.506-.147,1.014-.282,1.528-.394.24-.052.48-.1.722-.142.059-.01.555-.076.272-.045-.236.027.214-.014.257-.017.217-.016.435-.026.653-.023.1,0,.205.007.308.01.465.014.012.012-.074-.014.3.09.385.158.053-.03.185.1-.634-.405-.274-.207.057.031.085.109.136.147.206.154-.37-.558-.162-.2.1.177.223.344.325.522.268.469.492.959.715,1.45.217.475-.156-.4,0,0,.053.133.109.266.162.4q.194.487.371.981.362.995.671,2.007a32.465,32.465,0,0,1,.957,3.835c.026.148.049.3.073.445.077.464-.028-.3-.02-.158.014.251.053.5.062.753.007.193-.02.392-.008.584.019.328.116-.706.039-.31-.01.053-.068.159-.06.209-.039-.246.4-.745.477-.823,0,0,.683-.42.256-.221-.234.109-.454.259-.683.378-.156.081-.313.158-.471.235-.384.187.245-.089-.142.069a13.656,13.656,0,0,1-3.31.789c.4-.052-.11,0-.247.012-.237.017-.474.025-.712.021-.092,0-.678-.014-.338,0,.309.011.039.008-.036-.009-.113-.027-.227-.048-.339-.079s-.218-.081-.325-.1c.032.006.391.219.187.078s.358.407.068.09a3.849,3.849,0,0,0-.269-.293c.017,0,.3.437.185.239-.043-.076-.1-.146-.151-.219a9.066,9.066,0,0,1-.481-.826c-.087-.168-.17-.338-.251-.509-.1-.205-.253-.578-.071-.136-.321-.78-.6-1.575-.855-2.38a37.344,37.344,0,0,1-1.244-5.269c-.088-.55.036.391,0,.012-.015-.162-.029-.327-.048-.49a1.421,1.421,0,0,0-.01-.186c-.063-.227-.122.553-.012.155-.059.214-.515.763-.177.44.181-.171-.168.137-.171.138a2.245,2.245,0,0,0,.3-.174c.111-.059.224-.109.337-.163-.209.1-.233.091,0,.013.606-.209,1.216-.4,1.83-.581a2.4,2.4,0,1,0-1.276-4.629Z"></path>
                <path
                    className="b"
                    d="M130.45,173.071c1.71-.66,3.363-1.449,5.032-2.2a2.4,2.4,0,0,0,1.1-1.435,2.452,2.452,0,0,0-.242-1.849,2.425,2.425,0,0,0-1.434-1.1l-.638-.086a2.4,2.4,0,0,0-1.211.328c-1.137.512-2.263,1.048-3.411,1.535l.573-.242c-.347.146-.7.289-1.048.425a2.679,2.679,0,0,0-1.435,1.1,2.456,2.456,0,0,0-.242,1.85,2.426,2.426,0,0,0,1.1,1.434,2.343,2.343,0,0,0,1.85.242Z"></path>
                <path
                    className="b"
                    d="M145.278,159.42c3.277-1.654,6.445-3.524,9.805-5.012a22.756,22.756,0,0,1,4.772-1.573,7.381,7.381,0,0,1,1.765-.143,1.922,1.922,0,0,0,.419.025c.365-.094-.386-.284-.241-.026a2.842,2.842,0,0,0,.7.428,8.437,8.437,0,0,1,2.121,2.936,19.642,19.642,0,0,1,1.4,4.218,27.278,27.278,0,0,1,.644,4.281,20.879,20.879,0,0,1,.022,3.93c-.105.585-.112.747-.593.973a12.826,12.826,0,0,1-3.212.614,20.985,20.985,0,0,1-6.441.263,2.475,2.475,0,0,0-2.953,1.676,2.42,2.42,0,0,0,1.677,2.952,25.908,25.908,0,0,0,7.7-.077c2.3-.312,5.092-.455,6.847-2.161,2.741-2.664,1.908-7.77,1.411-11.165-.556-3.8-1.726-7.706-4.182-10.725a7.37,7.37,0,0,0-4.767-2.906,14.5,14.5,0,0,0-5.709.838c-4.8,1.549-9.131,4.247-13.612,6.509-2.758,1.393-.331,5.535,2.422,4.145Z"></path>
                <path
                    className="b"
                    d="M148.093,165.658c.775-.409,1.55-.817,2.357-1.16l-.574.241a11.513,11.513,0,0,1,1.861-.612,2.4,2.4,0,0,0,1.677-2.953,2.429,2.429,0,0,0-1.1-1.434,2.584,2.584,0,0,0-1.85-.242,20.292,20.292,0,0,0-4.791,2.015,2.4,2.4,0,0,0,.574,4.387,2.6,2.6,0,0,0,1.849-.242Z"></path>
                <path
                    className="b"
                    d="M117.3,181.23a4.935,4.935,0,0,0-2.533,4.3c.016,1.893,1.15,3.171,2.555,4.276a48.725,48.725,0,0,0,8.62,5.182c2.63,1.3,5.43,3.325,8.414,1.858,1.6-.785,2.64-2.484,3.562-3.943a41.723,41.723,0,0,0,2.226-4.114c1.835-3.776,3.823-7.474,5.7-11.227,1.381-2.754-2.759-5.188-4.145-2.423-1.708,3.407-3.508,6.767-5.193,10.186-.7,1.415-1.357,2.841-2.157,4.2-.4.676-1.507,3.105-2.4,3.088-.331-.007-.784-.384-1.066-.527-.514-.262-1.033-.514-1.551-.768-1.366-.671-2.73-1.348-4.067-2.077a37.466,37.466,0,0,1-4.452-2.767c-.379-.281-1-.771-1.221-.893s.106-.385-.03-.111c-.036.071-.449.252.158-.1,2.673-1.545.259-5.695-2.423-4.144Z"></path>
                <path
                    className="b"
                    d="M168.721,171.135a2.883,2.883,0,0,1,1.167.534s.022-.2.023-.216c0-.039-.683.708-.506.511-1.05,1.164-2.12,2.308-3.268,3.377q-1.359,1.264-2.833,2.4c-.592.456-1.2.9-1.8,1.333-.454.329-1.167.994-1.785.978-.9-.023-2.134-.935-2.927-1.322q-1.922-.936-3.822-1.912a38.4,38.4,0,0,1-5.238-2.912,2.417,2.417,0,0,0-3.284.861,2.455,2.455,0,0,0,.861,3.283,49.052,49.052,0,0,0,7.142,3.877c1.516.758,3.037,1.515,4.581,2.215a6.242,6.242,0,0,0,6.571-.69,52.27,52.27,0,0,0,8.474-7.294c1.489-1.619,3.1-3.307,2.491-5.69-.546-2.129-2.6-3.374-4.569-3.963-2.966-.885-4.234,3.746-1.276,4.629Z"></path>
                <path
                    className="b"
                    d="M107.462,125.317a20.1,20.1,0,0,0,9.681,2.239c3.463-.023,6.969-.7,9.744-2.863a2.414,2.414,0,0,0,0-3.394,2.463,2.463,0,0,0-3.395,0c.721-.561.133-.112-.052.007s-.4.241-.6.349c-.159.083-.887.378-.253.141-.277.1-.55.212-.831.3a14.282,14.282,0,0,1-2.327.531c.337-.049-.141.013-.257.024q-.342.034-.685.057c-.551.035-1.1.049-1.654.046q-.741,0-1.481-.053c-.226-.016-.452-.034-.678-.057,0,0-.8-.108-.363-.04a16.461,16.461,0,0,1-2.173-.482c-.305-.092-.6-.2-.9-.3-.1-.033-.192-.084-.291-.113.6.173.217.1.047.017-.373-.175-.74-.359-1.105-.551a2.452,2.452,0,0,0-3.283.861,2.426,2.426,0,0,0,.861,3.283Z"></path>
                <path
                    className="b"
                    d="M115.984,102.432a45.857,45.857,0,0,1,1.076,4.926l-.086-.638a2.974,2.974,0,0,1,.019.68l.086-.638a1.787,1.787,0,0,1-.075.3l.242-.573a1.345,1.345,0,0,1-.108.2l.375-.486a1.046,1.046,0,0,1-.161.158l.486-.375a3.234,3.234,0,0,1-.39.207l.573-.242a2.911,2.911,0,0,1-.657.192l.638-.086a2.661,2.661,0,0,1-.618,0l.638.085c-.232-.032-.461-.089-.693-.12a2.657,2.657,0,0,0-1.85.242,2.4,2.4,0,0,0-.861,3.284,2.3,2.3,0,0,0,1.435,1.1c.518.069,1.021.2,1.549.225a4.912,4.912,0,0,0,2.268-.538,3.248,3.248,0,0,0,1.809-2.143,5.438,5.438,0,0,0-.024-2.324c-.268-1.587-.635-3.159-1.043-4.716a2.4,2.4,0,0,0-4.628,1.276Z"></path>
                <path
                    className="b"
                    d="M89.819,88.517C89,91.19,89.533,97.339,94.6,97.862c6.693-.04,7.049-7.474,4.8-10.97C97.548,84.031,92.124,82.84,89.819,88.517Z"></path>
                <path
                    className="b"
                    d="M143.416,88.517c.823,2.673.287,8.822-4.777,9.345-6.693-.04-7.049-7.474-4.8-10.97C135.687,84.031,141.111,82.841,143.416,88.517Z"></path>
            </g>
        </svg>
    );
}
