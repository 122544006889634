import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { Suspense, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { AlarmClockCheckIcon, ArrowDown, ArrowUp, PlusIcon } from "lucide-react";

import LoaderIcon from "@/assets/icon/LoaderIcon";
import { useEvent } from "@/hooks/useEvent";
import { getEvent } from "@/axios/get-request";
import { EventdayFlowType } from "@/types";
import Spinner from "@/components/Spinner";
import DefaultView from "@/components/DefaultView";
import FilterDropdown from "@/components/FilterDropdown";
import AddEventdayTaskModal from "@/components/modals/AddEventdayTaskModal";
import VerticalSeparator from "@/components/VerticalSeparator";
import EditEventdayTaskModal from "@/components/modals/EditEventdayTaskModal";
import EventDayflowItem from "@/components/EventDayflowItem";
import DeleteEventTaskModal from "@/components/modals/DeleteEventTaskModal";
import useUser from "@/hooks/useUser";
import { CollaboratorPermissionList } from "@/data/types";
import "@/styles/checklist_new.scss";
import "@/styles/event_day_flow.scss";

enum ModalList {
    add_event_day_flow_modal = "add_event_day_flow_modal",
    edit_event_day_flow_modal = "edit_event_day_flow_modal",
    delete_event_day_flow_modal = "delete_event_day_flow_modal",
}

type EventDayTask = EventdayFlowType[0];

export default function EventDayFlow() {
    const [modal, setModal] = useState<string | null>(null);
    const { selectedEventId, activeCeremonyId, setActiveCeremonyId } = useEvent();
    const [selectedEventDayTask, setSelectedEventDayTask] = useState<EventDayTask | null>(null);
    const [limit, setLimit] = useState(5);
    const { data: userData } = useUser();
    const { data, status } = useQuery({
        queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`],
        queryFn: () => getEvent(selectedEventId),
    });

    const eventData = data?.data;
    const userDetails = userData?.result ? userData?.result : null;
    const viewOnly = userDetails?.permission === CollaboratorPermissionList.view_only;

    useEffect(() => {
        if (
            status === "success" &&
            activeCeremonyId === "all" &&
            eventData?.result?.ceremonies &&
            Array.isArray(eventData?.result?.ceremonies) &&
            eventData?.result?.ceremonies.length > 0
        ) {
            setActiveCeremonyId(eventData?.result?.ceremonies[0]?.id);
        }
    }, [status, data, selectedEventId]);

    function onCloseModal() {
        setModal(null);
    }

    const ceremonies = status === "success" && eventData?.result?.ceremonies ? eventData?.result.ceremonies : [];
    const selectedCeremony = activeCeremonyId ? ceremonies.filter((item) => item.id === activeCeremonyId) : [];
    const ceremonyName = selectedCeremony ? selectedCeremony[0]?.name : "";
    const eventName = eventData?.result ? eventData?.result?.event_name : "";
    const ceremonyChecklists: EventdayFlowType = [];

    if (selectedCeremony[0]?.eventdayFlow) {
        selectedCeremony[0]?.eventdayFlow.map((item) => {
            if (item.ceremony.id === activeCeremonyId) {
                ceremonyChecklists.push(item);
            }
        });
    }

    const activeCeremony = eventData?.result?.ceremonies ? eventData?.result?.ceremonies.filter((item) => item.id === activeCeremonyId) : [];

    function createEventHandler() {
        if (!selectedEventId) {
            return toast.error("Please create an event");
        }
        if (!userData?.result?.subscription_details?.id) {
            return toast.error("Subscription is required");
        }
        return setModal(ModalList.add_event_day_flow_modal);
    }

    function editEventDayTaskHandler(eventDayItem: EventDayTask) {
        setSelectedEventDayTask(eventDayItem);
        setModal(ModalList.edit_event_day_flow_modal);
    }

    function deleteEventDayTaskHandler(eventDayItem: EventDayTask) {
        setSelectedEventDayTask(eventDayItem);
        setModal(ModalList.delete_event_day_flow_modal);
    }

    const eventdayFlow = activeCeremony[0]?.eventdayFlow && Array.isArray(activeCeremony[0]?.eventdayFlow) ? activeCeremony[0]?.eventdayFlow : null;
    const markedAsDoneEvents = eventdayFlow ? eventdayFlow.filter((item) => item.isDone) : null;
    const noOfDoneEvents = markedAsDoneEvents ? markedAsDoneEvents.length : 0;
    const __percentage = noOfDoneEvents && eventdayFlow ? (noOfDoneEvents / eventdayFlow.length) * 100 : 0;
    const percentage = __percentage.toFixed(0);

    const progressStyle = { width: `${percentage}%` };

    const { lastDate, sortEventDayFlow } = useMemo(() => {
        const eventDayFlowWithDate = eventdayFlow && eventdayFlow?.length > 1 ? eventdayFlow.filter((item) => item.date) : [];
        const eventDayFlowWithOutDate = eventdayFlow && eventdayFlow?.length > 1 ? eventdayFlow.filter((item) => !item.date) : [];
        const __sortEventDayFlow =
            eventdayFlow && Array.isArray(eventdayFlow) && eventdayFlow.length > 1
                ? [...eventDayFlowWithDate].sort((a, b) => {
                    return +new Date(a.date as string) - +new Date(b.date as string);
                })
                : eventdayFlow;
        const sortEventDayFlowArray = __sortEventDayFlow ? __sortEventDayFlow : [];

        const sortEventDayFlow = [...sortEventDayFlowArray, ...eventDayFlowWithOutDate];

        const lastDate =
            __sortEventDayFlow && __sortEventDayFlow[__sortEventDayFlow?.length - 1]?.date
                ? __sortEventDayFlow[__sortEventDayFlow.length - 1]?.date
                : null;

        return {
            lastDate,
            sortEventDayFlow,
        };
    }, [status, eventdayFlow, activeCeremonyId, selectedEventId]);

    let offset = 0;
    let paginateEventdayFlow: EventdayFlowType = sortEventDayFlow;

    if (sortEventDayFlow.length > 5) {
        offset = limit - 5;
        paginateEventdayFlow = sortEventDayFlow.length > 5 ? sortEventDayFlow.slice(offset, limit) : sortEventDayFlow;
    }

    const disableLeftArrowBtn = limit === 5 ? true : false;
    const disableRightArrowBtn = sortEventDayFlow.length <= limit ? true : false;

    function incrementPageCounter() {
        setLimit((prev) => prev + 5);
    }

    function decrementPageCounter() {
        if (limit > 1) {
            setLimit((prev) => {
                if (prev > 5) {
                    return prev - 5;
                }
                return prev;
            });
        }
    }

    const paginationCounter = offset + 1;
    return (
        <>
            <Suspense>
                {modal === ModalList.add_event_day_flow_modal && (
                    <AddEventdayTaskModal eventName={eventName} ceremonyName={ceremonyName} onClose={onCloseModal} />
                )}
                {modal === ModalList.edit_event_day_flow_modal && selectedEventDayTask && (
                    <EditEventdayTaskModal eventName={eventName} eventDayTask={selectedEventDayTask} onClose={onCloseModal} />
                )}
                {modal === ModalList.delete_event_day_flow_modal && selectedEventDayTask && (
                    <DeleteEventTaskModal
                        eventDayTaskId={selectedEventDayTask.id}
                        eventDayTaskName={selectedEventDayTask.task}
                        onClose={onCloseModal}
                    />
                )}
            </Suspense>
            {status === "loading" ? (
                <div className="loading_view mx-auto d-flex justify-content-center align-items-center">
                    <Spinner />
                </div>
            ) : (
                <section className="checklist_page event_day_flow">
                    <div className="checklist_view mt-4">
                        <div className="title_row">
                            <div>
                                <LoaderIcon />
                                <h3>Event Day Flow</h3>
                            </div>
                            <div className="button_row">
                                <FilterDropdown title={ceremonyName} disabled={!selectedEventId}>
                                    {ceremonies.map((ceremony) => (
                                        <Dropdown.Item key={ceremony.id} onClick={() => setActiveCeremonyId(ceremony.id)}>
                                            {ceremony.name}
                                        </Dropdown.Item>
                                    ))}
                                </FilterDropdown>
                                {!viewOnly && (
                                    <button className="add_task mx-1" onClick={createEventHandler}>
                                        <PlusIcon /> <span>Add task</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        {paginateEventdayFlow && Array.isArray(paginateEventdayFlow) && sortEventDayFlow?.length > 0 ? (
                            <>
                                <div className="event_day_view">
                                    <div className="event_day_statistics">
                                        <div className="task_percentage">
                                            <p>{percentage}% complete</p>
                                            <div className="progress_container">
                                                <div className="progress" style={progressStyle}></div>
                                            </div>
                                        </div>
                                        <div className="date_group">
                                            <time className="start_time">
                                                <h6>Start Time</h6>
                                                <div>
                                                    <AlarmClockCheckIcon /> {eventdayFlow ? sortEventDayFlow[0]?.start_time : ""}
                                                </div>
                                            </time>
                                            <div className="date_period">
                                                <h6>Date</h6>
                                                <time className="date_from">
                                                    {sortEventDayFlow[0]?.date ? format(new Date(sortEventDayFlow[0]?.date), "do MMM yyyy") : "--"}
                                                </time>
                                                <VerticalSeparator />
                                                <time className="date_to">{lastDate ? format(new Date(lastDate), "do MMM yyyy") : "--"}</time>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="event_day_flow_list_wrapper">
                                        <div className="event_day_flow_list">
                                            {sortEventDayFlow &&
                                                paginateEventdayFlow?.map((item, index) => {
                                                    const eventdayFlowIndex = offset + index;
                                                    return (
                                                        <EventDayflowItem
                                                            key={index}
                                                            index={eventdayFlowIndex}
                                                            item={item}
                                                            setModal={setModal}
                                                            activeCeremonyId={activeCeremonyId}
                                                            selectedEventId={selectedEventId}
                                                            viewOnly={viewOnly}
                                                            editEventDayTaskHandler={editEventDayTaskHandler}
                                                            deleteEventDayTaskHandler={deleteEventDayTaskHandler}
                                                        />
                                                    );
                                                })}
                                        </div>
                                        {sortEventDayFlow.length > 0 && (
                                            <div className="paginate_event mt-3 w-full d-flex justify-content-between align-items-center">
                                                <div className="pagination_btn_group">
                                                    <button className="bg-white mr-1" disabled={disableLeftArrowBtn} onClick={decrementPageCounter}>
                                                        <ArrowUp />
                                                    </button>
                                                    <button className="bg-white ml-1" disabled={disableRightArrowBtn} onClick={incrementPageCounter}>
                                                        <ArrowDown />
                                                    </button>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    {/* <Select value={limit} onChange={onSelectEventLimit} input={selectLimitDetails} /> */}
                                                    <p className="mb-0 ml-2">
                                                        Showing {paginationCounter} -{" "}
                                                        {sortEventDayFlow.length > limit ? limit : sortEventDayFlow.length} of{" "}
                                                        {sortEventDayFlow.length}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <DefaultView
                                icon={<LoaderIcon />}
                                title="You have no Event Day Flow yet"
                                description="Create tasks and arrange them in the order you need"
                                buttonText="Add Task"
                                onClickHandler={createEventHandler}
                            />
                        )}
                    </div>
                </section>
            )}
        </>
    );
}
