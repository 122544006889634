import { ChangeEvent, useState } from "react";
import { format } from "date-fns";
import { Dropdown } from "react-bootstrap";

import CalendarIcon from "@/assets/icon/CalendarIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import Input2 from "@/components/form/Input2";
import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import GalleryIcon from "@/assets/icon/GalleryIcon";
import SiteDropdown from "@/components/Dropdown";
import usePlannerOnboarding from "@/hooks/usePlannerOnboarding";
import Loader from "@/components/loader";
import { SocialsList } from "@/types";
import "@/styles/onboarding_stages.scss";
import "@/styles/auth_page.scss";

const inputGroup = {
    name: { name: "business_name", label: "Business Name", placeholder: "Fantastic Event Planners" },
    phoneNumber: { name: "phone_number", label: "Phone Number", placeholder: "123 456 7890" },
    website: { name: "website", label: "Website (optional)", placeholder: "Fanstasticdeluxe.com" },
    socialMedia: {
        Instagram: { name: "instagram", label: "Instagram", placeholder: "Fanstastic Planners" },
        Tiktok: { name: "tiktok", label: "Tiktok", placeholder: "Fanstastic Planners" },
    },
    platform: { name: "platform", label: "Platform", placeholder: "Enter platform" },
    ceremony: {
        type: { name: "type", label: "Type", placeholder: "White Wedding" },
        location: { name: "location", label: "Location", placeholder: "Seychelles" },
        date: { name: "date", label: "Date", placeholder: format(new Date(), "dd-MM-yyyy") },
        guestNumber: { name: "no_of_guests", label: "Guests", placeholder: "500" },
    },
};

type onboardingFormStateType = {
	business_name: string;
	phone_number: string;
	website: string;
	ceremonies: Array<{
		type: string;
		location: string;
		date: Date | null;
		no_of_guests: string;
	}>;
};

type socialMediaType = Array<{ type: keyof typeof SocialsList; link: string }>;

export default function OnboardingEventPlannerStage1() {
    const [socialMedia, setSocialMedia] = useState<socialMediaType>([]);
    const [file, setFile] = useState<File | null>(null);
    const [previewEventImage, setPreviewEventImage] = useState<null | string>(null);
    const [onboardingForm, setOnboardingForm] = useState<onboardingFormStateType>({
        business_name: "",
        phone_number: "",
        website: "",
        ceremonies: [],
    });
    const { loading, onboardPlannerHandler } = usePlannerOnboarding(file);

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.name === inputGroup.phoneNumber.name && isNaN(Number(event.target.value))) return;

        setOnboardingForm((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            };
        });
    }

    function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        setPreviewEventImage(url);
        setFile(file);
    }

    function nextStageHandler() {
        onboardPlannerHandler(onboardingForm, socialMedia);
    }

    function addSocialMediaHandler(social: keyof typeof SocialsList) {
        const socialMediaCheck = socialMedia.filter((item) => item.type === social);
        if (socialMediaCheck.length > 0) {
            return;
        }
        setSocialMedia([
            ...socialMedia,
            {
                type: social,
                link: "",
            },
        ]);
    }

    function updateSocialMediaHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const tempSocialMedia = [...socialMedia];
        tempSocialMedia[index].link = event.target.value;
        setSocialMedia(tempSocialMedia);
    }

    const className = loading ? "loading" : "";

    return (
        <NewOnboardingLayout className="onboarding_page_stage" text="Unlock your dream event in a few steps...">
            <div className="content d-flex flex-column auth">
                <div className="auth_content onboarding_stage">
                    <h4 className="mb-0">Business details 📄</h4>
                    <form className="invite_collaborator_form">
                        <div className="input_list_group mb-4">
                            <div className="input_list">
                                <div className="business_name">
                                    <Input2 input={inputGroup.name} onChange={updateInputHandler} type="text" value={onboardingForm.business_name} />
                                    <div className="upload_event_banner">
                                        <input onChange={changePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" />{" "}
                                        {previewEventImage ? <img src={previewEventImage} alt="event image" /> : <GalleryIcon />}
                                    </div>
                                </div>
                                <Input2
                                    input={inputGroup.phoneNumber}
                                    onChange={updateInputHandler}
                                    value={onboardingForm.phone_number}
                                    type="text"
                                />
                                <Input2 input={inputGroup.website} onChange={updateInputHandler} value={onboardingForm.website} type="text" isLink />
                            </div>
                        </div>
                        <div className="form_row social_media">
                            <div className="section_title">
                                <div>
                                    <CalendarIcon />
                                    <h6>Social Media (Optional)</h6>
                                </div>
                                <div className="button_action_group">
                                    <SiteDropdown
                                        className="custom"
                                        title={
                                            <>
												Add <PlusIcon fill="#000" />
                                            </>
                                        }>
                                        <Dropdown.Item onClick={() => addSocialMediaHandler(SocialsList.Instagram)}>
                                            {SocialsList.Instagram}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => addSocialMediaHandler(SocialsList.Tiktok)}>{SocialsList.Tiktok}</Dropdown.Item>
                                    </SiteDropdown>
                                </div>
                            </div>
                            <div className="ceremonies">
                                {socialMedia.map((social, index) => {
                                    return (
                                        <div key={index}>
                                            <Input2
                                                isLink
                                                input={inputGroup.socialMedia[social.type]}
                                                linkTitle={`${social.type}.com/`}
                                                value={social.link}
                                                onChange={(event) => updateSocialMediaHandler(event, index)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="button_group mx-auto justify-content-center">
                    <button className={`next_btn  ${className}`} onClick={nextStageHandler} disabled={loading}>
						Next {loading && <Loader />}
                    </button>
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
