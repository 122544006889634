import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import React from "react";

const links = [
    { link: "#types_of_data", text: "Types of Data Collected" },
    { link: "#purpose_of_data_collection", text: "Purpose of Data Collection" },
    { link: "#data_sharing_and_disclosure", text: "Data Sharing and Disclosure" },
    { link: "#data_storage_security", text: "Data Storage and Security" },
    { link: "#intellectual_property", text: "User Rights" },
    { link: "#cookies_and_tracking_technologies", text: "Cookies and Tracking Technologies" },
    { link: "#international_data_transfers", text: "International Data Transfers" },
    { link: "#childrens_privacy", text: "Children's Privacy" },
    { link: "#changes_to_the_privacy_policy", text: "Changes to the Privacy Policy" },
    { link: "#contact_information", text: "Contact Information" },
];

function SidebarPolicyLink() {
    return (
        <ul className="policy_sidebar_link">
            {links.map((item, index) => (
                <li key={index}>
                    <a href={item.link}>{item.text}</a>
                </li>
            ))}
        </ul>
    );
}

export default function PrivacyPolicy() {
    return (
        <NewOnboardingLayout text="Privacy policy" className="policy_page" sidebarContent={<SidebarPolicyLink />}>
            <div className="content d-flex flex-column py-5 px-4 policy align-items-start justify-content-start">
                <h1>Effective Date: 4 September 2024</h1>
                <p>
                    At Planaday Technologies Nigeria Limited ("Planaday", "we", "us", or "our"), we value your privacy and are committed to protecting
                    your personal data. This Privacy Policy explains how we collect, use, store, and protect your information when you use our event
                    planning platform, https://planaday.events ("Website"). By using our Website, you agree to the practices described in this policy.
                </p>
                <div id="types_of_data">
                    <h5>1. Types of Data Collected</h5>
                    <p>We collect the following types of personal data from users:</p>
                    <ul>
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Phone number</li>
                        <li>Passwords</li>
                    </ul>
                    <p>We do not collect data automatically through cookies, analytics, or any other tracking technologies.</p>
                </div>
                <div id="purpose_of_data_collection">
                    <h5>2. Purpose of Data Collection</h5>
                    <p>We collect your personal data for the following purposes:</p>
                    <ul>
                        <li>
                            <b>Account Creation:</b>To create and manage your user account on the Planaday platform.
                        </li>
                        <li>
                            <b>Marketing:</b>
                            To send you promotional materials and updates about our services
                        </li>
                    </ul>
                </div>
                <div id="data_sharing_and_disclosure">
                    <h5>3. Data Sharing and Disclosure</h5>
                    <ul>
                        <li>
                            <b>Third-Party Sharing:</b>We do not share your personal data with any third parties.
                        </li>
                        <li>
                            <b>Legal Disclosure:</b>
                            We do not disclose your personal data for legal purposes or to protect our business.
                        </li>
                    </ul>
                </div>
                <div id="data_storage_security">
                    <h5>4. Data Storage and Security</h5>
                    <p>
                        Your personal data is stored securely on our servers and protected using industry-standard encryption and security measures.
                        We retain your personal data for as long as you hold an account with us. If you choose to close your account, we will securely
                        delete your data from our records.
                    </p>
                </div>
                <div id="user_rights">
                    <h5>5. User Rights</h5>
                    <p>As a user, you have the following rights regarding your personal data:</p>
                    <ul>
                        <li>
                            <b>Access and Update:</b> You can access and update your personal information at any time by logging into your account on
                            the Planaday platform.
                        </li>
                    </ul>
                    <p>
                        If you need further assistance with your data, you can contact us at{" "}
                        <a href="support@planaday.events">support@planaday.events</a>
                    </p>
                </div>
                <div id="cookies_and_tracking_technologies">
                    <h5>6. Cookies and Tracking Technologies</h5>
                    <p>We do not use cookies, web beacons, or any other tracking technologies on our Website.</p>
                </div>
                <div id="international_data_transfers">
                    <h5>7. International Data Transfers</h5>
                    <p>We do not transfer your personal data outside of Nigeria.</p>
                </div>
                <div id="childrens_privacy">
                    <h5>8. Children's Privacy</h5>
                    <p>
                        Our services are not targeted towards individuals under the age of 18. We do not knowingly collect personal data from
                        children. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete
                        such information.
                    </p>
                </div>
                <div id="changes_to_the_privacy_policy">
                    <h5>9. Changes to the Privacy Policy</h5>
                    <p>
                        We may update this Privacy Policy from time to time due to changes in our operations or legal requirements. If any changes are
                        made, we will notify you via email. Continued use of the Website after any such changes constitutes your acceptance of the new
                        Privacy Policy.
                    </p>
                </div>
                <div id="contact_information">
                    <h5>10. Contact Information</h5>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or how we handle your personal data, please contact us at{" "}
                        <a href="support@planaday.events">support@planaday.events</a>
                    </p>
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
