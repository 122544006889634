/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Path, Group } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onSelect: () => void;
	onDragStart: (value: itemType) => void;
	onTransformEndHandler: () => void;
}

export default function SinglechairElement({ refValue, onDragStart, onSelect, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            name="floor_plan_element"
            {...item}
            scaleX={3}
            scaleY={3}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <mask id="path-1-outside-1_11427_19935" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="15" fill="black">
                <rect fill="white" width="14" height="15" />
                <Path data="M0 2C0 1.44772 0.447715 1 1 1H13C13.5523 1 14 1.44772 14 2V14C14 14.5523 13.5523 15 13 15H1C0.447716 15 0 14.5523 0 14V2Z" />
            </mask>
            <Path
                data="M0 2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2C14 2 13.5523 2 13 2H1C0.447715 2 0 2 0 2ZM14 15H0H14ZM0 15V1V15ZM14 1V15V1Z"
                fill="#E845BA"
                mask="url(#path-1-outside-1_11427_19935)"
            />
            <Path
                data="M1 15L13 15C13.5523 15 14 14.5523 14 14L14 2C14 1.44772 13.5523 1 13 1L0.999999 1C0.447715 1 -5.92389e-07 1.44772 -5.68248e-07 2L-4.37114e-08 14C-1.95703e-08 14.5523 0.447716 15 1 15Z"
                fill="#D9D9D9"
            />
        </Group>
    );
}
