import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import UploadAsoebiIcon from "@/assets/icon/UploadAsoebiIcon";
import CancelIcon from "@/assets/icon/Cancel";

interface Props {
	showPreviewOnly?: boolean;
    selectedFiles: File[] | [];
    setSelectedFiles:Dispatch<SetStateAction<[] | File[]>>
}

const MAX_FILE_SIZE = 15728640; // 15MB, 1MB = 1048576 Byte


export default function UploadImage({ showPreviewOnly = false, selectedFiles, setSelectedFiles }: Props) {
    const [previewFiles, setPreviewFiles] = useState<string[] | []>([]);

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (selectedFiles.length < 5) {
                const result = [...selectedFiles, ...acceptedFiles];
                setSelectedFiles(result);
                const storageResult = [];
                if (selectedFiles.length > 0) {
                    storageResult.push(JSON.stringify(selectedFiles));
                }
                storageResult.push(JSON.stringify(acceptedFiles));
                window.localStorage.setItem("selected_files", JSON.stringify(storageResult));
                // setAcceptedFiles(JSON.stringify(acceptedFiles));
            } else {
                toast.error("You can only upload maximum of 5 files");
            }
        },
        accept: {
            "image/*": [".png", ".webp", ".gif", ".jpg", ".jpeg"],
        },
        maxFiles: 10,
        maxSize: MAX_FILE_SIZE,
        multiple: true,
    });

    function removeImageHandler(index: number) {
        const updateFilesArray = previewFiles.filter((_, idx) => idx !== index);
        const selectedFilesArray = selectedFiles.filter((_, idx) => idx !== index);
        setPreviewFiles(updateFilesArray);
        setSelectedFiles(selectedFilesArray);
    }

    const thumbs = previewFiles.map((file, index) => (
        <div key={index} className="position-relative">
            <button type="button" className="btn position-absolute btn-primary rounded-circle text-white" onClick={() => removeImageHandler(index)}>
                <CancelIcon fill="white" />
            </button>
            <img
                src={file}
                alt="Preview"
                className="rounded-[10px]"
            />
        </div>
    ));

    useEffect(() => {
        if (selectedFiles.length === 0) {
            setPreviewFiles([]);
            return;
        }
        const objectFileURLs = selectedFiles.map((file) => URL.createObjectURL(file));
        setPreviewFiles(objectFileURLs);
        window.localStorage.setItem("preview_files", JSON.stringify(Object.freeze(objectFileURLs)));

        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () =>{
            objectFileURLs.map((item) => URL.revokeObjectURL(item));
        };
    }, [selectedFiles]);

    const className = isDragAccept ? "is_drag_accept" : isDragReject ? "is_drag_reject" : "";

    const dragText = isDragAccept ?
        "✅ Drop the files here ..." :
        isDragReject ?
            "❌ You can only upload images in (.png, .webp, .gif, .jpg, .jpeg) format" :
            "Drag & drop or click to select the asoebi images or gif";

    const previewClassname = previewFiles.length === 1 ? "single" : "many";

    return (
        <div className="upload_images">
            {!showPreviewOnly && (
                <>
                    <small className="text-xs mb-0">You can upload - maximum of 10 files.</small>
                    <div {...getRootProps()} className={`upload_image ${className}  border  p-4 rounded`}>
                        <input {...getInputProps()} />
                        <div className="mx-auto d-flex justify-content-center py-4">
                            <UploadAsoebiIcon />
                        </div>

                        <p className="text-center text-sm">{dragText}</p>
                    </div>
                </>
            )}
            {(previewFiles.length > 0 || showPreviewOnly) && <div className={`preview_images ${previewClassname} mt-3 mb-4`}>{thumbs}</div>}
        </div>
    );
}
