import { useQuery } from "@tanstack/react-query";
import { Suspense } from "react";

import ChecklistSummary from "@/components/ChecklistSummary";
import ChecklistTodo from "@/components/ChecklistTodo";
import AddChecklistItemModal from "@/components/modals/AddChecklistItemModal";
import { getUserDetails } from "@/axios/get-request";
import { useChecklist } from "@/hooks/useChecklist";
import useTourGuide from "@/hooks/useTourGuide";
import DashboardLayout from "@/layout/DashboardLayout";
import "@/styles/checklist.scss";
import useMyEvents from "@/hooks/useMyEvents";

export default function Checklist() {
    const { checklistModal, setChecklistModal } = useChecklist();
    const { status } = useMyEvents();
    const { data, status: userStatus } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => getUserDetails(),
    });
    const _no_of_events = userStatus === "success" ? data?.result?.event_ids?.length : 0;
    const no_of_events = _no_of_events as number;

    useTourGuide(userStatus, "checklist", data?.result?.tour_guide?.checklist, no_of_events);

    function closeModalHandler() {
        setChecklistModal(null);
    }

    return (
        <DashboardLayout title="Checklist">
            <Suspense fallback={<div>Loading...</div>}>
                {checklistModal === "add_checklist_modal" && (
                    <AddChecklistItemModal show={checklistModal === "add_checklist_modal"} onHide={closeModalHandler} />
                )}
            </Suspense>
            <section className="checklist">
                <ChecklistSummary status={status} />
                <ChecklistTodo status={status} />
            </section>
        </DashboardLayout>
    );
}
