import { v4 as uuidv4 } from "uuid";

import { updateAudit } from "@/axios/post-request";
import useUser from "@/hooks/useUser";
import { auditType, update_audit_type } from "@/types";

type metadataType = {[key:string]: string | number | boolean}

export default function useAudit() {
    const { data: userData, selectedEventId } = useUser();

    function updateAuditHandler(type: update_audit_type, action: auditType[0]["action"], title: string, changes?: auditType[0]["changes"],
        metadata?: metadataType) {
        updateAudit(
            [{
                id: uuidv4(),
                name: userData?.result?.name as string,
                user_id: userData?.result?.id as string,
                email: userData?.result?.email as string,
                type,
                action,
                changes,
                metadata,
                title,
                timestamp: new Date(),
            }],
            selectedEventId,
        );
    }

    return {
        updateAuditHandler,
    };
}
