import { useState } from "react";
import CheckIcon from "../assets/icon/CheckIcon";

interface Props {
	label: string;
	name?: string;
	onClickHandler?: (name: string, check: boolean) => void;
	active?: boolean;
    onSelectHandler?: () => void
}

export default function Checkbox({ label, name, onClickHandler, onSelectHandler, active }: Props) {
    const [check, setCheck] = useState(false);

    function onCheckHandler() {
        setCheck((prev) => !prev);
        if (onClickHandler && name) {
            onClickHandler(name, !check);
        }
        if (onSelectHandler) {
            onSelectHandler();
        }
    }

    const checkFill = check || active ? "#cb3050" : "gray";
    const checkClassname = check || active ? "border_active" : "";

    return (
        <div className={`py-3 border checkbox w-100 ${checkClassname} shadow-sm px-4`} onClick={onCheckHandler}>
            {label}
            <CheckIcon checkFill={checkFill} />
        </div>
    );
}
