import CalculatorView from "@/components/CalculatorView";
import CalculatorWrapper from "@/components/CalculatorWrapper";

export default function Calculator() {
    return (
        <CalculatorWrapper>
            <CalculatorView />
        </CalculatorWrapper>
    );
}
