import OnboardingLayout from "@/layout/OnboardingLayout";

import OnboardingStage3 from "@/components/OnboardingStage3";

const CURRENTSTAGE = 2;

export default function InviteEventCollaborator() {
    const indicatorArray = new Array(4).fill(0);


    return (
        <OnboardingLayout className="invite_collaborator_onboarding">
            <div className="onboarding_slides">
                <ul className="indicator my-4 mb-5 d-flex align-items-center">
                    {indicatorArray.map((_, index) => {
                        const indicatorStyle = CURRENTSTAGE === index ? "active" : index < CURRENTSTAGE ? "visited" : "indicator_item";
                        return <li key={index} className={indicatorStyle} />;
                    })}
                </ul>
                <OnboardingStage3 />
            </div>
        </OnboardingLayout>
    );
}
