import { ChangeEvent, useState } from "react";
import { format, getHours, getMinutes } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import DashboardModal from "@/components/modals/DashboardModal";
import Input2 from "@/components/form/Input2";
import { useEvent } from "@/hooks/useEvent";
import Spinner from "@/components/Spinner";
import SelectDateInput from "@/components/form/SelectDateInput";
import SiteDropdown from "@/components/Dropdown";
import { EventdayFlowType, VendorType } from "@/types";
import VendorPicker from "./VendorPicker";
import SelectTimeInput from "../form/SelectTimeInput";
import { convertTimeToDate } from "@/helper";
import { updateEventDayFlowDetails } from "@/axios/put-request";
import "@/styles/add_budget_category.scss";
import useActivityLog from "@/hooks/useActivityLog";

const formDetails = {
    task: { label: "Task", name: "task", placeholder: "Task" },
    datepicker: { label: "Date", placeholder: format(new Date(), "dd-MM-yy"), name: "date" },
    startTime: { label: "Start time", placeholder: format(new Date(), "kk:mm aa"), name: "start_time" },
    duration: { label: "Duration", placeholder: "", name: "duration", options: ["10m", "20m", "30m", "40m", "50m", "1hr"] },
    venue: { label: "Venue", placeholder: "Enter venue", name: "venue" },
};

interface Props {
	onClose: () => void;
	eventDayTask: EventdayFlowType[0];
	eventName: string;
}

export default function EditEventdayTaskModal({ onClose, eventDayTask, eventName }: Props) {
    const { activeCeremonyId, selectedEventId } = useEvent();
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const { updateEventdaytaskAuditHandler } = useActivityLog();
    const time = eventDayTask.start_time ? eventDayTask.start_time : null;
    const splitTime = time ? time.split(":") : null;
    let hour = 0;

    if (splitTime && Number(splitTime[0]) > 12) {
        hour = Number(splitTime[0]) + 12;
    } else if (splitTime) {
        hour = Number(splitTime[0]);
    }

    const minutes = splitTime ? Number(splitTime[1].split(" ")[0]) : 0;
    const eventStartTime = eventDayTask?.start_time && eventDayTask?.date ? convertTimeToDate(eventDayTask.date, hour, minutes) : null;
    const [startTime, setStartTime] = useState(eventStartTime);
    const [timePicker, setTimePicker] = useState({
        hour: "",
        mins: "",
    });
    const [formValues, setFormValues] = useState({
        id: eventDayTask.id,
        task: eventDayTask.task,
        date: eventDayTask.date,
        duration: eventDayTask.duration,
        isDone: eventDayTask.isDone,
        venue: eventDayTask.venue,
        vendor: eventDayTask.vendor,
        ceremony: eventDayTask.ceremony,
    });

    const __hour = timePicker.hour ? `${timePicker.hour}h` : "";
    const __minutes = timePicker.mins ? `${timePicker.mins}m` : "";
    const period = __hour && __minutes ? `${__hour}:${__minutes}` : __hour ? __hour : __minutes;
    const title = __hour || __minutes ? period : "Enter duration";

    async function editEventDayFlowTask() {
        try {
            if (!formValues.task) {
                return toast.error("Task is required");
            }
            setLoading(true);
            let eventDate;
            if (startTime && formValues.date) {
                eventDate = new Date(formValues.date);
                const hour = getHours(startTime);
                const minute = getMinutes(startTime);
                eventDate.setHours(hour, minute);
            }
            const formData = {
                ...formValues,
                start_time: startTime ? format(new Date(startTime), "hh:mm aa") : startTime,
                date: eventDate ? new Date(eventDate).toISOString() : formValues.date,
                duration: period,
            };
            const response = await updateEventDayFlowDetails(eventDayTask.id, selectedEventId, activeCeremonyId, formData);
            setLoading(false);
            if (!response.data.status) {
                return toast.error(response.data.message || "Oops an error occured");
            }
            toast.success(response?.data?.message);
            onClose();
            await updateEventdaytaskAuditHandler(eventDayTask, formData, eventName);
            queryClient.invalidateQueries({ queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`] });
            return;
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Oops, unable to update event day task");
            onClose();
        }
    }

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>) {
        setFormValues((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    }

    function onSelectHandler(value: Date | string, name: string) {
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    function onSetStartTimeHandler(value: Date) {
        setStartTime(value);
    }

    function onSelectVendor(vendorDetails: VendorType | null) {
        setFormValues({
            ...formValues,
            vendor: vendorDetails,
        });
    }

    function inputHourHandler(event: ChangeEvent<HTMLInputElement>) {
        if (isNaN(Number(event.target.value)) || Number(event.target.value) > 24) return;
        setTimePicker({
            ...timePicker,
            hour: event.target.value,
        });
    }

    function inputMinuteHandler(event: ChangeEvent<HTMLInputElement>) {
        if (isNaN(Number(event.target.value)) || Number(event.target.value) > 60) return;
        setTimePicker({
            ...timePicker,
            mins: event.target.value,
        });
    }
    const date = formValues.date ? new Date(formValues.date) : null;

    return (
        <DashboardModal
            title="Edit event day task"
            description="update task"
            className="add_checklist_modal add_event_day_task_modal"
            onClose={onClose}>
            <div className="add_checklist_modal">
                <div className="checklist_group">
                    <div className="checklist_row">
                        <Input2 containerClassName="task_element" value={formValues.task} input={formDetails.task} onChange={updateInputHandler} />
                        <SelectDateInput
                            value={date}
                            label={formDetails.datepicker.label}
                            placeholder={formDetails.datepicker.placeholder}
                            onClick={(date) => onSelectHandler(date, formDetails.datepicker.name)}
                        />
                        <SelectTimeInput
                            value={startTime}
                            label={formDetails.startTime.label}
                            placeholder={formDetails.startTime.placeholder}
                            onClick={onSetStartTimeHandler}
                        />
                        <div className="dropdown_wrapper select_duration">
                            <label htmlFor="select_priority ml-1">Duration</label>
                            <SiteDropdown id="select_priority" title={title} className="ml-1 custom">
                                <div className="flex timepicker align-items-center">
                                    <Input2
                                        onChange={inputHourHandler}
                                        className="mr-1"
                                        value={timePicker.hour}
                                        input={{ placeholder: "5", name: "hour", label: "HH" }}
                                    />{" "}
                                    <Input2
                                        onChange={inputMinuteHandler}
                                        className="ml-1"
                                        value={timePicker.mins}
                                        input={{ placeholder: "30", name: "minute", label: "MM" }}
                                    />
                                </div>
                            </SiteDropdown>
                        </div>
                        <Input2 value={formValues.venue} input={formDetails.venue} onChange={updateInputHandler} />
                        <div className="vendor_input">
                            <div className="vendor_input_wrapper">
                                <label>Vendor</label>
                                <VendorPicker
                                    vendor={formValues.vendor ? { id: formValues.vendor.id, name: formValues.vendor.name } : { id: "", name: "" }}
                                    onSelectEventVendor={(__vendorDetails) => onSelectVendor(__vendorDetails)}
                                    addNew={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal_footer mt-3">
                <div className="button_group">
                    <button type="button" onClick={onClose} className="cancel_btn">
						Cancel
                    </button>
                    <button type="button" className="btn create_btn d-flex align-items-center" disabled={loading} onClick={editEventDayFlowTask}>
						Update {loading && <Spinner className="ml-2" size={20} />}
                    </button>
                </div>
            </div>
        </DashboardModal>
    );
}
