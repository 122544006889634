import { DiscussionEmbed } from "disqus-react";

export default function DiscussionEmbedApplication() {
    return (
        <DiscussionEmbed
            shortname="planaday-budget-calculator"
            config={{
                url: "https://tools.planaday.events/",
                identifier: "PlanadayBudgetCalculator",
                title: "Planaday Budget Calculator",
                language: "en_UK",
            }}
        />
    );
}
