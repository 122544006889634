import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams} from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import AsoebiLayout from "@/layout/AsoebiLayout";
import asoebiForm from "@/json/asoebi-tracker.json";
import CustomInput from "@/components/form/CustomInput";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import SelectBank from "@/components/form/SelectBank";
import Input from "@/components/form/Input";
import CheckIcon from "@/assets/img/checkIcon_green.svg";
import CancelIcon from "@/assets/img/cancel_icon_red.svg";
import {getAsoebiVendorDetails, getOrderFormDetails, resolveBankDetails } from "@/axios/get-request";
import Spinner from "@/components/Spinner";
import { AsoebiFormType } from "@/data/types";

type eventDetailsType = {
    name: string;
    email: string;
    phone_number: string;
    event_name: string;
};

const inputAccountNumber = { name: "account_number", type: "text", placeholder: "Account number" };
const inputAccountName = { name: "account_name", type: "text", placeholder: "Account name" };

const schema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    phone_number: yup.string().min(11, "Phone number must be at least 11 characters").required("Phone number is required"),
    event_name: yup.string().required("Event name is required"),
});

interface Props {
    type?: keyof typeof AsoebiFormType;
}

export default function AsoebiTracker({type = "orderform"}:Props) {
    const [defaultValues, setDefaultValues] = useState<null | eventDetailsType>(null);
    const navigate = useNavigate();
    const [accountName, setAccountName] = useState("");
    const [loading, setLoading] = useState(false);
    const [accountNumber, setAccountNumber] = useState("");
    const [searchParams] = useSearchParams();
    const userEmail = searchParams.get("email") as string;
    const orderFormId = searchParams.get("id") as string;
    const { data: orderFormData, status: orderFormStatus } = useQuery({
        queryKey: [`order_form_details_${orderFormId}`],
        enabled: !!orderFormId,
        queryFn: () => getOrderFormDetails(orderFormId),
        onSuccess: (data) => {
            const __existingOrderFormDetails = window.localStorage.getItem(LOCAL_STORAGE_KEYS.order_form_details);
            const existingOrderFormDetails = __existingOrderFormDetails ? JSON.parse(__existingOrderFormDetails) : null;
            if (!existingOrderFormDetails && data?.data?.result) {
                window.localStorage.setItem(LOCAL_STORAGE_KEYS.order_form_details, JSON.stringify(data?.data?.result));
            }
        }
    });
    console.log("orderFormData", orderFormData?.data);
    const { data, status } = useQuery({
        queryKey: [`vendor_details_${userEmail}`],
        enabled: !!userEmail,
        queryFn: () => getAsoebiVendorDetails(userEmail),
    });
    const [selectBank, setSelectBank] = useState({
        bankSlug: "",
        bankName: "",
        bankCode: "",
    });
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: defaultValues?.name ? defaultValues?.name : "",
            email: defaultValues?.email ? defaultValues?.email : "",
            phone_number: defaultValues?.phone_number ? defaultValues?.phone_number : "",
            event_name: defaultValues?.event_name ? defaultValues?.event_name : "",
        },
        values: defaultValues as eventDetailsType,
    });

    useEffect(() => {
        const eventDetailsStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.event_details);
        const eventDetails = eventDetailsStorage ? JSON.parse(eventDetailsStorage) : null;
        if (eventDetails) {
            setDefaultValues(eventDetails);
        }
        if (status === "success" && !defaultValues) {
            setDefaultValues({
                name: data?.data?.result ? data?.data?.result?.name : "",
                email: data?.data?.result ? data?.data?.result?.email : "",
                phone_number: "",
                event_name: ""
            });
        }

        if (orderFormStatus === "success" && orderFormData && !defaultValues) {
            setDefaultValues({
                name: orderFormData?.data?.result ? orderFormData?.data?.result?.eventDetails.name : "",
                email: orderFormData?.data?.result ? orderFormData?.data?.result?.eventDetails.email : "",
                phone_number: orderFormData?.data?.result ? orderFormData?.data?.result?.eventDetails.phone_number : "",
                event_name: orderFormData?.data?.result ? orderFormData?.data?.result?.eventDetails.event_name : "",
            });
            setAccountNumber(orderFormData.data.result?.eventDetails.accountNumber);
            setSelectBank({
                bankSlug: orderFormData.data.result.eventDetails.bankSlug,
                bankName: orderFormData.data.result.eventDetails.bankName,
                bankCode: orderFormData.data.result.eventDetails.bankCode,
            });
        }
    }, [status, orderFormStatus]);

    function onSubmitHandler(data: eventDetailsType) {
        const payload = {
            ...data,
            ...selectBank,
            accountName,
            accountNumber
        };
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.event_details, JSON.stringify(payload));
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.asoebi_order_form_type, type);
        navigate("/asoebi-tracker/create-questionnaire");
    }

    function onChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        setAccountNumber(event.target.value);
    }

    async function resolveBankDetailsHandler(accountNumber:string) {
        setLoading(true);
        return await resolveBankDetails(accountNumber, selectBank.bankCode);
    }

    useEffect(() => {
        if (accountNumber && selectBank.bankCode && accountNumber.length >= 10) {
            resolveBankDetailsHandler(accountNumber).then((response) => {
                setLoading(false);
                if (response.result) {
                    setAccountName(response.result.account_name);
                }
            }).catch((error) => {
                setLoading(false);
                setAccountName("error");
                return toast.error(error || "Unable to validate account details");
            });
        }
    }, [selectBank.bankName, accountNumber]);

    const validIcon = accountName === "error" ? CancelIcon : accountName ? CheckIcon : "";
    const validIconClassname = accountName ? "mr-2" : "";

    const loadingClassname = loading ? "mr-3" : "";

    const text = type === AsoebiFormType.orderform ? "Asoebi Order Form" : "Asoebi tracker";

    return (
        <AsoebiLayout>
            <section className="asoebi_tracker tracker_content">
                <h1 className="font-bold text-center">
                    Manage your event&#39;s asoebi order seamlessly, by using our <span className="text-[#cb3050]">{text}</span>
                </h1>
                <h4 className="text-lg">Let&#39;s get to know about your event </h4>
                <FormProvider {...methods}>
                    <form
                        className="w-full px-0 col-lg-8 col-md-10 col-12 mx-auto mb-2"
                        onSubmit={methods.handleSubmit(onSubmitHandler)}
                    >
                        {asoebiForm.map((input) => {
                            const inputName = input.name as keyof eventDetailsType;
                            const error = methods.formState.errors[inputName];
                            return <CustomInput key={input.name} input={input} error={error} />;
                        })}
                        {type === AsoebiFormType.orderform && <div>
                            <h6>Share the account details you would like us to pay the money received into?</h6>
                            <SelectBank selectedBank={selectBank} setSelectBank={setSelectBank} />
                            <div className="d-flex align-items-center mt-3">
                                <Input
                                    input={inputAccountNumber}
                                    className={`w-100 ${validIconClassname}`}
                                    value={accountNumber}
                                    onChange={onChangeHandler} />
                                {validIcon && <img src={validIcon} alt="icon" />}
                            </div>
                            <div className="d-flex align-items-center mt-3">

                                <Input input={inputAccountName} value={accountName} className={`w-100 ${loadingClassname}`} readOnly />
                                {loading && <Spinner size={35} />}
                            </div>
                        </div>}
                        {((accountName && accountName !== "error") || (type === AsoebiFormType.tracker)) && <button
                            type="submit"
                            className="w-fit px-4 py-2 hover:opacity-[0.8] mx-auto rounded font-bold"
                        >
                            Next
                        </button>}
                    </form>
                </FormProvider>
            </section>
        </AsoebiLayout>
    );
}
