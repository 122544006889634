import { useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import ContentLoader from "react-content-loader";

import useClickOutside from "@/hooks/useClickOutside";
import PlusIcon from "@/assets/icon/PlusIcon";
import Search from "@/assets/icon/Search";
import { getAllVendors } from "@/axios/get-request";
import getFirstChar from "@/utils/getFirstChar";
import AddVendorModal from "@/components/modals/AddVendorModal";
import { generateColorFromId } from "@/helper";
import { VendorType } from "@/types";
import UserSearchIcon from "@/assets/icon/UserSearchIcon";
import "@/styles/vendor_picker.scss";

interface Props {
    vendor: { id: string; name: string };
    onSelectEventVendor?: (item: VendorType | null) => void;
    addNew?: boolean;
}

export function VendorLineLoader() {
    return (
        <ContentLoader className="mt-3" title="Fetching vendors..." height="70px" backgroundColor="#cbbec0" foregroundColor="#dedede">
            <rect x="0" y="0" rx="10" ry="10" width="50%" height="20px" />
            <rect x="0" y="25" rx="10" ry="10" width="50%" height="20px" />
            <rect x="0" y="50" rx="10" ry="10" width="50%" height="20px" />
        </ContentLoader>
    );
}

interface DisplayVendorsDropdownProps {
    showAddVendorModalHandler: () => void;
    onSelectEventVendor: (item: VendorType | null) => void;
    addNew: boolean;
}

const defaultVendor = {
    name: "Unassigned",
    id: "",
    service: "",
    email: "",
    user_id: "",
    event_id: [],
    phone_number: "",
    account_number: "",
    bank_name: "",
    bank_code: "",
};

function DisplayVendorsDropdown({ showAddVendorModalHandler, onSelectEventVendor, addNew }: DisplayVendorsDropdownProps) {
    const { data, status } = useQuery({
        queryKey: ["all_event_vendors"],
        queryFn: () => getAllVendors(),
    });

    return (
        <div className="vendors_list">
            <div className="vendor_input_search">
                <Search /> <input placeholder="Search saved vendors" />
            </div>
            {addNew && (
                <button onClick={showAddVendorModalHandler}>
                    New vendor <PlusIcon />
                </button>
            )}
            <hr />
            {status === "loading" ? (
                <div className="pl-4 mb-3 vendor_line_loader">
                    <VendorLineLoader />
                </div>
            ) : (
                <div className="vendor_list_group">
                    <button className="unassigned" onClick={() => onSelectEventVendor(defaultVendor)}>
                        <UserSearchIcon /> Unassigned
                    </button>
                    <div className="vendors_list_view pb-2">
                        {data &&
                            data?.data?.result &&
                            data?.data?.result?.length > 0 &&
                            data.data?.result.map((item: VendorType) => {
                                const iconBg = item ? generateColorFromId(item.id) : "";
                                const styles = { backgroundColor: iconBg };
                                return (
                                    item && (
                                        <button key={item.id} className="vendor" onClick={() => onSelectEventVendor(item)}>
                                            <span style={styles}>{getFirstChar(item.name)}</span>
                                            {item.name === "Unassigned" && <UserSearchIcon />} {item.name}
                                        </button>
                                    )
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default function VendorPicker({ vendor, onSelectEventVendor, addNew = true }: Props) {
    const [showVendors, setShowVendors] = useState(false);
    const [showAddVendorModal, setShowAddVendorModal] = useState(false);
    const ref = useRef(null);
    useClickOutside(ref, closeDropdown);

    const vendorIconBg = vendor?.id && vendor?.name ? generateColorFromId(vendor.id) : "";
    const styles = { backgroundColor: vendorIconBg };

    function closeDropdown() {
        setShowVendors(false);
    }

    function onCloseHandler() {
        setShowAddVendorModal(false);
    }

    function showAddVendorModalHandler() {
        setShowAddVendorModal(true);
    }

    return (
        <>
            {showAddVendorModal && <AddVendorModal onClose={onCloseHandler} />}
            <div className="vendor_picker">
                <button className="vendor_name" type="button" onClick={() => setShowVendors(true)}>
                    {vendor?.id ? (
                        <span style={styles}>{getFirstChar(vendor.name)}</span>
                    ) : (
                        <div className="icon_wrapper">
                            <UserSearchIcon />
                        </div>
                    )}{" "}
                    {vendor?.name ? vendor.name : "Unassigned"}
                </button>
                {showVendors && onSelectEventVendor && (
                    <div ref={ref}>
                        <DisplayVendorsDropdown
                            showAddVendorModalHandler={showAddVendorModalHandler}
                            onSelectEventVendor={onSelectEventVendor}
                            addNew={addNew}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
