import NewDashboardLayout from "@/layout/NewDashboardLayout";

export default function DocumentsPage() {
    return (
        <NewDashboardLayout title="Documents">
            <div>
                <h1>Work in progress ⏱️</h1>
            </div>
        </NewDashboardLayout>
    );
}
