import { ChangeEvent, memo, useState } from "react";
import { Dropdown } from "react-bootstrap";

import ChevronDownIcon from "@/assets/icon/ChevronDownIcon";
import TrashIcon2 from "@/assets/icon/TrashIcon2";
import SelectDateInput from "@/components/form/SelectDateInput";
import SiteDropdown from "@/components/Dropdown";
import { formatStringToNumber } from "@/helper";
import { updateCostItems } from "@/axios/put-request";
import { useEvent } from "@/hooks/useEvent";
import Spinner from "@/components/Spinner";
import VendorPicker from "@/components/modals/VendorPicker";
import VendorDetailsCard from "@/components/modals/VendorDetailsCard";
import { useBudget } from "@/hooks/useBudget";
import type { CeremonyType, costItemType } from "@/types";

interface Props {
    ceremonies: CeremonyType | null;
    costItem: costItemType;
    activeCategoryId: string;
    index: number;
    hasSubscribed?: boolean;
    activeCostItemIndex: number | null;
    onToggleCostItem: (costItemId: string) => void;
    showDeleteCostItemModal: (costItemId: string, costItemName: string) => void;
}

let timer: number;
const timeoutValue = 1000;

function BudgetCategoryCardItem({
    ceremonies,
    index,
    hasSubscribed,
    activeCostItemIndex,
    activeCategoryId,
    showDeleteCostItemModal,
    onToggleCostItem,
    costItem,
}: Props) {
    const [loading, setLoading] = useState(false);
    const { selectedEventId } = useEvent();
    const { updateBudgetCategoryCostItem } = useBudget();

    function handleKeyUp() {
        window.clearTimeout(timer);
        timer = window.setTimeout(async () => await updateCostItems(activeCategoryId, costItem, selectedEventId), timeoutValue);
    }

    function handleKeyPress() {
        window.clearTimeout(timer);
    }

    async function updateCostItemHandler() {
        try {
            setLoading(true);
            await updateCostItems(activeCategoryId, costItem, selectedEventId);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }

    function onInputChangeHandler(event: ChangeEvent<HTMLInputElement>, formatAsNumber?: boolean) {
        const inputValue = formatAsNumber ? (event.target.value ? formatStringToNumber(event.target.value) : "") : event.target.value;
        const name = event.target.name;
        let inputValueDetails = {};
        if (name === "deliverables_name" || name === "deliverables_amount") {
            const inputName = name.split("_")[1];
            inputValueDetails = { deliverables: { ...costItem.deliverables, [inputName]: inputValue } };
        } else {
            inputValueDetails = { [name]: inputValue };
        }
        updateBudgetCategoryCostItem(
            {
                ...costItem,
                ...inputValueDetails,
            },
            activeCategoryId,
            costItem.id,
        );
    }

    async function onSelectCeremonyHandler(value: string) {
        updateBudgetCategoryCostItem(
            {
                ...costItem,
                tag: value,
            },
            activeCategoryId,
            costItem.id,
        );
        await updateCostItems(activeCategoryId, { ...costItem, tag: value }, selectedEventId);
    }

    async function onSelectBalanceDueDateHandler(value: Date) {
        updateBudgetCategoryCostItem(
            {
                ...costItem,
                balance_due: value,
            },
            activeCategoryId,
            costItem.id,
        );
        await updateCostItems(activeCategoryId, { ...costItem, balance_due: value }, selectedEventId);
    }

    async function onSelectVendor(vendor: costItemType["vendor"] | null) {
        updateBudgetCategoryCostItem(
            {
                ...costItem,
                vendor,
            },
            activeCategoryId,
            costItem.id,
        );
        await updateCostItems(activeCategoryId, { ...costItem, vendor }, selectedEventId);
    }

    const className = index === activeCostItemIndex ? "active" : "";
    const activeCeremony = ceremonies?.filter((item) => item.id === costItem?.tag)[0];

    const balance = costItem?.actual - costItem?.paid;

    const vendorDetails = {
        id: costItem?.vendor?.id ? costItem?.vendor?.id : "",
        name: costItem?.vendor?.name ? costItem?.vendor?.name : "",
    };

    function showDropdownHandler(costItemId: string) {
        onToggleCostItem(costItemId);
    }

    return (
        <tr className={`category ${className}`}>
            <td className="cost_item">
                <input
                    name="name"
                    onKeyUp={handleKeyUp}
                    onBeforeInput={handleKeyPress}
                    value={costItem?.name ? costItem?.name : "Item"}
                    placeholder={costItem?.name ? costItem?.name : "Item"}
                    onChange={onInputChangeHandler}
                    disabled={!hasSubscribed}
                />
            </td>
            <td className="cost_item_price">
                <div className="input_wrapper">
                    <input
                        onKeyUp={handleKeyUp}
                        onBeforeInput={handleKeyPress}
                        className="deliverables"
                        name="deliverables_name"
                        value={costItem?.deliverables?.name}
                        placeholder="Deliverables"
                        disabled={!hasSubscribed}
                        onChange={onInputChangeHandler}
                    />
                    <input
                        onKeyUp={handleKeyUp}
                        onBeforeInput={handleKeyPress}
                        className="amount"
                        name="deliverables_amount"
                        value={costItem?.deliverables?.amount ? Number(costItem?.deliverables?.amount).toLocaleString() : "0"}
                        placeholder="000"
                        disabled={!hasSubscribed}
                        onChange={(event) => onInputChangeHandler(event, true)}
                    />
                </div>
            </td>
            <td className="cost_item_price">
                <input
                    name="estimate"
                    type="text"
                    onKeyUp={handleKeyUp}
                    onBeforeInput={handleKeyPress}
                    value={costItem?.estimate ? costItem?.estimate.toLocaleString("en-US") : ""}
                    placeholder={costItem?.estimate ? costItem?.estimate.toLocaleString("en-US") : "000"}
                    onChange={(event) => onInputChangeHandler(event, true)}
                    disabled={!hasSubscribed}
                />
            </td>
            <td className="cost_item_price">
                <input
                    name="actual"
                    onKeyUp={handleKeyUp}
                    onBeforeInput={handleKeyPress}
                    value={costItem?.actual ? costItem?.actual.toLocaleString("en-US") : ""}
                    placeholder={costItem?.actual ? costItem?.actual.toLocaleString("en-US") : "000"}
                    onChange={(event) => onInputChangeHandler(event, true)}
                    disabled={!hasSubscribed}
                />
            </td>
            <td className="cost_item_price">
                <VendorPicker addNew={hasSubscribed} vendor={vendorDetails} onSelectEventVendor={(item) => onSelectVendor(item)} />
            </td>
            <td className="cost_btn_group">
                <button onClick={() => showDropdownHandler(costItem.id)}>
                    <ChevronDownIcon />
                </button>
                {hasSubscribed && (
                    <button onClick={() => showDeleteCostItemModal(costItem.id, costItem.name)}>
                        <TrashIcon2 />
                    </button>
                )}
            </td>
            {index === activeCostItemIndex && (
                <td className="more_details">
                    <div className="card_group">
                        <div className="column_1">
                            <div className="cost_item">
                                <p>Balance due</p>
                                <SelectDateInput
                                    value={costItem?.balance_due ? costItem?.balance_due : null}
                                    placeholder="Select date"
                                    onClick={(date) => onSelectBalanceDueDateHandler(date)}
                                />
                            </div>
                            <div className="cost_item">
                                <p>Amount paid</p>
                                <div className="input_wrapper">
                                    <input
                                        onKeyUp={handleKeyUp}
                                        onBeforeInput={handleKeyPress}
                                        placeholder={`${costItem?.paid}`}
                                        value={costItem?.paid ? costItem?.paid.toLocaleString("en-US") : ""}
                                        name="paid"
                                        disabled={!hasSubscribed}
                                        onChange={(event) => onInputChangeHandler(event, true)}
                                    />
                                    <span>NGN</span>
                                </div>
                            </div>
                            <div className="cost_item">
                                <p>Balance</p>
                                <div className="input_wrapper">
                                    <p>{balance ? `${balance.toLocaleString("en-US")}` : "N/A"}</p>
                                    <span>NGN</span>
                                </div>
                            </div>
                            <div className="cost_item">
                                <p>Ceremony</p>
                                <SiteDropdown className="custom" title={activeCeremony?.name ? activeCeremony.name : "Select a ceremony"}>
                                    {ceremonies &&
                                        ceremonies?.map((ceremony, ceremonyIndex) => (
                                            <Dropdown.Item key={ceremonyIndex} onClick={() => onSelectCeremonyHandler(ceremony.id)}>
                                                {ceremony.name}
                                            </Dropdown.Item>
                                        ))}
                                </SiteDropdown>
                            </div>
                        </div>
                        <div className="column_2">
                            <VendorDetailsCard onSelectEventVendor={(item) => onSelectVendor(item)} vendorId={costItem?.vendor?.id as string} />
                        </div>
                    </div>
                    {hasSubscribed && (
                        <button onClick={updateCostItemHandler} className="save_btn">
                            Save {loading && <Spinner size={20} />}
                        </button>
                    )}
                </td>
            )}
        </tr>
    );
}

export default memo(BudgetCategoryCardItem);
