import type { ChangeEvent, ReactNode } from "react";

interface Props {
    input: {
        label: string;
        name: string;
        options?: string[] | number[];
    };
    className?: string;
    value?: string | number;
    options?: string[];
    onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
    selectClassName?: string;
    index?: number;
    testId?:string;
    children?: ReactNode
}
export default function Select({ input, testId, options, onChange, index, value = "", className = "", selectClassName = "", children }: Props) {
    const selectArray = options ? options : input.options;
    const htmlForText= index ? `${input.name}_${index}` : input.name;
    const testIdData = testId ? {"data-testid": testId} : {};


    return (
        <div className={`form_element select ${className}`}>
            {input.label && <label htmlFor={htmlForText}>{input.label}</label>}
            <select
                {...testIdData}
                id={htmlForText}
                name={input.name}
                value={value}
                className={`form-control ${selectClassName}`}
                onChange={onChange}>
                {selectArray ?
                    selectArray.map((item, index) => (
                        <option key={index} value={item}>
                            {item}
                        </option>
                    )): children}
            </select>
            {/* {<p></p>} */}
        </div>
    );
}
