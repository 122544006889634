import Select from "react-select";
import type { SingleValue, ActionMeta, MultiValue } from "react-select";

type singleType = SingleValue<{ [key: string]: string | number }>;
type multiType = MultiValue<{ [key: string]: string | number }>;

interface Props {
    options: { [key: string]: string | number }[];
    placeholder?: string;
    name: string;
    className?: string;
    isMulti?: boolean;
    label?: string;
    value?: multiType | singleType;
    onChange?: (
        newValue: singleType | multiType,
        actionMeta?: ActionMeta<{ [key: string]: string | number }>,
    ) => void;
}

export default function SelectSearch({
    options,
    placeholder,
    name,
    label,
    className = "",
    onChange,
    ...rest
}: Props) {
    return (
        <div className={`form_element search_select ${className}`}>
            {label && <label htmlFor={name}>{label}</label>}
            <Select
                {...rest}
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                placeholder={placeholder}
                name={name}
                options={options}
                onChange={onChange}
            />
        </div>
    );
}
