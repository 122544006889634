/* eslint-disable max-len */

export default function TripleSofaIcon() {
    return (
        <svg width="42" height="15" viewBox="0 0 42 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-outside-1_11427_19950" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="15" fill="black">
                <rect fill="white" width="14" height="15" />
                <path d="M0 2C0 1.44772 0.447715 1 1 1H13C13.5523 1 14 1.44772 14 2V14C14 14.5523 13.5523 15 13 15H1C0.447716 15 0 14.5523 0 14V2Z" />
            </mask>
            <path
                d="M0 2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2C14 2 13.5523 2 13 2H1C0.447715 2 0 2 0 2ZM14 15H0H14ZM0 15V1V15ZM14 1V15V1Z"
                fill="#E845BA"
                mask="url(#path-1-outside-1_11427_19950)"
            />
            <path
                d="M1 15L13 15C13.5523 15 14 14.5523 14 14L14 2C14 1.44772 13.5523 1 13 1L0.999999 1C0.447715 1 -5.92389e-07 1.44772 -5.68248e-07 2L-4.37114e-08 14C-1.95703e-08 14.5523 0.447716 15 1 15Z"
                fill="#D9D9D9"
            />
            <path
                d="M13 14.9L1 14.9C0.502944 14.9 0.1 14.4971 0.1 14L0.0999994 2C0.0999994 1.50294 0.502943 1.1 0.999999 1.1L13 1.1C13.4971 1.1 13.9 1.50294 13.9 2L13.9 14C13.9 14.4971 13.4971 14.9 13 14.9Z"
                stroke="#545566"
                strokeOpacity="0.05"
                strokeWidth="0.2"
            />
            <mask id="path-5-outside-2_11427_19950" maskUnits="userSpaceOnUse" x="14" y="0" width="14" height="15" fill="black">
                <rect fill="white" x="14" width="14" height="15" />
                <path d="M14 2C14 1.44772 14.4477 1 15 1H27C27.5523 1 28 1.44772 28 2V14C28 14.5523 27.5523 15 27 15H15C14.4477 15 14 14.5523 14 14V2Z" />
            </mask>
            <path
                d="M14 2C14 0.895431 14.8954 0 16 0H26C27.1046 0 28 0.895431 28 2C28 2 27.5523 2 27 2H15C14.4477 2 14 2 14 2ZM28 15H14H28ZM14 15V1V15ZM28 1V15V1Z"
                fill="#E845BA"
                mask="url(#path-5-outside-2_11427_19950)"
            />
            <path
                d="M15 15L27 15C27.5523 15 28 14.5523 28 14L28 2C28 1.44772 27.5523 1 27 1L15 1C14.4477 1 14 1.44772 14 2L14 14C14 14.5523 14.4477 15 15 15Z"
                fill="#D9D9D9"
            />
            <path
                d="M27 14.9L15 14.9C14.5029 14.9 14.1 14.4971 14.1 14L14.1 2C14.1 1.50294 14.5029 1.1 15 1.1L27 1.1C27.4971 1.1 27.9 1.50294 27.9 2L27.9 14C27.9 14.4971 27.4971 14.9 27 14.9Z"
                stroke="#545566"
                strokeOpacity="0.05"
                strokeWidth="0.2"
            />
            <mask id="path-9-outside-3_11427_19950" maskUnits="userSpaceOnUse" x="28" y="0" width="14" height="15" fill="black">
                <rect fill="white" x="28" width="14" height="15" />
                <path d="M28 2C28 1.44772 28.4477 1 29 1H41C41.5523 1 42 1.44772 42 2V14C42 14.5523 41.5523 15 41 15H29C28.4477 15 28 14.5523 28 14V2Z" />
            </mask>
            <path
                d="M28 2C28 0.895431 28.8954 0 30 0H40C41.1046 0 42 0.895431 42 2C42 2 41.5523 2 41 2H29C28.4477 2 28 2 28 2ZM42 15H28H42ZM28 15V1V15ZM42 1V15V1Z"
                fill="#E845BA"
                mask="url(#path-9-outside-3_11427_19950)"
            />
            <path
                d="M29 15L41 15C41.5523 15 42 14.5523 42 14L42 2C42 1.44772 41.5523 1 41 1L29 1C28.4477 1 28 1.44772 28 2L28 14C28 14.5523 28.4477 15 29 15Z"
                fill="#D9D9D9"
            />
            <path
                d="M41 14.9L29 14.9C28.5029 14.9 28.1 14.4971 28.1 14L28.1 2C28.1 1.50294 28.5029 1.1 29 1.1L41 1.1C41.4971 1.1 41.9 1.50294 41.9 2L41.9 14C41.9 14.4971 41.4971 14.9 41 14.9Z"
                stroke="#545566"
                strokeOpacity="0.05"
                strokeWidth="0.2"
            />
        </svg>
    );
}
