/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { formatNumber, formatStringToNumber } from "@/helper";
import { TodoType } from "@/types";
import { addMultipleCeremonies, uploadCeremonyImage } from "@/axios/post-request";
import { useEvent } from "./useEvent";
import onboardingFormContent from "@/json/form/onboarding_form.json";

type CeremoniesType = Array<{
	id: string;
	name: string;
	location: string | null;
	no_of_guests: string | number | null;
	date: Date | null;
	checklist?: TodoType;
	image?: string;
	show_suggestion: boolean;
}>;

type CeremonyFilesType = Array<{ file: File | null; ceremonyId: string }>;

export default function useCeremony() {
    const { selectedEventId } = useEvent();
    const [ceremonyFiles, setCeremonyFiles] = useState<CeremonyFilesType>([]);
    const [previewCeremonyFiles, setPreviewCeremonyFiles] = useState<Array<string>>([]);
    const [loading, setLoading] = useState(false);
    const [ceremonies, setCeremonies] = useState<CeremoniesType>([]);
    const [ceremonyPickerOptions, setCeremonyPickerOptions] = useState(onboardingFormContent.ceremonyOptions);
    const queryClient = useQueryClient();

    function changeCeremonyPhotoHandler(event: ChangeEvent<HTMLInputElement>, ceremonyId: string) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        const inputIndex = Number(event.target.getAttribute("data-index"));
        setPreviewCeremonyFiles((prev) => {
            const selectedPreviewFileIndex = prev.findIndex((_, index) => index === inputIndex);
            if (selectedPreviewFileIndex) {
                prev[selectedPreviewFileIndex] = url;
            } else {
                prev = [url];
            }
            return prev;
        });
        setCeremonyFiles((prev) => {
            const selectedFileIndex = prev.findIndex((item) => item.ceremonyId === ceremonyId);
            prev[selectedFileIndex] = {
                file,
                ceremonyId,
            };
            return prev;
        });
    }

    function updateCeremonyTypeHandler(__option: unknown, index: number) {
        const option = __option as { label: string; value: string; __isNew__?: boolean };
        const tempCeremonies = [...ceremonies];
        const ceremonyIndex = ceremonies.findIndex((_, itemIndex) => itemIndex === index);
        tempCeremonies[ceremonyIndex] = {
            ...tempCeremonies[ceremonyIndex],
            name: option?.label ? option?.label : "",
        };
        setCeremonies(tempCeremonies);
        if (option?.__isNew__) {
            setCeremonyPickerOptions([{ label: option?.label, value: option.value }, ...ceremonyPickerOptions]);
        }
    }

    function addMoreCeremonyHandler() {
        const ceremonyId = uuidv4();
        setCeremonies((prev) => {
            return [
                ...prev,
                {
                    id: ceremonyId,
                    name: "",
                    location: "",
                    date: null,
                    no_of_guests: "",
                    checklist: [],
                    show_suggestion: true,
                },
            ];
        });
        setPreviewCeremonyFiles([...previewCeremonyFiles, ""]);
        setCeremonyFiles([...ceremonyFiles, { file: null, ceremonyId }]);
    }

    function deleteACeremonyHandler() {
        if (ceremonies.length >= 1) {
            setCeremonies((prev) => {
                const tempCeremony = [...prev];
                const updatedCeremony = tempCeremony.slice(0, tempCeremony.length - 1);
                return updatedCeremony;
            });
            setPreviewCeremonyFiles((prev) => {
                const tempPreviewCeremonyFiles = [...prev];
                const updatedPreviewCeremonyFiles = tempPreviewCeremonyFiles.slice(0, tempPreviewCeremonyFiles.length - 1);
                return updatedPreviewCeremonyFiles;
            });
            setCeremonyFiles((prev) => {
                const tempCeremonyFiles = [...prev];
                const updatedPreviewCeremonyFiles = tempCeremonyFiles.slice(0, tempCeremonyFiles.length - 1);
                return updatedPreviewCeremonyFiles;
            });
        }
    }

    function updateCeremonyHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const updateCeremony = ceremonies.map((formDetails, idx: number) => {
            if (event.target.name === "no_of_guests" && index === idx) {
                if (event.target.value && !formatStringToNumber(event.target.value)) {
                    return formDetails;
                } else {
                    return {
                        ...formDetails,
                        no_of_guests: event.target.value ? formatStringToNumber(event.target.value) : "",
                    };
                }
            }
            if (index === idx) {
                return {
                    ...formDetails,
                    [event.target.name]: event.target.value,
                };
            } else return formDetails;
        });
        return setCeremonies(updateCeremony);
    }

    function updateCeremonyDateHandler(date: Date, index: number) {
        const updateCeremony = ceremonies.map((formDetails, idx: number) => {
            if (index === idx) {
                return {
                    ...formDetails,
                    date,
                };
            } else return formDetails;
        });
        setCeremonies(updateCeremony);
    }

    async function onCreateCeremonyHandler(onCloseModal: () => void) {
        try {
            const ceremoniesTypeErrors = [];
            const ceremoniesDateErrors = [];
            const ceremoniesLocationErrors = [];
            const ceremoniesGuestErrors = [];

            if (ceremonies.length === 0) {
                return toast.error("Add at least one ceremony");
            }

            if (ceremonies.length > 0) {
                ceremonies.map((ceremony, index) => {
                    if (!ceremony.name) {
                        ceremoniesTypeErrors.push(index);
                    }
                    if (!ceremony.date) {
                        ceremoniesDateErrors.push(index);
                    }
                    if (!ceremony.location) {
                        ceremoniesLocationErrors.push(index);
                    }
                    if (!ceremony.no_of_guests) {
                        ceremoniesGuestErrors.push(index);
                    }
                });
                if (ceremoniesTypeErrors.length > 0) {
                    return toast.error("Ceremony type is required");
                }
                if (ceremoniesLocationErrors.length > 0) {
                    return toast.error("Ceremony location is required");
                }
                if (ceremoniesDateErrors.length > 0) {
                    return toast.error("Ceremony date is required");
                }
                if (ceremoniesGuestErrors.length > 0) {
                    return toast.error("Ceremony guest number is required");
                }
            }
            setLoading(true);
            await addMultipleCeremonies(ceremonies, selectedEventId);
            // if ceremony images are added upload ceremony images
            if (ceremonyFiles.length > 0) {
                const ceremonyPictureFormData = new FormData();
                const uploadCeremonyImageArray = ceremonyFiles.map((ceremonyFile) => {
                    if (ceremonyFile.file && ceremonyFile.ceremonyId) {
                        ceremonyPictureFormData.append("file", ceremonyFile.file);
                        uploadCeremonyImage(ceremonyPictureFormData, ceremonyFile.ceremonyId, selectedEventId);
                    }
                });
                await Promise.all(uploadCeremonyImageArray);
            }
            setLoading(false);
            const ceremonyText = ceremonies.length > 1 ? "Ceremonies added" : "Ceremony added";
            toast.success(ceremonyText);
            queryClient.invalidateQueries();
            onCloseModal();
        } catch (error) {
            setLoading(false);
            toast.error("Oops an error occured");
            console.log("error", error);
            onCloseModal();
        }
    }

    return {
        formValues: {
            ceremonyFiles,
            loading,
            ceremonies,
            ceremonyPickerOptions,
            previewCeremonyFiles,
        },
        functions: {
            onCreateCeremonyHandler,
            updateCeremonyDateHandler,
            updateCeremonyHandler,
            deleteACeremonyHandler,
            addMoreCeremonyHandler,
            updateCeremonyTypeHandler,
            changeCeremonyPhotoHandler,
        },
    };
}
