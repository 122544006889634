import { useQuery } from "@tanstack/react-query";

import { getEvents } from "@/axios/get-request";
import { useFilter } from "@/hooks/useFilter";
import { useModal } from "@/hooks/useModal";
import { useEvent } from "@/hooks/useEvent";
import { EventType, TodoType } from "@/types";
import { useChecklist } from "@/hooks/useChecklist";
import { EventFilterType } from "@/data/types";

export default function useMyEvents(filterEvent?: EventFilterType) {
    const { ceremony, setCeremony } = useFilter();
    const { modal, modalDetails, setModalDetails, setModal } = useModal();
    const { selectedEventId, setSelectedEventId, activeCeremonyId } = useEvent();
    const { setTodo } = useChecklist();

    function onSuccessHandler(data: { result: EventType[] }) {
        if (data && !data?.result) {
            setSelectedEventId("");
        }
        if (!selectedEventId && data?.result && Array.isArray(data?.result) && data?.result.length > 0) {
            setSelectedEventId(data.result[0]?.id);
        }
        if (ceremony === null && data?.result) {
            const activeEvent = selectedEventId ? data?.result.filter((event) => event.id === selectedEventId) : data?.result;
            const defaultCeremony = activeEvent && activeEvent[0]?.ceremonies.length > 0 ? activeEvent[0]?.ceremonies[0] : null;
            if (defaultCeremony) {
                return setCeremony({
                    name: defaultCeremony?.name ? defaultCeremony?.name : "Main Wedding",
                    id: defaultCeremony.id,
                });
            }
        }
        const selectedEvent = selectedEventId && data?.result ? data?.result.filter((event) => event.id === selectedEventId) : data?.result;

        if (selectedEvent && selectedEvent[0]?.ceremonies && selectedEvent[0]?.ceremonies.length > 0) {
            const selectedCeremony =
                ceremony?.id !== "all"
                    ? selectedEvent[0]?.ceremonies.filter((_ceremony) => _ceremony.id === ceremony?.id)
                    : selectedEvent[0]?.ceremonies;
            let allChecklist: TodoType = [];
            selectedCeremony?.map((item) => {
                allChecklist = item && item?.checklist ? [...allChecklist, ...item.checklist] : [...allChecklist];
            });
            return setTodo(allChecklist);
        }
    }
    const queryKey = filterEvent ? `get_all_events_${filterEvent}` : `get_all_events_${selectedEventId}_${activeCeremonyId}`;

    const { status, data, refetch } = useQuery({
        queryKey: [queryKey],
        queryFn: () => getEvents(onSuccessHandler, filterEvent),
    });

    function closeModalHandler() {
        setModal(null);
        setModalDetails(null);
    }
    const activeEvent =
        status === "success" && data?.data?.result && data?.data?.status
            ? selectedEventId
                ? data?.data?.result?.filter((event) => event.id === selectedEventId)
                : data?.data?.result
            : null;

    const selectedCeremonyData = activeEvent && activeEvent[0]?.ceremonies?.filter((_ceremony) => _ceremony.id === modalDetails?.ceremonyId)[0];

    const activeCollaborator = [];

    if (status === "success" && activeEvent && activeEvent[0]?.collaborators) {
        activeEvent[0]?.collaborators?.map((item) => {
            if (item.email && item.role) {
                activeCollaborator.push(item);
            }
        });
    }

    const activeCollaboratorCount = activeEvent && activeEvent[0]?.collaborators ? activeCollaborator?.length : null;

    return {
        activeCollaboratorCount,
        selectedCeremonyData,
        closeModalHandler,
        modal,
        data,
        status,
        activeCeremonyId,
        activeEvent,
        selectedEventId,
        setSelectedEventId,
        refetch,
    };
}
