export default function CheckIcon2({ height = "24", width = "24", fill = "#cb3050" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="M20 6 9 17l-5-5" />
        </svg>
    );
}
