/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Path, Group, Rect } from "react-konva";
import { FloorPlanObjectListType } from "@/data/types";

type itemType = {
	x: number;
	y: number;
	id: string;
	width: number;
	type: FloorPlanObjectListType;
	height: number;
	fill: string;
};

interface Props {
	item: itemType;
	refValue: React.MutableRefObject<undefined | any>;
	onChange: (value: itemType) => void;
	onDragStart: (value: itemType) => void;
	onSelect: () => void;
	onTransformEndHandler: () => void;
}

export default function SingleCloseDoorElement({ refValue, onSelect, onDragStart, item, onChange, onTransformEndHandler }: Props) {
    return (
        <Group
            onClick={onSelect}
            ref={refValue}
            name="floor_plan_element"
            {...item}
            scaleX={3}
            scaleY={3}
            width={44}
            height={14}
            draggable
            onDragStart={() => onDragStart(item)}
            onDragEnd={(e) =>
                onChange({
                    ...item,
                    x: e.target.x(),
                    y: e.target.y(),
                })
            }
            onTransformEnd={() => onTransformEndHandler}>
            <Rect x={16.1} y={7.1} width={11.8} height={0.2} rx={0.1} stroke="#404040" strokeWidth={0.2} />
            <Path stroke={item.fill} strokeWidth={0.4} data="M14 6H15.6C15.8209 6 16 6.17909 16 6.4V8.6C16 8.82091 15.8209 9 15.6 9H14" />
            <Path stroke={item.fill} strokeWidth={0.4} data="M30 6H28.4C28.1791 6 28 6.17909 28 6.4V8.6C28 8.82091 28.1791 9 28.4 9H30" />
        </Group>
    );
}
