/* eslint-disable @typescript-eslint/no-explicit-any */
import { Transformer, Text } from "react-konva";
import { CSSProperties, Fragment, PropsWithChildren, useEffect, useRef } from "react";
import { Html } from "react-konva-utils";
import { Dropdown } from "react-bootstrap";
import ThreeDotsIcon from "@/assets/icon/ThreeDotsIcon";
import SiteDropdown from "@/components/Dropdown";
import useFloorPlan from "@/hooks/useFloorPlan";
import { FloorPlanObjectListType } from "@/data/types";

type valueType = {
    x: number;
    y: number;
    id: string;
    width: number;
    height: number;
    fill: string;
    text?: string;
    type: FloorPlanObjectListType;
};

interface Props {
    trRef: React.MutableRefObject<undefined | any>;
    isSelected: boolean;
    isTransformable?: boolean;
    onSelect: () => void;
    shapeRef: React.MutableRefObject<undefined | any>;
    onChange: (value: valueType) => void;
    onTransformEndHandler: (resetScale?: boolean) => void;
    updateFloorPlanTextHandler: (textId: string, text: string) => void;
    item: {
        x: number;
        y: number;
        id: string;
        width: number;
        type: FloorPlanObjectListType;
        height: number;
        text?: string;
        fill: string;
    };
}

export default function ResizeableText({
    item,
    onSelect,
    onChange,
    shapeRef,
    onTransformEndHandler,
    isSelected,
    updateFloorPlanTextHandler,
    isTransformable = true,
}: PropsWithChildren<Props>) {
    const transformerRef: any = useRef(null);
    const { deleteFloorPlanElementHandler, activeFloorPlanElementId, floorPlanStage, activeFloorPlanStageId, duplicateFloorPlanElementHandler } =
        useFloorPlan();
    const currentFloorPlanIndex = floorPlanStage.findIndex((item) => item.id === activeFloorPlanStageId);

    useEffect(() => {
        if (isSelected && transformerRef) {
            transformerRef?.current?.nodes([shapeRef.current]);
            transformerRef?.current?.getLayer().batchDraw();
        }
    }, [item.text, isSelected]);

    function deleteElementHandler() {
        if (activeFloorPlanElementId) {
            deleteFloorPlanElementHandler(currentFloorPlanIndex, activeFloorPlanElementId);
        }
    }

    function duplicateElementHandler() {
        if (activeFloorPlanElementId) {
            duplicateFloorPlanElementHandler(currentFloorPlanIndex, activeFloorPlanElementId);
        }
    }

    const styles: CSSProperties = { position: "relative" };

    const dropdownStyles = {
        left: item.x + shapeRef?.current?.getTextWidth() + 20,
        top: item.y - 20,
        zIndex: 100,
    };

    const inputStyle: CSSProperties = {
        left: item.x,
        top: item.y + 0,
        position: "absolute",
        border: "none",
        background: "transparent",
        width: item.x + 10,
        color: item.fill,
    };

    return (
        <Fragment>
            <Text
                ref={shapeRef}
                onClick={onSelect}
                {...item}
                text={item.text}
                name="floor_plan_element"
                fontFamily="Inter, sans-serif"
                fontStyle="normal"
                width={Math.max(100, shapeRef?.current?.getTextWidth() + 20)}
                fill={isSelected ? "transparent" : item.fill}
                fontSize={16}
                draggable
                onDragEnd={(e) =>
                    onChange({
                        ...item,
                        x: e.target.x(),
                        y: e.target.y(),
                    })
                }
                onTransformEnd={() => onTransformEndHandler(true)}
            />
            <Html>
                <div style={styles}>
                    {isSelected && (
                        <SiteDropdown styles={dropdownStyles} className="element_menu custom" title={<ThreeDotsIcon />}>
                            <Dropdown.Item onClick={duplicateElementHandler}>Duplicate</Dropdown.Item>
                            <Dropdown.Item onClick={deleteElementHandler}>Delete</Dropdown.Item>
                        </SiteDropdown>
                    )}
                    {isSelected && (
                        <input
                            style={inputStyle}
                            type="text"
                            value={item.text}
                            onChange={(event) => updateFloorPlanTextHandler(item.id, event.target.value)}
                        />
                    )}
                </div>
            </Html>
            {isSelected && isTransformable && (
                <Transformer
                    ref={transformerRef}
                    shouldOverdrawWholeArea
                    flipEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Fragment>
    );
}
