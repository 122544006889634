import NewDashboardLayout from "@/layout/NewDashboardLayout";

export default function CommunityPage() {
    return (
        <NewDashboardLayout title="Community">
            <div>
                <h1>Work in progress ⏱️</h1>
            </div>
        </NewDashboardLayout>
    );
}
