import Button from "@/components/Button";
import Input from "@/components/form/Input";
import budget_breakdown from "@/json/budget_breakdown.json";
import chat from "@/assets/img/chat.svg";
import SelectDateInput from "@/components/form/SelectDateInput";
import type { budgetTrackerfuncType, costItemType } from "@/types";
import useMyEvents from "@/hooks/useMyEvents";

interface Props {
    isLoading: boolean;
    budget: costItemType | null;
    func: budgetTrackerfuncType;
    formError: string;
}

export default function BudgetListDropdownItem({ formError, budget, func, isLoading }: Props) {
    const { activeEvent, status } = useMyEvents();

    const { onAmountChange, onFormElementChange, onSelectDate, saveCostItem, showModal, onSelectChange } = func;

    const budgetBalance = Number(budget?.actual) - Number(budget?.paid);

    const budgetBalanceTd = budget?.actual && budget?.paid ? budgetBalance.toLocaleString("en-US") : 0;

    const balanceValue = budgetBalanceTd ? budgetBalanceTd : "";
    const balanceStyle = budgetBalance < 0 ? "error col-md-4 col-12 px-0 pl-lg-3 pl-md-2" : "col-md-4 col-12 px-0 pl-lg-3 pl-md-2";

    const budgetPaidValue = budget?.paid ? budget?.paid.toLocaleString("en-US") : "";
    const paid_error = formError === "paid" ? "input_error": "";
    const ceremony_error = formError === "ceremony" ? "input_error": "";

    return (
        <div className="budget_list_dropdown event_budget_dropdown">
            <div className="d-flex flex-md-row input_row flex-column">
                <div className="select_due_date pl-0 col-md-4 col-12 px-0 pr-md-2 pr-lg-3">
                    <SelectDateInput className="mt-0" label="Balance Due" value={budget?.balance_due} onClick={onSelectDate} />
                </div>
                <Input
                    showCurrency
                    className={`col-md-4 col-12 px-0 px-md-2 px-lg-2 paid_input ${paid_error}`}
                    input={budget_breakdown.dropdownfield1[0]}
                    value={budgetPaidValue}
                    maxLength={10}
                    onChange={onAmountChange}
                />
                <Input
                    showCurrency
                    input={budget_breakdown.dropdownfield1[1]}
                    value={balanceValue}
                    readOnly={true}
                    className={balanceStyle}
                    onChange={onAmountChange}
                />
            </div>
            <div className="d-flex vendor flex-md-row input_row flex-column">
                {status === "error" ? (
                    <p>unable to fetch ceremonies</p>
                ) : status === "loading" ? (
                    <p>fetching ceremonies...</p>
                ) : (
                    <div className="form_element select pl-0 col-md-4 col-12 px-0 pr-lg-3 pr-md-2">
                        <label htmlFor="tag">Ceremony</label>
                        <select name="tag" className={`form-control ${ceremony_error}`} value={budget?.tag} onChange={onSelectChange}>
                            <option>Select Ceremony</option>
                            {activeEvent &&
                                activeEvent[0]?.ceremonies.map((item: { id: string; name: string }) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name ? item.name : "Main Wedding"}
                                    </option>
                                ))}
                        </select>
                    </div>
                )}
                <Input
                    input={budget_breakdown.dropdownfield2[0]}
                    className="vendor_name col-md-4 col-12 px-0 px-lg-2 px-md-2"
                    value={budget?.vendor_name}
                    onChange={onFormElementChange}
                />
                <Input
                    input={budget_breakdown.dropdownfield2[1]}
                    className="vendor_ig col-md-4 col-12 px-0 pl-lg-3 pl-md-2"
                    value={budget?.vendor_ig}
                    onChange={onFormElementChange}
                />
            </div>
            <div className="form_element mb-4">
                <label htmlFor="note">Note</label>
                <textarea name="note" value={budget?.note} className="form-control" onChange={onFormElementChange}></textarea>
            </div>

            <div className="button_group mt-3 flex-md-row flex-column  d-flex justify-content-between">
                <Button
                    className="bg_dark text-white vendor order-md-1 order-2 col-md-4 col-lg-3 col-12"
                    text="Vendor Assistance"
                    icon={chat}
                    onClick={showModal}
                />
                <Button
                    className="bg_red text-white order-md-2 order-1 col-md-2 col-lg-1 col-12"
                    text="Save"
                    isLoading={isLoading}
                    onClick={saveCostItem}
                />
            </div>
        </div>
    );
}
