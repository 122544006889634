import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ChangeEvent } from "react";
import type { SingleValue } from "react-select";

import { useEvent } from "@/hooks/useEvent";
import SiteModal from "@/components/modals/SiteModal";
import Button from "@/components/Button";
import SelectSearch from "@/components/form/SelectSearch";
import budget_tracker_modal_content from "@/json/form/budget_tracker_modal.json";
import { updateCostItems } from "@/axios/put-request";
import InputGroup from "@/components/form/InputGroup";
import type { budgetTrackerAuditChanges, costItemObjectType, costItemType } from "@/types";
import useAudit from "@/hooks/useAudit";

interface Props {
    show: boolean;
    costItems: costItemObjectType[];
    onHide: () => void;
}

export default function AddPaymentModal({ show, onHide, costItems }: Props) {
    const { payment } = budget_tracker_modal_content;
    const queryClient = useQueryClient();
    const { selectedEventId } = useEvent();
    const { updateAuditHandler } = useAudit();
    const [defaultCostItem, setDefaultCostItem] = useState<null | costItemType>(null);
    const [categoryCostItems, setCategoryCostItems] = useState<Array<costItemType>>([]);
    const [paymentDetails, setPaymentDetails] = useState({
        name: "",
        actual: 0,
        paid: 0,
        vendor_name: "",
        vendor_ig: "",
    });

    useEffect(() => {
        let categoryCostItems: costItemType[] = [];
        costItems.forEach((item) => {
            categoryCostItems = [...categoryCostItems, ...item.cost_items];
        });
        setCategoryCostItems(categoryCostItems);
    }, []);

    useEffect(() => {
        const selectedCostItem = categoryCostItems.filter((item) => item.name === paymentDetails.name)[0];
        setDefaultCostItem(selectedCostItem);
        const selectedCostItemData = paymentDetails.name ?
            selectedCostItem :
            {
                name: "",
                actual: 0,
                paid: 0,
                vendor_name: "",
                vendor_ig: "",
            };

        setPaymentDetails({
            ...paymentDetails,
            ...selectedCostItemData,
        });
    }, [paymentDetails.name]);

    function updateSelectCostItem(newValue: unknown) {
        const _newValue = newValue as SingleValue<{
            [key: string]: string | number;
        }>;
        const value = _newValue?.value as string;
        setPaymentDetails({
            ...paymentDetails,
            name: value,
        });
    }

    function updateInputAmountHandler(e: ChangeEvent<HTMLInputElement>) {
        const formattedValue = Number(e.target.value.replace(/\D/g, ""));
        setPaymentDetails({
            ...paymentDetails,
            [e.target.name]: formattedValue,
        });
    }

    function updateInputHandler(e: ChangeEvent<HTMLInputElement>) {
        setPaymentDetails({
            ...paymentDetails,
            [e.target.name]: e.target.value,
        });
    }

    let selectCostItems: { label: string; value: string }[] = [{ label: "Select a cost Item", value: "" }];
    categoryCostItems.map((item) => {
        selectCostItems = [...selectCostItems, { label: item.name, value: item.name }];
    });

    type _costItemsType = costItemType & { categoryId: string };

    async function updatePayment() {
        let _costItems: _costItemsType[] = [];
        costItems.map((item) => {
            item.cost_items.map((_item) => {
                _costItems = [..._costItems, { ..._item, categoryId: item.categoryId }];
            });
        });
        const selectedCostItem = _costItems.filter((item) => item.name === paymentDetails.name)[0];
        const _paymentDetails = paymentDetails as costItemType;
        const balance = Number(paymentDetails.actual) - Number(paymentDetails.paid);

        return await updateCostItems(
            selectedCostItem.categoryId,
            {
                ..._paymentDetails,
                balance,
            },
            selectedEventId,
        );
    }

    const { mutate, isLoading } = useMutation({
        mutationKey: ["updatePayment"],
        mutationFn: () => updatePayment(),
        onSuccess: (data) => {
            toast.success(data.data.message);
            queryClient.resetQueries({
                queryKey: [`get_event_${selectedEventId}`],
            });
            queryClient.resetQueries({
                queryKey: [`get_categories_${selectedEventId}`],
            });


            const changes = {

                from: {
                    actual: defaultCostItem?.actual ? defaultCostItem?.actual : 0,
                    paid: defaultCostItem?.paid ? defaultCostItem?.paid : 0,
                    vendor_name: defaultCostItem?.vendor_name ? defaultCostItem?.vendor_name : "",
                    vendor_ig: defaultCostItem?.vendor_ig ? defaultCostItem?.vendor_ig : ""
                },
                to: {
                    actual: paymentDetails?.actual,
                    paid: paymentDetails?.paid,
                    vendor_name: paymentDetails?.vendor_name,
                    vendor_ig: paymentDetails?.vendor_ig
                },
            } as budgetTrackerAuditChanges;
            updateAuditHandler("budget_tracker", "update_budget_cost_item",
                paymentDetails.name, changes
            );
            onHide();
        },
        onError: (error) => {
            console.log("error", error);
        },
    });

    function addPaymentHandler(event: ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!paymentDetails.name) {
            return toast.error("Cost item name is required");
        }
        if (paymentDetails.actual === 0) {
            return toast.error("Cost item total cost is required");
        }
        if (paymentDetails.paid === 0) {
            return toast.error("Cost item amount paid is required");
        }
        if (paymentDetails.paid > paymentDetails.actual) {
            return toast.error("Update total cost");
        }
        mutate();
    }

    return (
        <SiteModal title="Add Payment" show={show} onHide={onHide}>
            <section className="add_payment_modal">
                <p>Add payment for your budget category cost item.</p>
                <form className="modal_form mb-3 mt-5" onSubmit={addPaymentHandler}>
                    <SelectSearch
                        options={selectCostItems}
                        name="name"
                        className="select_search"
                        placeholder="Select a cost item"
                        onChange={updateSelectCostItem}
                    />
                    <InputGroup inputArray={payment.row1} value={paymentDetails} onChange={updateInputAmountHandler} maxLength={10} />
                    <InputGroup inputArray={payment.row2} value={paymentDetails} onChange={updateInputHandler} />
                    <div className="button_view justify-content-end d-flex mt-3">
                        <Button text="Save" className="bg_red text-white" isLoading={isLoading} type="submit" />
                    </div>
                </form>
            </section>
        </SiteModal>
    );
}
