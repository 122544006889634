import { Navigate, Outlet } from "react-router-dom";
import Loading from "@/components/Loading";

interface GuardedRouteProps {
  isRouteAccessible?: boolean | null;
  redirectRoute?: string;
}

export default function GuardedRoute({
    isRouteAccessible = false,
    redirectRoute = "/",
}: GuardedRouteProps) {
    return isRouteAccessible === null ? (
        <Loading />
    ) : isRouteAccessible ? (
        <Outlet />
    ) : (
        <Navigate to={redirectRoute} replace />
    );
}
