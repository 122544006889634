/* eslint-disable max-len */

export default function SquareIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.1 12L0.1 2C0.1 0.950659 0.950659 0.1 2 0.1L12 0.1C13.0493 0.1 13.9 0.950659 13.9 2L13.9 12C13.9 13.0493 13.0493 13.9 12 13.9L2 13.9C0.950659 13.9 0.1 13.0493 0.1 12Z"
                fill="#D9D9D9"
                stroke="#404040"
                strokeWidth="0.2"
            />
        </svg>
    );
}
