export default function ChartIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <line x1="12" className="line_2" x2="12" y1="20" y2="10" />
            <line x1="18" className="line_3" x2="18" y1="20" y2="4" />
            <line x1="6" className="line_1" x2="6" y1="20" y2="16" />
        </svg>
    );
}
