import { Dropdown } from "react-bootstrap";
import { ChangeEvent, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import SiteDropdown from "@/components/Dropdown";
import SendIcon from "@/assets/icon/SendIcon";
import Input2 from "../form/Input2";
import AddUserRoundIcon from "@/assets/icon/AddUserRoundIcon";
import DashboardModal from "./DashboardModal";
import useCollaboratorInvite from "@/hooks/useCollaboratorInvite";
import "@/styles/invite_collaborator.scss";
import Spinner from "../Spinner";

interface Props {
	onClose: () => void;
	eventName: string;
}

const collaboratorInput = { name: "name", placeholder: "Collaborator's name" };

type roleOptionsType = "Groom 🤵🏻‍♂️" | "Bride 👰" | "Planner 👩🏻‍💻" | "Lead planner 🕵️‍♂️" | "Coordinator 👷‍♀️" | "Relative 👫🏻" | "Other 🧐";
const roleOptions: Array<roleOptionsType> =
["Groom 🤵🏻‍♂️", "Bride 👰", "Planner 👩🏻‍💻", "Lead planner 🕵️‍♂️", "Coordinator 👷‍♀️", "Relative 👫🏻", "Other 🧐"];
const collaboratorPermission = [
    { name: "can_edit", label: "Can Edit" },
    { name: "view_only", label: "View Only" },
];

/**
 * InviteCollaboratorModal: Invite a collaborator to an event
 * @param eventName
 * @param onClose
 * @returns JSX
 */

export default function InviteCollaboratorModal({ onClose, eventName }: Props) {
    const [collaborator, setCollaborator] = useState({
        name: "",
        email: "",
        role: "Planner",
        permission: "can_edit",
    });
    const { inviteCollaboratorRequest, loading } = useCollaboratorInvite();
    const queryClient = useQueryClient();

    function onSelectCollaboratorRoleHandler(role: string) {
        setCollaborator({
            ...collaborator,
            role,
        });
    }

    function onSelectCollaboratorPermissionHandler(permission: string) {
        setCollaborator({
            ...collaborator,
            permission,
        });
    }

    function onInputChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        setCollaborator({
            ...collaborator,
            [event.target.name]: event.target.value,
        });
    }

    const selectedCollaboratorPermission = collaboratorPermission.filter((item) => item.name === collaborator.permission);

    function inviteCollaboratorSuccess() {
        setCollaborator({
            name: "",
            email: "",
            role: "Planner",
            permission: "can_edit",
        });
        queryClient.resetQueries();
        onClose();
    }

    async function inviteCollaboratorHandler() {
        try {
            await inviteCollaboratorRequest(collaborator, inviteCollaboratorSuccess);
        } catch (error) {
            console.log("error", error);
        }
    }

    return (
        <DashboardModal
            className="invite_collaborator_modal"
            title="Invite Collaborator"
            description={`Invite collaborator to ${eventName} event`}
            onClose={onClose}>
            <section className="invite_modal_content">
                <div className="input_group">
                    <Input2 value={collaborator.name} input={collaboratorInput} onChange={onInputChangeHandler} />
                    <SiteDropdown className="custom add_role" title={collaborator.role ? collaborator.role : "Role"}>
                        {roleOptions.map((role, index) => (
                            <Dropdown.Item key={index} type="button" onClick={() => onSelectCollaboratorRoleHandler(role)}>
                                {role}
                            </Dropdown.Item>
                        ))}
                    </SiteDropdown>
                </div>
                <div className="top_row">
                    <div className="input_view">
                        <AddUserRoundIcon />
                        <input value={collaborator.email} placeholder="Enter email to invite" name="email" onChange={onInputChangeHandler} />
                    </div>
                    <div className="button_group">
                        <SiteDropdown className="custom permission" title={selectedCollaboratorPermission[0].label}>
                            {collaboratorPermission.map((permission, index) => (
                                <Dropdown.Item
                                    key={index}
                                    type="button"
                                    className="permission"
                                    onClick={() => onSelectCollaboratorPermissionHandler(permission.name)}>
                                    {permission.label}
                                </Dropdown.Item>
                            ))}
                        </SiteDropdown>
                        <button type="button" className="invite_btn" onClick={inviteCollaboratorHandler}>
                            {loading ? (
                                <span>
                                    <Spinner size={20} />
                                </span>
                            ) : (
                                <SendIcon />
                            )}{" "}
							Invite
                        </button>
                    </div>
                </div>
            </section>
        </DashboardModal>
    );
}
