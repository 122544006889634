import { Link } from "react-router-dom";

import planadayNewLogo from "@/assets/img/planaday_logo.svg";
import instagramredIcon from "@/assets/img/instagram_red.svg";
import tiktokredIcon from "@/assets/img/tiktok_red.svg";
import emailredIcon from "@/assets/img/email_red.svg";
import linkedin from "@/assets/img/linkedin.svg";
import arrowLeftIcon from "@/assets/img/arrow_left.svg";
import upwardarrowIcon from "@/assets/img/upward_arrow_red.svg";
import stopwatchIcon from "@/assets/img/stopwatch_icon.png";
import thumbprintIcon from "@/assets/img/thumbprint_icon.png";
import calendarIcon from "@/assets/img/calendar_icon.png";
import plan1Img from "@/assets/img/plan_img_1.webp";
import opeImg from "@/assets/img/ope.webp";
import twitterIcon from "@/assets/img/twitter_icon.svg";
import zainabImg from "@/assets/img/zainab.webp";
import bunmiImg from "@/assets/img/bunmi.webp";
import plan2Img from "@/assets/img/plan_img_2.webp";
import plan3Img from "@/assets/img/plan_img_3.webp";
import chartIcon from "@/assets/img/chart_icon.png";
import mailImg from "@/assets/img/mail.webp";
import "@/styles/about_us.scss";

export default function AboutusPage() {
    return (
        <>
            <header className="about_us_header">
                <div className="header_content">
                    <Link to="/landing-page">
                        <img src={planadayNewLogo} />
                    </Link>
                    <div>
                        <ul>
                            <li>
                                <Link to="/landing-page">Home</Link>
                            </li>
                            <li>
                                <a href="https://www.blog.planaday.events/">Community</a>
                            </li>
                            <li>
                                <Link to="/about-us" className="active">About us</Link>
                            </li>
                        </ul>
                        <Link to="/signup" className="button btn">Plan an event</Link>
                    </div>
                </div>
            </header>
            <main className="about_us">
                <section className="hero">
                    <div className="note_region">
                        <div className="row_1">
                            <div className="user_note first">
                                <span>Bimbo</span>
                                <div>
                                    <p>where do I start from...</p>
                                </div>
                            </div>
                            <div className="user_note second">
                                <span>Franklin</span>
                                <div>
                                    <p>how much does a wedding cost...?</p>
                                </div>
                            </div>
                        </div>
                        <div className="user_note third">
                            <span>Zainab</span>
                            <div>
                                <p>how do I find good vendors...?</p>
                            </div>
                        </div>
                    </div>
                    <div className="hero_content">
                        <h1>
							For us, <br />
							Weddings mean love...
                        </h1>
                        <p>
							Create a platform that guarantees the most thoughtful, personalized and unique event planning experience.
                            <br />
                            <br />
							If you’ve ever embarked on planning an event, these questions might be familiar.
                        </p>
                    </div>
                </section>
                <section className="vision">
                    <h3>
						Our <br />
                        <span>Vision</span>
                    </h3>
                    <p>
						Planning events can be demanding, <br />
						and there aren’t many tools to make this journey easier.
                        <br />
                        <br />
                        <span>That’s why we’ve founded Planaday.</span>
                    </p>
                </section>
                <section className="our_journey">
                    <div className="section_1">
                        <h3>
							Our Journey; <br /> To make weddings <br /> great again
                        </h3>
                        <div>
                            <p>We want to be with you every step of your planning journey,</p>
                            <ul>
                                <li>
                                    <img src={arrowLeftIcon} alt="arrow" className="mr-4" />
									1. From putting together your budget
                                </li>
                                <li>
                                    <img src={arrowLeftIcon} alt="arrow" className="mr-4" />
									2. To finding and selecting your dream vendors!
                                </li>
                            </ul>
                            <p>We will take the pressure off, leaving you to focus on what matters most - the happy moments.</p>
                        </div>
                    </div>
                    <div className="section_2">
                        <div>
                            <h5>BUDGETING</h5>
                            <img src={chartIcon} alt="BUDGETING" />
                        </div>
                        <div>
                            <h5>TO-DOs</h5>
                            <img src={mailImg} alt="TO-DOs" />
                        </div>
                        <div>
                            <h5>VENDOR SOURCING</h5>
                            <img src={thumbprintIcon} alt="VENDOR SOURCING" />
                        </div>
                        <div>
                            <h5>VENDOR MANAGEMENT</h5>
                            <img src={calendarIcon} alt="VENDOR MANAGEMENT" />
                        </div>
                        <div>
                            <h5>COLLABORATION</h5>
                            <img src={stopwatchIcon} alt="COLLABORATION" />
                        </div>
                    </div>
                </section>
                <section className="help_you_plan">
                    <h3>
						How we
                        <br />
                        <span>help you plan</span>
                    </h3>
                    <div className="plan_group">
                        <div>
                            <img src={plan1Img} />
                            <h6>Planning Tools</h6>
                            <p>
								With Planaday, you would have access to a comprehensive set of planning tools to empower you to plan your big day
								seamlessly. And because no two weddings are the same, we allow you to personalise your experience.
                            </p>
                        </div>
                        <div>
                            <img src={plan2Img} />
                            <h6>Planning Advice</h6>
                            <p>
								We curate articles and guides with the best wedding planning tips and advice. Join the Planaday community, a safe and
								honest forum to share all things wedding. Hear from wedding enthusiasts and experts on how to plan the perfect
								wedding!
                            </p>
                        </div>
                        <div>
                            <img src={plan3Img} />
                            <h6>Inspiration</h6>
                            <p>
								Imagining your wedding day is fun! Even better is browsing through our articles and expert guidance for the
								inspiration you need to make your wedding unique and memorable. Let us help make your wedding the day you’ve always
								dreamed of!
                            </p>
                        </div>
                    </div>
                </section>
                <section className="the_team">
                    <h3>
						The <br />
                        <span>Team</span>
                    </h3>
                    <div className="team_group">
                        <div className="team_member">
                            <img src={zainabImg} className="team_mate_img" alt="Zainab - Co-founder, passionate about simplifying planning." />
                            <div>
                                <h6>Zainab</h6>
                                <p>Co-founder, passionate about simplifying planning.</p>
                                <div className="social_icon_group">
                                    <a href="http://www.linkedin.com/in/zainab-onisarotu-8a404143" target="__blank">
                                        <img src={linkedin} alt="Linkedin icon" />
                                    </a>
                                    <a href="https://twitter.com/z_onisarotu" target="__blank">
                                        <img src={twitterIcon} alt="Twitter icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="team_member">
                            <img src={opeImg} className="team_mate_img" alt="Ope - Co-founder, making our tech work." />
                            <div>
                                <h6>Ope</h6>
                                <p>Co-founder, making our tech work.</p>
                                <div className="social_icon_group">
                                    <a href="http://www.linkedin.com/in/ahmedopeyemi" target="__blank">
                                        <img src={linkedin} alt="Linkedin icon" />
                                    </a>
                                    <a href="https://twitter.com/_ahmedMajeed" target="__blank">
                                        <img src={twitterIcon} alt="Twitter icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="team_member">
                            <img src={bunmiImg} className="team_mate_img" alt="Bunmi - Co-founder, helping to create customer delight" />
                            <div>
                                <h6>Bunmi</h6>
                                <p>Co-founder, helping to create customer delight.</p>
                                <div className="social_icon_group">
                                    <a href="https://www.linkedin.com/in/oluwabunmi-kolawole-a1158911b" target="__blank">
                                        <img src={linkedin} alt="Linkedin icon" />
                                    </a>
                                    <img src={twitterIcon} alt="Twitter icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="join_community">
                    <p>Exclusive offers, deals and content.</p>
                    <h4>Join our community</h4>
                    <a href="https://www.blog.planaday.events/" target="_blank" className="btn">Join Now</a>
                </section>
            </main>
            <footer className="footer about_us landing_page">
                <div className="top_section">
                    <img src={planadayNewLogo} alt="logo" />
                    <div className="footer_icon_group">
                        <div>
                            <p>Couples</p>
                            <p>Event Planners</p>
                        </div>
                        <div>
                            <a href="#icon" className="icon">
                                <img src={instagramredIcon} alt="Instagram" />
                            </a>
                            <a href="#icon" className="icon">
                                <img src={tiktokredIcon} alt="Tiktok" />
                            </a>
                            <a href="#icon" className="icon">
                                <img src={twitterIcon} alt="Twitter" />
                            </a>
                            <a href="#icon" className="icon">
                                <img src={emailredIcon} alt="Mail us" />
                            </a>
                            <a href="#top" className="go_up">
                                <img src={upwardarrowIcon} alt="go up" />
                            </a>
                        </div>
                    </div>
                </div>
                <hr className="my-0" />
                <div className="bottom_section">
                    <p>© Planaday 2024. All rights reserved.</p>
                    <div>
                        <Link to="#">Privacy Policy</Link>
                        <Link to="#">Terms of Service</Link>
                        <Link to="#">Cookies Settings</Link>
                    </div>
                </div>
            </footer>
        </>
    );
}
