export const constant_values = {
    MY_EVENT_TOUR_GUIDE: "MY_EVENT_TOUR_GUIDE",
    BUDGET_TRACKER_TOUR_GUIDE: "BUDGET_TRACKER_TOUR_GUIDE",
    CHECKLIST_TOUR_GUIDE: "CHECKLIST_TOUR_GUIDE",
    PROFILE_TOUR_GUIDE: "PROFILE_TOUR_GUIDE",
};
export const budgetCategories = {
    standard: {
        min: 0,
        max: 5000000,
    },
    mid_luxury: {
        min: 5000000,
        max: 10000000,
    },
    luxurious: {
        min: 10000000,
    },
};

export const EVENT_PRICING = {
    BASIC: 2500,
    ESSENTIAL: 10000,
    PREMIUM: 15000
};

export const EVENT_PRICING_TEXT = {
    BASIC: "₦2,500",
    ESSENTIAL: "₦10,000",
    PREMIUM: "₦15,000",
};

export const VENDOR_ASSISTANCE_PRICING = {
    one_vendor: {price: 7000, unit_price: 7000},
    three_vendors: {price: 18000, unit_price: 6000},
    five_vendors: {price: 29000, unit_price: 5800}
};

export const COLLABORATOR_PRICING = 2500; // 2500 PER COLLABORATOR
export const PAYSTACK_CURRENCY_CONSTANT = 100; // Paystack process currency in KOBO, 100 KOBO = 1 NAIRA

export const SOCIAL_LINKS = {
    instagram: "https://www.instagram.com/planaday_weddings",
    tiktok: "https://www.tiktok.com/@planaday",
    facebook: "https://www.facebook.com/Planaday.events/",
    twitter: "https://twitter.com/planadaywedding?s=21",
    email: "https://mail.google.com/mail/?view=cm&fs=1&to=info@planaday.events"
};

// eslint-disable-next-line max-len
export const VENDOR_ASSISTANCE_WHATSAPP_MESSAGE = "https://wa.me/2348054342349?text=Hello";

// eslint-disable-next-line max-len
export const VENDOR_ASSISTANCE_WHATSAPP_MESSAGE_DESKTOP = "https://web.whatsapp.com/send?autoload=1&amp;app_absent=0&amp;phone=2348054342349&amp;text=Hello";

export const PLANADAY_GHOST_CMS_ID="89a5c812b28660b1c8dc17dce4";

export const LOCAL_STORAGE_KEYS = {
    magiclink_token: "magiclink_token",
    onboarding_details: "onboarding_details",
    calculator_details: "calculator_details",
    model_result: "model_result",
    collaborator_details: "collaborator_details",
    skip_event_budget: "skip_event_budget",
    ceremonies: "ceremonies",
    guest_details: "guest_details",
    event_details: "event_details",
    asoebi_options: "asoebi_options",
    randomQuoteIndex: "randomQuoteIndex",
    planner_admin_email: "planner_admin_email",
    asoebi_order_form_type: "asoebi_order_form_type",
    order_form_details: "order_form_details",
    floor_plan_tour_guide: "floor_plan_tour_guide"
};

export const AuthEmulatorURL = "http://127.0.0.1:9099";
