import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { useRef, useState } from "react";
import { CodeIcon } from "lucide-react";

import { getEvents } from "@/axios/get-request";
import getFirstChar from "@/utils/getFirstChar";
import useClickOutside from "@/hooks/useClickOutside";
import { useEvent } from "@/hooks/useEvent";
import CaretIcon from "@/assets/icon/CaretIcon";

interface Props {
    onUpdateEventHandler?: (index: number) => void;
}

export default function EventSwitcher({ onUpdateEventHandler }: Props) {
    const [showEventList, setshowEventList] = useState(false);
    const { selectedEventId, setActiveCeremonyId, setSelectedEventId } = useEvent();
    const location = useLocation();
    const ref = useRef(null);
    const { data } = useQuery({
        queryKey: [`get_event_${selectedEventId}`],
        queryFn: () => getEvents(),
    });
    useClickOutside(ref, closeDropdown);

    const selectedEventData = data?.data;

    const eventName = selectedEventId && selectedEventData?.result
        ? selectedEventData?.result.filter((item) => item.id === selectedEventId)[0]?.event_name
        : "";

    function updateChecklistCeremony(eventIndex: number) {
        const locationCheck = location.pathname.includes("/dashboard/checklist") || location.pathname.includes("/dashboard/event-overview");
        if (
            locationCheck &&
            selectedEventData?.result &&
            selectedEventData?.result[eventIndex]?.ceremonies?.length > 0 &&
            selectedEventData?.result[eventIndex]?.ceremonies[0]?.id
        ) {
            setActiveCeremonyId(selectedEventData?.result[eventIndex]?.ceremonies[0]?.id);
        }
    }

    function closeDropdown() {
        setshowEventList(false);
    }

    function selectEventHandler(eventId: string, index: number) {
        setSelectedEventId(eventId);
        setActiveCeremonyId("all");
        updateChecklistCeremony(index);
        if (onUpdateEventHandler) {
            onUpdateEventHandler(index);
        }
        setshowEventList(false);
    }

    return (
        <div className="event_switcher">
            <div ref={ref}>
                {showEventList && (
                    <menu className="event_menu_list">
                        <ul>
                            {selectedEventData &&
                                selectedEventData?.result?.map((item, index) => (
                                    <li key={index}>
                                        <button className="event_switch_view" onClick={() => selectEventHandler(item.id, index)}>
                                            <div className="current_event_group">
                                                <span className="initials">{item.event_name ? getFirstChar(item.event_name) : "--"}</span>
                                                <p>{item.event_name}</p>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                        </ul>
                        {/* <div className="all_events_view">
                            <h6>All Events</h6>
                        </div> */}
                        <CaretIcon />
                    </menu>
                )}
                <button className="event_switch_view" disabled={!eventName} onClick={() => setshowEventList(true)}>
                    <div className="current_event_group">
                        <div className="initials">{eventName ? getFirstChar(eventName) : "--"}</div>
                        <p>{eventName ? eventName : "--"}</p>
                    </div>
                    <div className="update_event">
                        <CodeIcon />
                    </div>
                </button>
            </div>
        </div>
    );
}
