import { Link } from "react-router-dom";
import InfoIcon from "@/assets/icon/InfoIcon";
import "@/styles/info_banner.scss";
import { PropsWithChildren } from "react";

interface BannerProps {
	text: string;
	link?: string;
	linkText?: string;
	className?: string;
}
export default function InfoBanner({ text, link, linkText, className = "", children }: PropsWithChildren<BannerProps>) {
    return (
        <div className={`new_ui_banner ${className}`}>
            <InfoIcon /> {text}
            {link && linkText && <Link to={link}>{linkText}</Link>}
            {children}
        </div>
    );
}
