import eventImg1 from "@/assets/img/event_card_image_1.webp";
import eventImg2 from "@/assets/img/event_card_image_2.webp";
import eventImg3 from "@/assets/img/event_card_image_3.webp";
import eventImg5 from "@/assets/img/event_card_image_5.webp";
import eventImg6 from "@/assets/img/event_card_image_6.webp";

export function eventImage(eventName: string) {
    if (eventName) {
        const _eventName = eventName.toLowerCase();
        if (_eventName.includes("gradc")) {
            return eventImg3;
        } else if (_eventName.includes("wedding")) {
            return eventImg1;
        } else if (_eventName.includes("shower")) {
            return eventImg5;
        } else if (_eventName.includes("launch")) {
            return eventImg6;
        } else {
            return eventImg2;
        }
    } else {
        return eventImg2;
    }
}

export function eventCardStyle(eventName: string) {
    if (eventName) {
        const _eventName = eventName.toLowerCase();
        if (_eventName.includes("grad")) {
            return { background: "linear-gradient(90deg, #F9F0E7 0%, #F5E9DD 100%)" };
        } else if (_eventName.includes("wedding")) {
            return { background: "linear-gradient(90deg, #D4FCFC 0%, #DEE1F2 100%)" };
        } else if (_eventName.includes("shower")) {
            return { background: "linear-gradient(90deg, #F1DDE8 0%, #DEE1F2 100%)" };
        } else if (_eventName.includes("launch")) {
            return { background: "linear-gradient(90deg, #FBFCB6 0%, #DEE1F2 100%)" };
        } else {
            return { background: "linear-gradient(90deg, #F3E9F7 0%, rgba(223, 149, 51, 0.5) 100%)" };
        }
    }
}
