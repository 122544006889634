import { useState } from "react";
import type { ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";

import AuthLayout from "@/layout/AuthLayout";
import resetPasswordform from "@/json/form/reset_password_form.json";
import PasswordInput from "@/components/form/PasswordInput";
import useAuth from "@/hooks/useAuth";
import { toast } from "react-toastify";

type passwordDataType = {
	password: string;
	confirmPassword: string;
};

export default function ResetPassword() {
    const { confirmUserPasswordReset } = useAuth();
    const [searchParams] = useSearchParams();
    const oobCode = searchParams.get("oobCode") as string;

    const [passwordData, setPasswordData] = useState<passwordDataType>({
        password: "",
        confirmPassword: "",
    });

    function inputChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        setPasswordData({
            ...passwordData,
            [event.target.name]: event.target.value,
        });
    }

    function onSubmitFormHandler(event: ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!passwordData.password) {
            return toast.error("Password is required");
        }
        if (passwordData.password !== passwordData.confirmPassword) {
            return toast.error("Password must match");
        }
        if (passwordData.password.length < 6) {
            return toast.error("Password must be at least 6 characters long.");
        }
        confirmUserPasswordReset(oobCode, passwordData.password);
    }

    return (
        <AuthLayout type="reset_password">
            <form onSubmit={onSubmitFormHandler} className="reset_password_form">
                {resetPasswordform.map((input) => {
                    const inputName = input.name as keyof passwordDataType;
                    return <PasswordInput value={passwordData[inputName]} input={input} key={input.name} onChange={inputChangeHandler} />;
                })}
                {passwordData.password !== passwordData.confirmPassword && passwordData.confirmPassword ? (
                    <p className="text-sm text-danger error_text">Password must match</p>
                ) : (
                    passwordData.password.length < 8 && <p className="text-sm text-danger error_text">Password must be at least 8 characters.</p>
                )}
                <button
                    type="submit"
                    className="bg_red px-3 text-white border-0 mx-auto font-weight-bold d-flex align-items-center justify-content-center">
					Submit
                </button>
            </form>
        </AuthLayout>
    );
}
