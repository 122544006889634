import "@/styles/select_option.scss";

interface Props {
	icon: JSX.Element;
	title: string;
    onClick: () => void;
	text: string;
    className?:string;
}

export default function SelectOption({ icon, onClick, title, text, className="" }: Props) {
    return (
        <div onClick={onClick} className={`select_option ${className}`}>
            <span>{icon}</span>
            <div>
                <h4>{title}</h4>
                <p>{text}</p>
            </div>
            <div className="svg_wrapper">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#E72E50"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <path d="M5 12h14" />
                    <path d="m12 5 7 7-7 7" />
                </svg>
            </div>
        </div>
    );
}
