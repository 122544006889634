import UnionIcon from "@/assets/icon/UnionIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
	price: string | number;
	title: string;
	className?: string;
	info: string;
	icon: JSX.Element;
}

export default function BudgetCard({ price, title, info, icon, className = "" }: Props) {
    return (
        <div className={`budget_card   ${className}`}>
            <div className="top_row">
                <h4>
                    {price ? (
                        <>
                            {price} <span>NGN</span>
                        </>
                    ) : (
                        <>
							00.<span>00 NGN</span>
                        </>
                    )}
                </h4>
                {icon}
            </div>
            <div className="title_info_row">
                <h6>{title}</h6>
                <OverlayTrigger placement="right" overlay={<Tooltip className="tooltip" id="budget">{info}</Tooltip>}>
                    <div className="question">
                        <UnionIcon />
                    </div>
                </OverlayTrigger>
            </div>
        </div>
    );
}
