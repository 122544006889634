/* eslint-disable max-len */

export default function SingleOpenDoorIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_11634_7328" fill="white">
                <path d="M14.0037 13.9998C14.0037 12.1608 13.6414 10.3399 12.9377 8.64084C12.2339 6.94182 11.2024 5.39806 9.90206 4.0977C8.60169 2.79733 7.05793 1.76582 5.35892 1.06207C3.6599 0.358311 1.83891 -0.003906 -8.39233e-05 -0.00390564L-8.14039e-05 13.9998L14.0037 13.9998Z" />
            </mask>
            <path
                d="M14.0037 13.9998C14.0037 12.1608 13.6414 10.3399 12.9377 8.64084C12.2339 6.94182 11.2024 5.39806 9.90206 4.0977C8.60169 2.79733 7.05793 1.76582 5.35892 1.06207C3.6599 0.358311 1.83891 -0.003906 -8.39233e-05 -0.00390564L-8.14039e-05 13.9998L14.0037 13.9998Z"
                fill="white"
                fillOpacity="0.8"
                stroke="#404040"
                strokeWidth="0.56"
                mask="url(#path-1-inside-1_11634_7328)"
            />
        </svg>
    );
}
