import type { ChangeEvent } from "react";
import type { SingleValue, MultiValue } from "react-select";

import PlusIcon from "@/assets/icon/PlusIcon";
import Input from "@/components/form/Input";
import vendorAssistanceForm from "@/json/form/vendor_assistance_form.json";
import SelectDateInput from "@/components/form/SelectDateInput";
import MinusIcon from "@/assets/icon/MinusIcon";
import OptionsPicker from "@/components/form/OptionsPicker";
type multiType = SingleValue<{ [key: string]: string | number }> | MultiValue<{ [key: string]: string | number }>;

interface Props {
	index: number;
	vendorDetailsItem: {
		vendorService: string;
		eventLocation: string;
		vendorEstimatedBudget: string;
		eventDate?: null | Date;
	};
	vendorDetailsValue: {
		vendorService: string;
		eventLocation: string;
		vendorEstimatedBudget: string;
		eventDate?: null | Date;
		otherServices?: boolean;
	};
	rows: number;
	onAddMoreVendorDetails: () => void;
	onReduceVendorDetails: (index: number) => void;
	onUpdateVendorDetails: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement> | null, index: number, date?: Date | null) => void;
	onUpdateOptionsPicker: (newValue: multiType, index: number) => void;
}

const otherVendorServices = { name: "vendorService", label: "Other vendor services", placeholder: "Other vendor services" };

export default function VendorDetails({
    index,
    rows,
    vendorDetailsValue,
    vendorDetailsItem,
    onAddMoreVendorDetails,
    onUpdateVendorDetails,
    onUpdateOptionsPicker,
    onReduceVendorDetails,
}: Props) {
    const { select, eventLocation, estimatedBudget, eventDate } = vendorAssistanceForm.vendorDetails;
    const today = new Date();
    return (
        <div className="d-flex flex-md-row flex-column align-items-center mb-3 px-0">
            {!vendorDetailsValue?.otherServices ? (
                <OptionsPicker
                    isMulti={false}
                    className="col-md-3 pr-md-3 col-12 mb-3 mb-md-0 px-0"
                    input={select.input}
                    options={select.options}
                    onChange={(value) => onUpdateOptionsPicker(value, index)}
                />
            ) : (
                <Input
                    value={vendorDetailsItem.vendorService}
                    className="col-md-3 pr-md-3 col-12 mb-3 mb-md-0 px-0"
                    input={otherVendorServices}
                    onChange={(event) => onUpdateVendorDetails(event, index)}
                />
            )}
            <Input
                value={vendorDetailsItem.eventLocation}
                className="col-md-3 pr-md-3 col-12 mb-3 mb-md-0 px-0"
                input={eventLocation}
                onChange={(event) => onUpdateVendorDetails(event, index)}
            />
            <Input
                value={vendorDetailsItem.vendorEstimatedBudget}
                className="col-md-3 pr-md-3 col-12 mb-3 mb-md-0 px-0"
                input={estimatedBudget}
                maxLength={20}
                onChange={(event) => onUpdateVendorDetails(event, index)}
            />
            <SelectDateInput
                value={vendorDetailsItem.eventDate}
                className="mt-md-2 pr-md-2 px-0 mb-2 col-12"
                label={eventDate.label}
                minDate={today}
                onClick={(date) => onUpdateVendorDetails(null, index, date)}
            />
            <div
                className="vendor_details_button_group d-flex flex-row align-items-center
            align-items-md-start justify-item-md-start flex-md-column ml-2 mt-lg-4 px-0">
                {index === rows - 1 ? (
                    <button className="border-0 mb-0 mt-3 bg-transparent" type="button" onClick={onAddMoreVendorDetails}>
                        <PlusIcon fill="#000" />
                    </button>
                ):
                    (
                        <button
                            className="border-0 mb-0 mt-3 bg-transparent px-0 !w-[26px]"
                            type="button" onClick={() => onReduceVendorDetails(index)}
                        >
                            <MinusIcon />
                        </button>
                    )}
            </div>
        </div>
    );
}
