import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isNumber } from "lodash";

import Checkbox from "@/components/Checkbox";
import RadioGroup from "@/components/RadioGroup";
import SelectInputGroup from "@/components/SelectInputGroup";
import SelectDateInput from "@/components/form/SelectDateInput";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import Input from "@/components/form/Input";
import { AsoebiFormType, type AsoebiOptionType } from "@/data/types";

const modeOfCommunicationOptns = ["Email", "Whatsapp"];
const currenyOptions = ["Naira (₦)", "Dollar ($)"];

const emailAddressInput = {name: "email_address_for_communication", placeholder: "Email address"};
const whatsappInput = {name: "whatsapp_number", placeholder: "Whatsapp number"};

interface Props {
	children: React.ReactNode;
    selectedFiles: File[]
    onPreviewHandler: () => void;
}

export default function CompleteQuestionaire({ selectedFiles, children, onPreviewHandler}: Props) {
    const [orderFormType, setOrderFormType] = useState("");
    const [asoebiOptions, setAsoebiOptions] = useState({
        mode_of_communication: "",
        whatsapp_number: "",
        currency: "",
        email_address_for_communication: "",
        special_note: false,
        order_deadline: "",
        options: [{ price: "", option: "", max_stock_qty: 0, stock_bought_qty: 0 }],
    });

    const today = new Date();

    useEffect(() => {
        const asoebiOptionsStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.asoebi_options);
        const eventDetailsStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.event_details);
        const asoebiOrderFormType = window.localStorage.getItem(LOCAL_STORAGE_KEYS.asoebi_order_form_type) as string;
        setOrderFormType(asoebiOrderFormType);
        if (asoebiOptionsStorage) {
            const parsedAsoebiOptions = JSON.parse(asoebiOptionsStorage);
            setAsoebiOptions(parsedAsoebiOptions);
        }
        if (eventDetailsStorage) {
            const parsedEventDetails = JSON.parse(eventDetailsStorage);
            setAsoebiOptions({ ...asoebiOptions, email_address_for_communication: parsedEventDetails.email,
                whatsapp_number: parsedEventDetails.phone_number});
        }
    }, []);

    function onSelectDateHandler(date: Date) {
        setAsoebiOptions((prev) => ({
            ...prev,
            order_deadline: new Date(date).toISOString(),
        }));
    }

    function modeOfCommunicationHandler(event: ChangeEvent<HTMLInputElement>) {
        setAsoebiOptions({
            ...asoebiOptions,
            [event?.target.name]: event.target.value
        });
    }

    function previewHandler() {
        if (!asoebiOptions.mode_of_communication) {
            return toast.error("Mode of communication is required");
        }
        if (asoebiOptions.mode_of_communication === "Email" && !asoebiOptions.email_address_for_communication) {
            return toast.error("Email address for communication is required");
        }
        if (asoebiOptions.mode_of_communication === "Whatsapp" && !asoebiOptions.whatsapp_number) {
            return toast.error("Whatsapp number for communication is required");
        }
        if (!asoebiOptions.order_deadline) {
            return toast.error("Order deadline date is required");
        }
        const unFilledDetailsArray = [];
        asoebiOptions.options.map(({ option, price, max_stock_qty }) => {
            if (!option) {
                unFilledDetailsArray.push(option);
            }

            if (!price) {
                unFilledDetailsArray.push(price);
            }
            if (max_stock_qty === 0 || !isNumber(max_stock_qty)) {
                unFilledDetailsArray.push(max_stock_qty);
            }

            if (max_stock_qty > 10000) {
                return toast.error("Maximum stock quantity is 10,000");
            }
        });

        if (unFilledDetailsArray.length > 0) {
            return toast.error("Ensure that each asoebi option and price are filled appropriately ");
        }
        if (selectedFiles.length === 0) {
            return toast.error("Please add at least one asoebi image");
        }
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.asoebi_options, JSON.stringify(asoebiOptions));
        onPreviewHandler();
    }

    function updateAsoebiOptionsHandler(name: string, value: string | boolean) {
        setAsoebiOptions((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    function updateOptionsHandler(selectedOptions: AsoebiOptionType) {
        setAsoebiOptions((prev) => ({
            ...prev,
            options: selectedOptions,
        }));
    }

    const defaultDate = asoebiOptions.order_deadline ? new Date(asoebiOptions.order_deadline) : null;
    const placeholder = asoebiOptions.currency.includes("Dollar") ? "$": "₦";

    return (
        <section className="guest_questionaire tracker_content">
            <h1 className="text-center my-2">
				What details do you want to collect from your <span>guests</span>
            </h1>
            <h4 className="text-lg my-2">Select the ones you want to add</h4>
            <div className="w-full px-0 col-lg-8 col-md-11 col-12 mx-auto">
                <h6 className="font-bold mb-4 mt-4">B. Asoebi Options</h6>

                <div className="complete_questionnaire">
                    <RadioGroup
                        name="mode_of_communication"
                        value={asoebiOptions.mode_of_communication}
                        className="col-md-8 col-12  px-0"
                        label="Mode of communication"
                        options={modeOfCommunicationOptns}
                        onClickHandler={updateAsoebiOptionsHandler}
                    />
                    {asoebiOptions.mode_of_communication === "Email" &&
                    <Input
                        input={emailAddressInput}
                        value={asoebiOptions.email_address_for_communication}
                        type="email"
                        onChange={modeOfCommunicationHandler}
                    />}
                    {asoebiOptions.mode_of_communication === "Whatsapp" &&
                    <Input
                        input={whatsappInput}
                        value={asoebiOptions.whatsapp_number}
                        type="text"
                        onChange={modeOfCommunicationHandler}
                    />}
                    <div>
                        <SelectDateInput
                            onClick={onSelectDateHandler}
                            minDate={today}
                            value={defaultDate}
                            placeholder="What date will you like to stop taking orders?"
                        />
                        <p className="mb-0 pb-0 small pt-1">This form will be deactivated once the deadline elapses.</p>
                    </div>
                    <Checkbox
                        name="special_note"
                        label="Allow guest to write you special notes as regards the order?"
                        active={asoebiOptions.special_note}
                        onClickHandler={updateAsoebiOptionsHandler}
                    />
                    {orderFormType === AsoebiFormType.tracker && <RadioGroup
                        name="currency"
                        value={asoebiOptions.currency}
                        className="col-md-8 col-12  px-0"
                        label="Currency"
                        options={currenyOptions}
                        onClickHandler={updateAsoebiOptionsHandler}
                    />}
                    <SelectInputGroup
                        value={asoebiOptions.options}
                        placeholder={placeholder}
                        onClickHandler={updateOptionsHandler} />
                    <div>
                        <h6 className="font-weight-normal">Upload Asoebi Media(s)</h6>
                        {children}
                    </div>
                    <button className="btn btn-primary mx-auto my-4 font-weight-bold" onClick={previewHandler}>
						Preview Asoebi order form
                    </button>
                </div>
            </div>
        </section>
    );
}
