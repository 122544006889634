import { Helmet } from "react-helmet";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useRef, useState, type PropsWithChildren } from "react";
import { usePaystackPayment } from "react-paystack";

import NewDashboardSidebar from "@/components/NewDashboardSidebar";
import ChevronDownIcon from "@/assets/icon/ChevronDownIcon";
import UserRoundIcon from "@/assets/icon/UserRoundIcon";
import ChevronLeftIcon from "@/assets/icon/ChevronLeftIcon";
import ChevronRightIcon from "@/assets/icon/ChevronRightIcon";
import { getEvents, getUserDetails } from "@/axios/get-request";
import getFirstChar from "@/utils/getFirstChar";
import useClickOutside from "@/hooks/useClickOutside";
import LogoutIcon from "@/assets/icon/LogoutIcon";
import SettingsCogIcon from "@/assets/icon/SettingsCogIcon";
import useAuth from "@/hooks/useAuth";
import { useEvent } from "@/hooks/useEvent";
import smallLogo from "@/assets/img/planaday_logo_small.png";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import MenuIcon from "@/assets/icon/MenuIcon";
import useFloorPlan from "@/hooks/useFloorPlan";
import { useBudget } from "@/hooks/useBudget";
import InfoBanner from "@/components/InfoBanner";
import { payStackSubscriptionConfig } from "@/payStackConfig";
import { subscribeToPlan } from "@/axios/post-request";
import { initializePaymentType } from "@/data/types";
import EventSwitcher from "@/components/EventSwitcher";
import "@/styles/dashboard_new.scss";

interface Props {
    className?: string;
    title: string;
    description?: string;
    onUpdateEventHandler?: (eventIndex: number) => void;
}

export default function NewDashboardLayout({ title, description, children, onUpdateEventHandler, className = "" }: PropsWithChildren<Props>) {
    const [activeEventIndex, setActiveIndex] = useState<number | null>(null);
    const { activeCeremonyId, setActiveCeremonyId, selectedEventId, setSelectedEventId } = useEvent();
    const { data: __eventsData } = useQuery({
        queryKey: [`get_event_${selectedEventId}`],
        queryFn: () => getEvents(),
    });
    const eventsData = __eventsData?.data;
    const { setBudget, setBudgetCategoryId } = useBudget();
    const { toggleSidebar } = useFloorPlan();
    const navigate = useNavigate();
    const location = useLocation();
    const mobile = useMediaQuery("(max-width: 768px)");
    const { signUserOut } = useAuth();
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const activeEvent = selectedEventId && eventsData?.result ? eventsData?.result.filter((item) => item.id === selectedEventId) : null;
    const { data, status } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => getUserDetails(),
    });
    const ref = useRef(null);
    const queryClient = useQueryClient();
    useClickOutside(ref, closeDropdown);

    function updateBudgetCategory() {
        if (activeEvent && activeEvent[0]?.budget && activeEvent[0]?.budget?.categories[0]?.id) {
            setBudget(activeEvent[0]?.budget);
            setBudgetCategoryId(activeEvent[0]?.budget?.categories[0]?.id);
        }
    }

    function selectDefaultCeremony() {
        if (activeEvent && activeEvent[0]?.ceremonies && Array.isArray(activeEvent[0]?.ceremonies) && activeEvent[0]?.ceremonies.length > 0) {
            setActiveCeremonyId(activeEvent[0]?.ceremonies[0].id);
        }
    }

    useEffect(() => {
        if (activeEventIndex === null && selectedEventId && eventsData?.result) {
            const eventIndex = eventsData?.result?.findIndex((item) => item.id === selectedEventId);
            setActiveIndex(eventIndex);
        }
        if (eventsData?.result && activeEventIndex !== null && !selectedEventId) {
            const userEventId = eventsData.result[activeEventIndex]?.id;
            setSelectedEventId(userEventId);
            queryClient.invalidateQueries({ queryKey: [`get_budget_categories_${userEventId}`, `ceremony_${activeCeremonyId}`, "get_all_events"] });
        }
    }, [eventsData]);

    function closeDropdown() {
        setShowProfileDropdown(false);
    }

    function goBackHandler() {
        if (location.pathname.includes("dashboard")) {
            return navigate(-1);
        }
    }

    function nextHandler() {
        if (location.pathname.includes("dashboard")) {
            return navigate(+1);
        }
    }

    const config = payStackSubscriptionConfig(data?.result?.email as string);
    const initializePayment = usePaystackPayment(config) as initializePaymentType;

    async function makePaymentHandler() {
        try {
            initializePayment(() => {
                return subscribeToPlanHandler();
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    async function subscribeToPlanHandler() {
        const currentDate = new Date();
        /**
         * default subscription date is October 2,
         */
        // const defaultDate = set(currentDate, { year: 2024, month: 9, date: 2 });

        // const subscriptionStartDate = currentDate > defaultDate ? currentDate : defaultDate;

        await subscribeToPlan({
            email: data?.result?.email as string,
            startDate: currentDate.toISOString(),
            // startDate: subscriptionStartDate.toISOString(),
        });
    }

    // const showFloorPlan = data?.result?.user_type && data?.result?.user_type === UserList.planner ? true : false;
    const userName = data?.result?.name ? data?.result?.name : null;
    const businessName = data?.result?.business_name ? data?.result?.business_name : null;
    const name = userName || businessName;

    const showBanner = status === "success" && data?.result && !data?.result?.subscription_details;
    const showBannerClassName = showBanner ? "banner_info" : "";

    return (
        <>
            <Helmet>
                <title>{title} - Planaday | Effortless Event Planning & Management</title>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta
                    name="description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta
                    name="keywords"
                    content="nigerian wedding, asoebi, ankara, lagos wedding, wedding budget, wedding cost, Planaday, plan an event, wedding planning,
                    event planning, event budgeting, event manager, event planner, wedding planner, planning assistant"
                />
                <meta property="og:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    property="og:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta property="og:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta property="og:url" content="https://www.planaday.events" />
                <meta property="og:site_name" content="Planaday Events" />
                <meta property="og:type" content="website" />
                <meta property="og:type" content="Planaday Events" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Planaday | Effortless Event Planning & Management" />
                <meta
                    name="twitter:description"
                    content="Your go-to for easy, stress-free event planning. Discover tools, resources, and vendors in one seamless platform"
                />
                <meta name="twitter:image" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:image:alt" content="https://www.planaday.events/img/planaday.webp" />
                <meta name="twitter:site" content="@Planaday_events" />
                <meta name="twitter:creator" content="@Planaday_events" />
            </Helmet>
            {showBanner && (
                <InfoBanner
                    className="new_dashboard"
                    text="You're currently in free trial mode, and a subscription will be required starting October 2">
                    {data?.result?.email && (
                        <button className="mx-3" onClick={makePaymentHandler}>
                            Subscribe
                        </button>
                    )}
                </InfoBanner>
            )}
            <div className={`dashboard_layout ${showBannerClassName}`}>
                <NewDashboardSidebar showFloorPlan={true} selectDefaultCeremony={selectDefaultCeremony} updateBudgetCategory={updateBudgetCategory}>
                    <EventSwitcher onUpdateEventHandler={onUpdateEventHandler} />
                </NewDashboardSidebar>
                <div className={`dashboard_content ${className}`}>
                    <header className="dashboard_header bg-white d-flex">
                        {mobile && (
                            <div className="header_button_group">
                                <button onClick={toggleSidebar}>
                                    <MenuIcon />
                                </button>
                                <Link to="/dashboard" className="logo">
                                    <img src={smallLogo} alt="Planaday logo" className="logo-img" />
                                </Link>
                            </div>
                        )}
                        <div className="header_left">
                            <div className="button_group">
                                <button type="button" onClick={goBackHandler}>
                                    <ChevronLeftIcon />
                                </button>
                                <button type="button" onClick={nextHandler}>
                                    <ChevronRightIcon />
                                </button>
                            </div>
                            <div>
                                <h3>{title}</h3>
                                {description && <p>{description}</p>}
                            </div>
                        </div>
                        <div className="header_right">
                            {/* <div className="search_wrapper">
                                <Search fill="#828282" />
                                <input placeholder="Search" />
                            </div> */}
                            <div className="control_group" onClick={() => setShowProfileDropdown((prev) => !prev)}>
                                {/* <button>
                                    <AlarmIcon />
                                </button> */}
                                <span>{name ? getFirstChar(name) : <UserRoundIcon />}</span>
                                <button>
                                    <ChevronDownIcon />
                                </button>
                                {showProfileDropdown && (
                                    <div ref={ref} className="control_group_dropdown">
                                        <Link to="/dashboard/settings">
                                            <UserRoundIcon /> View Profile
                                        </Link>
                                        <Link to="/dashboard/settings/account">
                                            <SettingsCogIcon /> Account Settings
                                        </Link>
                                        <button className="logout" onClick={() => signUserOut()}>
                                            <span>
                                                <LogoutIcon /> Log out
                                            </span>
                                            <span className="app_version">v2.1</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </header>
                    <main className={`dashboard_main ${className}`}>
                        <div className="d-flex dashboard_layout_wrapper">
                            <div className="dashboard_content">{children}</div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
