import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import WalletIcon from "@/assets/icon/WalletIcon";
import { deleteBudgetCategory } from "@/axios/delete-request";
import DashboardModal from "@/components/modals/DashboardModal";
import { useEvent } from "@/hooks/useEvent";
import Spinner from "@/components/Spinner";
import { useBudget } from "@/hooks/useBudget";

interface Props {
	onClose: () => void;
	categoryDetails: {
		id: string;
		name: string;
	};
}

export default function DeleteEventBudgetCategoryModal({ onClose, categoryDetails }: Props) {
    const { selectedEventId, activeCeremonyId } = useEvent();
    const [loading, setLoading] = useState(false);
    const { deleteBudgetCategory: deleteEventBudgetCategory } = useBudget();
    const queryClient = useQueryClient();

    async function deleteBudgetCategoryHandler() {
        try {
            if (!selectedEventId) {
                return toast.error("Please select an event");
            }
            if (!categoryDetails?.id || !categoryDetails?.name) {
                return toast.error("Please select a category");
            }
            setLoading(true);
            const deleteCategoryResponse = await deleteBudgetCategory(categoryDetails.id, selectedEventId);
            setLoading(false);
            if (!deleteCategoryResponse.data.status) {
                return toast.error(deleteCategoryResponse.data.message ?? "Oops unable to delete category");
            }
            deleteEventBudgetCategory(categoryDetails.id);
            queryClient.resetQueries({ queryKey: [`get_budget_categories_${selectedEventId}_${activeCeremonyId}`] });
            queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
            onClose();
            return toast.success("Category deleted");
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }

    return (
        <DashboardModal showHeader={false} className="small_width delete_category_cost_item_modal" onClose={onClose}>
            <section className="delete_category_cost_item">
                <div className="icon_wrapper">
                    <WalletIcon />
                </div>
                <h4>Are you sure you want to delete {categoryDetails?.name}&#39;s category?</h4>

                <button onClick={deleteBudgetCategoryHandler}>
					Delete Category
                    {loading && <Spinner className="ml-2" size={20} />}
                </button>
            </section>
        </DashboardModal>
    );
}
