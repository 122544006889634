import CardLoader from "@/components/loaders/CardLoader";
import "@/styles/loader.scss";

export default function BudgetTrackerLoader() {
    const loaderArray = new Array(5).fill(0);
    return (
        <div className="budget_tracker_loader">
            <div className="card_loader d-flex align-items-center">
                <CardLoader height={120} width={500} className="mr-2 card_event_overview" />
                <CardLoader height={120} width={500} className="ml-2 card_event_overview" />
            </div>
            <CardLoader height={80} width="100%" className="my-4 card_event_overview" />
            <div className="card_group_view d-flex">
                <div className="card_group d-flex flex-column">
                    {loaderArray.map((_, index) => (
                        <CardLoader key={index} height={60} width={400} className={`card_${index}`} />
                    ))}
                </div>
                <CardLoader height={400} width={500} className="card_event_overview" />
            </div>
        </div>
    );
}
