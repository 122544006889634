interface Props {
  name: string;
  selectedChecklist: string;
  addChecklist: (name: string) => void;
}
export default function SuggestedChecklistItem({
    name,
    selectedChecklist,
    addChecklist,
}: Props) {
    function updateChecklistItem() {
        addChecklist(name);
    }

    return (
        <div className="suggested_checklist_item d-flex align-items-center justify-content-between">
            <p className="text">{name}</p>
            <input
                type="radio"
                checked={selectedChecklist === name}
                name={name}
                onClick={updateChecklistItem}
                readOnly
            />
        </div>
    );
}
