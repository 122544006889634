import { format } from "date-fns";
import { useState } from "react";

import type { auditType, budgetTrackerAuditChanges, costItemType } from "@/types";
import arrowdown from "@/assets/img/arrow_chevron_down.svg";
import arrowUp from "@/assets/img/arrow-chevron_up.svg";
import { toUnderscore } from "@/utils/toSlug";

interface Props {
    audit: auditType[0];
    showType: boolean;
}

const trackBudgetTrackerChangesArray = ["Name", "Balance", "Paid", "Actual", "Estimate", "Vendor Name", "Vendor IG", "Note", "Balance due"];

export default function BudgetTrackerAuditDropdown({ audit, showType }: Props) {
    const [dropdown, setDropdown] = useState(false);
    const auditChanges = audit?.changes as budgetTrackerAuditChanges;
    const _audit_date = new Date(audit.timestamp);
    const audit_date = format(_audit_date, "dd-MM-yyyy, KK:mm aa");

    function toggleDropdown() {
        setDropdown((prev) => !prev);
    }

    const arrow_image_src = dropdown ? arrowUp : arrowdown;
    const auditClassname = !dropdown ? "my-3" : "";

    function displayChanges(title: string, key: keyof costItemType) {
        const currency = title === "Paid" || title === "Actual" || title === "Estimate" || title === "Balance" ? "₦" : "";
        const balance_due_date_from = title === "Balance due" && auditChanges?.from?.balance_due ?
            format(new Date(auditChanges?.from?.balance_due), "dd-MM-yyyy") : "nill";

        const balance_due_date_to = title === "Balance due" && auditChanges?.to?.balance_due ?
            format(new Date(auditChanges?.to?.balance_due), "dd-MM-yyyy") : "nil";

        const emptyText = currency ? "0" : "nill";

        return (auditChanges?.from && auditChanges?.from[key]) || (auditChanges?.to && auditChanges?.to[key]) ? (
            <p key={key} className="mb-1">
                {title}:{" "}
                {auditChanges?.from && auditChanges?.to && auditChanges?.from[key] !== auditChanges?.to[key] && (
                    <span className="underline mr-2">
                        {title === "Balance due" ? balance_due_date_from : <>
                            {currency}
                            {auditChanges?.from[key] ? auditChanges?.from[key]?.toLocaleString("en-US") : emptyText}
                        </>}
                    </span>
                )}
                {auditChanges?.to && (
                    <span>
                        {title === "Balance due" ? balance_due_date_to :<>{currency}
                            {auditChanges?.to[key]?.toLocaleString("en-US")}</>}
                    </span>
                )}
            </p>
        ) : (
            ""
        );
    }

    const costItemText = audit?.metadata?.category ? `deleted from ${audit?.metadata?.category} category` : "deleted";
    const costItemactionText = audit.action === "delete_budget_cost_item" ? costItemText : "updated";
    const noOfCostItemText = audit.metadata?.noOfCostItem && Number(audit.metadata?.noOfCostItem) > 1 ? "cost items" : "cost item";
    const categoryText = audit?.metadata?.noOfCostItem ?
        `with ${audit.metadata?.noOfCostItem} ${noOfCostItemText} was deleted` :
        "was deleted";
    const categoryActionText = audit.action === "delete_budget_cost_item_category" ? categoryText : "updated";

    const actionText =
        audit.action === "delete_budget_cost_item" ?
            costItemactionText :
            audit.action === "delete_budget_cost_item_category" ?
                categoryActionText :
                "updated";

    const auditTypeClassname = showType ? "show_type" : "";

    const categoryTextPrefix = audit?.metadata?.category_name ? "in " : "";
    const categoryTextSuffix = audit?.metadata?.category_name ? "category" : "";

    return (
        <>
            <div
                className={`${auditClassname} ${auditTypeClassname} audit_list_card 
            bg-white p-3 rounded justify-content-between d-flex align-items-lg-center`}>
                <div className="audit_details">
                    {audit.action === "add_budget_cost_item_category" ? (
                        <>
                            <span className="font-weight-bold mr-1">{audit.title}</span>
                            category was added by {audit?.name ?? audit.email} at {audit_date}
                        </>
                    ) : audit.action === "delete_budget_cost_item_category" ?
                        <>
                            <span className="font-weight-bold mr-1">{audit.title}</span>
                            category {actionText} by {audit?.name ?? audit.email} at {audit_date}
                        </> :(
                            <>
                                <span className="font-weight-bold mr-1">{audit.title}</span>
                            cost item {categoryTextPrefix}
                                {audit?.metadata?.category_name ? <span>{audit?.metadata?.category_name} </span> : ""} {categoryTextSuffix} was
                                {" "}{actionText} by {audit?.name ?? audit.email} at {audit_date}
                            </>
                        )}
                </div>
                <div className="audit_type_group">
                    {showType && <div className="type">{audit.type ? "Budget Tracker" : ""}</div>}

                    {audit.action === "update_budget_cost_item" && (
                        <button className="border-0 bg-transparent" onClick={toggleDropdown}>
                            <img src={arrow_image_src} alt="dropdown icon" />
                        </button>
                    )}
                </div>
            </div>
            {dropdown && (
                <div className="dropdown checklist_dropdown bg-white px-3 pb-2 mb-3">
                    {trackBudgetTrackerChangesArray.map((item) => {
                        const itemSlug = toUnderscore(item) as keyof costItemType;
                        return displayChanges(item, itemSlug);
                    })}
                </div>
            )}
        </>
    );
}
