import { Pricing } from "./data/types";
import { PAYSTACK_CURRENCY_CONSTANT } from "./utils/constant";

type metaDataConfigType = {
    metadata: {
        custom_fields: Array<{
            display_name: string;
            variable_name: string;
            value: string;
        }>;
    };
};

export const subscriptionMetadata = {
    metadata: {
        custom_fields: [
            {
                display_name: "description",
                variable_name: "description",
                value: "You'll be charged the sum of N50.00 to check if your account is valid, this will be refunded",
            },
        ],
    },
};

export default function payStackConfig(email: string, amount: number, metaDataConfig?: metaDataConfigType) {
    const paystackMetadata = metaDataConfig ? metaDataConfig : {};
    const config = {
        reference: new Date().getTime().toString(),
        email,
        amount: amount * PAYSTACK_CURRENCY_CONSTANT, // Amount is in Kobo, so 20000 kobo = N200 , 100 kobo = N1
        publicKey: `${process.env.REACT_APP_PAYSTACK_PUBLIC_KEY}`,
        ...paystackMetadata,
    };

    return config;
}


export function payStackSubscriptionConfig(email: string) {
    const config = {
        reference: new Date().getTime().toString(),
        email,
        amount: Pricing.DefaultPlan * PAYSTACK_CURRENCY_CONSTANT, // Amount is in Kobo, so 20000 kobo = N200 , 100 kobo = N1
        publicKey: `${process.env.REACT_APP_PAYSTACK_PUBLIC_KEY}`,
        plan: `${process.env.REACT_APP_PLANADAY_PLAN_ID}`,
    };

    return config;
}
