import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import heartIcon from "@/assets/img/heart3.svg";
import { getQuotes } from "@/axios/get-request";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";

export default function Quotes() {
    const [quoteIndex, setQuoteIndex] = useState(0);
    const { data, status } = useQuery({
        queryKey: ["get_quotes"],
        queryFn: () => getQuotes(),
    });

    useEffect(() => {
        const _sessionQuoteIndex = window.sessionStorage.getItem(LOCAL_STORAGE_KEYS.randomQuoteIndex);
        const sessionQuoteIndex = Number(_sessionQuoteIndex);
        setQuoteIndex(sessionQuoteIndex);
    }, []);

    const quoteText =
        status === "success" ?
            data?.data?.result[quoteIndex].includes("&#39;") ?
                data?.data?.result[quoteIndex].replaceAll("&#39;", "'") :
                data?.data?.result[quoteIndex] :
            "";

    return (
        <div className="card quotes">
            <img src={heartIcon} alt="heart icon" />
            <h5>〝Quote of the Day〞</h5>
            <p className="mt-5">{status === "error" ? "unable to fetch quotes" : status === "loading" ? "fetching quotes..." : quoteText}</p>
        </div>
    );
}
