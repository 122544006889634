import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import type { PropsWithChildren } from "react";

import closeIcon from "@/assets/img/close.svg";

interface Props {
	title?: string;
	show?: boolean;
	onHide?: () => void;
	children: JSX.Element;
	className?: string;
	backdropClassName?: string;
}

/**
 *
 * @param title
 * @param show
 * @param onHide
 * @param className
 * @param children
 * @param backdropClassName
 * @returns
 */

export default function SiteModal({ title, show, onHide, children, className = "", backdropClassName = "" }: PropsWithChildren<Props>) {
    return (
        <Modal show={show} onHide={onHide} backdropClassName={backdropClassName} className={`px-lg-4 px-2 ${className}`}>
            {title && (
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    {onHide && (
                        <Button onClick={onHide} variant="">
                            <img src={closeIcon} className="close_icon" alt="close modal" />
                        </Button>
                    )}
                </Modal.Header>
            )}
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
}
