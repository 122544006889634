import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import SiteModal from "@/components/modals/SiteModal";
import Button from "@/components/Button";
import Checkbox from "@/components/form/Checkbox";
import { getCeremonies, getUserDetails } from "@/axios/get-request";
import { useFilter } from "@/hooks/useFilter";
import CeremonyLoader from "@/components/loaders/CeremonyLoader";
import { useEvent } from "@/hooks/useEvent";
import type { CeremonyType } from "@/types";

interface Props {
    show: boolean;
    onHide: () => void;
}

export default function FilterModal({ show, onHide }: Props) {
    const queryClient = useQueryClient();
    const { ceremony, setCeremony } = useFilter();
    const { selectedEventId } = useEvent();
    const location = useLocation();
    const fCeremony = ceremony as { name: string; id: string };
    const [eventCeremony, setEventCeremony] = useState({
        name: fCeremony.name,
        id: fCeremony.id,
    });
    const { mutate, isLoading } = useMutation({
        mutationKey: ["fetch_ceremony_details"],
        mutationFn: () => {
            return getUserDetails();
        },
        onSuccess: () => {
            setCeremony({
                ...eventCeremony,
            });
            queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
            onHide();
        },
    });
    const { data, status } = useQuery({
        queryKey: [`fetch_ceremonies_${selectedEventId}`],
        queryFn: () => getCeremonies(selectedEventId),
    });

    function onFilter(__ceremony: CeremonyType[0]) {
        setEventCeremony({
            name: __ceremony.name ? __ceremony.name : "Main Wedding",
            id: __ceremony.id as string,
        });
    }

    function selectAllFilter() {
        setEventCeremony({
            name: "All Ceremonies",
            id: "all",
        });
    }

    return (
        <SiteModal title="Filter" show={show} onHide={onHide}>
            <section className="filter_modal pb-4 pb-md-0">
                <p className="mb-5 mt-2">Filter by ceremony. You can only filter by one ceremony at a time.</p>
                <>
                    {status === "error" ? (
                        <p>Error unable to fetch ceremonies</p>
                    ) : status === "loading" ? (
                        <CeremonyLoader width="100%" height={40} className="mb-2" />
                    ) : data?.result ? (
                        <form>
                            <h4>Ceremony</h4>
                            {location.pathname !== "/dashboard" && (
                                <Checkbox
                                    input={{
                                        label: "All Ceremonies",
                                        name: "All",
                                    }}
                                    checked={eventCeremony?.id === "all"}
                                    className="d-flex filter_checkbox"
                                    onChange={selectAllFilter}
                                />
                            )}
                            {data.result.map((_ceremony: CeremonyType[0]) => (
                                <Checkbox
                                    key={_ceremony.id}
                                    input={{
                                        label: _ceremony.name ? _ceremony.name : "Main Wedding",
                                        name: _ceremony.name ? _ceremony.name : "Main Wedding",
                                    }}
                                    checked={_ceremony.id === eventCeremony?.id}
                                    className="d-flex filter_checkbox"
                                    onChange={() => onFilter(_ceremony)}
                                />
                            ))}
                            <div className="d-flex justify-content-end mt-4">
                                <Button text="Apply" className="bg_red text-white" onClick={mutate} isLoading={isLoading} />
                            </div>
                        </form>
                    ) : (
                        <p>No ceremony yet, please add a ceremony</p>
                    )}
                </>
            </section>
        </SiteModal>
    );
}
