/* eslint-disable max-len */

export default function SofaIcon() {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-outside-1_11427_19935" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="15" fill="black">
                <rect fill="white" width="14" height="15" />
                <path d="M0 2C0 1.44772 0.447715 1 1 1H13C13.5523 1 14 1.44772 14 2V14C14 14.5523 13.5523 15 13 15H1C0.447716 15 0 14.5523 0 14V2Z" />
            </mask>
            <path
                d="M0 2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2C14 2 13.5523 2 13 2H1C0.447715 2 0 2 0 2ZM14 15H0H14ZM0 15V1V15ZM14 1V15V1Z"
                fill="#E845BA"
                mask="url(#path-1-outside-1_11427_19935)"
            />
            <path
                d="M1 15L13 15C13.5523 15 14 14.5523 14 14L14 2C14 1.44772 13.5523 1 13 1L0.999999 1C0.447715 1 -5.92389e-07 1.44772 -5.68248e-07 2L-4.37114e-08 14C-1.95703e-08 14.5523 0.447716 15 1 15Z"
                fill="#D9D9D9"
            />
            <path
                d="M13 14.9L1 14.9C0.502944 14.9 0.1 14.4971 0.1 14L0.0999994 2C0.0999994 1.50294 0.502943 1.1 0.999999 1.1L13 1.1C13.4971 1.1 13.9 1.50294 13.9 2L13.9 14C13.9 14.4971 13.4971 14.9 13 14.9Z"
                stroke="#545566"
                strokeOpacity="0.05"
                strokeWidth="0.2"
            />
        </svg>
    );
}
