interface Props {
  mode?: "white" | "dark";
}
export default function Loader({ mode = "white" }: Props) {
    return (
        <>
            <div className={`lds-ring ${mode}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
}
