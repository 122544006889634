interface Props {
fill?:string
}

export default function DownArrow({fill="#cb3050"}:Props) {
    return (
        <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_trac
            erCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                    d="M12 6V18M12 18L7 13M12 18L17 13"
                    stroke={fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"></path>{" "}
            </g>
        </svg>
    );
}
