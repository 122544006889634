import { useEffect } from "react";
import { driver as Driver } from "driver.js";
import "driver.js/dist/driver.css";

import tourGuide from "@/json/tour_guide.json";
import { updateTourGuide } from "@/axios/post-request";

type statusType = "success" | "error" | "loading";
type stepsType = "my_events" | "budget_tracker" | "checklist" | "profile";

export default function useTourGuide(status: statusType, steps: stepsType, showTourGuide: boolean | undefined, no_of_events: number) {
    useEffect(() => {
        if (status === "success" && showTourGuide === false && no_of_events <= 1 && process.env.NODE_ENV === "production") {
            const tourGuideData =
                steps === "my_events" ?
                    { event_overview: true } :
                    steps === "profile" ?
                        { user_profile: true } :
                        steps === "checklist" ?
                            { checklist: true } :
                            { budget_tracker: true };

            const driver = Driver({
                showProgress: true,
                smoothScroll: true,
                steps: tourGuide[steps],
                onCloseClick: () => {
                    updateTourGuide(tourGuideData);
                    driver.destroy();
                },
                onDestroyed: () => updateTourGuide(tourGuideData),
            });

            const timer = setTimeout(() => {
                driver.drive();
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [status, showTourGuide]);
}
