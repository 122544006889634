import { create } from "zustand";

type searchType = {
    search: string | null;
    searching: boolean;
    setSearch: (value: string | null) => void;
    setSearching: (value: boolean) => void;
};

export const useSearchChecklist = create<searchType>((set) => ({
    search: null,
    searching: false,
    setSearch: (value) => set(() => ({ search: value })),
    setSearching: (value) => set(() => ({ searching: value })),
}));
