import { toast } from "react-toastify";
import { ChangeEvent, FormEvent, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import DashboardModal from "@/components/modals/DashboardModal";
import Input2 from "@/components/form/Input2";
import CalendarIcon from "@/assets/icon/CalendarIcon";
import GalleryIcon from "@/assets/icon/GalleryIcon";
import onboardingFormContent from "@/json/form/onboarding_form.json";
import SelectDateInput from "@/components/form/SelectDateInput";
import formDetails from "@/json/create_event_form.json";
import { formatNumber } from "@/helper";
import { updateEventPictureRequest } from "@/axios/post-request";
import { editUserEventDetails } from "@/axios/put-request";
import Button from "@/components/Button";
import type { EventType, TodoType } from "@/types";
import NewCeremonyPicker from "../form/NewCeremonyPicker";

interface Props {
    onClose: () => void;
    event: EventType | null;
}
type CeremoniesType = Array<{
    id: string;
    name: string;
    location: string | null;
    no_of_guests: string | number | null;
    date: Date | null;
    checklist?: TodoType;
    image?: string;
    show_suggestion: boolean;
}>;

export default function EditEventModal({ onClose, event }: Props) {
    const eventId = event?.id as string;
    const eventCeremonies = event?.ceremonies ? event?.ceremonies : [];
    const [file, setFile] = useState<File | null>(null);
    const [previewEventImage, setPreviewEventImage] = useState<null | string>(null);
    const [eventName, setEventName] = useState(event?.event_name);
    const [ceremonyPickerOptions, setCeremonyPickerOptions] = useState(onboardingFormContent.ceremonyOptions);
    const [loading, setLoading] = useState(false);
    const [ceremonies, setCeremonies] = useState<CeremoniesType>(eventCeremonies);
    const queryClient = useQueryClient();

    function changePhotoHandler(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const url = URL.createObjectURL(file);
        setPreviewEventImage(url);
        setFile(file);
    }

    function updateInputHandler(event: ChangeEvent<HTMLInputElement>) {
        setEventName(event.target.value);
    }

    function updateCeremonyTypeHandler(__option: unknown, index: number) {
        const option = __option as { label: string; value: string; __isNew__?: boolean };
        const tempCeremonies = [...ceremonies];
        tempCeremonies[index] = {
            ...tempCeremonies[index],
            name: option?.label,
        };
        setCeremonies(tempCeremonies);
        if (option?.__isNew__) {
            setCeremonyPickerOptions([{ label: option?.label, value: option.value }, ...ceremonyPickerOptions]);
        }
    }

    function updateCeremonyHandler(event: ChangeEvent<HTMLInputElement>, index: number) {
        const updateCeremony = ceremonies.map((formDetails, idx: number) => {
            if (event.target.name === "no_of_guests" && index === idx) {
                const guest_no_limit = formatNumber(event.target.value);
                return {
                    ...formDetails,
                    no_of_guests: event.target.value ? guest_no_limit : "",
                };
            }
            if (index === idx) {
                return {
                    ...formDetails,
                    [event.target.name]: event.target.value,
                };
            } else return formDetails;
        });
        return setCeremonies(updateCeremony);
    }

    function updateCeremonyDateHandler(date: Date, index: number) {
        const updateCeremony = ceremonies.map((formDetails, idx: number) => {
            if (index === idx) {
                return {
                    ...formDetails,
                    date,
                };
            } else return formDetails;
        });
        return setCeremonies(updateCeremony);
    }

    async function onEditEventDetails(event: FormEvent<HTMLFormElement>) {
        try {
            event.preventDefault();
            if (!eventName) {
                return toast.error("Event name is required");
            }
            const ceremoniesTypeErrors = [];
            const ceremoniesDateErrors = [];
            const ceremoniesLocationErrors = [];
            const ceremoniesGuestErrors = [];

            if (ceremonies.length === 0) {
                return toast.error("Add at least one ceremony");
            }

            if (ceremonies.length > 0) {
                ceremonies.map((ceremony, index) => {
                    if (!ceremony.name) {
                        ceremoniesTypeErrors.push(index);
                    }
                    if (!ceremony.date) {
                        ceremoniesDateErrors.push(index);
                    }
                    if (!ceremony.location) {
                        ceremoniesLocationErrors.push(index);
                    }
                    if (!ceremony.no_of_guests) {
                        ceremoniesGuestErrors.push(index);
                    }
                });
                if (ceremoniesTypeErrors.length > 0) {
                    return toast.error("Ceremony name is required");
                }
                if (ceremoniesLocationErrors.length > 0) {
                    return toast.error("Ceremony location is required");
                }
                if (ceremoniesDateErrors.length > 0) {
                    return toast.error("Ceremony date is required");
                }
                if (ceremoniesGuestErrors.length > 0) {
                    return toast.error("Ceremony guest number is required");
                }
            }
            setLoading(true);
            // Update event
            await editUserEventDetails(eventId, {
                event_name: eventName,
                ceremonies,
            });

            // If event image is added upload event image
            if (file) {
                const eventPictureFormData = new FormData();
                eventPictureFormData.append("file", file);
                await updateEventPictureRequest(eventPictureFormData, eventId);
            }
            setLoading(false);
            toast.success("Event updated");
            queryClient.invalidateQueries();
            onClose();
        } catch (error) {
            setLoading(false);
            toast.error("Oops an error occured");
            console.log("error", error);
            onClose();
        }
    }

    return (
        <DashboardModal title="Edit Event" type="drawer" className="small_width create_new_event" onClose={onClose}>
            <form className="create_new_event_form edit_event_form" onSubmit={onEditEventDetails}>
                <div className="form_row">
                    <div className="input_group">
                        <div className="upload_image">
                            <input onChange={changePhotoHandler} type="file" accept="image/png, image/webp, image/jpg, image/jpeg" />{" "}
                            {previewEventImage ? <img src={previewEventImage} alt="event image" /> : <GalleryIcon />}
                        </div>
                        <Input2 input={formDetails.eventName} onChange={updateInputHandler} value={eventName} />
                    </div>
                </div>
                <hr />
                <div className="ceremony_section form_row">
                    <div className="section_title">
                        <div>
                            <CalendarIcon />
                            <h6>Ceremonies</h6>
                        </div>
                    </div>
                    <div className="ceremonies">
                        {ceremonies &&
                            ceremonies.map((ceremonyDetails, index: number) => {
                                const counter = index + 1;
                                return (
                                    <div key={index} className="ceremony_input">
                                        <div>
                                            <h6>Ceremony {counter}</h6>
                                            <div className="dropdown_wrapper input_group">
                                                <NewCeremonyPicker
                                                    value={{ label: ceremonyDetails.name }}
                                                    defaultValue={{ label: ceremonyDetails.name, value: ceremonyDetails.name }}
                                                    options={ceremonyPickerOptions}
                                                    onChange={(ceremony) => updateCeremonyTypeHandler(ceremony, index)}
                                                />
                                            </div>
                                        </div>
                                        <div className="input_group">
                                            <Input2
                                                className="location_input"
                                                input={formDetails.ceremony.location}
                                                value={ceremonyDetails.location as string}
                                                onChange={(event) => updateCeremonyHandler(event, index)}
                                                type="text"
                                            />
                                            <SelectDateInput
                                                className="ceremony_date"
                                                label={formDetails.ceremony.date.label}
                                                placeholder={formDetails.ceremony.date.placeholder}
                                                value={ceremonyDetails.date}
                                                containerClassName="input2"
                                                onClick={(date) => updateCeremonyDateHandler(date, index)}
                                            />
                                            <Input2
                                                className="select_input"
                                                input={formDetails.ceremony.guestNumber}
                                                value={ceremonyDetails.no_of_guests as number}
                                                onChange={(event) => updateCeremonyHandler(event, index)}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="submit_btn">
                    <Button type="submit" text="Update Event" className="btn bg_red" isLoading={loading} />
                </div>
            </form>
        </DashboardModal>
    );
}
