import ContentLoader from "react-content-loader";

interface Props {
  width: number | string;
  height: number;
  className?: string;
}

export default function CeremonyLoader({ height, width, className }: Props) {
    const dummyArray = Array(4).fill(0);
    return (
        <>
            {dummyArray.map((_, index) => <ContentLoader
                key={index}
                width={width}
                height={height}
                viewBox="0 0 100% 100%"
                backgroundColor="#cbbec0"
                foregroundColor="#dedede"
                className={className}>
                <rect x="0" y="0" rx="4" ry="4" width="30px" height="25px" />
                <rect x="45" y="0" rx="4" ry="4" width="80%" height="25px" />
            </ContentLoader>)}
        </>
    );
}
