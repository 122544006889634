import { useNavigate } from "react-router-dom";

import UserIcon from "@/assets/icon/UserIcon";
import SelectOption from "@/components/SelectOption";
import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import GroupUserListIcon from "@/assets/icon/GroupUserListIcon";

export default function OnboardingPage() {
    const navigate = useNavigate();

    function proceedToAuthPage(type: "event_planner" | "celebrant") {
        return navigate(`/auth?type=${type}&mode=login`);
    }
    return (
        <NewOnboardingLayout text="Ready to plan the" text2="perfect event?">
            <div className="content">
                <form>
                    <SelectOption
                        onClick={() => proceedToAuthPage("event_planner")}
                        icon={<GroupUserListIcon />}
                        title="Event Planner"
                        text="Multiple events to plan? This is for you"
                    />
                    <SelectOption
                        onClick={() => proceedToAuthPage("celebrant")}
                        icon={<UserIcon />}
                        title="Celebrant"
                        text="Planning an event, need organisation?"
                    />
                </form>
            </div>
        </NewOnboardingLayout>
    );
}
