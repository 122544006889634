import { useState, type ChangeEvent } from "react";

import eyeIcon from "@/assets/img/eye_icon.svg";
import closedEyeIcon from "@/assets/img/closed_eye.svg";
import naira from "@/assets/img/naira_currency.svg";
import "@/styles/form.scss";

interface Props {
	input: {
		label?: string;
		placeholder: string;
		name: string;
		type?: string;
	};
	className?: string;
	showCurrency?: boolean;
	value?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	readOnly?: boolean;
	maxLength?: number;
	required?: boolean;
	onFocus?: () => void;
	min?: number;
	error?: string;
	index?: number;
	isLink?: boolean;
	linkTitle?: string;
	testId?: string;
}
export default function PasswordInput2({
    input,
    onChange,
    className,
    error,
    showCurrency = false,
    value = "",
    readOnly = false,
    maxLength = undefined,
    required = false,
    index,
    isLink,
    linkTitle,
    testId,
    ...props
}: Props) {
    const [showPassword, setShowPassword] = useState(false);
    const inputClassname = className ? className : "";
    const htmlForText = index ? `${input.name}_${index}` : input.name;
    const testIdData = testId ? { "data-testid": testId } : {};

    function showPasswordHandler() {
        setShowPassword((prev) => !prev);
    }

    const passwordIcon = showPassword ? eyeIcon : closedEyeIcon;
    const inputType = showPassword ? "text" : "password";

    return (
        <div className="custom_input_view">
            {isLink && (
                <div className="link_bar">
                    <p>{linkTitle ? linkTitle : "https://"}</p>
                </div>
            )}
            <div className={`form_element input2 input ${inputClassname}`}>
                <input
                    {...props}
                    {...testIdData}
                    id={htmlForText}
                    name={input.name}
                    placeholder={input.placeholder}
                    className="form-control"
                    value={value}
                    type={inputType}
                    readOnly={readOnly}
                    onChange={onChange}
                    maxLength={maxLength}
                    required={required}
                />
                {input?.label && <label htmlFor={htmlForText}>{input?.label}</label>}
                {showCurrency && <img src={naira} alt="Naira" className="icon" />}
                {error && <p className="text-danger small">{error}</p>}
                {value?.length > 0 && (
                    <button type="button" className="password_icon" onClick={showPasswordHandler}>
                        <img src={passwordIcon} alt="eye" />
                    </button>
                )}
            </div>
        </div>
    );
}
