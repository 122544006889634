import DatePicker from "react-datepicker";
import { forwardRef, useEffect, useState } from "react";
import type { ForwardedRef } from "react";

import calendarIcon from "@/assets/img/calendar.svg";
import "@/styles/form.scss";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

interface Props {
    value?: string;
    onClick?: () => void;
    className?: string;
    itemPlaceholder?: string;
    defaultValue?: string | null;
}

const SelectDateButton = forwardRef(
    ({ value, onClick, className, defaultValue, itemPlaceholder = "" }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
        const _value = defaultValue ? defaultValue : value;
        const formattedDate = _value ? _value : "";
        // const placeholder = showPlaceholder ? 'Event Date' : '';
        return (
            <div className={`d-flex select_date_input align-items-center ${className}`}>
                <input className="form-control bg-white" value={formattedDate} placeholder={itemPlaceholder} onClick={onClick} readOnly />
                <button className="btn calender_icon_btn" onClick={onClick} ref={ref} type="button">
                    <img src={calendarIcon} alt="calendar" />
                </button>
            </div>
        );
    },
);

interface SelectDateProps {
    label?: string;
    className?: string;
    value?: Date | null;
    placeholder?: string;
    minDate?: Date | null
    onClick: (date: Date) => void;
    containerClassName?: string;
}

export default function SelectTimeInput({ className, value, label, placeholder, onClick, containerClassName = "" }: SelectDateProps) {
    const defaultDate = value ? new Date(value) : null;
    const [selectDate, setSelectDate] = useState<Date | null>(null);

    function onSelectDateHandler(date: Date) {
        setSelectDate(date);
        onClick(date);
    }

    useEffect(() => {
        if (!selectDate) {
            setSelectDate(defaultDate);
        }
    }, [defaultDate]);

    const __inputDate = selectDate ? selectDate : value;
    const inputDate = __inputDate ? format(__inputDate, "hh:mm aa") : __inputDate;

    return (
        <div className={`select_date_input_view  d-flex flex-column form_element ${containerClassName}`}>
            {label && <label className="label">{label}</label>}
            <DatePicker
                selected={selectDate}
                showTimeSelect
                dateFormat="h:mm aa"
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                onChange={onSelectDateHandler}
                customInput={<SelectDateButton className={className} itemPlaceholder={placeholder} defaultValue={inputDate} />}
            />
        </div>
    );
}
