import NewDashboardLayout from "@/layout/NewDashboardLayout";

export default function SupportPage() {
    return (
        <NewDashboardLayout title="Support">
            <div>
                <h1>Work in progress ⏱️</h1>
            </div>
        </NewDashboardLayout>
    );
}
