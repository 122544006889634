import AsoebiDashboardLayout from "@/layout/AsoebiDashboardLayout";

export default function AsoebiAdminList() {
    return (
        <AsoebiDashboardLayout>
            <section>
                <div className="w-full relative d-flex title_view">
                    <h1 className="text-center mx-auto">Admin list</h1>
                    <button className="btn btn-primary position-absolute font-weight-bold end-0">Create admin</button>
                </div>
            </section>
        </AsoebiDashboardLayout>
    );
}
