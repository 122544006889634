import { GA4 } from "react-ga4/types/ga4";
import { ScreenCategory, Action, Label, budgetForm } from "./data/types";
import ReactGA from "react-ga4";
// const path = require("path");
// require("dotenv").config({ path: path.join(__dirname, "../", ".env") });
const trackingId = process.env.REACT_APP_TRACKING_ID;

function InitializeReactGA(ReactGA: GA4) {
    if (trackingId) {
        ReactGA.initialize([
            {
                trackingId,
            },
        ]);
    }
}

const eventTrack = (category: string, action: string, label: string) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};

const budgetReport = (data: budgetForm) => {
    switch (data.user) {
    case "Bride":
        eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, Action.BRIDE_COUNT, Label.BUTTON);
        break;
    case "Groom":
        eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, Action.GROOM_COUNT, Label.BUTTON);
        break;
    case "Other":
        eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, Action.OTHER_GENDER_COUNT, Label.BUTTON);
        break;
    }

    switch (data.hasBudget) {
    case false:
        eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, Action.NO_BUDGET_COUNT, Label.BUTTON);
        break;
    case true:
        eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, Action.HAS_BUDGET_COUNT, Label.BUTTON);
        break;
    }
};

const socialMediaReport = async (val: string) => {
    switch (val) {
    case "facebook":
        eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, Action.FACEBOOK_SHARE, Label.BUTTON);
        break;
    case "twitter":
        eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, Action.TWITTER_SHARE, Label.BUTTON);
        break;
    case "whatsapp":
        eventTrack(ScreenCategory.BUDGET_FORM_SCREEN, Action.WHATSAPP_SHARE, Label.BUTTON);
        break;
    }
};
export { InitializeReactGA, eventTrack, budgetReport, socialMediaReport };
