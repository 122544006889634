import { create } from "zustand";

type useDownloadBudgetSubcribeType = {
    commSubscribed: boolean;
    setCommSubscribed: (value: boolean) => void;
};

export const useDownloadBudgetSubcribe = create<useDownloadBudgetSubcribeType>((set) => ({
    commSubscribed: false,
    setCommSubscribed: (value: boolean) => set(() => ({ commSubscribed: value })),
}));
