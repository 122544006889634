import { useState } from "react";
import CheckIcon from "../assets/icon/CheckIcon";

interface Props {
	options: string[];
	name: string;
	onClickHandler?: (name: string, value: string) => void;
	className?: string;
    containerClassName?: string;
    value:string;
    label:string;
}

export default function RadioGroup({ options, label, value, name, onClickHandler, className = "", containerClassName="" }: Props) {
    const [check, setCheck] = useState("");

    function onSelectHandler(option: string) {
        setCheck(option);
        if (onClickHandler) {
            onClickHandler(name, option);
        }
    }

    return (
        <div className={`radio_group ${containerClassName}`}>
            <label htmlFor={label}>{label}</label>
            <div className={className}>
                {options.map((option) => {
                    const checkFill = check === option || value === option ? "#cb3050" : "#B2BEB5";
                    const checkClassname = check === option || value===option ? "border_active" : "";

                    return (
                        <div
                            key={option}
                            className={`py-3 border ${checkClassname} w-full rounded-[6px] shadow-sm px-4 flex items-center justify-between`}
                            onClick={() => onSelectHandler(option)}>
                            {option}
                            <CheckIcon checkFill={checkFill} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
